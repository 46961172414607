import React, { useEffect, useState } from "react";
import slide1 from "../../../assets/images/blogs/Defi.png";
import { infographicDta } from "../../../demo-data/infographic-data";

const Details = () => {
  const [infographicDetailsData, setInfographicsDetailsData] = useState([]);
  useEffect(() => {
    let catagoryName = window.location.href.split("/");
    let slug = catagoryName[catagoryName.length - 1];
    let getDetails = infographicDta?.filter((_i) => _i?.slug == slug)[0];
    setInfographicsDetailsData(getDetails);
  }, []);
  return (
    <>
      <div class="text-center m-2 md:m-20 rounded-md">
        <div class="h-min overflow-hidden rounded-md">
          <img
            className="h-full w-full object-cover object-center"
            // class="hover:scale-125 transition-all duration-500 cursor-pointer"
            src={infographicDetailsData?.image}
            alt=""
          />
        </div>
        <h3 class="text-3xl py-4">{infographicDetailsData?.name}</h3>
        <span class="text-lg text-gray-700">
          {infographicDetailsData?.description}
        </span>
      </div>
    </>
  );
};

export default Details;
