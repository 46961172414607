import React, { useEffect } from "react";
import TailsOfStartupCard from "../../../components/common/tails-of-startup-card";
import {
  industryList,
  tosData,
} from "../../../demo-data/tails-of-startups-data";

const Details = () => {
  let catagoryName = window.location.href.split("/");
  console.log(catagoryName);
  const catagoryArry = tosData.filter(
    (_i) => _i.industry == catagoryName[catagoryName.length - 1]
  );
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        className="container mx-auto bg-gray-200 rounded-xl shadow border p-4 m-10 mb-0"
        data-aos="flip-up"
      >
        <p className="text-3xl text-gray-700 font-bold">
          {catagoryName[catagoryName.length - 1]}
        </p>
      </div>
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-4 mx-auto p-8 m-10">
        {catagoryArry.map((_) => (
          <div className="m-4" data-aos="zoom-in">
            <TailsOfStartupCard items={_} />
          </div>
        ))}
      </div>
    </>
  );
};

export default Details;
