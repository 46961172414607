import React, { useMemo, useState } from "react";
import CatagoryCards from "../common/catagory-card";
import { top4TOS } from "../../demo-data/home-data";
import useRedirect from "../../custom-hooks/common/useRedirect";
import { infographicDta } from "../../demo-data/infographic-data";

const products = [
  {
    id: 1,
    name: "Basic Tee",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
  {
    id: 1,
    name: "Basic Tee",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
  {
    id: 1,
    name: "Basic Tee",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
    imageAlt: "Front of men's Basic Tee in black.",
    price: "$35",
    color: "Black",
  },
];

const InfographicsSection = () => {
  const redirectTo = useRedirect();
  const redirectionHandler = (category = "") =>
    redirectTo(`infographics/${category}`);
  return (
    <div className="rounded-lg m-2 md:m-8 p-4 bg-gray-100">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl text-gray-700 font-semibold text-xl md:text-3xl">
          Infographics
        </h1>
        <p
          className="flex justify-center gap-2 content-center text-lg text-gray-700 font-semibold hover:text-green-500 fill-gray-700 hover:fill-green-500"
          onClick={() => redirectionHandler("")}
        >
          <span className="text-xs md:text-lg">View more</span>
          <svg
            className="w-3 md:w-6 h-4 md:h-6 text-xs md:text-lg"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
          </svg> */}
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        {infographicDta?.map((product, index) => (
          <div
            className="bg-slate-300 rounded-lg hover:shadow-2xl hover:shadow-green-600 hover:border-1 hover:border-green-700 cursor-pointer"
            data-aos="zoom-in"
            onClick={() => redirectionHandler(product?.slug)}
          >
            <div key={product?.slug} className="group relative">
              <div className="aspect-h-1 aspect-w-1 w-full h-48 overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                {/* <img
                src={product.imageSrc}
                alt={product.imageAlt}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              /> */}
                <img
                  class="hover:scale-125 transition-all duration-500 cursor-pointer h-auto w-full object-cover object-center lg:h-full lg:w-full"
                  src={product?.image}
                  alt={product?.slug}
                />
              </div>
              <div className="mt-2 flex justify-between  p-4">
                <div>
                  <h3 className="text-sm font-semibold text-gray-700">
                    {product?.name}
                  </h3>
                  {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                </div>
                {/* <p className="text-sm font-medium text-gray-900">{product.price}</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfographicsSection;
