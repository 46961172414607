import React, { useEffect, useMemo, useState } from "react";
import useRedirect from "../../../custom-hooks/common/useRedirect";
// import { blogsListData } from "../../../demo-data/blogs-data";
import { glossaryData } from "../../../demo-data/glosarry-data";

const colorsArray = [
  "bg-red-200",
  "bg-orange-200",
  "bg-amber-200",
  "bg-yellow-200",
  "bg-lime-200",
  "bg-green-200",
  "bg-emerald-200",
  "bg-teal-200",
  "bg-cyan-200",
  "bg-sky-200",
  "bg-blue-200",
  "bg-indigo-200",
  "bg-violet-200",
  "bg-purple-200",
  "bg-fuchsia-200",
  "bg-pink-200",
  "bg-rose-200",
];

const Listing = () => {
  const [selectedTab, setSelectedTab] = useState("startups");
  const [scrollTop, setScrollTop] = useState(0);
  const redirectTo = useRedirect();
  const redirectionHandler = (category = "") => redirectTo(`blog/${category}`);
  const glossaryTabList = Object.keys(glossaryData);
  const tabHandler = (tab) => setSelectedTab(tab);
  const filterGlossaryData = useMemo(() => {
    const originalData = glossaryData[selectedTab];

    // Initialize an empty object to store the organized data
    const organizedData = {};

    // Iterate through the original data
    originalData.forEach((item) => {
      // Get the first letter of the term
      const firstLetter = item.term.charAt(0).toUpperCase();

      // Check if the letter is already a key in organizedData
      if (!organizedData[firstLetter]) {
        // If not, create an array with the first item
        organizedData[firstLetter] = { data: [item] };
      } else {
        // If yes, push the item to the existing array
        organizedData[firstLetter].data.push(item);
      }
    });
    const resultArray = Object.keys(organizedData)
      .sort() // Sort the keys alphabetically
      .map((key) => ({
        letter: key,
        data: organizedData[key]?.data ?? [],
        // [key]: organizedData[key],
      }));
    return resultArray;
  }, [selectedTab]);
  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("selectedTab", scrollTop);

  return (
    <>
      <div
        className="container mx-auto bg-gray-200 rounded-xl shadow border p-4 m-10"
        data-aos="flip-up"
      >
        <p className="text-3xl text-gray-700 font-bold">Glossary</p>
      </div>
      <ul class="flex flex-wrap justify-center text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {glossaryTabList?.map((_tabItems) => (
          <li class="me-2" onClick={() => tabHandler(_tabItems)}>
            <a
              class={`${
                selectedTab == _tabItems
                  ? "inline-block px-4 py-3 text-white bg-blue-600 rounded-lg active"
                  : "inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-500 dark:hover:text-white"
              }`}
            >
              {_tabItems}
            </a>
          </li>
        ))}
      </ul>
      <ul class="flex flex-wrap justify-center text-sm font-medium text-center text-gray-500 dark:text-gray-400 mt-4">
        {filterGlossaryData?.map((glossary) => (
          <li
            class="me-2 mb-2"
            onClick={() => {
              // document
              //   .getElementById(`${selectedTab}_${glossary?.letter}`)
              //   .scrollIntoView({
              //     behavior: "smooth",
              //   });
              var scrollDiv = document.getElementById(
                `${selectedTab}_${glossary?.letter}`
              ).offsetTop;
              window.scrollTo({ top: scrollDiv - 200, behavior: "smooth" });
            }}
          >
            <a class="inline-block px-4 py-3 text-black bg-green-50 rounded-lg hover:bg-green-600 hover:text-white">
              {glossary?.letter}
            </a>
          </li>
        ))}
      </ul>

      <div className="container grid grid-cols-1 md:grid-cols-1 gap-4 mx-auto p-8">
        {filterGlossaryData?.map((glossary, index) => (
          <div data-aos="zoom-in" id={`${selectedTab}_${glossary?.letter}`}>
            <h2 className="text-3xl font-semibold text-gray-900 text-start line-clamp-2 mb-2">
              #{glossary?.letter}
            </h2>
            {glossary?.data?.map((_items, index) => (
              <div data-aos="zoom-in" className="mt-4" key={index + 1}>
                <h3 className="text-xl font-semibold text-gray-600 text-start line-clamp-2">
                  {_items?.term ?? ""}
                </h3>
                <p className="mt-2 text-sm text-gray-500 text-start">
                  {_items?.definition ?? ""}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
      <button
        className={`bg-red-900 rounded-full p-4 fixed bottom-8 right-8 animate-bounce ${
          scrollTop > 200 ? "" : "invisible"
        }`}
        onClick={scrollToTop}
      >
        <svg
          class="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13V1m0 0L1 5m4-4 4 4"
          />
        </svg>
      </button>
    </>
  );
};

export default Listing;
