export const glossaryData = {
  startups: [
    {
      id: 1,
      term: "Accelerator",
      definition:
        "An accelerator is a programme or organisation that helps early-stage startups grow and expand quickly in exchange for equity by offering coaching, cash, and resources.",
    },
    {
      id: 2,
      term: "Acquihire",
      definition:
        'The term "acquihire" describes the purchase of a company largely for its superb employees, frequently in the startup ecosystem.',
    },
    {
      id: 3,
      term: "Acquisition(business)",
      definition:
        "An acquisition is the process by which one firm buys or seizes control of another company, frequently to increase its market presence or product line.",
    },
    {
      id: 4,
      term: "Acquisition (customer)",
      definition:
        "Customer acquisition is the term used to describe the methods and actions a company use to attract new customers and grow its client base.",
    },
    {
      id: 5,
      term: "Angel Investor",
      definition:
        "An angel investor is a person who lends money to early-stage firms in exchange for equity ownership, frequently providing coaching and experience in addition to finance.",
    },
    {
      id: 6,
      term: "Annual Recurring Revenue (ARR)",
      definition:
        "Annual Recurring Revenue (ARR) is a crucial indicator for subscription-based firms since it shows the entire annual revenue from ongoing customer contracts or subscriptions.",
    },
    {
      id: 7,
      term: "Anti-dilution Clause",
      definition:
        "An anti-dilution clause safeguards investors from equity dilution by modifying their ownership interest if the company issues more shares at a lower valuation than the initial investment.",
    },
    {
      id: 8,
      term: "Attribution",
      definition:
        'The term "attribution" often refers to the process of determining and assessing the influence of various marketing channels and campaigns on user acquisition and revenue generation.',
    },
    {
      id: 9,
      term: "Attrition",
      definition:
        "Attrition is the gradual loss of customers or staff over time, frequently as a result of churn, natural turnover, or other circumstances.",
    },
    {
      id: 10,
      term: "BHAG",
      definition: `BHAG, which stands for "Big Hairy Audacious Goal," is a word frequently used in the startup community to indicate a very long-range, highly ambitious aim that inspires and encourages a company's stakeholders.`,
    },
    {
      id: 11,
      term: "Bootstrapping",
      definition:
        'In the startup environment, "bootstrapping" is the process of establishing and expanding a business without the help of outside capital and therefore depending on internal revenue and resources to achieve long-term success.',
    },
    {
      id: 12,
      term: "Bubble",
      definition:
        'In the context of startups, a "bubble" is often defined as a time of excessive market speculation and overvaluation during which the values of startup investments or assets diverge from their intrinsic value, potentially presaging a downturn or collapse.',
    },
    {
      id: 13,
      term: "Burn Rate",
      definition:
        "Burn rate, which is commonly calculated on a monthly basis, is the rate at which a startup or company is using its capital or funds to cover operating expenses.",
    },
    {
      id: 14,
      term: "Cap Table",
      definition:
        'The term "cap table," which stands for "capitalization table," refers to a document that describes the ownership structure of a business, including the equity stakes held by founders, investors, and other stakeholders. It frequently includes details on various share classes and their relative ownership percentages.',
    },
    {
      id: 15,
      term: "Capital",
      definition:
        "Capital refers to the financial resources, often in the form of cash or assets, that a company employs to fund its operations, invest in growth, and accomplish its business objectives.",
    },
    {
      id: 16,
      term: "Carried Interest",
      definition:
        'Carried interest, also shortened to "carry," is a portion of profits that venture capitalists or private equity investors receive in exchange for running and expanding an investment fund. Usually, it represents a portion of the returns on successful investments made by the fund.',
    },
    {
      id: 17,
      term: "Churn Rate",
      definition:
        "Churn rate, which is frequently measured monthly or annually, is the proportion of consumers or subscribers who discontinue using a service, cancel a subscription, or stop being engaged with a product during a given period.",
    },
    {
      id: 18,
      term: "Cliff",
      definition:
        'A "cliff" in startup equity is the amount of time an employee or founder must work for the business before being qualified for stock options or equity grants.',
    },
    {
      id: 19,
      term: "CMO (Chief Marketing Officer)",
      definition:
        "A CMO, or chief marketing officer, is a senior executive in responsible for managing a company's branding, marketing, and promotional initiatives in order to increase client base and income.",
    },
    {
      id: 20,
      term: "Content Marketing",
      definition:
        'A method known as "content marketing" focuses on creating and distributing worthwhile, pertinent, and consistent content to draw in and engage a target audience with the intention of fostering brand awareness and trust and, ultimately, encouraging profitable consumer actions.',
    },
    {
      id: 21,
      term: "Conversion Rate Optimisation (CRO)",
      definition:
        "Conversion rate optimisation (CRO) is the process of methodically enhancing a website, app, or marketing campaign to raise the proportion of users who complete desired actions, such as completing a purchase, signing up, or filling out a form.",
    },
    {
      id: 22,
      term: "Convertible Note",
      definition:
        "A convertible note is a kind of short-term loan instrument used to finance startups; it normally has a fixed interest rate and a maturity date. It may change into equity (ownership) in the business at a later date, typically when the firm receives a subsequent round of funding.",
    },
    {
      id: 23,
      term: "Cottage Business / Lifestyle Business",
      definition:
        "A cottage business, also known as a lifestyle business, is a small-scale entrepreneurial attempt that is often conducted by one or more people, frequently from their homes, and whose main objective is to sustain a pleasant lifestyle rather than pursuing large development or rapid growth.",
    },
    {
      id: 24,
      term: "CPO (Chief Product Officer)",
      definition:
        "A chief product officer (CPO) is the executive in charge of a company's strategic product initiatives. They direct the whole course of a brand's product, including features and how to express them.",
    },
    {
      id: 25,
      term: "Crowdfunding",
      definition:
        "Crowdfunding is a technique for obtaining small investments or contributions from a large number of people, generally through online platforms or social media, in exchange for rewards, equity, or other incentives.",
    },
    {
      id: 26,
      term: "Crowdsourcing",
      definition:
        "Crowdsourcing is the practise of collecting ideas, services, material, or solutions by asking for contributions from a sizable and frequently varied group of people, usually through online platforms, in order to tap into the collective wisdom and creativity of the crowd.",
    },
    {
      id: 27,
      term: "CTO (Chief Technology Officer)",
      definition:
        "A CTO, or chief technology officer, is a senior executive in charge of managing a company's technological strategy, innovation, and development activities, with a focus on coordinating technological endeavours with organisational objectives.",
    },
    {
      id: 28,
      term: "Customer Acquisition Cost (CAC)",
      definition:
        "Customer Acquisition Cost (CAC) is the overall cost incurred by a business to acquire a new customer. It is commonly determined by dividing all marketing and sales expenses by the number of new customers acquired during a given time period.",
    },
    {
      id: 29,
      term: "Customer Relationship Management (CRM)",
      definition:
        "CRM is a tool and approach used by organisations to track and manage communications with both current and potential clients. In order to increase sales, marketing, and customer service, it frequently entails using software tools and techniques to track interactions, optimise customer communication, and strengthen connections.",
    },
    {
      id: 30,
      term: "Data room",
      definition:
        "A data room, sometimes referred to as a virtual data room, is an online information database used for document storage and sharing. typically used to store records related to investment round due diligence.",
    },
    {
      id: 31,
      term: "Debt Capital",
      definition:
        "Debt capital is the term used to describe money that a business raises through borrowing, usually in the form of loans or bonds that must be repaid with interest over a set period of time. In contrast to equity financing, it allows businesses to raise money without giving up ownership interests.",
    },
    {
      id: 32,
      term: "Dilution",
      definition:
        "the decrease in existing shareholders' ownership stake when a business issues new shares, frequently during fundraising rounds.",
    },
    {
      id: 33,
      term: "Down Round",
      definition: `When a firm receives money at a lower valuation than its prior investment round, it is known as a "down round" and can indicate financial difficulties or a drop in the company's perceived worth.`,
    },
    {
      id: 34,
      term: "Drag-Along Rights / Drag-Along Clause",
      definition:
        "Drag-Along Clauses, often referred to as Drag-Along Rights, are clauses in a startup's shareholder agreement that permit majority shareholders to compel minority shareholders to participate in the sale of the company, assuring a unified sale process.",
    },
    {
      id: 35,
      term: "Due Dilligence",
      definition:
        "The process used by investors to determine whether a potential investment is viable and to confirm the accuracy of the startup's information.",
    },
    {
      id: 36,
      term: "Elevator pitch",
      definition: "a brief description of your company's operations.",
    },
    {
      id: 37,
      term: "Enterprise Investment Scheme (EIS)",
      definition:
        "The UK government's Enterprise Investment Scheme (EIS) assists younger, riskier businesses in raising capital by providing considerable tax breaks to investors. ",
    },
    {
      id: 38,
      term: "Equity",
      definition:
        "equity is used to describe ownership shares or ownership interests in the firm, which are sometimes expressed as stocks or shares and reflect a claim on the company's assets and earnings.",
    },
    {
      id: 39,
      term: "Equity Capital",
      definition:
        "Equity capital is funding for a startup or firm that is raised without the use of debt by giving investors ownership stakes in the company in exchange for their investments.",
    },
    {
      id: 40,
      term: "Equity Crowdfunding",
      definition:
        "Equity crowdfunding is the practise of raising money by collecting small investments from a large number of individuals in exchange for a tiny percentage of equity, typically through a third-party platform.",
    },
    {
      id: 41,
      term: "Exit Strategy",
      definition:
        "In the startup business, an exit strategy is a prearranged plan for how investors and founders want to get their money back or realise their investments, frequently through choices like merger, IPO (Initial Public Offering), or acquisition.",
    },
    {
      id: 42,
      term: "Founder",
      definition:
        "A founder is a person or group of people who create and launch a startup or business. They are frequently in charge of the venture's initial conception, conception, and early development.",
    },
    {
      id: 43,
      term: "Fundraising",
      definition:
        "The process of raising money by exchanging equity for outside investment is referred to as fundraising; it can also refer to alternate methods of acquiring money, such debt finance or crowdfunding.",
    },
    {
      id: 44,
      term: "Gamification",
      definition:
        "A startup can use gamification to add a game-layer to a product or service that motivates customers to execute a particular action in exchange for a reward.",
    },
    {
      id: 45,
      term: "Go-to-market Strategy (GTM)",
      definition:
        "A go-to-market strategy (GTM) is a detailed plan created to successfully introduce a good or service to the market.",
    },
    {
      id: 46,
      term: "Growth Hacking",
      definition:
        "Growth hacking is a type of startup marketing that focuses on quick and inexpensive ways to attract and keep customers, frequently using innovative and data-driven strategies to accelerate growth.",
    },
    {
      id: 47,
      term: "Hockey Stick",
      definition: `A "hockey stick" growth curve is a quick, sudden rise in a company's growth or revenue following a period of relatively modest progress that resembles the shape of a hockey stick.`,
    },
    {
      id: 48,
      term: "Incubator",
      definition:
        "An incubator is a company or programme that helps early-stage entrepreneurs grow and succeed by offering them resources, mentoring, and assistance, frequently in exchange for ownership or a part in the business.",
    },
    {
      id: 49,
      term: "Investment Syndicate",
      definition:
        "An investing syndicate is a group of individual investors or venture capital firms who pool their funds and resources to make a number of startup investments.",
    },
    {
      id: 50,
      term: "Landing Page",
      definition:
        "A landing page is a single web page created for a particular marketing or promotional campaign. Its primary goal is to persuade visitors to do a certain action, such as downloading an ebook, signing up for a newsletter, or making a purchase.",
    },
    {
      id: 51,
      term: "Lead Investor",
      definition:
        "A lead investor in a round of startup fundraising is often the first individual or venture capital firm to commit to making a sizeable investment. They frequently decide on the investment's terms and conditions and are essential in luring further investors into the round.",
    },
    {
      id: 52,
      term: "Lean Startup",
      definition:
        "A process used when starting a new firm or releasing a new product to swiftly and affordably validate a business proposition.",
    },
    {
      id: 53,
      term: "Lifetime Value (LTV)",
      definition:
        "Lifetime Value (LTV), which is frequently used to evaluate the long-term value of attracting and maintaining consumers, is a metric that measures the total revenue a business can expect to generate from a single customer or user throughout the course of their relationship with the organisation.",
    },
    {
      id: 54,
      term: "Market Penetration (Market Share)",
      definition:
        "The percentage of a total market that your startup will win as customers, within a given timefame.",
    },
    {
      id: 55,
      term: "Marketing Automation",
      definition:
        "The process of employing software to automate marketing tasks is known as marketing automation.",
    },
    {
      id: 56,
      term: "Marketing Dashboard / Marketing Intelligence Dashboard",
      definition:
        "A marketing dashboard, also known as a marketing intelligence dashboard, is a visual display or software tool that offers insights and data on various marketing metrics and key performance indicators (KPIs) periodically or in real-time. It assists businesses in tracking and analysing their marketing activities and in making strategic decisions.",
    },
    {
      id: 57,
      term: "Media Spend",
      definition:
        'The term "media spend" is the amount of money that a business allots or invests in several forms of advertising and marketing, such as television, digital advertising, print media, or social media, to market its goods and services.',
    },
    {
      id: 58,
      term: "Minimum Viable Product (MVP)",
      definition:
        "A minimum viable product (MVP) is an early customer-usable version of a product that is used to test a business or product concept. These clients subsequently offer comments for upcoming product development.",
    },
    {
      id: 59,
      term: "Moat (Economic Moat)",
      definition:
        'Any competitive advantage a company has over competitors that renders the business model "defensible" is known as an economic moat.',
    },
    {
      id: 60,
      term: "Monthly Recurring Revenue (MRR)",
      definition:
        "Monthly recurring revenue is regular money that a company gets every month.",
    },
    {
      id: 61,
      term: "Pay-per-click (PPC)",
      definition:
        "In the pay-per-click (PPC) model of internet advertising, advertisers are charged a fee each time a user clicks one of their ads. To direct visitors to a website or landing page, it is frequently used in search engine advertising, display advertising, and social media advertising.",
    },
    {
      id: 62,
      term: "Pitch Deck",
      definition:
        "Startups generally use a pitch deck, a succinct and aesthetically appealing presentation, to explain their business concept, value proposition, market opportunity, and financial projections to potential investors or stakeholders.",
    },
    {
      id: 63,
      term: "Pivot",
      definition:
        "The process of turning a bad product or service into a successful one using expertise and available resources.",
    },
    {
      id: 64,
      term: "Portfolio Company",
      definition:
        "A company that has obtained funding from a VC fund or an angel investor becomes one of the fund's or investor's portfolio companies.",
    },
    {
      id: 65,
      term: "Post-money Valuation",
      definition:
        "Post-money valuation refers to the projected value of a startup or company following the addition of outside funds and finance, such as contributions from investors or venture capitalists, to the balance sheet of the business. It displays the firm's valuation right after the investment round.",
    },
    {
      id: 66,
      term: "Pre-money Valuation",
      definition:
        "Pre-money valuation describes the worth of a startup excluding outside investment or funding. The value of a startup before receiving any funding.",
    },
    {
      id: 67,
      term: "Pre-Seed Round",
      definition:
        "Pre-seed rounds are the first round of fundraising for businesses, during which the founders look for an insignificant amount of money, frequently from family and friends or angel investors, to develop an idea, build a prototype, or conduct preliminary market research before pursuing larger investment rounds.",
    },
    {
      id: 68,
      term: "Private Equity (PE)",
      definition:
        "Private equity consists of capital that is invested directly in private businesses and is not listed on a public exchange.",
    },
    {
      id: 69,
      term: "Product Led Growth (PLG)",
      definition:
        "Product-Led Growth (PLG) is a business approach in which the product itself serves as the main engine for gaining, keeping, and growing a customer base. In PLG, the product is made to be simple to use, self-serve, and easy to adopt. This enables users to rapidly see its value and frequently results in viral growth.",
    },
    {
      id: 70,
      term: "Product Manager",
      definition:
        "A product manager is someone who defines the product vision, leads the team developing the product, and typically recognises the consumer demand and bigger business objectives that a product or feature will meet.",
    },
    {
      id: 71,
      term: "Product Marketing",
      definition:
        "A company's product marketing department is responsible for promoting and positioning a product or service to its target market. In addition to performing market research to understand consumer demands and competition, it entails developing marketing strategies, messaging, and campaigns to communicate the product's value, features, and benefits.",
    },
    {
      id: 72,
      term: "Product Roadmap",
      definition:
        "A product roadmap is a high-level visual overview that depicts the vision and direction of your product offering, typically focused on features and the advantages for users.",
    },
    {
      id: 73,
      term: "Proof of concept",
      definition:
        "A sample or prototype of a good or service to demonstrate its viability.",
    },
    {
      id: 74,
      term: "Referral Marketing",
      definition:
        "Referral marketing is a sales strategy that uses word-of-mouth and referrals to expand the customer base of a business through the networks of its current customers.",
    },
    {
      id: 75,
      term: "Run Rate",
      definition:
        "Run rate is the extrapolation of current financial performance over a predetermined time frame, usually one year. Based on the most recent information available, it is used to predict future income, expenses, or other financial variables.",
    },
    {
      id: 76,
      term: "Runway",
      definition:
        "Monthly or weekly rate at which a business uses cash on reserve to cover expenses.",
    },
    {
      id: 77,
      term: "Scalable",
      definition:
        'The term "scalable" is used to describe a company model or technology that can effectively handle expansion and rising demand without proportionately increasing costs or resources. It suggests the capacity to enhance operations or services without a linear rise in costs.',
    },
    {
      id: 78,
      term: "Scale-up",
      definition:
        "Scale-up is the typical term used to describe a startup as it enters the growth phase.",
    },
    {
      id: 79,
      term: "Search Engine Optimisation (SEO)",
      definition:
        "The act of enhancing a website so that it will appear more prominently in search results for relevant search terms, typically with a focus on user experience, crawlability, indexability, and content relevancy.",
    },
    {
      id: 80,
      term: "Seed Enterprise Investment Scheme (SEIS)",
      definition:
        "The UK government established SEIS, a tax relief programme, to promote investment in startups and early-stage companies.",
    },
    {
      id: 81,
      term: "Seed Funding",
      definition:
        "Seed financing, which is frequently obtained through angel investors, venture capitalists, or incubators, is the initial capital raised by a startup to develop its product, do market research, and get ready for additional investment rounds.",
    },
    {
      id: 82,
      term: "Series A",
      definition:
        "In the Series A investment round, a startup that has successfully completed the seed stage receives sizeable funding from venture capitalists to further develop its product, broaden its market reach, and scale the business.",
    },
    {
      id: 83,
      term: "Shareholders' Agreement",
      definition:
        "A shareholders' agreement, which is a legally binding agreement defining the rights, obligations, and regulations regulating the administration and ownership of a company among its shareholders, is frequently used in startups to safeguard interests and settle disagreements.",
    },
    {
      id: 84,
      term: "Software-as-a-Service (SaaS)",
      definition:
        "Software as a Service (SaaS) is a model for licencing and delivering software in which the software is centrally hosted, typically via the cloud.",
    },
    {
      id: 85,
      term: "Stack (Tech or Marketing)",
      definition:
        "Originally used to refer to all the technical services required to create and maintain a single application, the term is now more broadly used to refer to a group of technologies and tools a company uses to carry out a specific role.",
    },
    {
      id: 86,
      term: "Startup",
      definition:
        "Startups are new businesses that are still in their early phases of operation and are typically characterised by high costs and little to no revenue (financed either by the founders themselves or external investment).",
    },
    {
      id: 87,
      term: "Stealth mode",
      definition: `In the startup environment, the phrase "stealth mode" is used to describe the state in which a business operates in secrecy, keeping its product, technology, or plans secret to maintain a competitive advantage until it's ready to divulge them to the general public or investors.`,
    },
    {
      id: 88,
      term: "Sweat Equity",
      definition:
        "Awarding shares/equity in a startup to early employees or contractors in place of some (or all) of their salary.",
    },
    {
      id: 89,
      term: "Target Company",
      definition:
        "A startup that a VC or angel investor is considering investing in.",
    },
    {
      id: 90,
      term: "Term Sheet",
      definition:
        "Term sheets are non-binding contracts that outline the fundamental terms and conditions of an investment.",
    },
    {
      id: 91,
      term: "Total Addressable Market (TAM)",
      definition:
        "Total Addressable Market (TAM) is the collective income potential for a given good or service in a certain market or industry, assuming ideal market circumstances and no competition restrictions.",
    },
    {
      id: 92,
      term: "Traction",
      definition:
        "The evidence that customers are willing to pay for your product or service. Progress or early growth is exhibited by traction.",
    },
    {
      id: 93,
      term: "Unicorn",
      definition:
        'A privately owned company with a valuation of $1 billion or more is referred to as a "unicorn" in the startup sector. This achievement is often the result of the company seeing rapid development, having a substantial market presence, and having investors who believe in its potential.',
    },
    {
      id: 94,
      term: "Valuation",
      definition: "The calculation of what the startup is worth.",
    },
    {
      id: 95,
      term: "VC (Venture Capitalist)",
      definition:
        "A venture capitalist (VC) is a type of private equity investor who lends money to businesses with strong development potential in return for an equity ownership.",
    },
    {
      id: 96,
      term: "Vesting (Schedule)",
      definition:
        "The period of time that founders and employees must work for the business before getting their full ownership stake.",
    },
    {
      id: 97,
      term: "Wireframe",
      definition:
        "A wireframe is a simple, two-dimensional visual depiction of the layout of a web page, app interface, or product.",
    },
    {
      id: 98,
      term: "Monetization",
      definition:
        "The process of earning money from a startup's good or service is known as monetization.",
    },
    {
      id: 99,
      term: "Unicorn Exit",
      definition:
        "A unicorn exit is when a startup successfully exits after reaching a valuation of $1 billion or more, either through an acquisition or an initial public offering (IPO).",
    },
    {
      id: 100,
      term: "Lean Canvas",
      definition:
        "A Lean Canvas, which functions similarly to a business plan, is a one-page visual tool that assists companies in quickly outlining their essential business elements, such as problem, solution, target market, and income streams.",
    },
  ],
  web3: [
    {
      id: 1,
      term: "Airdrop ",
      definition:
        "An airdrop is a marketing or distribution method used by blockchain projects to give out free tokens or coins to a particular audience or to the wider crypto community.",
    },
    {
      id: 2,
      term: "Altcoin ",
      definition: "An Altcoin is any cryptocurrency others than Bitcoin ",
    },
    {
      id: 3,
      term: "Angel Investor",
      definition:
        "An angel investor is a person who invests money in new companies or small businesses in exchange for equity ownership or convertible debt, usually in the early phases of the company' development.\n",
    },
    {
      id: 4,
      term: "Address",
      definition:
        "A cryptocurrency's address is a special identification used to send or receive digital assets across a blockchain.\n",
    },
    {
      id: 5,
      term: "Active Management ",
      definition:
        "active management is an investment approach used by fund managers or portfolio managers to actively decide when to buy, sell, and alter the assets in a portfolio in an effort to outperform a benchmark or generate better returns than the general market. ",
    },
    {
      id: 6,
      term: "Absolute Advantage",
      definition:
        'The term "absolute advantage" describes a situation in which a nation or an individual can create a good or service more effectively (using fewer resources than another nation or an individual), giving them a production advantage in that particular sector.',
    },
    {
      id: 7,
      term: "Algorithm",
      definition:
        "Algorithm can be described as a set of commands designed to achieve a specific goal or solve a particular problem.",
    },
    {
      id: 8,
      term: "All or None Order (AON)",
      definition:
        "AONs are financial orders that require the full execution of the entire quantity, or none at all.",
    },
    {
      id: 9,
      term: "All-Time High (ATH)",
      definition:
        'The term "All-Time High" (ATH) designates the highest price or value that a specific asset, such as a cryptocurrency, stock, or commodity, has ever achieved since its launch or within a given time period.',
    },
    {
      id: 10,
      term: "Allocation ",
      definition:
        "Allocation refers to the distribution of tokens or equity to a particular investor, team, group, organisation, or other relevant entity. ",
    },
    {
      id: 11,
      term: "Anti Money Laundering (AML)",
      definition:
        "Anti-Money Laundering (AML) refers to a collection of laws, rules, and policies put in place to stop the unauthorised conversion of unlawful cash into legal assets.",
    },
    {
      id: 12,
      term: "Application Programming Interface (API)",
      definition:
        "An API is a set of instructions that enables several software programmes to interact and communicate with one another, sharing information and features.",
    },
    {
      id: 13,
      term: "Application-Specific Integrated Circuit (ASIC)",
      definition:
        "ASICs are specialised electronic chips with performance and efficiency advantages over general-purpose processors since they are created specifically for a given activity or function.\n",
    },
    {
      id: 14,
      term: "Arbitrage ",
      definition:
        'The term "arbitrage" refers to the practise of profiting from differences in prices for the same item in various marketplaces, taking advantage of the difference to increase profits while minimising risk.',
    },
    {
      id: 15,
      term: "ASIC-resistant",
      definition:
        'The term "ASIC-resistant" is a property of some cryptocurrencies or algorithms intended to prohibit or discourage the use of Application-Specific Integrated Circuits (ASICs) for mining, ensuring a more decentralised distribution of mining power among users using general-purpose hardware.',
    },
    {
      id: 16,
      term: "Ask Price",
      definition:
        "The price at which a seller is prepared to deal with a financial instrument, such as a stock or cryptocurrency, on the open market is known as the ask price. It stands for the lowest amount a seller will take in exchange for their asset.",
    },
    {
      id: 17,
      term: "Asset Management",
      definition:
        "Asset management is the practise of professionally managing different kinds of assets, including investments, real estate, and other priceless resources, in order to maximise their performance, growth, and overall value through time.\n",
    },
    {
      id: 18,
      term: "Ape",
      definition:
        'The term "ape" refers to enthusiastic investment in assets like cryptocurrencies or equities, frequently motivated by FOMO or hype and typically made by those with little to no experience.\n',
    },
    {
      id: 19,
      term: "All-Time Low (ATL)",
      definition:
        'The term "All-Time Low" (ATL) designates the lowest price or value that a specific asset, such as a cryptocurrency, stock, or commodity, has ever achieved since its launch or within a given time period."',
    },
    {
      id: 20,
      term: "Arbitrum",
      definition:
        "Arbitrum is a layer 2 scaling solution for Ethereum that uses multi-round fraud proofs to execute transactions outside of the Ethereum Mainnet in order to reduce costs and network congestion.",
    },
    {
      id: 21,
      term: "Archival Nodes",
      definition:
        "Archival nodes keep a complete record of all previous blockchain data, enabling in-depth research, auditing, and analysis.",
    },
    {
      id: 22,
      term: "Base Fee",
      definition:
        "The base fee in blockchain refers to the minimal transaction cost necessary to include a transaction in the following block, which is often impacted by network demand and congestion.\n",
    },
    {
      id: 23,
      term: "Block Gas Estimator Feed",
      definition:
        "The Block Gas Estimator Feed predicts gas prices for the upcoming block by analyzing the gas fees of transactions currently awaiting confirmation in the mempool, which contains pre-chain transactions.",
    },
    {
      id: 24,
      term: "Blockchain",
      definition:
        "Blockchain is a decentralised, open-source, and secure digital ledger system that stores data on a network of computers and offers immutability as well as a wide range of applications outside of cryptocurrencies.",
    },
    {
      id: 25,
      term: "Bridge",
      definition:
        "A bridge is a programme that connects two or more blockchain networks, allowing for the exchange of digital assets between them.",
    },
    {
      id: 26,
      term: "Cancel Transactions ",
      definition:
        "A cancel transaction is a sort of replacement transaction in which a user submits an identical transaction with a larger gas limit so that it is mined ahead of the prior transaction.",
    },
    {
      id: 28,
      term: "Confirmed Transaction",
      definition:
        "A confirmed transaction is an approved and permanent transaction that is added to a blockchain's block after being verified and irreversible.",
    },
    {
      id: 29,
      term: "Dapp",
      definition:
        'A "Dapp," or "decentralised application," is a piece of software that runs on a decentralised network or blockchain and provides functionality with transparency, security, and autonomy, often without the need for a centralised authority.',
    },
    {
      id: 30,
      term: "Defi",
      definition:
        "DeFi, or Decentralised Finance, is the name for a group of blockchain-based financial services and applications that seek to replicate established financial institutions, such as lending, borrowing, trading, and investing, in a decentralised and open setting, frequently without the use of middlemen like banks.",
    },
    {
      id: 31,
      term: "DEX",
      definition:
        "A DEX, or decentralised exchange, is a blockchain-based platform that enables users to transact in cryptocurrencies with one another directly, removing the need for a middleman or centralised authority and offering increased control, privacy, and security.",
    },
    {
      id: 32,
      term: "Dropped Transaction",
      definition:
        "A dropped transaction is a cryptocurrency transaction that, for various reasons, such as network congestion or insufficient transaction fees, does not make it into a block and is invalidated or deleted.",
    },
    {
      id: 33,
      term: "EIP-1559",
      definition:
        "The Ethereum Improvement Proposal 1559, often known as EIP-1559, is a substantial update to the Ethereum blockchain that adds a new fee structure and method in order to increase network efficiency, decrease fee volatility, and improve user experience.",
    },
    {
      id: 34,
      term: "Ethereum",
      definition:
        "Ethereum is a decentralised, open-source blockchain platform that makes it possible to build smart contracts and decentralised apps (DApps), with its native cryptocurrency, Ether (ETH), serving as a means of value transfer and network operation. ",
    },
    {
      id: 35,
      term: "Ethereum 2.0",
      definition:
        "The Ethereum blockchain has undergone a big upgrade with Ethereum 2.0, which implements sharding for scalability and sustainability and switches from proof-of-work to proof-of-stake.",
    },
    {
      id: 36,
      term: "Ethereum Virtual Machine (EVM)",
      definition:
        "The Ethereum Virtual Machine (EVM) is a decentralised, Turing-complete computing environment that processes code and transactions on the Ethereum blockchain, enabling the execution of smart contracts and decentralised applications (DApps).",
    },
    {
      id: 37,
      term: "failed transaction",
      definition:
        "An Ethereum blockchain transaction that fails and cannot be undone, cancelled, or refunded is known as a failed transaction.",
    },
    {
      id: 38,
      term: "full nodes",
      definition:
        "Any computer or server that downloads and validates new blocks of the Ethereum blockchain as well as address states is referred to as a full node.",
    },
    {
      id: 39,
      term: "Front-running",
      definition:
        "Front-running is the process of inserting transactions ahead of a later transaction with the sole purpose of profiting from the later transaction.",
    },
    {
      id: 40,
      term: "Gas",
      definition:
        "Gas is a unit of measurement that represents the amount of computational processing required to finish a transaction.",
    },
    {
      id: 41,
      term: "Gas Estimator",
      definition:
        "In blockchain networks like Ethereum, a gas estimator is a tool or component that determines the quantity of gas (transaction fees) necessary to carry out a specific transaction or smart contract function, assisting users in estimating the cost of their blockchain interactions.",
    },
    {
      id: 42,
      term: "Gas Fees ",
      definition:
        "Gas fees are transaction costs that users of a blockchain network pay to miners or validators in exchange for the processing and confirmation of transactions that ensure the security and effectiveness of the network. ",
    },
    {
      id: 43,
      term: "Gas Limit",
      definition:
        "The maximum amount of gas that miners are permitted to use to finish a transaction is known as the gas limit.",
    },
    {
      id: 44,
      term: "Gas Price",
      definition:
        "The price a user is willing to pay in Ether (ETH) for each unit of gas needed to execute a transaction is known as the gas price.",
    },
    {
      id: 45,
      term: "Goerli",
      definition:
        "Goerli is an Ethereum testnet that enables developers to test and validate their Ethereum-based applications across different Ethereum clients.",
    },
    {
      id: 46,
      term: "Gwei",
      definition:
        'One of the smallest units of ETH is called a "gwei," which is equal to one billionth of an ETH.',
    },
    {
      id: 47,
      term: "internal transaction",
      definition:
        "An internal transaction is a transaction between one smart contract and another smart contract on a blockchain.",
    },
    {
      id: 48,
      term: "JIT Arbitrage",
      definition:
        'The term "Just-In-Time Arbitrage," or JIT Arbitrage, refers to a trading method that takes advantage of price differences across several cryptocurrency exchanges by placing trades precisely when an opportunity presents itself, hence reducing exposure to market volatility.',
    },
    {
      id: 49,
      term: "Layer 1",
      definition:
        "A blockchain network's base layer, or Layer 1, houses the fundamental consensus mechanism and the main blockchain ledger, which are in charge of safeguarding and preserving all network transactions and data. ",
    },
    {
      id: 50,
      term: "Layer 2",
      definition:
        "On top of a Layer 1 blockchain, Layer 2 is a supplementary scaling solution that increases scalability and lowers transaction costs by processing transactions off-chain or more effectively while ensuring security through interaction with the Layer 1 blockchain.",
    },
    {
      id: 51,
      term: "light node",
      definition:
        "A light node is a device that interacts with full nodes and serves as a gateway for the blockchain without verifying blocks or keeping track of the entire blockchain and address state.",
    },
    {
      id: 52,
      term: "Liquidity",
      definition:
        "The ease with which an asset or security can be bought or sold in the market without significantly affecting its price is referred to as liquidity.",
    },
    {
      id: 53,
      term: "Loopring",
      definition:
        "Loopring is a decentralized exchange protocol and Layer 2 scaling solution for Ethereum that uses zkRollups technology to improve scalability and reduce transaction fees, enabling efficient and secure cryptocurrency trading.",
    },
    {
      id: 54,
      term: "Max Fee",
      definition:
        "Max fee is the maximum permitted amount that a user is willing to pay as a transaction fee to guarantee that their transaction is speedily processed and added to the next block on the blockchain.",
    },
    {
      id: 55,
      term: "Mempool",
      definition:
        'Mempool, or "memory pool," is a temporary storage location within a blockchain network where pending and unconfirmed transactions are kept until they are added to a block by a miner or validator.\n',
    },
    {
      id: 56,
      term: "Hash tree",
      definition:
        "Merkle tree, also known as a hash tree a data structure used by blockchain to safely validate and condense massive data sets.\n",
    },
  ],
  sustainability: [
    {
      id: 1,
      term: "Sustainable Finance",
      definition:
        "In order to increase long-term investments in sustainable economic activities and projects, sustainable finance refers to the process of taking environmental, social, and governance (ESG) issues into account when making investment decisions in the financial sector. In addition to the environment more generally, such as the preservation of biodiversity, pollution avoidance, and the circular economy, environmental issues may also involve the mitigation and adaptation of climate change. Social factors might include human rights issues as well as inequality, inclusivity, labour relations, and investments in people, their talents, and communities. A key factor in ensuring the incorporation of social and environmental factors in the decision-making process is the governance of public and commercial institutions, which includes management structures, employee relations, and CEO compensation.",
    },
    {
      id: 2,
      term: "Green Financing",
      definition:
        "The goal of green financing is to enhance the amount of money flowing to priorities for sustainable development from the public, private, and not-for-profit sectors (from banking, microcredit, insurance, and investment). An important aspect of this is to increase accountability, better manage social and environmental risks, and seize opportunities that will improve the environment and provide a respectable rate of return.",
    },
    {
      id: 3,
      term: "Sustainable Investing",
      definition:
        'The term "sustainable investing" refers to a variety of strategies used by investors to maximise financial gains while advancing long-term environmental or social value. Investors have produced more thorough assessments and made better investment decisions by fusing conventional investment methods with environmental, social, and corporate governance (ESG) insights. Sustainable investing makes ensuring that businesses are evaluated for their contributions to society as a whole rather than only for their short-term financial success. Investors need to consider closely how investments may affect the political, social, and environmental environments.',
    },
    {
      id: 4,
      term: "Active Ownership (ESG)",
      definition:
        "Active ownership means using your rights as a shareholder to improve corporate behavior and make investments more sustainable. The two main ways to do this are by engaging with investee companies or through voting at shareholder meetings",
    },
    {
      id: 5,
      term: "Blue Bonds",
      definition:
        "In order to generate money from impact investors for marine and ocean-based projects that have favourable effects on the environment, the economy, and the climate, governments, development banks, and other organisations have created the blue bond.",
    },
    {
      id: 6,
      term: "Carbon Markets",
      definition:
        "Carbon markets are essentially trading platforms where carbon credits are bought and sold. By purchasing carbon credits from organisations that eliminate or reduce greenhouse gas emissions, businesses and individuals can utilise carbon markets to make up for their greenhouse gas emissions.\n" +
        " One tonne of carbon dioxide or the equivalent amount of another greenhouse gas reduced, sequestered, or avoided is equal to one trading carbon credit. A credit becomes an offset and is no longer tradable when it is applied to the reduction, sequestration, or avoidance of emissions.",
    },
    {
      id: 7,
      term: "Carbo Neutrality",
      definition:
        "Carbon neutrality means having a balance between emitting carbon and absorbing carbon from the atmosphere in carbon sinks. Removing carbon oxide from the atmosphere and then storing it is known as carbon sequestration. In order to achieve net zero emissions, all worldwide greenhouse gas (GHG) emissions will have to be counterbalanced by carbon sequestration.",
    },
    {
      id: 8,
      term: "Carbon Sequestration",
      definition:
        "The most often produced greenhouse gas is carbon dioxide. The technique of removing and storing carbon dioxide from the atmosphere is known as carbon sequestration. It is one way to lessen atmospheric carbon dioxide in an effort to slow down the rate of climate change. ",
    },
    {
      id: 9,
      term: "Carbon Sink",
      definition:
        "A carbon sink is anything that absorbs more carbon from the atmosphere than it releases – for example, plants, the ocean and soil.",
    },
    {
      id: 10,
      term: "Carbon Source",
      definition:
        "In contrast, a carbon source is anything that releases more carbon into the atmosphere than it absorbs – for example, the burning of fossil fuels or volcanic eruptions.",
    },
    {
      id: 11,
      term: "Carbon Cycle",
      definition:
        "All life on Earth depends on carbon; it is present in our DNA, the food we eat, and the air we breathe. The total amount of carbon on Earth has never changed, but its distribution is continually shifting as it is emitted or absorbed and moves between the atmosphere and living things. The carbon cycle, which has existed for thousands of years, is what is meant by this.",
    },
    {
      id: 12,
      term: "Clean Development Mechanism",
      definition:
        "The UNFCCC established the Clean Development Mechanism (CDM), the only offset mechanism of its kind so far, while the Paris Agreement is creating new ones. In essence, the CDM enables people and organisations to contribute money to initiatives in developing countries that aim to cut or eliminate greenhouse gas emissions.",
    },
    {
      id: 13,
      term: "Certified Emission Reduction",
      definition:
        " Certified Emission Reduction (CER) are the credits received by each participant in the Clean Development Mechanism which they can use to help them achieve their personal emission reduction goals. It is vetted by the United Nations. For each cubic tonne of greenhouse gas avoided, one CER is issued.",
    },
    {
      id: 14,
      term: "Carbon Offsets",
      definition: `Carbon offsets are tradable "rights" or certificates connected to actions that reduce the atmospheric concentration of carbon dioxide (CO2). Instead of taking steps to reduce their own carbon emissions, an individual or organisation can purchase these certificates and use the money to support climate change initiatives. The certificates "offset" the buyer's CO2 emissions in this way by reducing CO2 emissions elsewhere by an equivalent amount.`,
    },
    {
      id: 15,
      term: "Carbon Tax",
      definition:
        'A charge on carbon emissions that are generated during the production of goods and services is known as a carbon tax. The goal of carbon taxes is to bring to light the "hidden" socioeconomic costs of carbon emissions, which are currently only felt indirectly through things like more extreme weather. They work to lower greenhouse gas emissions by raising the cost of the fossil fuels that produce them when burned. It also encourages the production of less carbon-intensive goods and services by reducing demand for those that generate significant emissions.',
    },
    {
      id: 16,
      term: "Climate Finance",
      definition:
        "Climate finance refers to local, national or transnational financing—drawn from public, private and alternative sources of financing—that seeks to support mitigation and adaptation actions that will address climate change.  Climate finance is needed for mitigation, because large-scale investments are required to significantly reduce emissions. Climate finance is equally important for adaptation, as significant financial resources are needed to adapt to the adverse effects and reduce the impacts of a changing climate.",
    },
    {
      id: 17,
      term: "Green Climate Fund",
      definition:
        'The Green Climate Fund (GCF) – a critical element of the historic Paris Agreement - is the world"s largest climate fund, mandated to support developing countries raise and realize their Nationally Determined Contributions (NDC) ambitions towards low-emissions, climate-resilient pathways.\n' +
        "\n" +
        "GCF operates through a network of over 200 Accredited Entities and delivery partners who work directly with developing countries for project design and implementation. Our partners include international and national commercial banks, multilateral, regional and national development finance institutions, equity funds institutions, United Nations agencies, and civil society organizations.\n" +
        "\n",
    },
    {
      id: 18,
      term: "Decarbonization",
      definition:
        'When we talk about "decarbonization," we typically mean the process of lowering "carbon intensity," or the volume of greenhouse gas emissions brought on by the burning of fossil fuels. Typically, this entails lowering CO2 emission per unit of produced electricity. To reach the global temperature goals established by the Paris Agreement and the UK government, it is imperative to reduce the quantity of carbon dioxide produced by transportation and energy production.',
    },
    {
      id: 19,
      term: "Ecosystem Services",
      definition:
        "Ecosystem services are the many and varied benefits to humans provided by the natural environment and healthy ecosystems. Such ecosystems include, for example, agroecosystems, forest ecosystem, grassland ecosystems, and aquatic ecosystems. These ecosystems, functioning in healthy relationships, offer such things as natural pollination of crops, clean air, extreme weather mitigation, and human mental and physical well-being. Collectively, these benefits are becoming known as ecosystem services, and are often integral to the provision of food, the provisioning of clean drinking water, the decomposition of wastes, and the resilience and productivity of food ecosystems.",
    },
    {
      id: 20,
      term: "ESG",
      definition: `The term "environmental, social, and governance" (ESG) refers to a group of corporate performance evaluation criteria that evaluates a company's ability to effectively manage its environmental and social consequences as well as the strength of its governance procedures. Quantifying a company's carbon emissions, water use, or consumer privacy violations are a few examples of ESG data. The use of sustainability and social responsibility disclosure data by institutional investors, stock exchanges, and boards to investigate the link between a company's management of ESG risk factors and its financial performance is on the rise.`,
    },
    {
      id: 21,
      term: "European Green Deal",
      definition:
        "The European Green Deal, approved in 2020, is a set of policy initiatives by the European Commission with the overarching aim of making the European Union (EU) climate neutral in 2050.The plan is to review each existing law on its climate merits, and also introduce new legislation on the circular economy, building renovation, biodiversity, farming and innovation.\n" +
        "\n" +
        "The European Commission has adopted a set of proposals to make the EU's climate, energy, transport and taxation policies fit for reducing net greenhouse gas emissions by at least 55% by 2030, compared to 1990 levels. \n" +
        "\n" +
        "One third of the €1.8 trillion investments from the NextGenerationEU Recovery Plan, and the EU’s seven-year budget will finance the European Green Deal.",
    },
    {
      id: 22,
      term: "Green Bonds",
      definition:
        "A green bond is a type of fixed-income instrument that is specifically earmarked to raise money for climate and environmental projects. These bonds are typically asset-linked and backed by the issuing entity’s balance sheet, so they usually carry the same credit rating as their issuers’ other debt obligations.​\n" +
        "\n" +
        'The names "green bonds" and "climate bonds," which date from the first decade of the twenty-first century, are not necessarily interchangeable. Green bonds represent a broader category of instruments relating to projects with a beneficial environmental impact, whereas climate bonds are especially used to finance initiatives that cut carbon emissions or mitigate the consequences of climate change.\n' +
        "\n" +
        "Green bonds may come with tax incentives such as tax exemption and tax credits, making them a more attractive investment vs. a comparable taxable bond.",
    },
    {
      id: 23,
      term: "Impact Investing",
      definition:
        `Investments "made into companies, organisations, and funds with the intention to generate a measurable, beneficial social or environmental impact alongside a financial return" are referred to as impact investments. Impact investing is fundamentally about matching an investor's values and views with the allocation of funds to deal with social and/or environmental challenges.\n` +
        "\n" +
        "Impact investors actively look for opportunities to invest in companies, charitable organisations, and funds in sectors like microfinance, sustainable agriculture, renewable energy, housing, healthcare, and education. Impact investment has advanced significantly thanks to institutional investors, particularly pension funds, endowments, and North American and European development finance agencies.",
    },
    {
      id: 24,
      term: "Natural Capital",
      definition:
        "Natural capital is the world's stock of natural resources, which includes geology, soils, air, water and all living organisms. Some natural capital assets provide people with free goods and services, often called ecosystem services. All of these underpin our economy and society, and thus make human life possible.",
    },
    {
      id: 25,
      term: "Socially Responsible Investing (SRI)",
      definition:
        'Any investment approach that aims to take into account both financial return and social/environmental benefit in order to bring about social change that supporters deem to be positive is known as socially responsible investing (SRI) or Social Investment, Sustainable Social Consciousness, "green," or Ethical Investing. Socially responsible investments often constitute a small percentage of total funds invested by corporations and are riddled with obstacles.',
    },
    {
      id: 26,
      term: "Tripple Bottom Line",
      definition:
        "According to the triple bottom line (TBL), businesses should pledge to give social and environmental issues the same amount of attention as they do to earnings. According to TBL theory, there should be three bottom lines: profit, people, and the environment. A TBL aims to evaluate a company's level of adherence to corporate social responsibility and its long-term effects on the environment.",
    },
    {
      id: 27,
      term: "Sustainable Ocean Economy",
      definition:
        "All ocean-based economic sectors that operate in or invest in sustainable systems (environmental, social, and economic systems) are included in the sustainable ocean economy. \n" +
        "\n" +
        "The sustainable ocean economy differs from the ocean economy in that it places more emphasis on conservation, livelihoods, and employment rather than shifting the emphasis from the production unit and economic output to a people-centered vision of systems, including territorial systems, wealth, and well-being. This policy brief will concentrate on the oceanic sustainable ocean economy, among other things, to ensure conformity with the Sustainable Development Goal 14. Some definitions of the sustainable ocean economy also include inland waters. ",
    },
    { id: 28, term: "UNFCCC", definition: "" },
    {
      id: 29,
      term: "Corporate Social Responsibility",
      definition:
        'Corporate Social Responsibility is a management concept whereby  companies integrate social and environmental concerns in their business  operations and interactions with their stakeholders. CSR is generally  understood as being the way through which a company achieves a balance  of economic, environmental and social imperatives ("Triple-Bottom-Line-  Approach"), while at the same time addressing the expectations of  shareholders and stakeholders.\n' +
        "\n" +
        "Key CSR issues: environmental management, eco-efficiency, responsible sourcing, stakeholder engagement, labour standards and working conditions, employee and community relations, social equity, gender balance, human rights, good governance, and anti-corruption measures.",
    },
    {
      id: 30,
      term: "Sustainable Development Goals",
      definition:
        "The Sustainable Development Goals are the blueprint to achieve a better and more sustainable future for all. They address the global challenges we face, including those related to poverty, inequality, climate change, environmental degradation, peace and justice. The 17 Goals are all interconnected, and in order to leave no one behind, it is important that we achieve them all by 2030. Click on any specific Goal below to learn more about each issue and take action.",
    },
    {
      id: 31,
      term: "Paris Agreement",
      definition:
        "The Paris Agreement is a legally binding international treaty on climate change. It was adopted by 196 Parties at the UN Climate Change Conference (COP21) in Paris, France, on 12 December 2015. It entered into force on 4 November 2016.\n" +
        "\n" +
        "Its overarching goal is to hold “the increase in the global average temperature to well below 2°C above pre-industrial levels” and pursue efforts “to limit the temperature increase to 1.5°C above pre-industrial levels.”\n" +
        "\n" +
        "Implementation of the Paris Agreement requires economic and social transformation, based on the best available science. The Paris Agreement works on a five-year cycle of increasingly ambitious climate action -- or, ratcheting up -- carried out by countries. Since 2020, countries have been submitting their national climate action plans, known as nationally determined contributions (NDCs). Each successive NDC is meant to reflect an increasingly higher degree of ambition compared to the previous version.",
    },
    {
      id: 32,
      term: "National Determined Contributions",
      definition:
        "Nationally Determined Contributions, or NDCs, are each country's self-described national climate promises under the Paris Agreement, outlining what they would do to support efforts to limit global warming to 1.5°C, adapt to its effects, and secure adequate funding to support these efforts. \n" +
        "\n" +
        "Based on each country's strengths and capacity, NDCs are short- to medium-term plans that must be updated every five years with progressively larger ambition.  Particularly in developing nations, real progress is already being made towards achieving the goals of the Paris Agreement. \n" +
        "\n" +
        "For instance, pledges from African nations are more detailed than the global average when describing how goals would be met. ",
    },
    {
      id: 33,
      term: "Principles for Sustainable Insurance",
      definition:
        "A global framework for the insurance industry to address environmental, social and governance (ESG) risks and opportunities and a global initiative to strengthen the insurance industry’s contribution as risk managers, insurers and investors to building resilient, inclusive and sustainable communities and economies",
    },
    {
      id: 34,
      term: "Principles for Responsible Banking",
      definition:
        "Created by the United Nations in partnership with founding banks, the Principles are designed to be integrated into banks’ strategies and across their portfolio of activities, bringing purpose, vision and ambition on sustainable finance to the core of the organisation. The 6 principles are:\n" +
        "\n" +
        "With over 300 signatory banks representing almost half of the global banking industry, the Principles are the world’s foremost sustainable banking framework. Through the Principles, banks take action to align their core strategy, decision-making, lending and investment with the UN Sustainable Development Goals, and international agreements such as the Paris Climate Agreement. The Principles for Responsible Banking include the Net-Zero Banking Alliance, which is the climate-focused initiative of this global framework.",
    },
    {
      id: 35,
      term: "Partnership for Carbon Accounting Financials",
      definition:
        "PCAF is a global partnership of financial institutions that work together to develop and implement a harmonized approach to assess and disclose the greenhouse gas (GHG) emissions associated with their loans and investments.\n" +
        "\n" +
        "The harmonized accounting approach provides financial institutions with the starting point required to set science-based targets and align their portfolio with the Paris Climate Agreement. PCAF enables transparency and accountability and has developed an open-source global GHG accounting standard for financial institutions, the Global GHG Accounting and Reporting Standard for the Financial Industry.",
    },
    {
      id: 36,
      term: "Global GHG Accounting and Reporting Standard",
      definition:
        "The Global GHG Accounting and Reporting Standard, developed by the Partnership for Carbon Accounting Financials (PCAF) Global Core Team, is comprised of three parts, A, B and C:-\n" +
        "\n" +
        "Part A: Financed Emissions provides detailed methodological guidance to measure and disclose GHG emissions associated with seven asset classes as well as guidance on emission removals.\n" +
        "\n" +
        "Part B: Facilitated Emissions provides methodological guidance for measuring and reporting the GHG emissions associated with the capital markets transactions (to be launched in early 2023).\n" +
        "\n" +
        "Part C: Insurance-Associated Emissions provides methodological guidance for measuring and reporting the GHG emissions associated to re/insurance underwriting",
    },
    {
      id: 37,
      term: "Paris Agreement Capital Transition Assessment (PACTA)",
      definition:
        "PACTA is a free software that calculates the extent to which corporate capital expenditures and industrial assets being financed through equity, bond, or funds are aligned with various climate scenarios. PACTA for Banks enables banks to measure the alignment of their corporate lending portfolios with climate scenarios across a set of key climate-relevant sectors and technologies.",
    },
    {
      id: 38,
      term: "Global Alliance for Banking on Values (GABV)",
      definition:
        "The Global Alliance for Banking on Values (GABV) is an international network of frontrunner organisations and leaders in the banking industry that use finance to serve people and the planet. Our collective goal is to make the banking system more transparent and support positive economic, social and environmental change. It is a network of banking CEO's from around the world committed to advancing positive change in the banking sector",
    },
    {
      id: 39,
      term: "Intergovernmental Panel on Climate Change (IPCC)",
      definition:
        "The IPCC prepares comprehensive Assessment Reports about the state of scientific, technical and socio-economic knowledge on climate change, its impacts and future risks, and options for reducing the rate at which climate change is taking place. It also produces Special Reports on topics agreed to by its member governments, as well as Methodology Reports that provide guidelines for the preparation of greenhouse gas inventories. ",
    },
    {
      id: 40,
      term: "International Sustainability Standards Board (ISSB)",
      definition:
        "The Trustees of the International Financial Reporting Standard (IFRS) Foundation announced the formation of the International Sustainability Standards Board (ISSB) on 3 November 2021 at COP26 in Glasgow, following strong market demand for its establishment. The ISSB is developing—in the public interest—standards that will result in a high-quality, comprehensive global baseline of sustainability disclosures focused on the needs of investors and the financial markets.\n" +
        "\n" +
        "The ISSB has international support with its work to develop sustainability disclosure standards backed by the G7, the G20, the International Organization of Securities Commissions (IOSCO), the Financial Stability Board, African Finance Ministers and Finance Ministers and Central Bank Governors from more than 40 jurisdictions.\n" +
        "\n" +
        "The ISSB has set out four key objectives:\n" +
        "\n" +
        "a. to develop standards for a global baseline of sustainability disclosures;\n" +
        "b. to meet the information needs of investors;\n" +
        "c. to enable companies to provide comprehensive sustainability information to global capital markets; and\n" +
        "d. to facilitate interoperability with disclosures that are jurisdiction-specific and/or aimed at broader stakeholder groups.",
    },
    {
      id: 41,
      term: "Climate Bond Initiative",
      definition:
        "Climate Bonds Initiative is an international organisation working to mobilise global capital for climate action. We achieve this through the development of the Climate Bonds Standard and Certification Scheme, Policy Engagement and Market Intelligence work. We empower our Partner organisations with the tools and knowledge needed to navigate, influence and instigate change.",
    },
    {
      id: 42,
      term: "Global Reporting Initiative (GRI)",
      definition:
        "The Global Reporting Initiative (known as GRI) is an international independent standards organization that helps businesses, governments, and other organizations understand and communicate their impacts on issues such as climate change, human rights, and corruption.\n" +
        "\n" +
        "Since its first draft guidelines were published in March 1999, GRI's voluntary sustainability reporting framework has been adopted by multinational organizations, governments, small and medium-sized enterprises (SMEs), NGOs, and industry groups. Over 10,000 companies from more than 100 countries use GRI. According to the 26 October 2022, KPMG Survey of Sustainability Reporting, 78% of the world’s biggest 250 companies by revenue (the G250) and 68% of the top 100 businesses in 58 countries (5,800 companies known as the N100) have adopted the GRI Standards for reporting. GRI is used as a reporting standard by a majority of the companies surveyed in all regions.",
    },
    {
      id: 43,
      term: "ISO 26000: Social Responsibility",
      definition:
        "For businesses and organizations committed to operating in a socially responsible way, there’s ISO 26000. It provides guidance to those who recognize that respect for society and environment is a critical success factor. As well as being the “right thing” to do, application of ISO 26000 is increasingly viewed as a way of assessing an organization’s commitment to sustainability and its overall performance.  ISO 26000 was developed by a working group of about 500 experts.\n" +
        "\n" +
        "The standard was launched in 2010 following five years of negotiations between many different stakeholders across the world. Representatives from government, NGOs, industry, consumer groups and labour organizations around the world were involved in its development, which means it represents an international consensus.\n" +
        "\n",
    },
    {
      id: 44,
      term: "ISO 14001: Environmental Management Systems",
      definition:
        "With over 360,000 ISO 14001 certificates issued globally, ISO 14001 is the international standard for environmental management systems (EMS) and the most used EMS in the world.\n" +
        "\n" +
        "The major management system standard, ISO 14001, outlines the conditions for creating and maintaining an EMS. This aids in maintaining environmental control, minimising negative effects, and ensuring legal compliance. According to studies, up to 70% of all office garbage is recyclable, yet only 7.5% actually makes it to a recycling plant.\n" +
        "\n" +
        "Along with ISO 14001, there is ISO 14004 Environmental Management Systems - General Guidelines, which covers principles, systems, and supporting procedures. The standard includes topics like the creation, application, upkeep, and enhancement of an EMS.",
    },
    {
      id: 45,
      term: "Positive screening",
      definition:
        "Positive screening is the process of finding companies that score highly on environmental, social and governance (ESG) factors relative to their peers. These companies can then be selected for sustainable investing portfolios. \n" +
        "\n" +
        "The positive screening process is often used to build portfolios with enhanced sustainability profiles subject to criteria such as superior environmental records, strong reputations for labor practices and gender equality, and good governance of issues such as non-involvement in controversies. As such it is a good tool for thematic strategies and impact investing strategies. Positive screening is also commonly used for building best-in-class strategies that target the companies in sectors with superior ESG scores.",
    },
    {
      id: 46,
      term: "Negative Screening",
      definition:
        "Negative screening is the process of finding companies that score poorly on environmental, social and governance (ESG) factors relative to their peers.\n" +
        "\n" +
        "Negative screening therefore aims to wheedle out the wheat from the chaff when choosing stocks for a portfolio. Typical factors that the screening process looks out for include a poor environmental or waste management record, including unacceptably high carbon footprints; poor labor relations, particularly linked to the non-payment of living wages; and poor governance issues such as a lack of diversity on boards, or overly controlling private shareholders. All are taken into consideration when making the final peer group comparison.",
    },
    {
      id: 47,
      term: "Stakeholder capitalism",
      definition:
        "Stakeholder capitalism is a form of capitalism in which companies seek long-term value creation by taking into account the needs of all their stakeholders, and society at large. It is a form of capitalism in which companies do not only optimize short-term profits for shareholders, but seek long term value creation, by taking into account the needs of all their stakeholders, and society at large.\n" +
        "\n" +
        "This approach was common in the post-war decades in the West, when it became clear that one person or entity could only do well if the whole community and economy functioned. There was a strong linkage between companies and their community. ",
    },
    {
      id: 48,
      term: "Greenwashing",
      definition: `Greenwashing (a compound word modeled on "whitewash"), also called "green sheen", is a form of advertising or marketing spin in which green PR andgreen marketing are deceptively used to persuade the public that an organization's products, aims and policies are environmentally friendly. Companies that intentionally take up greenwashing communication strategies often do so in order to distance themselves from their own environmental lapses or those of their suppliers.`,
    },
    {
      id: 49,
      term: "Stranded Assets",
      definition:
        "When something of worth is left behind by the passage of time, it becomes stranded. The items are regarded as stranded when market changes mean they cannot generate enough economic return to be viable. Although the phrase can be used to describe any asset in any sector, it has gained popularity in recent years as stakeholders' concerns about the environment, social responsibility, and long-term viability have increased. This business world is experiencing a tremendous river of change, leaving many global assets stranded or at risk of doing so. This will most definitely worry investors.\n" +
        "\n" +
        "For such assets, companies do a premature writeoff in the books which are incompatible with a sustainable economy",
    },
    {
      id: 50,
      term: "Sustainability",
      definition:
        "Although sustainability is linked to the environmental movement, the notion that it is only focused on the environment is a misconception. Sustainability is based on three dimensions:-\n" +
        "\n" +
        "1. Environmental Sustainability - occurs when humanity’s rate of consumption does not exceed nature’s rate of replenishment and when humanity’s rate of generating pollution and emitting greenhouse gases does not exceed nature’s rate of restoration.\n" +
        "\n" +
        "2. Social Sustainablity - is the ability of a society to uphold universal human rights and meet people's basic needs, such as healthcare, education, and transportation. Healthy communities ensure personal, labour, and cultural rights are respected and all people are protected from discrimination.\n" +
        "\n" +
        "3. Economic Sustainability - is the ability of human communities around the world to maintain their independence and have access to the resources required to meet their needs, meaning that secure sources of livelihood are available to everyone.",
    },
  ],
};
