import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

// import './styles.css';

// import required modules
import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";
import slide1 from "../../assets/images/carousel/carousel-img-1.png";
import slide2 from "../../assets/images/carousel/carousel-img-2.png";
import slide3 from "../../assets/images/carousel/carousel-img-3.png";

const CarouselFeatured = () => {
  const imgList = [
    slide1,
    slide2,
    slide3,
  ];
  // const imgList = [
  //   "https://drive.google.com/uc?export=view&id=1Gqq_MYo76rZ9MRqY6PN9Sx1PFO5rK4CV",
  //   "https://drive.google.com/uc?export=view&id=1FcjBjZm6o-PjKZof1hXyYKscfrLef50I",
  //   "https://drive.google.com/uc?export=view&id=1CK6YYnDm5ZAWM8tw5C0cflwMZ1uyMs6d",
  // ];
  const [img, setImg] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (img == 2) {
        setImg(0);
      } else {
        setImg(img + 1);
      }
    }, 3000);

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [img]);
  console.log("img", imgList[img]);
  return (
    <div className="m-0 md:m-8">
      <BrowserView>
        <div className="flex gap-2">
          {/* <img src={slide1} className="w-1/2 h-1/2" /> */}
          <div className="w-1/2 h-1/2">
            <h1 className="text-6xl	text-left mt-8">
              Money Matters Unveiled
            </h1>
            <p className="text-4xl text-left mt-10">Finance in Every Tale</p>
          </div>
          {img==0&&<img src={slide1} className={`w-1/2 h-1/2 rounded-xl`} id={img}/>}
          {img==1&&<img src={slide2} className={`w-1/2 h-1/2 rounded-xl`} id={img} />}
          {img==2&&<img src={slide3} className={`w-1/2 h-1/2 rounded-xl`} id={img}/>}
        </div>
      </BrowserView>

      <MobileView>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          effect={"fade"}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectFade, Pagination, Navigation]}
          className="mySwiper"
          pagination={false}
          navigation={false}
        >
          <SwiperSlide>
            <img src={imgList[1]} className="w-full rounded-xl md:h-full" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={imgList[2]} className="w-full rounded-xl md:h-full" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={imgList[3]} className="w-full rounded-xl md:h-full" />
          </SwiperSlide>
        </Swiper>
      </MobileView>
    </div>
  );
};

export default CarouselFeatured;
