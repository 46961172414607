import React from "react";
import useRedirect from "../../../custom-hooks/common/useRedirect";
import { blogsListData } from "../../../demo-data/blogs-data";
import parse from "html-react-parser";
import { BrowserView, MobileView } from "react-device-detect";

const Listing = () => {
  const redirectTo = useRedirect();
  const redirectionHandler = (category = "") => redirectTo(`blogs/${category}`);
  return (
    <>
      <div
        className="container mx-auto bg-gray-200 rounded-xl shadow border p-4 m-10 mb-0"
        data-aos="flip-up"
      >
        <p className="text-3xl text-gray-700 font-bold">Blogs</p>
      </div>
      <div className="container grid grid-cols-1 gap-4 w-full mx-auto p-4 m-10 mb-0">
        {blogsListData?.map((callout, index) => (
          <div
            data-aos="zoom-in"
            className="mt-4"
            onClick={() => redirectionHandler(callout?.slug)}
          >
            <BrowserView>
              <div
                key={callout?.title}
                className="group relative flex gap-4 justify-center"
              >
                <div
                  className={`${index} relative h-80 w-1/2 overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64 ${
                    index % 2 == 0 ? "order-last" : ""
                  }`}
                >
                  <img
                    src={callout?.image}
                    alt={callout?.slug}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="w-1/2">
                  <h3 className="text-lg font-semibold text-gray-900 text-start line-clamp-2">
                    {callout?.title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-700 font-semibold text-start">
                    {callout?.published_date} by {callout?.author}
                  </p>
                  <p className="mt-2 text-sm text-gray-500 text-start line-clamp-6">
                    {callout?.description}
                  </p>
                </div>
              </div>
            </BrowserView>
            <MobileView>
              <div
                key={callout?.title}
                className="group relative flex flex-col gap-4 justify-center bg-gray-100 rounded-lg"
              >
                <div
                  className={`${index} relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64`}
                >
                  <img
                    src={callout?.image}
                    alt={callout?.slug}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="w-full p-2">
                  <h3 className="text-lg font-semibold text-gray-900 text-start line-clamp-2">
                    {callout?.title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-700 font-semibold text-start">
                    {callout?.published_date} by {callout?.author}
                  </p>
                  <p className="mt-2 text-sm text-gray-500 text-start line-clamp-6">
                    {callout?.description}
                  </p>
                </div>
              </div>
            </MobileView>
          </div>
        ))}
      </div>
    </>
  );
};

export default Listing;
