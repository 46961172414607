import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import demo from "../../assets/images/demo.jpg";
import { TOSlistData } from "../../demo-data/tails-of-startups-data";

const TailOfStartupsModal = ({ open = false, onClose = () => {}, content }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 w-screen overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="mx-auto max-w-2xl rounded-lg bg-white">
              <img
                className="rounded-t-lg object-cover w-full h-full md:w-2/5 md:h-2/5 float-none md:float-left p-4"
                src={content?.image_links_logo}
                alt=""
              />
              {content?.startup_name && (
                <h3 className="text-3xl md:text-5xl text-gray-700 font-bold mb-5 w-full m-4">
                  {content?.startup_name}
                </h3>
              )}
              {content?.legally_known_as && (
                <h4 className="text-lg text-gray-700 font-bold mb-5 w-full m-4">
                  {content.legally_known_as}
                </h4>
              )}
              {content?.profile_summary && (
                <p className="text-gray-500 text-lg m-4">
                  {content?.profile_summary}
                </p>
              )}
              <div className="p-4">
                <table className="table-auto overflow-y-auto h-32 w-full">
                  <tbody>
                    {content.legally_known_as && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Legal name
                        </td>
                        <td>{content.legally_known_as}</td>
                      </tr>
                    )}
                    {content?.based_out_of && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Headquater
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.based_out_of}
                        </td>
                      </tr>
                    )}
                    {content?.business_model && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Business model
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.business_model}
                        </td>
                      </tr>
                    )}
                    {content?.founded_in && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Founding date
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.founded_in}
                        </td>
                      </tr>
                    )}
                    {content?.employees_headcount && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Employee count
                        </td>
                        <td className="text-gray-500 text-lg ms-4">
                          {content?.employees_headcount.includes("<")
                            ? `more than ${
                                content?.employees_headcount.split("<")[1]
                              }`
                            : content?.employees_headcount}
                        </td>
                      </tr>
                    )}
                    {content?.revenue_stream && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Revenue stream
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.revenue_stream}
                        </td>
                      </tr>
                    )}
                    {content?.customer_group && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Client segment
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.customer_group}
                        </td>
                      </tr>
                    )}
                    {content?.client_business_structure && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Target companies
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.client_business_structure}
                        </td>
                      </tr>
                    )}
                    {content?.geographical_segment && (
                      <tr>
                        <td className="text-lg text-gray-700 font-semibold">
                          Target companies
                        </td>
                        <td className="text-gray-500 text-lg">
                          {content?.geographical_segment}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <span
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={onClose}
                >
                  Close
                </span>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default TailOfStartupsModal;
