import React from "react";
import useRedirect from "../../custom-hooks/common/useRedirect";
import IndianFlag from "./svg-icons/indian-flag";
import Earth from "./svg-icons/earth";
import CurvedUpArrow from "./svg-icons/curved-up-arrow";
import FintechIcon from "./svg-icons/fintech-icon";
import { BrowserView, MobileView } from "react-device-detect";

const CatagoryCards = ({ _ }) => {
  console.log(_);
  const redirectTo = useRedirect();
  const redirectionHandler = (category = "") =>
    redirectTo(`tales-of-startups/${category}`);
  return (
    <div
      className={`flex flex-col items-start rounded-lg m-2 p-4 bg-slate-300 hover:shadow-2xl hover:shadow-green-600 hover:border-1 hover:border-green-700 cardItem cursor-pointer`}
      // data-aos="zoom-in"
      onClick={() => redirectionHandler(_.name)}
    >
      <div className="flex justify-between items-start w-full">
        <div className=" grow flex flex-col gap-4 w-4/5 md:w-auto">
          <div className="flex justify-between w-full">
            <h3 className="text-xl text-gray-700 font-semibold">
              {_.name ?? ""}
            </h3>
            {/* <span
              onClick={() =>
                redirectionHandler(_?.industryName.toLocaleLowerCase() ?? "")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                className="fill-gray-700 hover:fill-green-500"
              >
                <path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" />
                <path d="M9.293 8.707 12.586 12l-3.293 3.293 1.414 1.414L15.414 12l-4.707-4.707-1.414 1.414z" />
              </svg>
            </span> */}
          </div>
          <div className="flex justify-start items-start flex-col md:flex-row">
            <p className="grow flex justify-start items-center gap-2 text-gray-500 text-sm md:text-lg">
              <CurvedUpArrow height={24} width={24} />{" "}
              <span>{_?.cagr}% CAGR</span>
            </p>
            <p className="grow flex justify-start items-center gap-2 text-gray-500 text-sm md:text-lg">
              <IndianFlag height={24} width={24} /><span>${_?.india}</span>
            </p>
            <p className="grow flex justify-start items-center gap-2 text-gray-500 text-sm md:text-lg">
              <Earth height={24} width={24} />
              <span>${_?.global}</span>
            </p>
          </div>
          <BrowserView>
            <div className="flex flex-wrap justify-start gap-2">
              {_?.key_players?.split(",").map((_i) => (
                <span
                  className={`px-2 rounded-3xl bg-green-800 shadow-xl text-white text-md font-semibold`}
                >
                  {_i}
                </span>
              ))}
            </div>
          </BrowserView>
        </div>
        <img src={_?.logo} height={100} width={100} className="ms-2" />
        {/* <FintechIcon height={150} width={150} className='ms-2'/> */}
        {/* <span
          onClick={() =>
            redirectionHandler(_?.industryName.toLocaleLowerCase() ?? "")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            className="fill-gray-700 hover:fill-green-500"
          >
            <path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" />
            <path d="M9.293 8.707 12.586 12l-3.293 3.293 1.414 1.414L15.414 12l-4.707-4.707-1.414 1.414z" />
          </svg>
        </span> */}
      </div>
      <MobileView>
        <div className="flex flex-wrap justify-start gap-2 mt-2">
          {_?.key_players?.split(",")?.map((_i) => (
            <span
              className={`px-2 rounded-3xl bg-green-800 shadow-xl text-white text-md font-semibold`}
            >
              {_i}
            </span>
          ))}
        </div>
      </MobileView>
    </div>
  );
};

export default CatagoryCards;
