export const infographicDta = [
  {
    name: "Edtech Industry",
    slug: "edtech-industry",
    image:
      "https://drive.google.com/uc?export=view&id=1xHxPVOJ44ZxqwlTPKRbERS9kJH49N8n_",
    description: "",
  },
  {
    name: "Sustainable Finance",
    slug: "sustainable-finance",
    image:
      "https://drive.google.com/uc?export=view&id=1z2YJDHyj7w4FnBC4BnZ2Hn9ITISHFKxt",
    description: "",
  },
  {
    name: "Unicorns in India",
    slug: "unicorns-in-india",
    image:
      "https://drive.google.com/uc?export=view&id=1H99uJAWOTNH45BD34m3_Gv3b96hFKB5r",
    description: "",
  },
];