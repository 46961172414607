import React, { useEffect, useState } from "react";
import slide1 from "../../../assets/images/blogs/Defi.png";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { blogsListData } from "../../../demo-data/blogs-data";

const Details = () => {
  const [blogDetailsData, setBlogDetailsData] = useState(null);
  let catagoryName = window.location.href.split("/");
  const { pathname } = useLocation();
  const slug = pathname
    ? pathname?.split("/")?.length > 0 &&
      pathname?.split("/")[pathname?.split("/")?.length - 1]
    : "";
  
  useEffect(() => {
    const slug = pathname
      ? pathname?.split("/")?.length > 0 &&
        pathname?.split("/")[pathname?.split("/")?.length - 1]
      : "";
    const getBlogDetailsData = blogsListData?.filter((_i) => _i?.slug == slug);
    setBlogDetailsData(
      getBlogDetailsData && getBlogDetailsData?.length > 0
        ? getBlogDetailsData[0]
        : null
    );
  }, []);
  return (
    <>
      <div className="mx-0 md:mx-40">
        <div className="p-2 md:p-8">
          <img
            src={blogDetailsData?.image ?? ""}
            className="rounded-lg object-cover mb-5"
          />
          <div className="flex justify-start gap-2 items-center align-center mb-5">
            <img
              src="https://drive.google.com/uc?export=view&id=1CIQgjiF6xXdwXrUcqMW-n6o5vvVQAZI5"
              className="w-10 h-10 rounded-full"
            />
            <p className="text-lg font-bold">{blogDetailsData?.author}</p>
            <span>{blogDetailsData?.published_date}</span>
          </div>
          <h1 className="text-left text-3xl md:text-5xl font-semibold mb-5">
            {blogDetailsData?.title}
          </h1>
          <div className="blogDetails">
            {parse(`${blogDetailsData?.content}`)}
          </div>
        </div>
      </div>
      {/* <div
        className={`flex relative h-1/2 w-3/5 overflow-hidden rounded-lg bg-white m-10 mx-auto`}
      >
        <img
          src={slide1}
          className="h-full w-full object-cover object-center"
        />
      </div>

      <div className="w-3/5 mx-auto">
        <h1 className="text-4xl text-start font-semibold text-gray-900 mx-auto mb-4">
          DECODING DEFI - FUTURE OF FINANCE.
        </h1>
        <h2 className="text-2xl text-start font-semibold text-gray-600 mx-auto">
          INTRODUCTION
        </h2>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          Web3.0, often known as Web3, is a planned evolution of the World Wide
          Web that aims to make it more intelligent, interconnected, and
          practical. It is also known as the "Semantic Web" or the "Intelligent
          Web". Web3 is built upon open protocols that value transparency and
          innovation. The goal of Web3.0 is to establish a decentralized Web
          that is supported by blockchain technology and artificial
          intelligence, as opposed to Web 1.0 and Web 2.0, which is concentrated
          on the presentation and sharing of information. The application of
          Web3.0 Technology enables the internet to be more personalized,
          accurate and intelligent. Web3 puts power in the hands of individuals
          rather than corporations. Let's look at how we got here before we
          discuss Web3.
        </p>
        <h2 className="text-2xl text-start font-semibold text-gray-600 mx-auto">
          WEB, WEB2.0 & WEB3.0 - THE EVOLUTION
        </h2>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          Tim Berners-Lee, a British computer scientist who applied the
          hypertext concepts for linking digital text presented in 1963 by Ted
          Nelson, an American information technology pioneer, created the first
          iteration, known as Web 1.0, in 1989. Web 1.0 began in the early 1990s
          and lasted until the mid-2000s. Websites in the Web 1.0 era were
          mostly static because they were mainly created using HTML (Hypertext
          Markup Language), which is a static language. The Websites were not
          very interactive. You couldn’t do much apart from reading stuff and
          publishing basic content for others to read. The focus was on making
          information available online and enabling users to access it. That's
          why, Web 1.0 is also called read only Web.{" "}
        </p>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          In a 1999 piece for print magazine, Darcy DiNucci used the term "Web
          2.0" for the first time. Web 2.0 was all about developing dynamic,
          interactive experiences that promoted user interaction, as opposed to
          prior iterations of the Web, which were primarily static and
          concentrated on providing information to users. Because of Web 2.0,
          people were now able to publish articles and comments, and it became
          possible to create user accounts on different sites, therefore
          increasing participation. However, Web 2.0 still has several drawbacks
          like centralization, interoperability, trust and many more.
        </p>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          These limitations have led to the development of Web3. With the help
          of distributed ledger technology (DLT), such as blockchain, Web3
          aspires to overcome these restrictions by building a more accessible
          and decentralized internet. In early 2006, a blog post by Jeffrey
          Zeldman that was "critical of Web 2.0 and associated technologies such
          as Ajax" introduced the phrase "Web 3.0," which was first used by John
          Markoff in the New York Times.{" "}
        </p>
        <h2 className="text-2xl text-start font-semibold text-gray-600 mx-auto">
          WEB, WEB2.0 & WEB3.0 - THE EVOLUTION
        </h2>
        <ul className="text-sm text-gray-500 text-start list-disc ms-8 m-2">
          <li className="mb-2">
            Decentralization: A key component of Web3 is decentralization, which
            refers to the idea that power and control should be shared among all
            users of a network rather than being concentrated in the hands of a
            small number of centralized institutions. Decentralization in Web3
            is made possible by blockchain technology, a distributed ledger that
            enables safe and transparent transactions without the use of
            middlemen.
          </li>
          <li className="mb-2">
            Interoperability: In Web3, interoperability refers to the
            development of a more interconnected and effective ecosystem where
            various systems can cooperate to achieve shared objectives. Common
            standards, cross-chain communication, decentralized exchanges, and
            oracles are some of the methods used to accomplish this.
            Interoperability has the potential to unleash the full power of Web3
            and build a more decentralized, open, and inclusive internet by
            allowing various systems to communicate with one another.
          </li>
          <li className="mb-2">
            Transparent and trust: Blockchain technology, which offers a
            transparent and immutable platform for data storage and processing,
            is used in Web 3.0 to achieve transparency. By eliminating the need
            for middlemen like banks or other centralized institutions and
            allowing users to independently verify transactions and data,
            blockchain technology helps to create a more open and reliable
            digital environment. Trust is also a critical characteristic of
            Web3. By creating decentralized systems, Web3 eliminates the need
            for trust in third-party institutions and enables trust between
            parties directly.{" "}
          </li>
          <li className="mb-2">
            Security and privacy: In security and privacy, Web3 also places a
            strong emphasis. It is crucial now more than ever to guarantee that
            user data is secure and protected due to the rise in data breaches
            and cyberattacks. Web3 does this by utilizing decentralized systems,
            in which data is kept throughout a network of nodes as opposed to in
            a single location. As a result, it is far more challenging for
            hackers to corrupt the system because they would have to do so
            concurrently with several nodes. Web3 also makes use of encryption
            technology to safeguard consumer data. This is essential for
            protecting sensitive information, such as financial transactions or
            personal details.{" "}
          </li>
        </ul>
        <h2 className="text-2xl text-start font-semibold text-gray-600 mx-auto">
          PEBBLES IN THE PATH - CHALLENGES
        </h2>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          <b>Scalability:</b> Scalability refers to the ability of a network to
          handle a large number of transactions per second (TPS) without
          compromising its security or effectiveness. While companies like Visa
          can process up to 1700 TPS, Bitcoin can only process 7 TPS, and
          Ethereum can handle 20 TPS. Scalability is a critical concern for Web
          3.0, as it aims to handle massive amounts of data and traffic. In
          order to accommodate the massive volumes of data that will be produced
          by decentralized applications (dApps), smart contracts, and other
          decentralized services, Web3 must be scalable. One of the main
          challenges facing Web3 is the limited processing power and storage
          capacity of individual nodes on the network. Unlike centralized
          systems, where large data centers can handle massive amounts of
          traffic and data, Web3 relies on a network of nodes, each with limited
          resources. To achieve this, Web 3.0 leverages a variety of
          technologies and techniques, such as sharding, layer 2 solutions, PoS
          consensus, interoperability, and optimized data storage.
        </p>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          <b>Interoperability:</b> Interoperability refers to the ability of
          different systems and networks to work together seamlessly. In the
          context of Web3, it means that different blockchain platforms,
          decentralized applications, and other components must be able to
          communicate and interact with each other. The lack of interoperability
          can lead to fragmentation, where different networks and applications
          operate in isolation, limiting their usefulness and adoption. It also
          makes it difficult for users to move assets and data between different
          platforms, reducing their flexibility and control. Several projects
          are working on solving interoperability challenges in Web3. One of the
          most promising is Polkadot, which uses a unique sharding architecture
          to allow different blockchain networks to connect and communicate with
          each other. Polkadot is also called the ‘internet of blockchains’.
        </p>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          <b>Regulatory and legal issues:</b> Web3 faces regulatory and legal
          issues that can impact its development and adoption. One of the main
          issues is the lack of clarity and consistency in regulations around
          blockchain technology and cryptocurrencies. Governments and regulatory
          bodies worldwide are struggling to create a regulatory framework that
          balances innovation and consumer protection. Another issue is the
          potential for illegal activities such as money laundering and
          terrorism financing facilitated by the anonymity of blockchain
          transactions. Additionally, there is a risk of smart contracts being
          used to facilitate illegal activities. To overcome these challenges,
          collaboration between governments, regulators, and the industry is
          necessary to create a balanced regulatory framework that fosters
          innovation while protecting consumers and preventing illegal
          activities.
        </p>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          <b>User adoption:</b> User adoption is a crucial aspect of the success
          of any new technology, and Web 3.0 is no exception. Web3 aims to
          create a more decentralized and open internet, allowing users to have
          more control over their data and online activities. However, for this
          vision to become a reality, it is essential that users adopt and use
          Web 3.0 technologies. One challenge to user adoption is the lack of
          awareness and education about Web 3.0. Many users may not even be
          aware of the benefits that Web 3.0 technologies can offer, such as
          improved privacy, security, and control over personal data. Therefore,
          it is crucial to educate users about these benefits and how to use Web
          3.0 technologies effectively. Another challenge is the user
          experience. Web3 technologies can be complex and difficult to use,
          which may deter some users from adopting them. Therefore, it is
          important to design user-friendly interfaces and experiences that make
          it easy for users to interact with Web 3.0 technologies. Ultimately,
          user adoption in Web 3.0 will depend on how well the technology
          addresses users' needs and concerns, as well as how effectively the
          industry can promote and educate users about the benefits of a
          decentralized and open internet.
        </p>
        <h2 className="text-2xl text-start font-semibold text-gray-600 mx-auto">
          NONETHELESS, THIS IS FUTURE
        </h2>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          <b>Growth potential and market opportunities: </b>Web3 opens up a wide
          range of market potential, especially for companies who work in the
          technology industry. Making use of blockchain technology to create
          decentralized applications (dApps) is one of the biggest prospects.
          Industry sectors including finance, healthcare, and supply chain
          management could be completely transformed by these dApps. The
          expansion of the peer-to-peer transactions without the use of
          middlemen enabled by the decentralized finance (DeFi) ecosystem is
          another opportunity. In the market for fine art and collectibles, the
          emergence of non-fungible tokens (NFTs) is opening up new
          possibilities. Businesses that can innovate and take use of the
          potential of this new technology will have more chances as Web3
          develops.
        </p>
        <h2 className="text-2xl text-start font-semibold text-gray-600 mx-auto">
          CALL TO ACTION
        </h2>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          Web3.0 is the future of the Internet and it is very important that we
          understand its importance and potential. As we can see, Web3.0 offers
          a more decentralized, secure and connected network than its
          predecessor. To fully embrace Web3.0, we need to know and commit to
          this technology. Here are some steps you can take to contribute to
          Web3.0:
        </p>
        <ul className="text-sm text-gray-500 text-start list-disc ms-8 m-2">
          <li className="mb-2">
            Learn more about Web3.0: Read articles, watch videos, and attend
            online events to educate yourself about Web3.0 and its potential
            benefits.
          </li>
          <li className="mb-2">
            Participate in Web3.0 projects: You can join various projects
            related to blockchain technology and Web3.0.
          </li>
          <li className="mb-2">
            Support Web3.0 startups: Many startups are working on innovative
            Web3.0 projects. By supporting these startups, you can help bring
            Web3.0 closer to reality.
          </li>
          <li className="mb-2">
            Advocate for Web3.0: Share your knowledge and enthusiasm about
            Web3.0 with your family, friends, and colleagues.
          </li>
        </ul>
        <h2 className="text-2xl text-start font-semibold text-gray-600 mx-auto">
          CONCLUSION
        </h2>
        <p className="m-2 text-sm text-gray-500 text-start mx-auto">
          The importance of Web3 lies in its potential to create a truly open
          and decentralized internet that benefits everyone. With Web3, users
          will be able to own and control their data, identities, and digital
          assets without the need for intermediaries. This will give users more
          control and autonomy over their digital lives, and it will also
          promote innovation and competition by lowering the barriers to entry
          for new players. To realize the potential of Web 3.0, it is important
          to address the challenges of scalability, interoperability, and
          regulatory and legal issues. We also need to focus on user adoption by
          creating education and user-friendly interfaces that make it easy for
          people to join the Web3 revolution. With these efforts, we can create
          a more open, transparent, and decentralized internet that benefits
          everyone.
        </p>
      </div> */}
    </>
  );
};

export default Details;
