export const TOSlistData = [
  {
    name: "Klue",
    link: "https://propliners.in/",
    description:
      "Propliners reality takes complete care of their investors and buyers as we are taking the properties for their own selves thus they take care of all aspects related to the properties like legal-check, proper documentation as per the local authority norms and most important guaranteed best price/rentals.",
    legal_name: "Propliners reality",
    headquarter: "Noida, Uttar Pradesh, India",
    business_model: "B2B",
    founding_date: "2018",
    employee_count: "10",
    team_member: [
      {
        name: "Achal Agarwal",
        designation: "Founder",
        profile_link: "https://www.linkedin.com/in/achal-agarwal-411a4037/",
      },
      {
        name: "Himanshu Propliners",
        designation: "Co-Founder",
        profile_link:
          "https://www.linkedin.com/in/himanshu-propliners-realty-noida/",
      },
    ],
    revenue_stream:
      "Advertising, Affiliate Marketing, E-commerce - Service, Lending",
    client_segment:
      "Administrative Services,  Consumer,  Services,  Media,  E-Commerce",
    target_companies: "Small Enterprise",
    target_geography: "India",
    user_age: "",
    user_income: "",
    user_location: "",
    color: "#dbeafe",
    category: "Fintech",
    startupName: "Klue",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1k6PJItGxjZFPgw9II_AKQshBlFS8IdhA",
    startupCount: "12K+",
  },

  {
    name: "RentNet",
    link: "https://rentnet.in/",
    description:
      "rentNet: Free Property Management & Maintenance Software for 5 Properties. Simplify Real Estate Management using the best application. Collect Rent and Manage Properties with Ease!",
    legal_name: "",
    headquarter: "Bangalore, Karnataka, India",
    business_model: "B2B",
    founding_date: "2023",
    employee_count: "21 to 40",
    team_member: [
      {
        name: "Nikhil Adiga",
        designation: "Founder",
        profile_link: "https://www.linkedin.com/in/nikhil-adiga-253503176/",
      },
    ],
    revenue_stream: "Fee-For-Service",
    client_segment: "Real Estate  ",
    target_companies:
      "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
    target_geography: "India,  Global ",
    user_age: "",
    user_income: "",
    user_location: "",
    color: "#d1fae5",
    category: "Edtech",
    startupName: "Keya Medical",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1k6xABozAjm9GnDasqJDFxREqALAB0Nhi",
    startupCount: "14K+",
  },

  {
    name: "Piscis Networks",
    link: "https://piscisnetworks.com/",
    description:
      "Piscis Networks is an India-based OEM company that provides an SD-WAN solution to organizations of all sizes. Their SD-WAN solution is designed to help organizations improve their network performance, reliability, and security while reducing costs. Piscis Networks' SD-WAN solution is designed to be easy to deploy and manage, and it can be integrated with existing network infrastructure. The company also offers a range of professional services, including network assessment, design, and deployment, to help organizations get the most out of their SD-WAN investment. Overall, Piscis Networks is a reputable vendor that offers a comprehensive SD-WAN solution that can help organizations improve their network performance, reliability, and security.",
    legal_name: "Piscis Networks Private Limited",
    headquarter: "Mumbai, Maharashtra , India",
    business_model: "B2B",
    founding_date: "2021",
    employee_count: "<10",
    team_member: [
      {
        name: "Mandar Lokegaonkar",
        designation: "Founder & Director",
        profile_link: "https://www.linkedin.com/in/mandar-lokegaonkar/",
      },
    ],
    revenue_stream: "Licensing",
    client_segment:
      "E-Commerce,  Government & Military,  HealthTech / MedTech / Healthcare,  Logistics  ",
    target_companies:
      "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise",
    target_geography: "Global ",
    user_age: "",
    user_income: "",
    user_location: "",
    color: "#ffedd5",
    category: "Healthtech",
    startupName: "Kavout",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1kAJi5hbKGibYmRmIjs64lCv3aqOlB_Ad",
    startupCount: "18K",
  },

  {
    name: "ORBITX INDIA AEROSPACE",
    link: "https://www.orbitx.in/",
    description:
      "Jaipur based, ORBITX INDIA AEROSPACE is developing reusable launch vehicle 'Atal-Yaan' to make space access more accessible and commercialize space travel.",
    legal_name: "ORBITX INDIA AEROSPACE PVT LTD",
    headquarter: "Jaipur, Rajasthan, India",
    business_model: "",
    founding_date: "2020",
    employee_count: "61 to 100",
    team_member: [
      {
        name: "Diraja Singh",
        designation: "Director",
        profile_link: "",
      },
      {
        name: "Pankaj Singh",
        designation: "Director",
        profile_link: "",
      },
    ],
    revenue_stream: "",
    client_segment: "",
    target_companies: "",
    target_geography: "",
    user_age: "",
    user_income: "",
    user_location: "",
    color: "#bae6fd",
    category: "Agritech",
    startupName: "ioCurrents",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1kCLyqH2tJE9NHNmGGos2x6VKfd6qZTKS",
    startupCount: "50+",
  },

  {
    name: "Aone Packers And Movers",
    link: "https://aonepacker.com/",
    description:
      "Aone Packers And Movers are one of the best Packers and Movers in Gurgaon. Their team of highly proficient and experienced professional packers and movers in Gurgaon. Their team of experts acknowledges the importance of your belongings and takes every measure to ensure that they are safely packed, loaded, and transported to your new destination.",
    legal_name: "",
    headquarter: "Gurgaon, Haryana, India",
    business_model: "B2C",
    founding_date: "1980",
    employee_count: "11 to 20",
    team_member: [
      {
        name: "SHUBHAM PATEL",
        designation: "Founder",
        profile_link: "",
      },
      {
        name: "Pankaj Singh",
        designation: "Director",
        profile_link: "",
      },
    ],
    revenue_stream: "",
    client_segment: "Logistics",
    target_companies: "Medium Enterprise  ",
    target_geography: "India ",
    user_age: "18 to 25  ",
    user_income: "Lower-middle Income",
    user_location: "India",
    color: "#fef2f2",
    category: "Spacetech",
    startupName: "Inyore",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1kLCiQ0K99pOb93kA0tN9C7gXtUorrb3X",
    startupCount: "20K",
  },

  {
    name: "Halfsquare Inc",
    link: "https://halfsquare.io/",
    description:
      "HalfSquare Inc an innovative cyber security and data security company, providing top-notch services to our clients. We specialize in preventing the unauthorized access of data by defending networks, systems, and database from cyber-attacks and vulnerabilities. We keep our clients data protected using the latest technologies, such as AI-driven dataprotection, Encryption, and Next-Generation Firewalls. Our experienced professionals provide custom solutions customized to the needs of each individual customer. As a team, we strive hard to make sure that each of our customers is provided with superior cybersecurity tailored to their specific circumstances.",
    legal_name: "",
    headquarter: "New York, New York, United States of America",
    business_model: "B2B",
    founding_date: "1st Jan 2022",
    employee_count: "151 to 250",
    team_member: [
      {
        name: "Rahul Vinodkumar Dadhich",
        designation: "Founder and CEO",
        profile_link: "https://www.linkedin.com/in/yesiamdadhich/",
      },
    ],
    revenue_stream: "Subscription, Fee-For-Service",
    client_segment: "",
    target_companies: "",
    target_geography: "",
    user_age: "",
    user_income: "",
    user_location: "",
    color: "#fef9c3",
    category: "Foodtech",
    startupName: "Theta Additive",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1ki-99T-1j6CMEUWNKSydq4oAAhOFUMfH",
    startupCount: "10K",
  },

  {
    name: "Aeroin SpaceTech",
    link: "https://www.spaceaero.in/",
    description:
      "Aeroin SpaceTech is an “Aerospace Research Startup”.This establishment deals with all the projects related to Space Science and Technology. The main motto is to connect the world through SpaceTech. Aeroin focuses on building advanced Space Technology. It essentially consists of a collaboration that takes part in projects, research articles, and patent publications together. Aeroin currently working on Electric Propulsion System for Cubesat , Sounding rocket and dealing with the STEM engagement program. This collaboration mainly focuses to develop a brilliant and the most innovative space sector in INDIA itself. They are ready to collaborate with all SpaceTech companies. Lets fly to Space They not only build but also teach.",
    legal_name: "Aeroin SpaceTech Pvt. Ltd",
    headquarter: "Krishnagiri, Tamil Nadu, India",
    business_model: "B2B, B2C",
    founding_date: "2022",
    employee_count: "<10",
    team_member: [
      {
        name: "MS Ragul",
        designation: "Founder & CEO",
        profile_link: "https://www.linkedin.com/in/ragulms/",
      },
    ],
    revenue_stream: "Other",
    client_segment:
      "Aerospace & Defense,  Collaboration,  DeepTech,  SpaceTech  ",
    target_companies: "Startup",
    target_geography: "India",
    user_age: "26 to 34",
    user_income: "Lower-middle Income  ",
    user_location: "India",
    color: "#ffe4e6",
    category: "Web3",
    startupName: "Humanly",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1kcs1xpHXgRitFsoXA4mFOC3-PC8GFsXD",
    startupCount: "30K+",
  },

  {
    name: "Wavetech Telecom",
    link: "https://www.wavetechtelecoms.com/",
    description:
      "Wavetech Telecom Pvt Ltd. takes pleasure in introducing itself as a prominent Manufacturer, Exporter, Importer, Supplier and Service Provider of the market for the comprehensive range of Products such as RF Telecom microwave & Optical components With a vision to provide world-class products such as all types of RF components for CDMA, WCDMA, 2G, 3G, 4G, 5G & WIFI. Apart from products, they are also known for offering End to End In Building Solutions, WIFI solutions for Indoor & Outdoor Spaces, they also take Annual Maintenance Contracts/Sub Contracts for our offered products; therefore, you get all solutions under one roof and at the most competitive prices. They are pleased to inform you that the company's phenomenal success is related to the fact that the organization operates in a customer-centric manner by supplying innovative, cost-effective & suitable technical solutions of superior quality with zero defects in the shortest possible time.",
    legal_name: "Wavetech Telecom Private Limited",
    headquarter: "Bengaluru, Karnataka, India",
    business_model: "B2B",
    founding_date: "2012",
    employee_count: "21 to 40",
    team_member: [
      {
        name: "Srinivas Raju Chinda",
        designation: "Founder & Managing Director",
        profile_link:
          "https://www.linkedin.com/in/chinda-srinivas-raju-45138422/",
      },
    ],
    revenue_stream: "Fee-For-Service",
    client_segment: "Medium Enterprise  ",
    target_companies: "India,  Asia Pacific  ",
    target_geography: "India",
    user_age: "26 to 34,  35 to 45,  46 to 60 ",
    user_income: "Lower-middle Income,  Upper-middle Income,  High Income  ",
    user_location: "India,  Asia Pacific  ",
    color: "#fae8ff",
    category: "Deeptech",
    startupName: "Neuconomi",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1kYug201dyvQNS_1pXWicJkZ4Kho6yKx3",
    startupCount: "9K",
  },

  {
    name: "Astranis",
    link: "https://www.astranis.com/",
    description:
      "Astranis is a developer of a new satellite technology that aims to transmit data down to specific terrestrial locations with each satellite it launches. The company's mission is to help get online the 4 billion people who are without internet access. It was founded in 2015 and is headquartered in San Francisco, California.",
    legal_name: "",
    headquarter: "San Francisco, California, United States",
    business_model: "",
    founding_date: "2015",
    employee_count: "251 to 500",
    team_member: [
      {
        name: "Ryan McLinko",
        designation: "Co-Founder",
        profile_link: "https://www.linkedin.com/in/mclinkor/",
      },
      {
        name: "John Gedmark",
        designation: "Co-Founder & CEO",
        profile_link: "https://www.linkedin.com/in/johngedmark/",
      },
    ],
    revenue_stream: "",
    client_segment: "",
    target_companies: "",
    target_geography: "",
    user_age: "",
    user_income: "",
    user_location: "",
    color: "#e2e8f0",
    category: "SaaS",
    startupName: "Ctruh",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1kXiPfZXXBB6Cs62mEGEPwp56JfFoj20D",
    startupCount: "3K+",
  },

  {
    name: "Nile",
    link: "https://nilesecure.com/enterprise-network/security/",
    description:
      "Nile understand that to take care of their customers, they must take care of their team members. To be the best company, they must be their best selves. Every team member has a voice at Nile, one that is always valued, respected, and appreciated. These values define each of them and all of them, with innovation at the center of all they do.",
    legal_name: "Nile Secure",
    headquarter: "San Jose, California, United States of America",
    business_model: "B2C",
    founding_date: "2020",
    employee_count: "<10",
    team_member: [
      {
        name: "Ali Burhan",
        designation: "",
        profile_link: "https://www.linkedin.com/in/abratul/",
      },
    ],
    revenue_stream:
      "Advertising, E-commerce - Service, Commission on transaction, E-commerce - Product",
    client_segment: "",
    target_companies: "",
    target_geography: "",
    user_age: "26 to 34,  More than 60",
    user_income: "Lower-middle Income,  Upper-middle Income  ",
    user_location: "Global,  North America,  South America  ",
    color: "#e2e8f0",
    category: "E-Commerce",
    startupName: "ecoRobotix",
    startupLogo:
      "https://drive.google.com/uc?export=view&id=1kQThNQazYOenEPtehS4rLqVSLJtTkNfG",
    startupCount: "1K",
  },
];

export const tosData = [
  {
      "startup_name": "VirtualGGC",
      "website": "https://virtualggc.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1C-_Nd8H9pfKBNxiEwQmq-SfbT8jjMvjj",
      "profile_summary": "At VirtualGGC, they are committed to transforming the way you approach finances by providing a unique blend of comprehensive financial services and top-notch educational resources. Their goal is to empower individuals like you to take control of your financial well-being and achieve your dreams in the virtual world. Financial Services: Their team of experienced financial experts is dedicated to guiding you through the complexities of the financial landscape. Whether you're looking for personalized investment strategies, retirement planning, debt management, or assistance with budgeting and saving, VirtualGGC has you covered. They believe that everyone deserves access to reliable financial services that align with their goals, and we strive to make the process efficient, transparent, and tailored to your needs. Financial Education: At VirtualGGC, they understand that financial literacy is crucial for long-term success. That's why they go beyond traditional financial services and provide wealth.",
      "legally_known_as": "GGC'S PRACTICAL TRAINING ACADEMY PRIVATE LIMITED",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "41 to 60",
      "team_member_1": "Ritika Agarwal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/sumit-tajpuriya-9b51323a/",
      "team_member_2": "Sumit Tajpuriya",
      "revenue_stream": "E-commerce product ",
      "customer_group": "Collaboration, Consumer, Services",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60 ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  "
  },
  {
      "1": "Rakesh Yadav",
      "2": "Aditya Naik",
      "3": "Rahul Raj",
      "startup_name": "BitSave",
      "website": "https://www.bitsave.club/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1C3Y7OpX9wQN0KLCNImEj6MZa16NTTUu2",
      "profile_summary": "BitSave is a comprehensive crypto investment platform designed to solve the problem of asset selection and capital loss for long-term investors. BitSave functions by offering a range of index funds based on reputable and trusted cryptocurrency indices, such as Bloomberg's Crypto Index BGCI.",
      "legally_known_as": "Casper Wealth Pte. Ltd",
      "industry": "Fintech",
      "based_out_of": "Singapore, Global ",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Zakhil Suresh",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/vishnukarthikeyan/",
      "team_member_2": "Asif Kattakath",
      "team_member_3": "Vishnu Karthik",
      "revenue_stream": "Fee-for-service",
      "age_group": "26 to 34,  35 to 45  \n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global",
      "total_funding_millions": "0.08"
  },
  {
      "startup_name": "Sharpely",
      "website": "https://sharpely.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1C1zXysfyxgA5eS8V6BxS866d8V6ZN-q-",
      "profile_summary": "Thane-based startup Company was founded by Shubham Satyarth in 2023. sharpely is a data-driven investment research platform. SEBI registered Research Analyst. SEBI Registration no. INH000009524",
      "legally_known_as": "Mintbox Solutions Private Limited",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "11 to 20",
      "team_member_1": "Shubham Satyarth",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/shubham-satyarth-03910021/",
      "revenue_stream": "Freemium, subscription ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  \n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  "
  },
  {
      "startup_name": "AGAM AI",
      "website": "https://agam.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1CeB61bYR0DP0shVqe96YaaIQERhnjvht",
      "profile_summary": "AGAM is an AI driven digital SaaS platform connecting individual and businesses to banks, NBFIs and MFIs to enable instant access to finance. Available in API, APK and SDK for banks and digital financial platforms alike to connect and setup lending engine. AGAM enables banks and financial institutions to facilitate end to end digital lending with credit scoring and borrowing mechanism.",
      "legally_known_as": "AGAM AI",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Shabnam Wazed",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/rohitsankhe/",
      "team_member_2": "Rohit Sankhe"
  },
  {
      "startup_name": "Tellephant",
      "website": "https://www.tellephant.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1C_NYTeLWb9Il0IUkyo6KNrb04PIYdvKg",
      "profile_summary": "Tellephant helps your business to leverage the WhatsApp Business API to grow your business at the speed of messaging. Automate your Sales, Marketing, and Customer Support on WhatsApp with Tellephant’s Conversational Commerce Suite.",
      "legally_known_as": "Tellephant",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "founded_in": "2021",
      "employees_headcount": "41 to 60",
      "team_member_1": "Suhas Entur",
      "position": "Co-Founder & CPO",
      "profile_link": "https://www.linkedin.com/in/suhas-entur/"
  },
  {
      "startup_name": "Prooof",
      "website": "https://www.prooof.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1CY4l1pxXEkveS1Uc5xNlimfkvZT72Mtg",
      "profile_summary": "Their company's mission is to provide free ERP software solutions for small and medium-sized businesses/organizations. Their software aims to help entrepreneurs, business persons, and schools save money that is typically spent on purchasing and maintaining ERP software. By offering their software for free, they enable small and medium-sized organizations to focus on their core business growth, rather than being burdened with infrastructure maintenance costs.",
      "legally_known_as": "Rizzle Technology Private Limited",
      "industry": "Fintech",
      "based_out_of": "Andhra Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Poorna Shekar Reddy Puli",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/psrpuli/",
      "revenue_stream": "Advertising, commission on transactions, Freemium",
      "customer_group": "Administrative Services,  AdTech,  Consumer,  Services,  Enterprise ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  North America,  Africa  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  North America,  Africa "
  },
  {
      "startup_name": "Fincover",
      "website": "https://www.fincover.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1CQpt7vkHQvQU8neVSMlwURJioMHXvy9-",
      "profile_summary": "Fincover is one of India’s fastest-growing fintech sites and helps you quickly make correct financial decisions. They aim to simplify the world of personal finance and ensure transparency at every stage. With that in mind, they have built a platform to help you find the most suitable financial products. At Fincover you can compare prices and benefits to buy a range of financial products such as insurance policies, investment plans, loans, and savings schemes from multiple institutions. They have tied up with leading banks, insurance companies, and NBFCs to help you get the best deals on loans and various other investment schemes.",
      "legally_known_as": "Fincover Financial Services Private Limited",
      "industry": "Fintech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "151 to 250",
      "team_member_1": "GURUMOORTHY ANTHONYSAMY",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/gurumoorthy-anthonysamy/",
      "revenue_stream": "commission on transaction",
      "customer_group": "FinTech,  Insurance",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  \n\n",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "1": "Sanabil 500 MENA",
      "startup_name": "EdfaPay",
      "website": "https://edfapay.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Csk58tzxHU5nb57DZa6JgvfWxts_iAp9",
      "profile_summary": "EdfaPay was established on Jan 2022 as a financial payment service provider serving retail business owners covering all their payment needs. EdfaPay has started to provide the payment services in SAUDIA Arabia and has recently launched a new strategy to expand internationally and started to offer the payment services in the Pakistani and the South American markets. The aim of this funding will be focus on executing the strategy and to expand its service.",
      "legally_known_as": "EdfaPay",
      "industry": "Fintech",
      "based_out_of": "Saudi Arabia, Global ",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Ghormallah Al-Ghamdi",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/nedal-sabbah-5312b634/",
      "team_member_2": "Nedal Sabbah",
      "revenue_stream": "Licensing, commission on transaction ",
      "customer_group": "E-Commerce,  FinTech,  Services,  Tourism  \n",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  \n\n",
      "geographical_segment": "Global,  Europe,  South America,  Africa  \n\n",
      "total_funding_millions": "3.2"
  },
  {
      "1": "SCOR Ventures",
      "2": "Breega",
      "startup_name": "Coverflex",
      "website": "https://www.coverflex.com/en-pt",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1D12ODjdjPaegXJVt9C7QCw9roROb3fCO",
      "profile_summary": "Coverflex is on a mission to improve the way companies compensate their people, making it more transparent, flexible and easier for everyone to make the most of what they get. Through a fully digital solution, Coverflex helps companies manage every part of compensation beyond salary — benefits, insurance, meal allowance and exclusive discounts — while empowering people to spend on what suits them.",
      "legally_known_as": "Coverflex",
      "industry": "Fintech",
      "based_out_of": "Portugal, Global ",
      "founded_in": "2019",
      "employees_headcount": "101 to 150",
      "team_member_1": "Luis Rocha",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/tiago-m-fernandes/",
      "team_member_2": "Nuno Pinto",
      "team_member_3": "Rui Carvalho",
      "team_member_4": "Tiago Fernandes"
  },
  {
      "1": "Singularity Ventures",
      "2": "Netgraph Investment",
      "3": "LV Angel Fund",
      "4": "Catalyst Fund LP",
      "5": "Artha Venture Fund",
      "startup_name": "KarmaLife",
      "website": "https://karmalife.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1CgNPj-laevdCrRztbzsowo3mPKR9iNxM",
      "profile_summary": "KarmaLife empowers gig & blue collar workers with earnings-linked finance offered on a subscription model. It addresses the unmet need for small-ticket, recurring finance, which helps low-income earners address cash flow volatility, grow earnings and increase resilience. Through the combination of a dynamic, AI-powered credit underwriting engine and a trust-first user experience, KarmaLife offers affordability and value at scale. It partners with employers to channel its solutions, access work data, and link repayments to worker payouts. Employers are able to offer financial benefits at low overhead and no liability, and also improve worker engagement and retention. Within two years, KarmaLife has become India’s #1 Earned Wage Access provider.",
      "legally_known_as": "Onionlife Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "61 to 100",
      "team_member_1": "Rohit Rathi",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/badalmalick/",
      "team_member_2": "Naveen Budda",
      "team_member_3": "Badal Malick"
  },
  {
      "startup_name": "IndiaBizForSale",
      "website": "https://www.indiabizforsale.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Ck7VKhOiO23WfhyBrVKgm2EVy9gYdR9t",
      "profile_summary": "IndiaBizForSale connects SMEs and Startups with capital, acquirers, partners, distributors, advisors. Currently, more than 130,000 professionals from India and abroad. With a dedicated and professional team; IndiaBizForSale.com provides end-to-end business transaction support related to Mergers and Acquisitions, Joint Venture, Partnership, Fund Raise - Equity, Debt, Investment Opportunities for SMEs.",
      "legally_known_as": "MANALI E-BUSINESS PVT LTD",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B",
      "founded_in": "2013",
      "employees_headcount": "11 to 20",
      "team_member_1": "Haripriya B. Bhagat",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/bhavinbhagat/",
      "team_member_2": "Bhavin Bhagat",
      "revenue_stream": "Subscription ",
      "customer_group": "FinTech",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  \n\n",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  \n"
  },
  {
      "1": "Breega",
      "2": "SCOR Ventures",
      "startup_name": "Coverflex",
      "website": "https://www.coverflex.com/en-pt",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1D12ODjdjPaegXJVt9C7QCw9roROb3fCO",
      "profile_summary": "Coverflex is on a mission to improve the way companies compensate their people, making it more transparent, flexible and easier for everyone to make the most of what they get. Through a fully digital solution, Coverflex helps companies manage every part of compensation beyond salary — benefits, insurance, meal allowance and exclusive discounts — while empowering people to spend on what suits them best.",
      "legally_known_as": "Coverflex",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "founded_in": "2019",
      "employees_headcount": "101 to 150",
      "team_member_1": "Luis Rocha",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/tiago-m-fernandes/",
      "team_member_2": "Nuno Pinto",
      "team_member_3": "Rui Carvalho",
      "team_member_4": "Tiago Fernandes"
  },
  {
      "startup_name": "TesQuirel",
      "website": "https://www.tesquirel.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DGe3vyibQugJ-rMQSfsRDsktnH-_Sw0N",
      "profile_summary": "Started in 2018, TesQuirel aims to ease the work of the manual tester by automating mundane tasks without writing a single line of code. Organizations can benefit from the reduced workloads, increased speed to market and bug-free deliveries within a short period. This increases speed to market and reduces costs.",
      "legally_known_as": "TesQuirel Solutions Private Limited",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Srilakshmi Krishnamurthy",
      "position": "Co-Founder & Director",
      "profile_link": "https://www.linkedin.com/in/prasadjwalapuram/",
      "team_member_2": "Renu Aggarwal",
      "team_member_3": "Prasad Jwalapuram",
      "revenue_stream": "Licensing,  Subscription, Fee-for-service",
      "customer_group": "FinTech,  Insurance,  Software,  SaaS  ",
      "client_business_structure": "Startup,  Medium Enterprise,  Large Enterprise ",
      "geographical_segment": "India,  Asia Pacific,  Europe,  North America  "
  },
  {
      "startup_name": "ArrowPe",
      "website": "https://arrowpe.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DGcU_W36dfbiKlJh_hf3BJHW31bQTpAa",
      "profile_summary": "ArrowPe Inc. is a cross-border FinTech company that offers innovative solutions to financial services and institutions. Our goal is to create a more accessible and affordable financial system for everyone. We specialize in cross-border payments, currency exchange, and more. Our mission is to provide financial services and products that are uncorrupted by either political or economic forces and are superior in terms of quality, speed, and convenience. With our cutting-edge technology, we strive to revolutionize the way financial transactions take place, making them more efficient and secure.",
      "legally_known_as": "ArrowPe Inc",
      "industry": "Fintech",
      "based_out_of": "Singapore, Global",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "11 to 20",
      "team_member_1": "Rahul Vinodkumar Dadhich",
      "position": "Co-Founder",
      "profile_link": "https://in.linkedin.com/in/yesiamdadhich",
      "revenue_stream": "Advertising, Freemium, Lending, commission on transaction "
  },
  {
      "startup_name": "Gyizer ",
      "website": "https://gyizer.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DAEsFLkmqF0l2GdCsAd3gc0xqmuurqIk",
      "profile_summary": "At Gyizer, They're on a mission to make advanced technology accessible to everyone. They're passionate about their commitment to upholding the highest standards of integrity and building strong relationships with people. Their values are at the core of everything they do. Gyizer's identity is centered around a culture of trust and a collective drive to challenge limits and provide inventive solutions that improve people's lives. Whether it's in product development or customer support, Gyizer's approach is always centered on making a positive impact, all while maintaining the highest standards of integrity. With a strong focus on making edge technology accessible to all, Gyizer has become a trusted and respected partner for clients worldwide. Its team of talented and value-driven individuals are passionate about leveraging technology to create a better world.",
      "legally_known_as": "Gyizer Systems Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2017",
      "employees_headcount": "41 to 60",
      "team_member_1": "Nayan Gaikwad",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/nayan-gaikwad/",
      "revenue_stream": "Freemium, Subscription, E-commerce - product, E-commerce - service",
      "customer_group": "Cryptocurrency",
      "client_business_structure": "Small Enterprise,  Large Enterprise ",
      "geographical_segment": "India,  Global,  Europe,  North America  "
  },
  {
      "startup_name": "Pepcorns",
      "website": "https://pepcorns.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DE4hLaXmA6gFqbd_gyRiPFOwwNEv1BFy",
      "profile_summary": "Pepcorns is an inclusive investment platform for everyone, connecting private investors, founders, VCs, angels, experts, and mentors. Invest in potential unicorns starting with as little as INR 1000, or raise capital for your startup with their easy application process.",
      "legally_known_as": "Pepcorns Technologies Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Ashish Pathak",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ashishpathak0513/",
      "revenue_stream": "Advertising, commission on transaction, Fee-for-service",
      "customer_group": "Financial Services",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  \n\n",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  Global  "
  },
  {
      "startup_name": "Haru Invest",
      "website": "https://haruinvest.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DQpgWJA1esfC5LuKIZ3t1llKfb5p-vqB",
      "profile_summary": "Haru Invest is a centralized finance (CeFi) digital asset management platform consisting of an expert in-house trading team dedicated to helping you make smarter financial decisions through innovative crypto experiences. We use an algorithmic trading model to provide the most returns on BTC, ETH, USDT, USDC, and XRP deposits. This model allows us to work towards our vision to provide our members with maximum revenue while bearing minimal risk.",
      "legally_known_as": "Haru Management Limited",
      "industry": "Fintech",
      "based_out_of": "Singapore, Global ",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "11 to 20",
      "team_member_1": "Hugo Hyungsoo Lee",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/hugo-hyungsoo-lee-297b4298/",
      "revenue_stream": "Commission on transaction ",
      "age_group": "18 to 25,  26 to 34  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Nimble Appgenie",
      "website": "https://www.nimbleappgenie.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DR9qwSF-FzfT9nLQ_8TWIs8mgi1vozQS",
      "profile_summary": "Nimble Appgenie has been offering extraordinary kinds of help to its clients and experts in the hour of mobile applications and online development. Nimble AppGenie has been a top App development company in USA. We offer incredible mobile apps that are completely included to fan out and shiny new affiliations. We are a fair Mobile app development firm which has helped establishments in getting on the web.",
      "legally_known_as": "Nimble Apps LTD",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global ",
      "business_model": "B2B, B2C",
      "founded_in": "2017",
      "employees_headcount": "21 to 40",
      "team_member_1": "Jermaine Trotman",
      "position": "Managing Partner",
      "profile_link": "https://uk.linkedin.com/in/jermainetrotman-nimbleappgenie",
      "revenue_stream": "Commission on transaction, Lending "
  },
  {
      "startup_name": "munshiG",
      "website": "https://munshig.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DaRn2C-bntPriQ081pYpeRBINTUphLwr",
      "profile_summary": "munshiG is provider of a book keeping software designed to use artificial intelligence to make billing easy. The company's application provides transaction log, reminders when a transaction is saved, tracking expenses and save ledgers, enabling users to manage their business accounts with ease. The app is available for android ",
      "legally_known_as": "munshiG",
      "industry": "Fintech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Nishant Sharma",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/inu8421/",
      "team_member_2": "Ishan Mohammed"
  },
  {
      "startup_name": "Zenus Bank ",
      "website": "https://zenus.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DeI6LrYPhQ8JWjkIuAT0iPolWAt4cUad",
      "profile_summary": "Their mission is to take banking beyond borders. Operating in over 180 countries, they enable people and businesses to open a US bank account online, without the need to be a US citizen, resident, or company registered in the US — opening-up the security, stability and freedom of US banking to the world. As a signatory of the UN’s Principles for Responsible Banking, they are committed to making finance fair.",
      "legally_known_as": "Zenus Bank International",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "41 to 60",
      "team_member_1": "Mushegh Tovmasyan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/forexify/",
      "revenue_stream": "other",
      "customer_group": "FinTech",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  \n\n",
      "geographical_segment": "India,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  \n\n",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Mollet",
      "website": "https://mollet.co.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Du63ec0R-xZXT8AiM_lYOuJaLysFvRkc",
      "profile_summary": "Mollet is a B2B2C fintech startup building a single multi-currency instant payment system for worldwide banks and bank customers. MOLLET Unique and innovative ai Cross-Border, Cross-Currencies Hybrid Payment System That Works with No Internet, Low Internet and with Internet Designed and Built for World Wide Banks and Individuals.",
      "legally_known_as": "Mollet Tech Private Limited",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Raj Tore",
      "position": "Co-Founder & Director",
      "profile_link": "https://www.linkedin.com/in/arpita-tore-1b8866202/",
      "team_member_2": "Arpita Tore",
      "revenue_stream": "Fee-for-service, Commission on transaction, Subscription ",
      "customer_group": "FinTech,  Software,  Technology  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  \n",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Logical Soft tech",
      "website": "http://logicalsofttech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DtOcNSkpXn2GPH4D9CvGj4GzASZyoRsu",
      "profile_summary": "LogicalSoftTech is an IT Firm located in Central India, we deals in IT projects related to Mobile app Android and ios, web development, Game Development on UNITY 3d, AR/VR, Block chain. We provide Mobile App design and development for your business, B2B, B2C, as well as we supporting your business through your ideas into realistic. Also we are specialised in SEO/SMM/Digital marketing which helps you to stand out from traffic as well as help to grow your business. We provide compete on page, off page , PPC, google Adwords, CPC, Amazon PPC, Affilate marketing with complete solution to support your business and generate lead as well as organic traffic.",
      "legally_known_as": "Logical Soft Tech Private Limited",
      "industry": "Fintech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sumit",
      "position": "Managing Director",
      "profile_link": "https://www.linkedin.com/in/pinkee-gupta/",
      "team_member_2": "Anju Kumari",
      "revenue_stream": "E-commerce - product, Fee-for-service",
      "customer_group": "FinTech,  Marketplace,  SaaS,  Software ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Asia Pacific,  Europe,  North America  "
  },
  {
      "startup_name": "Diabetes Life Solutions ",
      "website": "https://diabeteslifesolutions.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DgD6IEpsjkbxBX95VFcFXkLP1zyj36D_",
      "profile_summary": "Diabetes Life Solutions was founded in 2012 by Robert and Matt Schmidt. Matt’s life had changed when his father was diagnosed with diabetes. After this diagnosis Matt saw first hand the impact that diabetes can have on not only the individual, but the entire family. They had struggled to obtain life insurance for Robert due to diabetes and other health related issues. They were told several times by multiple companies that coverage was not obtainable. After countless hours of speaking with underwriters and companies, a carrier was finally found. Diabetes Life Solutions was then created to help find affordable life insurance for diabetics. Many people with diabetes have been turned down for life insurance due to their chronic conditions. Diabetes Life Solutions does not accept that outcome for our clients. Since we have access to over 80 life insurance carriers, we find the best available rates for you, no matter what company that may be from.",
      "legally_known_as": "Diabetes Life Solutions LLC",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2012",
      "employees_headcount": "<10",
      "team_member_1": "Matthew Schmidt",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/insuringpittsburgh/",
      "revenue_stream": "Commission on transaction ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "North America"
  },
  {
      "startup_name": "A&R Group Financial Services",
      "website": "https://argroups.live/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1DfVXi4iiPbyfkMjJHqiYVn7Jnhz94AEX",
      "profile_summary": "A&R Group are dealing with Digital Banking, Retail, IT Sales/Telecom & Ecommerce Sector. Digital is the digitization (or moving online) of all the traditional banking activities and programs services, This includes activities like. Money Deposits, Withdrawals, Transfers, loan, insurance, credit card, demat everything, recharge, DMT, Utility bill payment, Pan card, Aeps, ETC",
      "legally_known_as": "A&R GROUP FINANCIAL SERVICES",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "251 to 500",
      "revenue_stream": "Commission on transaction, E-commerce  - service",
      "customer_group": "FinTech,  FMCG,  Insurance,  Recruitment",
      "client_business_structure": "Large Enterprise",
      "geographical_segment": "Global"
  },
  {
      "1": "Dundee Venture Capital",
      "startup_name": "Dobby",
      "website": "https://hellodobby.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Dw4NIFDpaYHmJAT0guLjj1vyI4ai2SZT",
      "profile_summary": "Dobby is the smartest and fastest way to manage, maintain, and carry out home refurbishing. The app partners with home service providers and uses AI to enable you to keep the reins over what is being done in the property and get in touch with maintenance professionals.",
      "legally_known_as": "Washington, D.C., Washington D.C., United States of America\n",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2018",
      "team_member_1": "Alexandra Fontova",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/satadrusg/",
      "team_member_2": "Satadru Sengupta"
  },
  {
      "startup_name": "FIKAA",
      "website": "https://www.fikaa.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1FRXKh_F2aQ2r6b3LGU-ujY5OgMDnCOCX",
      "profile_summary": "FIKAA is an App that is focused on every women's desire to be financially independent FIKAA draws inspiration from the Swedish concept of ‘Fika’ which essentially means ‘chit-chat over coffee with friends’ At FIKAA, they strongly believe in easy conversations about investments in a way that is fresh and snackable They believe that women should start taking their own investment decisions and aim to bridge the gap in knowledge and build confidence. FIKAA mission is to create a community of over 1 million like-minded women to learn, invest and build wealth - achieve financial independence. FIKAA wants to be the authoritative voice in changing the conversation and knowledge around investing for women. One stop mobile App truly for the women of today.",
      "legally_known_as": "KEYNOTE CAPITALS LTD",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Harsh Choksey",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/rinku-suchanti-8502a117b/",
      "team_member_2": "Rinku Suchanti",
      "revenue_stream": "Commission on transaction ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  \n",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  \n\n"
  },
  {
      "1": "Krishnavataram Vinjamuri",
      "startup_name": "Kredily",
      "website": "https://kredily.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1E1EXQdHMgl0V5udJ3TSo7ywECPH5QGLy",
      "profile_summary": "Kredily is a powerful payroll software designed to simplify and automate payroll management for businesses of all sizes. It eliminates manual calculations and human errors, streamlining processes such as attendance tracking, salary calculations, tax management, compliance, and reporting. With customizable salary structures, accurate tax calculations, and seamless integration with other HR and accounting systems, Kredily ensures accuracy, efficiency, and compliance in payroll management. It also provides employees with a self-service portal for accessing payslips, tax documents, and leave applications. With robust data security measures and insightful reporting and analytics features, Kredily empowers businesses to transform their payroll management experience, save time, and focus on strategic initiatives.",
      "legally_known_as": "Peopleprosper Technologies Private Limited\n\n",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2017",
      "employees_headcount": "61 to 100",
      "team_member_1": "Devendra Khandegar",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/dkhandegar/",
      "revenue_stream": "Freemium, Fee-For-Service",
      "customer_group": "FinTech,  SaaS,  Software,  Technology  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India"
  },
  {
      "startup_name": "Truyalty Ventures ",
      "website": "https://www.truyaltyventures.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1EDGlwzJNICOdp-mjW403-hFPsfQMX1dV",
      "profile_summary": "They are most fastest growing Fin-Tech company in northeastern, they works on various projects such as: Trupe-Digi-Kendra: where we set-up physical digi point in every panchyat to provide banking services to the people and empower rural banking services.(2) TruPe fleet Services: where you provide Fleet Management services where fleet owner can manage expenses, recharge fastag, fuelling by using TruPe Rupay card and get cashback in every transaction, Track Vehicles location.(3) Truyalty Connect: where you provide various FinTech APIs to individual and corporates.",
      "legally_known_as": "Truyalty Ventures Private Limited (TruPe)\n",
      "industry": "Fintech",
      "based_out_of": "Odisha, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Truptikanta Panigrahi",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/truptikanta-panigrahi-196320113/",
      "revenue_stream": "Commission on transaction, Fee-for-services",
      "customer_group": "FinTech,  SaaS,  Software,  Technology ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  \n",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  \n",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  \n\n"
  },
  {
      "startup_name": "Xmonies",
      "website": "https://www.xmonies.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1EHFIXjUgunNCDTsSHMgye3TOD_m2nYfn",
      "profile_summary": "Xmonies, is an International/ Cross Border Remittance and Payments Platform fully owned by XeOPAR Fintech Private Limited, a company registered in India under The Companies Act 2013. At Xmonies, we believe in empowering our customers to take control of their financial futures, and we are committed to delivering exceptional customer service and support.",
      "legally_known_as": "XeOPAR Fintech Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "<10",
      "team_member_1": "Vikas Chandra Saxena",
      "position": "Director",
      "team_member_2": "Arvind Kumar Gupta",
      "revenue_stream": "commission on transaction ",
      "customer_group": "E-Commerce,  Enterprise Tech,  Marketplace  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India, Global ",
      "age_group": "26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global "
  },
  {
      "1": "Freesprit Venture",
      "2": "Pureinfra",
      "startup_name": "Invest19",
      "website": "https://www.invest19.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1E3oRX8rrSS1DLQX2O7z3w2bjcWKPmdGX",
      "profile_summary": "Invest19 is an infrastructure app for brokers while it is an aggregator app for investors to operate as a one-stop investment place. Invest19 is the platform that was missing in the Indian stock investment scene. Invest19 will make stock market investment for a new investor as easy as online shopping. It provides simplified research and data for informed investors to have a go-to app for all stock investments.",
      "legally_known_as": "Invest19 Technologies Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Mahek Tomer",
      "position": "Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/kaushlendra-singh-sengar-39930323/",
      "team_member_2": "Kaushlendra Singh Sengar",
      "total_funding_millions": "0.55"
  },
  {
      "startup_name": "Walkfin",
      "website": "https://walkfin.com/#[object%20Object]",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1EK-7qo6RhwxBjtwgpEW8bPJ7RK3u-xEC",
      "profile_summary": "Walkfin Financing Services Private Limited is a new age fintech company offering quick, hassle-free and safe lending solutions to businesses in the MSME sector. Walkfin is supply chain financing platform which allow FMCG distributors to avail growth capital on daily basis. Recently Walkfin overtake the NBFC license to operate lending business. Till date Walkfin has onboarded 300+ FMCG business at platform and disbursed >60Cr to them. Walkfin is operating in Pune/ Mumbai/ Nashik/ Delhi/ Gurgram/ Lucknow & Patna.",
      "legally_known_as": "Walkfin Financing Services Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "41 to 60",
      "team_member_1": "Naveen Upadhyay",
      "position": "Co-Founder",
      "team_member_2": "Anoj more",
      "profile_link": "https://www.linkedin.com/in/anoj-more-831b9221b/"
  },
  {
      "startup_name": "Funddo",
      "website": "https://funddo.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1EMKpmTD_Nzp1Kt4TRqWaBTMq15s72Z9z",
      "profile_summary": "Funddo is an online platform for business loans. It offers multiple loans including business credit lines, business term loans, customer credit, retailer wallet credits, vehicle loans, and more. It also provides insights & recommendations on business implementations; retail insurance; a dashboard to manage loan payments, offers, credit scores, and more. The app is available on Android and IOS platforms.",
      "legally_known_as": "Areeva Products & Services Private Limited",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B",
      "founded_in": "2017",
      "employees_headcount": "<10",
      "team_member_1": "Niraj Chatri",
      "position": "Co-Founder",
      "team_member_2": "Ruchi Chatri",
      "profile_link": "https://www.linkedin.com/in/sanjay-sharma-5b615b37/",
      "team_member_3": "Sanjay Sharma"
  },
  {
      "startup_name": "AppOctet ",
      "website": "https://appoctet.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BsOsvIU1HHTGdO2jhzrrAq9XXxrL4_z-",
      "profile_summary": "Appoctet Technologies is a Product Development Company based in Mumbai Maharashtra, incorporated in the year 2020. They have helped to build scalable products for startups and enterprises. In the span of just 3 years, They have covered 5+ countries and worked for major industries. They understand the client's requirements and offer end-to-end solutions that convert our client's ideas into products using cutting-edge technologies like Web apps, Mobile apps, UI/UX, Artificial Intelligence, Quality Assurance, internet of things(IoT), and Block Chain solutions. They are a young, vibrant organization that understands the pulse of the industry. If you are looking for a technology partner who can help you take your business to the next level, Appoctet Technologies is a perfect partner.",
      "legally_known_as": "AppOctet Technologies Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Srinivas Guni",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/srinivas-guni-676a73103/",
      "revenue_stream": "E-commerce  - Service, Subscription, Fee-for-service",
      "customer_group": "Enterprise Tech,  FinTech,  Software,  SaaS  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  \n",
      "geographical_segment": "India,  Global,  Europe,  North America,  South America  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  \n",
      "user_belongs_to": "India "
  },
  {
      "startup_name": "Anilax",
      "website": "https://anilaxsoftware.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BkH7oNr0ragCZJQ9jLRPGcaujVs2vzrX",
      "profile_summary": "Anilax is a digital agency that serves its customers with all their digital needs. We offer a wide range of services from website design, development, mobile app development to CRM software, and many more. With us, you get a guarantee of high quality and timely delivery. For our clients, we also have various products like B2B software, MLM software, travel portal, Api solution, etc. In short, you can say that we are surely a one-stop solution for all digital requirements.",
      "legally_known_as": "Anilax Software Private Limited",
      "industry": "Fintech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B, B2C",
      "founded_in": "2017",
      "employees_headcount": "<10",
      "team_member_1": "Lakshya Gujrati",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/lakshya-gujrati-051522ba",
      "customer_group": "FinTech,  Gaming,  Software  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India ",
      "age_group": "26 to 34,  35 to 45,  46 to 60,  More than 60  \n",
      "income_bracket": "\nUpper-middle Income,  High Income",
      "user_belongs_to": "India "
  },
  {
      "startup_name": "Credit klick",
      "website": "https://www.creditklick.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Bh_WZrcvgabF5QdhGU3xA2A1tb21VIv6",
      "profile_summary": "Credit klick, a company based in Gurgaon, India, was established to revolutionize credit delivery in the country by utilizing technology and the digital platform. The company's objective is to enable customers to understand their credit potential and leverage this knowledge to their advantage. Credit klick provides customers access to their credit scores, assists them in improving their credit health, resolving past credit issues, lowering current borrowing costs, and identifying products that are best suited to their credit profile.",
      "legally_known_as": "Incredible Management Services Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "251 to 500",
      "team_member_1": "Rajnish Datta",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/dharmendrakalra/",
      "team_member_2": "Dharmendra Kalra",
      "revenue_stream": "Subscription, Fee-for-service ",
      "customer_group": "FinTech",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  \n",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Fetcch",
      "website": "https://fetcch.xyz/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BfIm9MO2z03xkKYiKd7juVsmL3adoGQm",
      "profile_summary": "Fetcch is a decentralised payments connectivity network connecting users, wallets and dApps. The protocol will allow wallets and dApps to issue cross-chain, composable wallet IDs to users, giving them a single identity to connect to any number of addresses from any chain.",
      "legally_known_as": "Fetcch",
      "industry": "Fintech",
      "based_out_of": "Singapore, Global ",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Mandar Dange",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/satyam-kulkarni/",
      "team_member_2": "Satyam Kulkarni"
  },
  {
      "1": "SFC Capital",
      "startup_name": "KYC Hub",
      "website": "https://www.kychub.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BPght-N89przdGrFyny-YDW8rbh9laJR",
      "profile_summary": "KYC Hub enables organizations to mitigate the risk of financial crime via workflow orchestration, data aggregation, and a risk engine enabling rapid building and deployment of risk detection and automation applications for different use cases.",
      "legally_known_as": "KYC Hub",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global ",
      "founded_in": "2018",
      "employees_headcount": "41 to 60",
      "team_member_1": "Farnoush Mirmoeini",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/jayrao1/",
      "team_member_2": "Jay Rao",
      "revenue_stream": "Fee-for-Service"
  },
  {
      "startup_name": "Krab.ai",
      "website": "http://krab.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BOxLwH7PhDq8LZgvU8dN0cPfoZP5G9DR",
      "profile_summary": "Krab is a next generation cloud app for Transportation Businesses. We have been in transportation industry for over 5 years. Now we are building products that solve the most important problems faced by Indian transporters. Using Krab for your business incorporates best practices from best companies directly impacting your profits.",
      "legally_known_as": "Quads Financial Technologies Private Limited",
      "industry": "Fintech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Vijay Kumar",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/yogesh-bathina-23460a111/",
      "team_member_2": "Yogesh Bathina"
  },
  {
      "startup_name": "IndiaDeposit",
      "website": "https://www.indiadeposit.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1FP_pT5BsFfhEQD5i50eKlDHVu7veYwV-",
      "profile_summary": "The app aims to provide comprehensive options to the investor in fixed return investment space.It helps the investor in comparing various options in terms of interest rate and features and thereby make the right decisions.It also enable completing the user journey which is Application,KYC and Payment.It also help in redemptions of the investment.It has a very efficient customer support.It has been recognised by StartUp India and AMFI registered.",
      "legally_known_as": "IndiaDeposit",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Abhinandan Baid",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/abhinandan-baid-8950a714/",
      "revenue_stream": "Advertising, Commission on transaction, Sponsorship ",
      "customer_group": "Financial Services",
      "client_business_structure": "Medium Enterprise,  Large Enterprise",
      "geographical_segment": "India",
      "age_group": "46 to 60  ",
      "income_bracket": "Upper-middle Income,  High Income",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Facta",
      "website": "http://www.facta.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BKycDlaqUOokoikynj-JqNHthL8gtGWI",
      "profile_summary": "Facta is a software company for SaaS financial insights. It is a platform for controlling, analyzing, and communicating financial data in the cloud. Their products such as SaaS accounting services built to scale, SaaS metrics software, and automated SaaS accounting services.",
      "legally_known_as": "Teleport Group, LLC",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "David Ingraham",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/dsingraham/"
  },
  {
      "startup_name": "47Billion",
      "website": "https://47billion.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BJ0IQFEgIRz73yUit1uS3-imHZF8X2Ml",
      "profile_summary": "47Billion specializes in health tech, banking and financial services, agriculture, edtech, telecom, logistics, ad-tech, and tourism. Their services include ideation, implementation, and innovation, with a focus on delivering exceptional quality within estimated timelines. The organization boasts a decade of experience in User experience and design, Data analytics and visualization, Machine learning, and End-to-end product development. Their team of experienced strategists, designers, and developers collaborates at all stages of the product journey, from conception to delivery. The company has received numerous accolades, including recognition as one of the most innovative tech companies in Madhya Pradesh and the prestigious DB Eminence Award. 47Billion has partnered with several prominent companies, including Databricks, Microsoft, AWS, and NVIDIA, to provide its leading services. They have helped more than 100 companies worldwide in their digital transformation journey.",
      "legally_known_as": "Fourty Seven Billion Information Technology PVT LTD",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2012",
      "employees_headcount": "151 to 250",
      "team_member_1": "Amol Vaidya",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/rajeevkdixit/",
      "team_member_2": "Rajeev Dixit",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "AgriTech,  Analytics & BI,  FinTech,  Technology",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise\n\n",
      "geographical_segment": "Global,  Europe,  North America  \n"
  },
  {
      "startup_name": "Settleloans",
      "website": "https://www.settleloans.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BCtGC6Aa751eNoNjjO0ArBXPtJkCdEAx",
      "profile_summary": "Dealing with debt can have a significant impact on your mental and emotional well-being. It can lead to feelings of hopelessness, anxiety, and depression, making it difficult to concentrate on other important aspects of your life, such as work or relationships. Moreover, the constant pressure of being in debt can strain your personal relationships, leading to further stress and isolation. However, seeking help from Settleloans can provide you with much-needed relief. They understand the challenges of being in debt and the impact it can have on your life. Their team is dedicated to providing you with comprehensive debt management solutions tailored to your unique needs, so you can regain control of your finances and your life. With their support, you can develop a plan to pay off your debts gradually while still maintaining your basic needs. Settleloans will also work with your creditors to negotiate lower interest rates and more favorable repayment terms.",
      "legally_known_as": "Settleloans",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "revenue_stream": "Fee-for-service ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60 \n\n",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income\n\n",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Deepvue.tech",
      "website": "https://deepvue.tech/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1BCEQqYQl4kp_Ui4PsBHmjk0EgVpawRGt",
      "profile_summary": "At Deepvue, they're all about empowering businesses to deliver seamless customer journeys and achieve successful digital transformations. Their platform provides valuable insights into customer behavior, helping businesses make better decisions and improve the overall customer experience. One of the standout features of their platform is its ability to perform KYCB verification and fraud mitigation checks, which are more important than ever in today's fast-paced digital landscape. Plus, with their embedded finance solutions, businesses can expand their reach and offer financial services to a wider range of customers, promoting financial inclusion and driving growth. And the best part? They promise that businesses can go live with their platform within just 24 hours. That means you can start seeing results and reaping the benefits of their platform in no time. At Deepvue, they're committed to helping businesses of all sizes succeed and thrive, and their platform is just one way we're making that happen.",
      "legally_known_as": "Deepvue Technologies Private Limited",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Chandra Shekhar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/askgauravarya/",
      "team_member_2": "Gaurav Arya",
      "revenue_stream": "Freemium, Subscription ",
      "customer_group": "FinTech,  HealthTech / MedTech / Healthcare,  Logistics,  Retail\n\n",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2 "
  },
  {
      "startup_name": "Quikdox",
      "website": "https://www.quikdox.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1B3YY7A28CgWaLFOZNb4Riky15soDVclb",
      "profile_summary": "Quikdox is an organization passionate about making life simple and easier. So that you can spend more time with people you love. They aim to provide you tools to quickly make documents in an automated secure manner by leveraging latest technologies, management concepts and subject matter expertise. This saves your valuable time, lower your costs and brings you peace of mind.",
      "legally_known_as": "SKN Technologies (OPC) Private Limited",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Shiv Kumar Goyal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/shiv-kumar-goyal/",
      "revenue_stream": "Advertising, Fee-for-service ",
      "customer_group": "Consumer,  Services,  FinTech,  Direct-To-Consumer Brands  \n\n",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  India Tier 1  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60 ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  \n"
  },
  {
      "startup_name": "Liech International",
      "website": "https://www.liechintl.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AogAaBBJUE0zOvSD_VntP7hQJR1sX20v",
      "profile_summary": "Founded in 2017, Liech is a leading service provider in rewards fulfilment in Asia. They are committed to being your best partner in your incentive, reward, marketing, loyalty and employee benefit programmes. Their clients and members come from a wide range of industries including banking, technology, airlines, travel, public utility etc.",
      "legally_known_as": "Liech International Limited",
      "industry": "Fintech",
      "based_out_of": "China, Global",
      "founded_in": "2017",
      "employees_headcount": "<10",
      "team_member_1": "Elaine Wong",
      "position": "Founder & Director",
      "profile_link": "https://www.linkedin.com/in/welaine/"
  },
  {
      "startup_name": "Apiko",
      "website": "https://apiko.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AlHKLwDwswjYDhd9xAWcv3x9Ce44fAke",
      "profile_summary": "Apiko is a software development company that enters markets with digital businesses, using a solid process and clever strategies. They merge imagination and technology to help brands grow in the age of constant change and transformation. Based in Ukraine, Apiko also has branch offices in Denmark, the UK, Germany, the USA, and Australia. They combine 7 years of software engineering, project management, and talent acquisition, delivering custom web and mobile solutions that produce measurable results.",
      "legally_known_as": "Apiko",
      "industry": "Fintech",
      "based_out_of": "Ukraine, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "151 to 250",
      "team_member_1": "Mykola Striletskyi",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/mykola-striletskyi-7036b18a/",
      "revenue_stream": "E-commerce - Service ",
      "customer_group": "Enterprise Tech,  SaaS,  Services,  Software ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  \n\n",
      "geographical_segment": "Global,  Europe,  North America  \n",
      "age_group": "26 to 34,  35 to 45,  46 to 60 ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": " \nGlobal"
  },
  {
      "1": "Emergence Capital",
      "startup_name": "Federato",
      "website": "https://www.federato.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AkmwVTTXwwBvYL27TgLcw9ABqvS6X02f",
      "profile_summary": "Federato provides an underwriting platform for insurance companies. The company's underwriter workflow software ostensibly allows actuaries and portfolio analysts to drive high-level risk analysis into the hands of front-line underwriters, allowing clients to understand and make better decisions. Federato was founded in 2020 and has itsheadquarters in Palo Alto, California, United States.",
      "legally_known_as": "Federato Technologies, Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "William Steenbergen",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/williamross826/",
      "team_member_2": "William Ross",
      "total_funding_millions": "15"
  },
  {
      "startup_name": "Coin Chemistry ",
      "website": "https://coinchemistry.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AjH_XaJgZC7pIGVG1YM277S2MjjNrAhw",
      "profile_summary": "Coin Chemistry believes that financial well-being is crucial to leading a happy and fulfilled life. That’s why they’ve created a platform specifically designed for urban Indian married couples, where they can align their financial goals and habits with their spouses. Coin Chemistry empowers couples to achieve their financial goals faster by combining practical life skills education with powerful financial strategies. Their community members save more money, invest smarter, and create wealth together. Join us and experience the power of financial synergy. They’re currently in the soft launch phase, with a sales target of 1 crore rupees for 2023. Their goal is to launch their mobile app in 2023, providing an even more user-friendly experience for their customers. By the end of 2023, they aim to become a self-sufficient business and expand their services to other geographies.",
      "legally_known_as": "Coinchem Consulting LLP",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Jayteerth Katti",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/jayka/",
      "revenue_stream": "Subscription ",
      "age_group": "26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income\n\n",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  "
  },
  {
      "startup_name": "Praxent",
      "website": "https://praxent.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AffROdG8YEBvZNm2kfdnFBkbjrqOzZM8",
      "profile_summary": "Praxent is a fintech design and engineering partner to financial companies determined to lead in digital. With a unique blend of expertise in product management, user experience design, and software development, Praxent has helped more than 400 organizations launch, scale, and modernize products that win in an increasingly digital world. Praxent’s client-centered culture of collaboration, reliability, and accountability provides peace of mind to stakeholders throughout the product development lifecycle.",
      "legally_known_as": "Praxent, LLC",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2000",
      "employees_headcount": "61 to 100",
      "team_member_1": "Tim Hamilton",
      "position": "Managing Partner",
      "profile_link": "https://www.linkedin.com/in/kevin-hurwitz/",
      "team_member_2": "Kevin Hurwitz"
  },
  {
      "1": "Y Combinator",
      "startup_name": "Ontop",
      "website": "https://www.getontop.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ATCcAwY6xJ87-I8zcnM82yIq4SzdLbR6",
      "profile_summary": "Ontop is a bank for remote workers connected to payroll. Ontop allows companies to hire international workers, and they enable those workers to save in USD. They automate international hiring and payments. Never worry about contracts, paperwork, compliance or cross border payments again.",
      "legally_known_as": "Ontop Holdings Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2020",
      "employees_headcount": "251 to 500",
      "team_member_1": "Julian Torres Gomez",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/jaime-abella/",
      "team_member_2": "Jaime Abella Alvarez"
  },
  {
      "1": "First Check Ventures",
      "2": "Luxor Capital Group",
      "startup_name": "Treinta",
      "website": "https://www.treinta.co/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AOjSY2NIkWfKRXOKLBjHsd83O2YRtqEz",
      "profile_summary": "Treinta is a digital ledger app for Latin American microbusinesses. Through Treinta, users can easily record transactions by assigning products from their inventory and see how their business metrics evolve in real time. Users can also access a B2B marketplace and digital payment solutions.",
      "legally_known_as": "Treinta",
      "industry": "Fintech",
      "based_out_of": "Colombia, Global",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "251 to 500",
      "team_member_1": "Lluís Cañadell",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/manheilou/",
      "team_member_2": "Man Hei Lou"
  },
  {
      "startup_name": "Finanvo",
      "website": "https://finanvo.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AOUpVYUylOc4nYmz94I0_v0Tc9A00hiN",
      "profile_summary": "Finanvo is one stop tool to get all sources of financial information & real-time market research to make efficient business goals in 2023. They offer personalized financial data services that help clients identify their business goals and develop a plan to achieve them. The platform's team of financial planners & advisors work with clients to understand their economic situation, risk appetite, and investment preferences to develop a customized financial plan that suits their needs.",
      "legally_known_as": "Finanvo Solutions Private Limited",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "PRABHAT VISHNU SOMANI",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ravi-somani-ca/",
      "team_member_2": "RAVI VISHNU SOMANI",
      "revenue_stream": "Selling data, Subscription ",
      "customer_group": "Consumer,  Services,  FinTech  \n",
      "client_business_structure": "Small Enterprise ",
      "geographical_segment": "India"
  },
  {
      "startup_name": "LendStreet",
      "website": "https://lendstreet.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AF_gTWZWmURODadV9hgrJqLZxPtXnpOC",
      "profile_summary": "LendStreet is a marketplace of “financiers/lenders”(Include Banks/NBFCs/MFIs/HFCs /Private funders/Pawn brokers etc.), they majorly focused to provide a complete business services start from digitizing their “ offerings/value propositions ” to majorly lend/save to/at “Quality Borrowers”, and vice versa they find suitable \"lenders to value borrowers\".",
      "legally_known_as": "LendStreet Automation India Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Bhimavarapu Harikrishna Reddy",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/harikrishnareddy-b-66079a88/",
      "revenue_stream": "Commission on transaction, Subscription, Fee-for-service ",
      "customer_group": "FinTech",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": ": India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60,  More than 60  \n",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "K A Financial Gains",
      "website": "https://www.myalgoai.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AEtOjj-R5V5s4RUoZfjE7ROEzgeNGyBd",
      "profile_summary": "KAFG is a group of professional traders with 15+ years of experience in trading in equities, derivatives, commodities and forex. They have built an Algo trading platform for retail traders. This platform will be helpful for the novice traders or beginners to copy the proven strategies from the expert. KAFG application is a complete automated trading tool for the retail traders, where customers has access to the pre-defined strategies and there past performance. These strategies are built by the professional traders and proven over time. Customer can choose the strategies according to their preference, past performance, risk apatite and trading style. This is a very easy to use platform, very minimum manual intervention is required to trade in the live market. Customer can choose from 75+ pre-defined Algo strategies for Index Futures and Commodities. Once Customers are connected to the KAFG application, the application will execute the trades according to the Customer strategies selection.",
      "legally_known_as": "K A FINANCIAL GAINS PVT LTD",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Ashwath Narayana",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/ashwath-narayana-20887138/",
      "revenue_stream": "Subscription ",
      "customer_group": "FinTech",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  \n\n",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "F-Pay",
      "website": "https://fpay.net.in/Fpay/Default.aspx",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1AEiMt6qx-lYB7EY_bA8uVX72yk0iFn84",
      "profile_summary": "F-Pay is an Amravati, Maharashtra based company. It was established in 2021. Their goal is to simplify the process such that you don't need to stay a minute longer than required on their site or apps. The technologies are changing and fpay.net.in provides services from wherever and whenever you need. fpay.net.in is also equipped with a secure online wallet called fpay.net.in Cash. They give you discount coupons from top food joints and popular retailers, delivered to your inbox or mobile. You can use these coupons to save money when you eat out, watch movies, book travel tickets, shop online, visit a salon, go gaming, pick up books, music, groceries and enjoy with your family. fpay.net.in's B2B dealers are located in B and C class towns having large number of loyal customers in their towns. This is an important strength of fpay.net.in They promise:- An easy and instant recharging process A hassle-free online experience A safe and secure payment process Discount coupons valuable for you.",
      "legally_known_as": "F-Pay Communication Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Mohammad Faizan",
      "position": "Founder & MD",
      "profile_link": "https://www.linkedin.com/in/mohammad-faizan-1969a8250/",
      "revenue_stream": "Commission on transaction",
      "customer_group": "Consumer,  Services,  FinTech,  Travel/Hospitality",
      "client_business_structure": "Small Enterprise,  Medium Enterprise",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  \n\n",
      "age_group": "18 to 25,  26 to 34  \n",
      "income_bracket": "Lower-middle Income",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Vider Bussiness Solutions ",
      "website": "https://vider.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ACnbw3GnS_w5ItGRPGvwfoikLD5zgkxR",
      "profile_summary": "Vider Business Solutions is a private limited company, formed by a group of chartered accountants with the sole intention of providing better professionals & professional services to the society. In the process, the step was to make the professional life easy by providing a multi-functional web application called ATOM - an office automation tool. many such applications are under the development stage and they are providing employment to various people in different designations and roles. To make professional services available around the country even in the most remote locations and thus helping the general public succeed in their business goals and extending the scope of professionals by removing geographical barriers and thus helping the country as a whole by building & contributing to the economy.",
      "legally_known_as": "VIDER BUSINESS SOLUTIONS PRIVATE LIMITED",
      "industry": "Fintech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sai Veer Kumar Muthyam",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/ca-sai-veer-kumar-muthyam-a5b677102/",
      "revenue_stream": "Subscription ",
      "customer_group": "FinTech,  Software,  Technology,  Services  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  \n",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  "
  },
  {
      "startup_name": "Ram Fincorp",
      "website": "https://www.ramfincorp.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1A6yKGkFdC0jOidcQUrDOEdMdJPzjmBbu",
      "profile_summary": "Ram Fincorp offers tension-free, easy, and instant personal loans right at your fingertips. Not only do they provide you with the fastest disbursal ever possible but also low-interest rates. The loan will be credited to your bank account through us, your trusted NBFC in around 30 minutes! Rhey is an RBI registered NBFC committed to offering personal credits to salaried professionals. They stand for catering to your individual financing needs in an easy way by including innovation to the credit buying handle, in this manner giving you moment get to reserves in a straightforward and straightforward way. They provide loans that are available at affordable interest rates due to a minimal risk profile. They ensure that their borrowers are not facing any unnecessary financial burden due to hefty interest rates.",
      "legally_known_as": "R.K. Bansal Finance Private Limited",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2C",
      "founded_in": "1984",
      "employees_headcount": "21 to 40",
      "team_member_1": "Bharat Soni",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/bharat-soni-193b3710a/",
      "revenue_stream": "Interest, Lending ",
      "age_group": "26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  \n",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2  "
  },
  {
      "startup_name": "PoolFunders",
      "website": "https://poolfunders.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1A4Ai7Hk9ygmdFeKRCx4MWFG1E92KSb6g",
      "profile_summary": "World first DeFi reward-based crowdfunding platform that aims to solve the biggest pain of funding for indie artists and startups. PoolFunders helps in raising an initial capital of up to $50,000, to market and validate their idea. The platform is built on a polygon blockchain and works on a reward-based model in a decentralized way where backers are provided a platform token (TPFT) as a reward. It also opens ups opportunities for angel investors and VCs who are constantly looking for the right projects for investment.",
      "legally_known_as": "eSrot Consulting labs",
      "industry": "Fintech",
      "based_out_of": "Uttarakhand, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Saurabh Ahuja",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/amit-blockchain/",
      "team_member_2": "Rakhi Soni",
      "team_member_3": "Amit Gupta",
      "revenue_stream": "Commission on transaction, Lending, Fee-for-service ",
      "customer_group": "Services,  Social Media,  Software,  Technology  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India Tier 2,  India Tier 3,  Global  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global  "
  },
  {
      "1": "Liberatha Kallat",
      "2": "Dinesh Nagpal",
      "startup_name": "Fyp Money ",
      "website": "https://www.fypmoney.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19zkftMrwMLnbmSisZ1Q_eBqH5pjykxGE",
      "profile_summary": "F.Y.P. Card is a combination of Finance and Literacy designed for teens to learn to budget, saving and make wiser expenditure decisions. Additionally, it is the first-ever card with a gamification feature that will help teach financial literacy in teenagers. It’s not just a card, but with its advanced innovative technology, it has made the process of learning and managing expenses easier for these young, fierce, tech-savvy brains.",
      "legally_known_as": "Fyp Money ",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "founded_in": "2021",
      "employees_headcount": "41 to 60",
      "team_member_1": "Kapil Banwari",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/kapil-banwari/",
      "total_funding_millions": "2"
  },
  {
      "startup_name": "TIPTALK",
      "website": "https://tiptalk.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19x0NeSZBFM9jSvKRcmDXlci53TiPPMhk",
      "profile_summary": "India’s first mobile application that personally connect's Investment Advisors & Market experts with the Users, over Chat, Call and Video, In Real time , On Demand and on Pay Per Minute basis. It is now on your finger tips to Chat, Voice call and Video call with India's Top Advisors and Market Experts. Market doesn't wait for any one, TipTalk's instant connect allows you to get critical assistance in no time from Top Market Experts. TipTalk is redefining the Investment Advisory Market in India. It is bringing a Paradigm shift in the way users get answers to their personal Investment queries. TipTalk is using future ready technology and building an AI and ML enabled cutting edge platform. TipTalk is fully compliant with prevailing regulations. TipTalk is also committed to Privacy and confidentiality of every user on their platform. All the Chat and call data is protected with strict Privacy policy and procedure",
      "legally_known_as": "RELYTEK TECHNOLOGIES PRIVATE LIMITED",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Devansh Pateria",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/kaushikvipin/",
      "team_member_2": "Ishan Sharma",
      "team_member_3": "Rohit Pateria",
      "team_member_4": "Vipin Kaushik",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "FinTech",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  \n\n",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "360factors",
      "website": "https://www.360factors.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19quPj7VXiD0vbBATPSIOfZc3q-POtAef",
      "profile_summary": "360factors empowers organizations to accelerate profitability, innovation, and productivity by predicting risks and streamlining compliance. 360factors offers a software solution that is based on Artificial Intelligence technology, to mitigate risks while streamlining regulatory compliance. It integrates regulations and obligations, compliance management, risks and controls, KRIs, audits and assessments, policies and procedures, and training in a single cloud-based SaaS platform based to provide predictive analytics and organizational dashboards for predicting risks and streamlining compliance in the financial sector.",
      "legally_known_as": "360factors",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2012",
      "employees_headcount": "151 to 250",
      "team_member_1": "Ed Sattar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/edsattar/",
      "revenue_stream": "Commission on transaction, Subscription, Fee-for-service ",
      "customer_group": "Enterprise Tech,  FinTech,  Services,  Technology  ",
      "client_business_structure": "Small Enterprise",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Routable",
      "website": "https://routable.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19qd5Fer7qGUip737zrfkJIYz2tbx6OCA",
      "profile_summary": "Routable’s secure B2B payments platform helps finance teams automate and simplify the payables process from invoice receipt to settlement. With support for your existing workflows and the flexibility to scale transactions from 100 to 100,000+, the platform was purpose-built to handle mass payouts reducing time spent on tedious tasks.",
      "legally_known_as": "Routable Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2017",
      "employees_headcount": "151 to 250",
      "team_member_1": "Omri Mor",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/tom-harel-74668864/",
      "team_member_2": "Tom Harel"
  },
  {
      "startup_name": "Seaflux Technologies ",
      "website": "https://www.seaflux.tech/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19pnEVFR4ZScP9LHXC3A-2HHtQYKn7Vng",
      "profile_summary": "Seaflux is an alliance of software architects, designers, developers, testers and implementers. Seaflux, a global technology company, helps enterprises in building their visionary products and solutions. They claim that they are one stop solution when it comes to enterprise digital needs. They are committed to deliver a solution in very advanced technology areas like: 1. AI & Machine Learning, 2. Blockchain, 3. Cloud Computing, 4. Internet of Things, 5. Data Science, 6. Enterprise Mobility",
      "legally_known_as": "Seaflux Technologies Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "founded_in": "2021",
      "employees_headcount": "41 to 60",
      "team_member_1": "Ashish Shah",
      "position": "Co-Founder & Director Of Engineering",
      "profile_link": "https://www.linkedin.com/in/jay-mehta-seaflux/",
      "team_member_2": "Murtuza Rampurwala",
      "team_member_3": "Jay Mehta",
      "revenue_stream": "other",
      "customer_group": "Blockchain,  FinTech,  Machine Learning,  Real Estate",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Global "
  },
  {
      "1": "CoVenture",
      "2": "FinTech Collective",
      "startup_name": "Constrafor ",
      "website": "https://www.constrafor.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19nuwVpSUa1v_PV2sI6IeyY6g9sPu1c-h",
      "profile_summary": "As a SaaS and fintech innovator purpose-built for construction, Constrafor is setting new standards of productivity and cost-efficiency for the way GCs and subcontractors manage essential back-office tasks and financial transactions. For GENERAL CONTRACTORS, Constrafor’s robust cloud platform streamlines and standardizes subcontractor procurement & administration, including contracts, COIs, invoices & payments and diversity procurement – all in one place. For SUBCONTRACTORS, the platform offers fast and cost-effective receivables financing, including an Early Pay Program (EPP) that shortens the revenue cycle, frees up cash and allows them to take on more projects.",
      "legally_known_as": "Constrafor, Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2009",
      "employees_headcount": "21 to 40",
      "team_member_1": "Douglas Reed",
      "position": "Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/anwar-ghauche/",
      "team_member_2": "Anwar Ghauche",
      "total_funding_millions": "106.3"
  },
  {
      "1": "Global Ventures",
      "startup_name": "Hakbah",
      "website": "https://hakbah.sa/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19oYtqgbHZycC5SLVBxqUwmIkaH6wufu2",
      "profile_summary": "Riyadh based, Hakbah is a Fintech startup focusing on an alternative financial saving. Hakbah is modernizing financial saving via its intelligent platform by offering a comprehensive one-stop-shop solution for Savings Groups to initiate, manage, join, and pay directly within the mobile app.",
      "legally_known_as": "Hakbah",
      "industry": "Fintech",
      "based_out_of": "Saudi Arabia, Global",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Naif AbuSaida",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/dimitarkazakov/",
      "team_member_2": "Dimitar Kazakov"
  },
  {
      "startup_name": "EcoCart",
      "website": "https://ecocart.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19gXtA_k0__EIUc1blX8JnA7bHfH7FLtz",
      "profile_summary": "We live at the intersection of E-Commerce and Climate Tech and build software that determines the ecological footprint of the manufacturing and delivery of goods sold by e-commerce brands around the world. Then through consumer-facing features, EcoCart enables shoppers to offset the emissions of the goods they are purchasing by donating to offset projects that EcoCart vets and supports.",
      "legally_known_as": "EcoCart, Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "61 to 100",
      "team_member_1": "Dane Baker",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/petertwomey7/",
      "team_member_2": "Peter Twomey",
      "revenue_stream": "E-commerce  - Service "
  },
  {
      "1": "Mindrock Capital",
      "2": "SoftBank Vision Fund",
      "3": "Crowdcube",
      "4": "Arctic Ventures",
      "startup_name": "Klarna ",
      "website": "http://www.klarna.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1FdWOOP8DcdgHlfNkGPxb0NkZoy7hfdOb",
      "profile_summary": "Klarna is an eCommerce payment solutions platform for merchants and shoppers. The platform gives customers the freedom to choose how and when to pay. The company also offers direct payments, pay-after-delivery options, and installment plans. Klarna is a regulated bank known for its “buy now, pay later” model that offers shoppers interest-freefinancing on retail purchases over a period of installments.",
      "legally_known_as": "Klarna AB",
      "industry": "Fintech",
      "based_out_of": "Sweden, Global",
      "founded_in": "2005",
      "employees_headcount": "1000+",
      "team_member_1": "Sebastian Siemiatkowski",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/sebastian-siemiatkowski-768977/",
      "team_member_2": "Niklas Adalberth",
      "team_member_3": "Victor Jacobsson",
      "revenue_stream": "E-commerce  - Service ",
      "total_funding_millions": "4500"
  },
  {
      "1": "Index Ventures",
      "2": "Kleiner Perkins",
      "3": "New Enterprise Associates",
      "4": "Goldman Sachs Investment Partners",
      "5": "Altimeter Capital",
      "6": "Spark Capital",
      "startup_name": "Plaid",
      "website": "https://plaid.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1FZQnsmeUCjMyAntCbHdxuO5_qE9cjPcA",
      "profile_summary": "Plaid is the technology platform providing the tools and access needed for the development of a fully modern, and digitally-enabled financial system. Plaid makes it easier and safer for developers — from the smallest startups to the largest financial institutions — to build innovative financial services and applications. Plaid offers beautifulconsumer experiences, developer-friendly infrastructure, and intelligence tools that give everyone the ability to build the future of financial services. Plaid was launched by William Hockey and Zach Perret in 2012 and is headquartered in San Francisco, California.",
      "legally_known_as": "Plaid Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2013",
      "employees_headcount": "1000+",
      "team_member_1": "Zachary Perret",
      "position": "Co-Founder and Board Director",
      "profile_link": "https://www.linkedin.com/in/william-hockey-04536710/",
      "team_member_2": "William Hockey",
      "total_funding_millions": "734.3"
  },
  {
      "1": "Y Combinator",
      "2": "GGV Capital",
      "3": "DST Global",
      "startup_name": "Frubana ",
      "website": "https://www.frubana.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19TtMJA7pURm1gs_kn1o23wdGcfDWd-iD",
      "profile_summary": "Frubana is a one-stop shop for restaurants that connect the countryside and different suppliers with the city through a digital platform. The company was founded in 2018 and is headquartered in Bogotá, Distrito Especial, Colombia.",
      "legally_known_as": "Frubana Inc",
      "industry": "Fintech",
      "based_out_of": "Mexico, Global",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Fabian Gomez Gutierrez",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/fegomezgutierrez/"
  },
  {
      "1": "Y Combinator",
      "2": "Softbank Ventures Asia",
      "3": "Ribbit Capital",
      "4": "DST Global",
      "5": "Horizons Ventures",
      "startup_name": "Ajaib",
      "website": "https://ajaib.co.id/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19QzGEdDS7aFPkcHW4xTmHZJvYT_ChjWk",
      "profile_summary": "Ajaib is a financial super app that allows Indonesians to buy and sell stocks, ETFs, and mutual funds. The company is committed to opening the door to access investment instruments that are safe, trusted, and affordable. Its stock and mutual fund services can be accessed online, by all walks of life. The company was founded in 2019 and isheadquartered in Jakarta, Indonesia.",
      "legally_known_as": "Ajaib",
      "industry": "Fintech",
      "based_out_of": "Indonesia, Global ",
      "founded_in": "2018",
      "employees_headcount": "251 to 500",
      "team_member_1": "Anderson Sumarli",
      "position": "Co-Founder & CPO",
      "profile_link": "https://www.linkedin.com/in/yada-piyajomkwan-88713865/",
      "team_member_2": "Yada Piyajomkwan"
  },
  {
      "startup_name": "10decoders",
      "website": "https://10decoders.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19MRDEoKFG_XW05uCziUf6fepZQkHzzLi",
      "profile_summary": "10decoders is a custom software development company helping clients to grow businesses through IT Consulting, Architecture Workshops, and Low Code Platforms. Their Custom Software Development Company in Chennai offers services ranging from software application development to business transformation. Their strategy is to approach problems with clarity and offer solutions that help you grow bigger, and faster and differentiate you from your competitors. Their software consulting services are led by industry experts with decades of experience who are eager to listen to your challenges and provide the best-fit solutions.",
      "legally_known_as": "10decoders Consultancy Services Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2015",
      "employees_headcount": "151 to 250",
      "team_member_1": "Venkatachalam Dekshinamurthy",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/venkatachalam-d/",
      "revenue_stream": "Renting/Leasing",
      "customer_group": "AdTech,  AgriTech,  FinTech,  EdTech/Education",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Siddhanam Capital",
      "website": "https://www.siddhanamcapital.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Fg5qIXj0dlgUZ-dTBXg3akMDFGh_WK8G",
      "profile_summary": "Siddhanam Capital is an AMFI registered Mutual Fund Distributor which associates itself in bringing world class innovative products for their Indian & Global users. They are a multi-asset execution-only platform which leverages on the technology to intelligently track and manage one’s own portfolio as per their asset allocation.",
      "legally_known_as": "Siddhanam Capital",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Apurva Singhi",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/apurvasinghi/",
      "revenue_stream": "Fee-for-service, Commission on transaction ",
      "customer_group": "FinTech",
      "client_business_structure": "Startup",
      "geographical_segment": "Global",
      "age_group": "35 to 45,  26 to 34,  46 to 60,  More than 60  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Mera Sunaar",
      "website": "https://merasunaar.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Fe-NUIIjU8izQ6kSDfbiHeV_MLcA2-2t",
      "profile_summary": "Welcome to Mera Sunaar, the ultimate destination for digital gold investment. Experience the convenience and security of investing in gold online with their user-friendly platform. Explore a world of opportunities to grow your wealth with their trusted and reliable services. Join thousands of satisfied investors who have found a smart and efficient way to capitalize on the value of gold. Start your journey with Merasunaar today and unlock the potential of digital gold investment like never before.",
      "legally_known_as": "Mera Sunaar",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Brijesh Kumar Sharma",
      "position": "Co-Founder",
      "revenue_stream": "Commission on transaction ",
      "age_group": "35 to 45  ",
      "income_bracket": "Lower Income",
      "user_belongs_to": "India"
  },
  {
      "1": "Y Combinator",
      "2": "Index Ventures",
      "3": "Andreessen Horowitz",
      "startup_name": "Pave",
      "website": "https://www.pave.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=19LISJyeileeNqb4opmjMUKzFEal7N-FS",
      "profile_summary": "Compensation survey and merit cycle processes are integral to so many businesses, and yet remain time-consuming manual activities. Staff often lack financial training resulting in spreadsheet induced errors. Pave saw a need to update and streamline the process. The core advantage of Pave is its ease of integration with HRIS, ATS and cap table software, creating real-time compensation data views without the need for spreadsheets. Using the software creates a fair and transparent system that management can rely on and employees can respect. The company has seen strong momentum, with backing from big names like Y Combinator and az16. Some organizations have been reluctant to adopt solutions like Pave’s on account of the radical transparency it provides. However, with companies like Credit Karma, Miro and Discord all signing on, Pave has proven its ability to draw lucrative enterprise-level clients. This bodes well for the company’s ongoing success.",
      "legally_known_as": "Trove Information Technologies, Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "151 to 250",
      "team_member_1": "Matt Schulman",
      "position": "Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/matt-schulman-15911861/",
      "total_funding_millions": "163"
  },
  {
      "startup_name": "Bankberry",
      "website": "https://www.bankberry.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18vT70acNgc9yqsVzMmwBu2yXTQXotiqK",
      "profile_summary": "Bankberry was founded in the year 2017. Bankberry is in to agriculture business having business model B2B and B2C for fresh vegetables, fresh fruits, dry fruits and spices, sea foods etc operating on eCommerce and non-eCommerce plat-forms.",
      "legally_known_as": "Bankberry Horticulture Private Limited",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Balaji Aditya Chavan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/balaji-aditya-chavan-5b928618/",
      "revenue_stream": "E-commerce - product, E-commerce - Service, Lending",
      "customer_group": "AgriTech,  E-Commerce,  FinTech,  SaaS  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  "
  },
  {
      "1": "Y Combinator",
      "startup_name": "Flutterwave",
      "website": "https://www.flutterwave.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18u88HicUSdMdDYrKd269oLFLo61UCFdx",
      "profile_summary": "Flutterwave provides a payment service for global merchants and payment service providers. It provides technology, infrastructure, and services to enable global merchants, payment service providers, and helps banks and businesses build secure and seamless payment solutions for their customers by smoothening the exchange of funds.",
      "legally_known_as": "Flutterwave",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2016",
      "employees_headcount": "251 to 500",
      "team_member_1": "Olugbenga GB Agboola",
      "position": "Co-founder",
      "profile_link": "https://www.linkedin.com/in/lekekoya/",
      "team_member_2": "Adeleke Adekoya"
  },
  {
      "1": "Altimeter Capital",
      "2": "Benchmark",
      "3": "Flutterwave",
      "startup_name": "Modern Treasury",
      "website": "https://www.moderntreasury.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18rzTTAExPqIVwJ3KYoi6E2rLFRoRFYkl",
      "profile_summary": "Modern Treasury is a fintech company looking to modernise legacy B2B banking by accelerating the pace of payments. Their payments operation software offers real-time transaction tracking, as well as an API or dashboard to help companies manage payments from a single place. With approximately $120 trillion B2B business payments processed annually globally, Modern Treasury are tapping into a huge market opportunity. Key to the success of their offering has been to renovate legacy payment methods, such as checks. Their software significantly reduces the processing time required to carry out transactions - and is applicable across a broad swathe of high-value sectors like real estate, healthcare, and financial services. With the market expanding rapidly and the pace of digital transformation accelerating, Modern Treasury’s smart offering has seen the company enjoy impressive growth. Founded in just 2018, they quickly reached Unicorn status. Partnerships with the likes of Metropolitan Commercial Bank and the development of products like the Swift alternative Global ACH, shows Modern Treasury to be a highly valuable market solution that is adeptly navigating a growing customer base.",
      "legally_known_as": "Modern Treasury Corp",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "151 to 250",
      "team_member_1": "Dimitri Dadiomov",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/samaarons/",
      "team_member_2": "Matt Marcus",
      "team_member_3": "Sam Aarons",
      "total_funding_millions": "183"
  },
  {
      "startup_name": "Monzo Bank",
      "website": "https://monzo.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18rfZ6zOmXPNz1zQEEJIY4oOIJQwdxh67",
      "profile_summary": "Monzo (previously Mondo) is a bank for people who live their lives on their smartphones and is targeted at people who want to get things done in a click and who don’t see the need for branches and cheque books. The platform is focussed on building the best current account in the world and ultimately working with a range of other providers so that Monzo can be an intelligent hub for your entire financial life.",
      "legally_known_as": "Monzo Bank",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global",
      "founded_in": "2015",
      "employees_headcount": "1000+",
      "team_member_1": "Jonas Templestein",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/jonashuckestein/"
  },
  {
      "1": "Kleiner Perkins",
      "2": "Stripe",
      "startup_name": "Imprint",
      "website": "https://www.imprint.co/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18nodBIMxEF8PhqUGo-uwjG1S01XJx_hN",
      "profile_summary": "These days, all sorts of cards abound, from virtual cards to co-branded credit cards. Imprint aims to stand out in the increasingly crowded space by partnering with businesses to provide branded rewards cards for customers. While there has been an increase in the number of companies offering branded financial products to reduce costs, Imprint sees its card programs as offering a new platform for deepening customer relationships: less of the nastiness of credit and a much greater focus on rewards. The startup is focusing on refining its embedded finance solution to fulfil its vision of an Apple-pay like experience for merchants and consumers. Imprint will hope that its lightweight service, fit for smaller customers like hotel chains, will allow it to compete with the major venture-backed players in the space like Cardless, Apto and Alviere. Impri",
      "legally_known_as": "Imprint Payments, Inc.",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "61 to 100",
      "team_member_1": "Daragh Murphy",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/michael-pechman/",
      "team_member_2": "Michael Pechman",
      "total_funding_millions": "52"
  },
  {
      "startup_name": "MuffinPay",
      "website": "https://muffinpay.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18mrfFs6c-Vhr9K3j83PVITLx8a4LHoZ-",
      "profile_summary": "Singapore-based startup Company was founded by Dileep Seinberg and Karamjit Chana in 2022. MuffinPay is CryptoNeo Bank and offers Crypto payments network with real world applications. Its native token Muffincoin is world's first Utility Bill Payment Token.",
      "legally_known_as": "MuffinPay",
      "industry": "Fintech",
      "based_out_of": "Singapore, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Dileep Seinberg",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/karamjit-chana-592472b3/",
      "team_member_2": "Karamjit Chana",
      "revenue_stream": "Commission on transaction, E-commerce - service, Interest, Fee-for-service ",
      "customer_group": "Blockchain,  Cryptocurrency,  FinTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific,  Europe  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income ",
      "user_belongs_to": "India,  Global,  Asia Pacific,  Europe  "
  },
  {
      "startup_name": "Startup Khata",
      "website": "https://www.startupkhata.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18iNYoDfv5RwD4wA_xlCmPxBbSGY2Baos",
      "profile_summary": "Startup Khata is a comprehensive cloud accounting platform that take care of your finances & entire business In perfect order with their advance digital accounting software fully secure and reliable data base. The starting point for your next business with Startuphata, built on the newest version of Business, ready to be customized to your Business.",
      "legally_known_as": "Startup Khata",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Anzar Ali",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mohd-anzar-ali-b14074100/",
      "revenue_stream": "Advertising ",
      "customer_group": "Administrative Services,  Consumer,  Services,  Real Estate,  Retail  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India"
  },
  {
      "startup_name": "Klasha",
      "website": "https://klasha.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18hBB3ZKsMhy5PyKJLifX5hWVxVqrXwSc",
      "profile_summary": "Klasha is the new fashion e-commerce store that was founded in Lagos, Nigeria, in 2018 by Jessica Anuna. Klasha is a technology company that builds cross-border commerce solutions for Africa. Klasha Business allows international merchants to accept payments from Africa using African money methods and currencies with payouts in hard currencies. B2B African merchants can also send money cross-border to different parts of the world in G20 and hard currencies. Klasha Personal allows consumers in Africa to shop and spend cross-border internationally through an app using African money methods and currencies.",
      "legally_known_as": "Klasha",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2018",
      "employees_headcount": "151 to 250",
      "team_member_1": "Jess Anuna",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/jessicaanuna/"
  },
  {
      "1": "VMG Partners",
      "2": "Forerunner Ventures",
      "3": "Atalaya Capital",
      "startup_name": "Ampla",
      "website": "https://www.getampla.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18cfE366Xdex53BsRYUlOIBWeP1iNVbJY",
      "profile_summary": "Ampla Technologies is a financial technology company that provides tech-enabled financial solutions to help businesses grow. It provides an all-in-one financing platform that includes a growth capital solution, modern banking services, and data analytics. The company was founded in 2019 and is headquartered in New York.",
      "legally_known_as": "Ampla Technologies Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "101 to 150",
      "team_member_1": "Jim Cummings",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/jie-zhou-8031a24/",
      "team_member_2": "Anthony Santomo",
      "team_member_3": "Jie Zhou",
      "revenue_stream": "Commission on transaction, Lending",
      "total_funding_millions": "306.5"
  },
  {
      "startup_name": "M.M. Enterprise",
      "website": "https://www.linkedin.com/in/manojmishraINDR/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18Y_gpPsi2u_RcwL4w-vHohqipWURY3nJ",
      "profile_summary": "M.M. Enterprise is a financial market and wealth management starup with experties and exposure in reasearch / analysis in Money Market. Core focus on wealth management, index trading with Future and Options. Commodities and Forex trading with digital mediums. The Startep was founded in Jan 2023 with a deep effort and vision to scale it multifold by 2025 End. Till now the journey has been full of learning and its on track to become Big with increasing no of clients and funds.",
      "legally_known_as": "M.M. Enterprise",
      "industry": "Fintech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Manoj Mishra",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/manojmishraINDR/",
      "revenue_stream": "other",
      "age_group": "35 to 45  ",
      "income_bracket": "High Income",
      "user_belongs_to": "India,  India Tier 1  \n"
  },
  {
      "startup_name": "Trends & Tactics",
      "website": "https://www.trendsandtactics.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18b26cwHK5tMaV3rsxtTinj82jCtUK3Gv",
      "profile_summary": "Trends & Tactics is the best place for content to help readers learn new hacks to make more money. Their mission is to help you live life on your terms by showcasing the best personal finance and entrepreneurship trends and the tactics to implement them successfully. For this, they develop in-depth guides and interview successful entrepreneurs across industries. They have been mentioned in publications from Yahoo Finance, GoBanking Rates, DataBox, NewsBreak, and more.",
      "legally_known_as": "Trends & Tactics",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "John-Paul Cody",
      "position": "Co-Founder",
      "revenue_stream": "Advertising ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Opris ",
      "website": "https://www.opris.exchange/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18UrTWgBRORDwIT3uRbpURwaHLY4OUOOb",
      "profile_summary": "Opris exchange is a Madurai, Tamil Nadu based company. It was established in 2021. Cryptocurrency exchange application software supports for all modes of exchange startup innovators. They have built global standard ready made cryptocurrency exchange software that is highly customizable and ready-to-use with all essential features & functionalities and launch your centralized and decentralized cryptocurrency exchange business without hassle. A software comes with carefully curated variants. So, you get to choose what suits you the best!",
      "legally_known_as": "Opris exchange",
      "industry": "Fintech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Senthil Kumar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/senthil-kumar-605976234/",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "Blockchain,  Cryptocurrency,  FinTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Fundu",
      "website": "http://fundu.games/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18P9LsfEV8PuqSIBWA5xjR_gsm7TmaIlo",
      "profile_summary": "Fundu aims to provide users with risk free competitive and cooperative financial learning Fundu provides users a platform to connect network and follow fellow traders and experts In addition it provides options for users to test their trading skills in a simulated environment Fundu also provides access to advanced tools like ML based sentiment analysis portfolio backtesting stock selector etc to users for learning trade related skills.",
      "legally_known_as": "FundU Fantasy Games Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Kunal Anand",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/khare-iitk/",
      "team_member_2": "Abhishek Khare"
  },
  {
      "startup_name": "Heute and Morgen",
      "website": "https://www.heuteandmorgen.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18JntMtqlemgQdNIrkDgr5oOTnfDKC5jg",
      "profile_summary": "Heute and Morgen provides clients with unparalleled, best-in-class services. As one of the leading insurance brokers headquartered in Lucknow, India. Having mastered the art of understanding, interpreting, and pre-empting the different kinds of risks that their clients face, they have become truly empowered, and in return, they empower them with confidence even during uncertain times.",
      "legally_known_as": "Heute and Morgen Insurance Broker Private Limited",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "61 to 100",
      "team_member_1": "Manoj Upadhyay",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/manoj-upadhyay-hm/",
      "revenue_stream": "Commissions on transaction ",
      "customer_group": "Energy,  Manufacturing,  Technology,  FinTech  ",
      "client_business_structure": "Medium Enterprise  ",
      "geographical_segment": "India",
      "age_group": "\n35 to 45  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Sigma Infosolutions",
      "website": "https://www.sigmainfo.net/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18IY9d26Lycc0zT8K1rgTq7fGB4OP9i2K",
      "profile_summary": "Sigma delivers innovative IT solutions in a variety of industries including FinTech, eCommerce, Technology, Telecom, Retail, and Healthcare, helping companies drive enhanced customer engagement, operational efficiency and return on IT investments. They excel at technology and strategy, a combination they employ to drive enhancement, engagement, efficiency, and ROI.",
      "legally_known_as": "Sigma Infosolutions Ltd",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2004",
      "employees_headcount": "251 to 500",
      "team_member_1": "Sandeep Phophaliya",
      "position": "Founder & Director",
      "profile_link": "https://www.linkedin.com/in/sphophaliya/",
      "revenue_stream": "E-commerce - service, Fee-for-Service",
      "customer_group": "Art & Design,  Blockchain,  E-Commerce,  FinTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "CresEquity",
      "website": "https://www.cresequity.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18JO5NhirvfV2XtTne5d_QrYem6PTYrfA",
      "profile_summary": "CresEquity is a Platform for investors to invest in startups. They connect entrepreneurs and startups with investors and provide them with financial and experiential capital. They give people the opportunity to participate in the venture economy and provide startups with the required resources and financial aid they need for their businesses. Their services enable businesses to streamline the fundraising process and secure funding from renowned investors around the world and boost their valuation. With their investment, an investor can create a consequential value proposition in the form of equity based on the valuation of the company they invest in. Their goal at CresEquity is to strengthen new-age entrepreneurs and start-ups by providing them with a financial services platform that focuses on raising funds for startups from angel investors and VCs. Moreover, they assist businesses in creating pitch decks, business plans, and other documentation for obtaining investor funding.",
      "legally_known_as": "CRES ADVISORS PVT. LTD",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Shubham Yadav",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/shubham-yadav-67b20b262/",
      "revenue_stream": "Commission on transaction, Fee-for-service ",
      "customer_group": "AgriTech,  AdTech,  Clothing & Apparel,  Analytics & BI  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "1": "Prime Venture Partners",
      "2": "Kalaari Capital",
      "startup_name": "Affordplan",
      "website": "https://www.affordplan.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18IG3EjNKdP2M2IWcMPLiRQV4LbVy7wAS",
      "profile_summary": "Affordplan is an alternative finance platform that enables healthcare affordability through planned savings. Hospitals and patients can co-design flexi payment plans so that patients can obtain medical treatment and products through a manageable payment structure developed based on individual need and cash flow. Affordplan enables patients to comply with installments through a wide payment network/options and an intelligent communication system. With this product, Affordplan allows hospitals to widen their customer base and allows patients to obtain life-changing surgeries and other services they otherwise could not afford. The app is available for android & ios for both users.",
      "legally_known_as": "UseKiwi Infolabs Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2C",
      "founded_in": "2016",
      "employees_headcount": "101 to 150",
      "team_member_1": "Tejbir Singh",
      "position": "Head of Finance",
      "profile_link": "https://www.linkedin.com/in/eishaanvarshneya/",
      "team_member_2": "Aditya Sharma",
      "team_member_3": "Ishaan Varshney",
      "series_name": "SERIES-B",
      "date": "Apr 2018",
      "funds_raised_usd_mns": "6.81",
      "name_of_investors": "Kalaari Capital, Prime Venture Partners, Omidyar Network, Lok Capital "
  },
  {
      "startup_name": "KUSH Fintech Consultants",
      "website": "https://www.kushfintech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18FppDeC6nD7Rby2Vo-M_NuU4Jr6uGbYG",
      "profile_summary": "KUSH Fintech Consultants was started a year ago with the motive of serving businesses in both finance and technology related services. It doesn't matter, what the challanges your team of finance and technology is facing, they serve multiple, with 100% project completion rate. Hire the best programmer and financial person for your next project.",
      "legally_known_as": "KUSH Fintech Consultants",
      "industry": "Fintech",
      "based_out_of": "Nepal, Global",
      "business_model": "B2B",
      "founded_in": "2010",
      "employees_headcount": "11 to 20",
      "team_member_1": "Vivekananda Kushwaha",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/bibekanand-kushwaha/",
      "revenue_stream": "Advertising, Affliate Marketing ",
      "customer_group": "Administrative Services  ",
      "client_business_structure": "Small Enterprise",
      "geographical_segment": "Asia Pacific  "
  },
  {
      "startup_name": "KapitalTech",
      "website": "http://www.kapitaltech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=18F36S4MVJMej7Tpnm1Z76uWNbjkCiphy",
      "profile_summary": "KapitalTech (Registered as United Petro Finance Limited) is a leading FinTech in India which offers multiple products like unsecured loans and loans against property with customized loan repayments to its MSME customers. We, at KapitalTech, are an innovative and highly passionate team that blends cutting edge technology with deep human insight into lending. We aim at delivering instant hassle-free credit to a wider spectrum of MSME businesses including millions of unorganized enterprises with limited credit history or are highly leveraged on paper. Dexter, our groundbreaking statistical modeling-based proprietary analytics engine, enables us to “see” beyond the perceived risk of traditional credit frameworks. KapitalTech is backed by Fortune Financial Services (India) Limited, a conglomerate firm involved in Investment Banking , Housing Finance, Equities and Derivatives trading, Equity Research, Commodities Trading, Portfolio Management Services, Distribution of Mutual Funds & Insurance products.",
      "legally_known_as": "United Petro Finance Limited",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "101 to 150",
      "team_member_1": "Shaili Maheshwari",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/vivek-raghavan-7856802/",
      "team_member_2": "Vivek Raghavan",
      "revenue_stream": "Commission on transaction, Interest, Lending"
  },
  {
      "startup_name": "Rukbe",
      "website": "https://www.rukbe.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=188Y2FOoCB2UGB0uUt52My6s8gH-bebHY",
      "profile_summary": "Rukbe aims to accustom SMEs' owners to using facts, metrics, provide unbiased advisory and assist them in making financial decisions in the best of their interests. They know that the fundamentals to managing an SME or a multiple billion business are the same across the board (no matter the business size, you still have to think about managing operations, strategies, marketing, managing people and finances). It is just bigger businesses have more resources (funding and manpower).",
      "legally_known_as": "Rukbe Tech Inc",
      "industry": "Fintech",
      "based_out_of": "Canada, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Jean Pierre Rukebesha",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/jean-pierre-rukebesha-184a512/",
      "revenue_stream": "Freemium, Subscription ",
      "customer_group": "Administrative Services,  FMCG,  Travel/Hospitality,  Hyperlocal  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Asia Pacific,  North America,  South America  "
  },
  {
      "startup_name": "Finsmart",
      "website": "https://www.finsmart.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=180OqaEVv6awSBKn7PdRxC_DDF3YbCasr",
      "profile_summary": "Finsmart was founded with the goal of becoming a user-friendly, affordable financial management platform and fractional CFO service platform. They started this business as financial consultancy at 2019, then noticed the need in the market especially for startups for an affordable financial management option. They wanted to help fill this need and reach as many businesses as possible. That's when they decided to use technology to make their services more accessible to everyone.",
      "legally_known_as": "Finsmart",
      "industry": "Fintech",
      "based_out_of": "Turkey, Global ",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Suat Gorgulu",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/suat-gorgulu-5b227b4/",
      "revenue_stream": "E-commerce product, E-commerce service ",
      "customer_group": "Enterprise Tech,  Financial Services  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "Global,  Europe,  North America  "
  },
  {
      "startup_name": "Sonic Wallet",
      "website": "https://www.sonicwallet.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=17yNaa3V-gNX7HtLE3ljBSOdVnYVVBxgn",
      "profile_summary": "Sonic Wallet mission is to create a financial system that promotes prosperity for all. They believe that everyone should have access to affordable and secure financial services, regardless of their income or location. Their goal is to empower individuals to take control of their financial future and to create a more inclusive global economy.",
      "legally_known_as": "Sonic Wallet",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Rituraj",
      "position": "Co-Founder",
      "revenue_stream": "other",
      "age_group": "\n18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "North America  \n"
  },
  {
      "startup_name": "Gromally",
      "website": "https://gromally.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1LosETOqEG3LxFQQH-Gm0L50YvZdhcQcE",
      "profile_summary": "Gromally enables the largest last-mile delivery, the sabziwalas, through a full-stack solution catering to all their needs. From bulk procurement directly from the farms to formal credit(on the back of AA framework) for their varying needs. Down the line Gromally will enable D2C brands and various other businesses to leverage this traditional distributional network in the form of a simple tech offering to grow. At scale, they will feed precise geography wise fresh-produce data back to the farm layer to curb spoilage and price volatility.",
      "legally_known_as": "Gromally Grocery Supplies Private Limited",
      "industry": "Fintech",
      "based_out_of": "Punjab, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Abhishek Saini",
      "position": "Founder & Chief Sabziwala",
      "profile_link": "https://www.linkedin.com/in/abhishek-saini-527898131/",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "AgriTech,  Consumer,  Services,  E-Commerce,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India Tier 2,  India Tier 3  "
  },
  {
      "1": "Accel",
      "startup_name": "Foundercrate",
      "website": "https://www.foundercrate.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Lx3oF3Vbs5-knmq8pCNPI_9nAoiGHPN6",
      "profile_summary": "Foundercrate is a cloud-based software suite designed to help entrepreneurs manage and streamline fundraising in a highly structured & disciplined manner. It provides a comprehensive set of powerful tools that can help streamline fundraising, communication, and investor relationship management. Their core features that every entrepreneur would like to have Investor CRM: Manages contacts and communication with investors. Data Room: Store, organise, and share critical documents with stakeholders.",
      "legally_known_as": "Ashto Innovations Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Setu Sharma",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/setusharmaprofile/",
      "revenue_stream": "Freemium, Subscription ",
      "customer_group": "Direct-To-Consumer Brands,  Real Estate,  SaaS,  Technology  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  Global  ",
      "total_funding_millions": "100"
  },
  {
      "startup_name": "Fina",
      "website": "https://fina.xyz/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1M80Z4dG1PM53_iXhqByh4QrKe33cy7q1",
      "profile_summary": "Fina is an all-in-one financial management platform where you can connect live accounts, set custom categories and budgets, clean & categorize your transaction data, and finally customize your tracking experience. They use a notion-style interface, lego-block style tracking scenarios, and AI to make the process seamless.",
      "legally_known_as": "Fina Labs, Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Clay Raterman",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/caoxhua/",
      "team_member_2": "Shawn Cao",
      "revenue_stream": "Subscription ",
      "customer_group": "SaaS,  Software,  Technology,  Services  ",
      "client_business_structure": "Startup",
      "geographical_segment": "North America  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "North America  "
  },
  {
      "startup_name": "Equity Address",
      "website": "https://www.equityaddress.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1M8zGu7WTHBGBNByWOYUcg6TER0CA0Otu",
      "profile_summary": "Equity Address properties are located in stable high appreciation and high cash-flowing vacation markets that deliver a strong long-term return. With Equity Address you'll receive economic rights in the underlying property, including potential net rental income, tax benefits, and appreciation. They retain a direct ownership interest in each property. In short, they partner directly with you so you can invest with confidence.",
      "legally_known_as": "Equity Address",
      "industry": "Fintech",
      "based_out_of": "United Arab Emirates, Global",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Mohit Prem Gupta",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/puneet-g/",
      "team_member_2": "Puneet Gupta",
      "revenue_stream": "Fee-for-service ",
      "age_group": "26 to 34  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  "
  },
  {
      "startup_name": "SPARK+ Technologies ",
      "website": "https://sparkplustech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MCpD-HecwuXKjsRVk06w_hM8Db5zTaLJ",
      "profile_summary": "SPARK+ helps businesses through unique processes of product invention beginning with the discovery phase followed by planning, research, development, Marketing & Launch. With a proven track record in blockchain development, SPARK+ deals with various industry domains such as Payment & finance, Decentralized Finance, Documents & Legal, Supply chain, Digital Transformation, E-commerce, Health Care and Identity.",
      "legally_known_as": "SparkRex Technologies PVT LTD",
      "industry": "Fintech",
      "based_out_of": "Goa, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sudesh Sangelkar",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/ramakantsamant1/",
      "team_member_2": "Ramakant Samant",
      "revenue_stream": "Fee-for-service ",
      "age_group": "26 to 34  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  "
  },
  {
      "startup_name": "Profinch",
      "website": "https://profinch.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Lmka0R9EW5h56sodPE6MYRH3E5CXQt7k",
      "profile_summary": "Profinch is a fintech company offering technology solutions that enable financial institutions to transform how they work and be future-ready. The solutions include Consulting services for the advancement of core operations and processes, and off-the-shelf products for 360-degree digitization and data transformation.",
      "legally_known_as": "Profinch Solutions Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "1000+",
      "team_member_1": "Jaskaran Singh Bhogal",
      "position": "Executive Director",
      "profile_link": "https://www.linkedin.com/in/kiran-kumar-aa2a049/",
      "team_member_2": "Ashok Kumar",
      "team_member_3": "Kiran Kumar",
      "revenue_stream": "Interest, Lending"
  },
  {
      "startup_name": "KDC",
      "website": "https://kdc.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1LhMVtMfkA-RlRg2_b1fimyPS4axv5JYh",
      "profile_summary": "KDC is a team of Indian and International designers & digital experts, catering to the domestic and international markets. The core principles of the agency revolve around innovation with creativity. They also have a plethora of products and services based on this principle. They also take active part in the FOSS (Free & Open Source Software) community.",
      "legally_known_as": "KDC Tek Private Limited",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Vachan Kudmule",
      "position": "Chief Operating Officer",
      "profile_link": "https://www.linkedin.com/in/shwetambari/",
      "team_member_2": "Meher Bala",
      "team_member_3": "Shwetambari Shinde",
      "revenue_stream": "E-commerce - product, E-commerce - service, Subscription, Fee-for-service ",
      "customer_group": "E-Commerce,  FinTech,  SaaS,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific,  North America,  Europe  ",
      "age_group": "18 to 25,  26 to 34  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global  "
  },
  {
      "startup_name": "Experian Ventures",
      "website": "https://www.experian.com/ventures/index",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1LiIH0mq0pJ381yNR0sWkHG23PIrLHNv0",
      "profile_summary": "Experian Ventures is the venture capital arm of Experian plc, the world's leading global information services company. It was founded in 2016 on the premise that disruption in today’s data and technology driven world is largely dependent on the creative forces of entrepreneurs. Their team of venture investment professionals has helped entrepreneurs reach their vision with investment capital and strategic partnerships across the globe. The firm is headquartered in Silicon Valley, California and has offices in London, Singapore, Costa Mesa and São Paulo.",
      "legally_known_as": "Experian Ventures",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2016",
      "employees_headcount": "<10",
      "team_member_1": "Rick Gallagher",
      "position": "Senior Vice President & Global Head",
      "profile_link": "https://www.linkedin.com/in/alexmarquez/",
      "team_member_2": "Alex M"
  },
  {
      "startup_name": "Maybright Ventures",
      "website": "https://www.linkedin.com/company/maybright-ventures/about/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Le4DL-8nvd5r72Js50ajbq7CyT0dh-dx",
      "profile_summary": "Maybright Ventures is a young and energetic Fintech Startup looking to revolutionise financial services through use of technology.",
      "legally_known_as": "Maybright Ventures Private Limited",
      "industry": "Fintech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2016",
      "employees_headcount": "41 to 60",
      "team_member_1": "Dhruv Deepak Saxena",
      "position": "Director",
      "team_member_2": "Abhishek Agarwal",
      "team_member_3": "Amtabh Mattoo",
      "team_member_4": "Prabha Jain",
      "team_member_5": "Gaurav Jalan"
  },
  {
      "startup_name": "Business Connect Magazine ",
      "website": "https://businessconnectindia.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Ldx-ikxdC7bRQ6vUVtI6mYafs3-j-V1o",
      "profile_summary": "Business Connect focus is concentrated on publishing inspirational success stories of business houses and innovations spread across the globe in the entrepreneurship world. If your interest lies in the world business market, they are your best choice to know what happened in the life backstage of all sorts of flourished and budding businesses. Business Connect is the window into the minds of the business leaders of India.",
      "legally_known_as": "Business Connect Magazine",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "1998",
      "employees_headcount": "41 to 60",
      "team_member_1": "ASHUTOSH OJHA",
      "position": "Founder & Creative Head",
      "profile_link": "https://www.linkedin.com/in/ashutosh-ojha-8ba61740/",
      "revenue_stream": "Advertising ",
      "customer_group": "HealthTech / MedTech / Healthcare,  Travel/Hospitality,  Services,  Social Media  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  Europe  "
  },
  {
      "startup_name": "Beinsure Media",
      "website": "https://beinsure.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1LZpCzRzQz68JFoYLIg9l3-jnYBdBgdcP",
      "profile_summary": "Beinsure take away the uncertainty of insurance and investment by connecting you with insightful, useful information, in order to help you understand you insurance needs. With this unbiased information, you can get insurance and investment products.",
      "legally_known_as": "Beinsure Media",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Oleg Parashchak",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/oleg-parashchak/",
      "revenue_stream": "Advertising, Subscription, Sponsorship,  Affiliate Marketing ",
      "customer_group": "Blockchain,  FinTech,  Technology,  Insurance  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global,  Europe,  North America,  South America  ",
      "age_group": "26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global,  Europe,  North America  \n"
  },
  {
      "startup_name": "Phi Commerce",
      "website": "http://www.payphi.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1LLEvtxzYVeAKlGH211na5XpaAIxKzPhF",
      "profile_summary": "Phi Commerce has created PayPhi, a ground-breaking omni-channel payment processing platform which processes digital payments at doorstep, online & in-store across variety of form factors such as cards, net-banking, UPI, Aadhaar, BharatQR, wallets, NEFT, RTGS, and NACH. The company was established with the objective to digitize white spaces in payments & go beyond routine payment processing. Phi Commerce’s PayPhi Digital Enablement suite has been developed with the mission of empowering very large untapped blue-ocean sectors dominated by offline payment modes such as cash & cheque to accept digital payments.",
      "legally_known_as": "Payphi",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2015",
      "employees_headcount": "11 to 20",
      "team_member_1": "Jose Thattil",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/rajesh-londhe-8046425/",
      "team_member_2": "Anil Sharma",
      "team_member_3": "Tushar Shankar",
      "team_member_4": "Rajesh Londhe",
      "series_name": "ANGEL",
      "date": "Oct 2016",
      "funds_raised_usd_mns": "0.8"
  },
  {
      "1": "Prabhu Rangarajan",
      "2": "Spark Capital",
      "3": "Adept Ventures",
      "4": "DevX",
      "startup_name": "Finsire",
      "website": "https://finsire.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1L824cs9XPqZKt0M3tvrQbsf0QYxCoAj1",
      "profile_summary": "Finsire's tech rails provide easy identification and verification tools to fetch all assets of the user spread across different asset classes such as Mutual funds, Stocks and Earned salary. Moreover, the APIs also enable easy pledging of assets to enable secured lending.",
      "legally_known_as": "Finsire Technologies Private Limited",
      "industry": "Fintech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Shreyans Nahar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mridul-manhas/",
      "team_member_2": "Subramaniam Chintamani",
      "team_member_3": "Mridul Manhas",
      "revenue_stream": "Commission on transaction, Fee-for-service ",
      "customer_group": "FinTech,  Machine Learning,  Technology,  Software  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global  ",
      "total_funding_millions": "1.3",
      "series_name": "SEED",
      "date": "16 Mar 2023",
      "funds_raised_usd_mns": "1.3",
      "name_of_investors": "DevX, iSeed, Spark Capital, Adept Ventures, 1947 Rise"
  },
  {
      "startup_name": "Keastox",
      "website": "https://www.keastox.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MJouwA6Kn1eYo7wfW-GRR_QMAgRE3TNI",
      "profile_summary": "Keastox provides complete and best stock advice to clients. Keastox believes in clients' best interests and their growth is always their top priority, and they take every step possible to ensure that our recommendations are beneficial to their clients and that their advice is based on perfect analysis through in-depth data.",
      "legally_known_as": "Keastox Best Stock Broker in Ahmedabad",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "<10",
      "revenue_stream": "other",
      "customer_group": "Blockchain,  Cryptocurrency,  Insurance,  FinTech  ",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "INKA Entworks",
      "website": "https://inka.co.kr/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1LTtRw7tzMS6K6CNruSV1WPZeJoH0aQ8L",
      "profile_summary": "INKA Entworks is the inventor of DRM interoperability, DRM technologies- Play ready, and NCG has commercialized very successfully as a worlds-first DRM Interoperability. They provide global customers with content and mobile app security technologies to protect their assets and business models from growing security threats. They have built robust and innovative security solutions that are easy and fast to deploy at an affordable price to protect valuable assets and businesses from Indie studios to Enterprise-class customers.",
      "legally_known_as": "INKA ENTWORKS India Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "South Korea, Global",
      "business_model": "B2B",
      "founded_in": "2014",
      "employees_headcount": "151 to 250",
      "team_member_1": "James Ahn",
      "position": "Co-Founder & Managing Director",
      "profile_link": "https://www.linkedin.com/in/govindraj-basatwar-0670022/",
      "team_member_2": "Govindraj Basatwar",
      "revenue_stream": "Subscription ",
      "customer_group": "Enterprise Tech,  Gaming,  Media,  FinTech  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific  "
  },
  {
      "1": "Dvara Venture Studio",
      "2": "Axilor Ventures",
      "startup_name": "Dvara E-Dairy",
      "website": "https://dvaraedairy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MDm2PtJEAMANlMDCHU7kO2SHnraKZUBo",
      "profile_summary": "Dvara E-Dairy Solutions Private Limited is focused on solving the problems of small and marginal dairy farmers by building an ecosystem platform and deploying new-age technologies, and providing access and scale in financial services and cattle management services. The Dvara E-Dairy solution aims to create synergy between dairy farmers, financial services institutions and value chain companies to assist them in making informed decisions.",
      "legally_known_as": "Dvara E-Dairy Solutions Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "151 to 250",
      "team_member_1": "Ravi KA",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/balajilakshmanan/",
      "team_member_2": "Balaji Lakshmanan",
      "revenue_stream": "E-commerce product, Lending",
      "customer_group": "AgriTech,  FinTech,  Insurance,  Technology  ",
      "client_business_structure": "Large Enterprise",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  ",
      "total_funding_millions": "2"
  },
  {
      "startup_name": "One India Securities",
      "website": "https://www.1sy.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1L7bFq6Kl2pYjtAnBz52qw_TPRowe9v2q",
      "profile_summary": "1sy is offering a one-stop shop for all of your financial needs. They deal with securities, loans, insurance, F&O, FD, and other kinds of bonds. Financial goods offered by 1sy, such as zero-cost Demat accounts, loans, insurance, and fixed deposits, are carefully crafted to satisfy every customer’s demands in a convenient and inexpensive manner.",
      "legally_known_as": "One India Securities Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Arpan Bose",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/arpan-bose-192696207/",
      "revenue_stream": "Commission on transaction ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60 ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Cleo.one",
      "website": "https://cleo.one/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MOML7HnkAVVlshnrA8CM9N3f8_B4MjhQ",
      "profile_summary": "CLEO.one is a platform, that makes investment strategy research, creation, testing, and validation easy. Creating strategy in plain English by connecting available data points in any way you see fit allows you to be more productive than ever, find better investment ideas, and generating better results. We eliminate the need for testing andvalidating investment strategies by programming specialists, who can then focus on more exciting challenges financial markets offer.",
      "legally_known_as": "CLEO Finance Ltd",
      "industry": "Fintech",
      "based_out_of": "Czech Republic, Global",
      "founded_in": "2016",
      "employees_headcount": "21 to 40",
      "team_member_1": "Kevin Grulich",
      "position": "Founder and CEO"
  },
  {
      "startup_name": "Statista Book Keeping",
      "website": "https://statistabookkeeping.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1L4obMf04xNPPVsNkBV7zQyLZC7jI27xH",
      "profile_summary": "Statista Book Keeping has always focused on becoming a trendsetter by optimizing the usage of its abundant resources, extensive experience and unmatched expertise to deliver efficient and timely services to global enterprises across several industries. With a sturdy experience of 3+ years, the company has 15+ satisfied clients globally.",
      "legally_known_as": "STATISTA BOOKKEEPING PVT LTD",
      "industry": "Fintech",
      "based_out_of": "Jammu and Kashmir, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2020",
      "employees_headcount": "151 to 250",
      "team_member_1": "Anand Sunder",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/anand-sunder-4b71291a3/",
      "revenue_stream": "Affiliate Marketing, Commission on transaction, E-commerce Service ",
      "age_group": "35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  Global,  Europe,  North America,  South America  "
  },
  {
      "startup_name": "Vaultus Gold",
      "website": "https://vaultusgold.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1L2Hg_sJy5TB7KP6mP-g0XdHPO2CTKW-m",
      "profile_summary": "Vaultus Gold team has more than 30 years’ experience in the business and are fed-up with the sales tactics and lack of transparency that is common in the industry. The company is on a mission to change the consumer metals industry for the better. They have championed the foremost process in which to buy physical Gold and Silver. They pride ourselves on being efficient with their express service, green eco-friendly processes, and transparency with their clients.",
      "legally_known_as": "Vaultus Gold",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Steve Pell",
      "position": "Co-Founder",
      "revenue_stream": "Commission on transaction, Interest ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "North America,  South America  "
  },
  {
      "startup_name": "DreamStocks",
      "website": "https://dreamstocks.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MhAcFQS2FYCpl_ufahD6QwxEwPDOrHyT",
      "profile_summary": "DreamStocks allows user to make daily trading portfolio by analysing stocks. It is always said anything which is free has no value. So to get the real feel of stock market Traders will have to participate in a contest, which will have an entry fee and compete with other Traders. To keep this interesting each traders will be allotted points based on the stocks they have selected in their Trading portfolio. The Trading Portfolio with highest Points will win the contest.",
      "legally_known_as": "Dreamspursuit Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Ajay Assudani",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ajay-assudani-81265523a/",
      "revenue_stream": "Other",
      "age_group": "18 to 25,  26 to 34,  35 to 45",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Poonawalla Fincorp",
      "website": "https://poonawallafincorp.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1KwlqKApB1CO7MpPj3OMHik-5sHotkGZQ",
      "profile_summary": "Poonawalla Fincorp offers a diversified product suite to address the growing financing needs of their customers and enterprises. They believe in having complete transparency in their dealings as we understand and value the trust customers bestow upon them. Through deep investment in technology and innovation at the core, they strive to create endless possibilities each time and partner in the growth journey of their customers.",
      "legally_known_as": "Poonawalla Fincorp Limited",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "1988",
      "employees_headcount": "1000+",
      "team_member_1": "Adar Poonawalla",
      "position": "Managing Director",
      "team_member_2": "Abhay Bhutada",
      "profile_link": "https://www.linkedin.com/in/ca-abhay-bhutada-927a45269/",
      "revenue_stream": "Lending",
      "customer_group": "Clothing & Apparel,  Consumer Electronics,  Direct-To-Consumer Brands,  Enterprise Tech  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India",
      "age_group": "26 to 34,  35 to 45,  46 to 60 ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "DF Conseils",
      "website": "https://dfconseils.ch/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MhoTkS-8lxGpiskp2Brnl8c5G5lGWsAf",
      "profile_summary": "Founded in 2012, DF Conseils is a Geneva fiduciary made up of a dynamic and passionate team. Particularly close to their customers, they make it a point of honor to adapt their services to your needs in order to support you effectively in the development of your activity. Constantly evolving, their fiduciary provides expertise, rigor and new technologies in order to be able to satisfy your most specific requests. They offer the following services: - Creations, transformations and liquidations of companies - Accounting and taxation - Salary management - Domiciliation and mandates of representations Fiduciaire DF Conseils, your trusted partner. As a 100% digitized fiduciary, their customers benefit from simple and efficient software to keep their daily accounts.",
      "legally_known_as": "DF Conseils",
      "industry": "Fintech",
      "based_out_of": "Switzerland, Global",
      "business_model": "B2C",
      "founded_in": "2012",
      "employees_headcount": "<10",
      "team_member_1": "Fabio Dacuña",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/fabio-dacu%C3%B1a-b7ab864a/",
      "revenue_stream": "other",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "FinTech School",
      "website": "http://www.fintechschool.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MmtEhKvFTwmF15kgT6EQR5G8KS5U5IbZ",
      "profile_summary": "FinTech School is one of its kind online as well as offline training organization founded by FinTech entrepreneurs and subject matter experts who have been there and done that! We are dedicated to bringing practical training to individuals. FinTech School is for companies, banks, educational organizations or individuals looking to get more exposure to FinTech.",
      "legally_known_as": "FinTech Academy, Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2016",
      "employees_headcount": "11 to 20",
      "team_member_1": "Stuart Jivapongse",
      "position": "Data and Growth Manager",
      "profile_link": "https://www.linkedin.com/in/alejandrobuitrago/",
      "team_member_2": "Mateo Pedroza",
      "team_member_3": "Alejandro Buitrago",
      "revenue_stream": "Subscription, Fee-for-service "
  },
  {
      "startup_name": "Complyance.io",
      "website": "https://www.complyance.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MqQZEYVeDsjX16MCKgwcgs3aXHVGM_IL",
      "profile_summary": "Complyance is a company enabling businesses of the Kingdom of Saudi Arabia(KSA) with E-Invoicing Capabilities within their existing systems such as ERP systems and working with a few ERP companies to add E-Invoicing capabilities within their product.",
      "legally_known_as": "Antna Technologies Private Limited",
      "industry": "Fintech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Meiyaappan M M",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/harikrishnan98/",
      "team_member_2": "Hari Krishnan S",
      "revenue_stream": "Subscription ",
      "customer_group": "Administrative Services,  Enterprise Tech,  Software,  SaaS",
      "client_business_structure": "Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global, Europe "
  },
  {
      "startup_name": "Eventyr",
      "website": "https://eventyr.pro/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1KJvD_NnMm07AQmm4diNvxtOtl652RYoW",
      "profile_summary": "Eventyr provides a full range of consulting and development services worldwide for game, metaverse, mobile, and web solutions. They always work to the deadline we give you before the development process, providing the best possible service as quickly as possible. Their goal is to turn your technology ideas into a scalable, transparent, end-to-end custom solution tailored to your business's and your audience's needs.",
      "legally_known_as": "Eventyr",
      "industry": "Fintech",
      "based_out_of": "Ukraine, Global",
      "business_model": "B2C",
      "founded_in": "2017",
      "employees_headcount": "61 to 100",
      "team_member_1": "Daniil Slupskiy",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/danielslupskiy/",
      "revenue_stream": "Selling data",
      "customer_group": "Blockchain,  Software,  FinTech  ",
      "client_business_structure": "Startup",
      "geographical_segment": "Europe,  North America  "
  },
  {
      "startup_name": "FluxFilm",
      "website": "https://gostore.app/fluxfilm",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MqxTUYLRMj5keqsQYf2ctb212LkpgVhA",
      "profile_summary": "FluxFilm buy subscriptions from customers and share it with others to make it affordable. Basically they share the burden of one among others. Not only this they also have introduced a new concept of 1 subscription for all subscriptions. Customers love them and their goal is #stream affordable.",
      "legally_known_as": "FluxFilm",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Harsh Walia",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/harsh-walia-20bb05229/",
      "revenue_stream": "Donations, Renting/Leasing, Subscription ",
      "customer_group": "Communication,  Direct-To-Consumer Brands,  Gaming,  Entertainment  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34,  35 to 45  \n",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "FormuApp",
      "website": "https://www.formuapp.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MrwWFfBSx4c1eVKF0wKN93f7fH5mIARY",
      "profile_summary": "FormuApp have step-by-step guides, instructions, help and technical support so that you fill out and download endless forms and formats from the application; And best of all, without having to repeat your information over and over again, your forms will also be saved so you can edit them and take them to the cloud, and if you're an entrepreneur, you'll have the chance to customize them with your brand.",
      "legally_known_as": "FormuApp",
      "industry": "Fintech",
      "based_out_of": "Colombia, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Alejandro Cabrejo",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/alejandrocabrejoporras/",
      "revenue_stream": "Freemium ",
      "customer_group": "Communication,  Real Estate,  Software,  Media  ",
      "client_business_structure": "Startup",
      "geographical_segment": "South America ",
      "age_group": "26 to 34,  35 to 45,  46 to 60 ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "South America"
  },
  {
      "startup_name": "Unboxing Community",
      "website": "https://unboxing.community/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MuJ4B6btuq3-fiJh6WbDOzj8jTyB7LZK",
      "profile_summary": "Unboxing Community marketplace is a structured setting for electric and electronic products that is free of adverts, banners, and tracking, allowing customers to concentrate on important product information, search by purpose of use, price, financing choices, and make purchase decisions based on objective usage. Their vision is to provide open-access resources that assist users in making buying decisions through an Intelligent Purchasing Thought Process.",
      "legally_known_as": "UnBoxing Community Technology Solutions Private Limited",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "21 to 40",
      "team_member_1": "Dinesh Vairamani",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/nikhil-chowdary/",
      "team_member_2": "Nikhil Chowdary",
      "revenue_stream": "Commission on transaction, E-commerce product, Lending ",
      "customer_group": "Consumer Electronics,  Hardware",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India",
      "age_group": "26 to 34,  35 to 45  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "aVenture",
      "website": "https://aventure.vc/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MvOKaWRuWRzCVFhbMeg2AU2HNJSTS2gW",
      "profile_summary": "aVenture Investment Company intends to provide private funds on its platform upon launch. They can increase your fund’s exposure to new investors, increasing your total assets under management. When your fund gets listed on aVenture it will be showcased to users on the platform who can invest directly into your fund. They help you manage your fund operations by streamlining admin tasks. This includes automating investor communications / updates, accounting / transaction processing, and legal formation / upkeep.",
      "legally_known_as": "aVenture",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "William Callahan",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/williamacallahan/",
      "revenue_stream": "Subscription ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Sourcetable ",
      "website": "https://www.sourcetable.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1MxrB_vtxnZCNag2PhHtzokIHpH7I_XUq",
      "profile_summary": "Sourcetable is building an operating system for the future of work, starting with a spreadsheet that syncs and centralizes all business data. They are building a networked spreadsheet for the contemporary knowledge worker and the modern web. A spreadsheet that is always-on, always connected, and takes advantage of the latest technology advances.",
      "legally_known_as": "Sourcetable Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Eoin McMillan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/eoin-mcmillan/",
      "revenue_stream": "Freemium, E-commerce service ",
      "customer_group": "E-Commerce,  Logistics,  SaaS,  Marketplace  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  "
  },
  {
      "startup_name": "BigEye Global",
      "website": "https://bigeyeglobal.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1N6w2J68G6NkDhUFTWUvqsE9yMvmyV0gI",
      "profile_summary": "BigEye Global provides full-service talents for your custom software development and Cloud Solutions. They gather, understand the requirement based on customer needs and then provide solution with proper analysis. Their comprehensive range of Software solutions will save you time and money, allowing you to focus on growing your business.",
      "legally_known_as": "BigEye Global Solutions Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "M.Kumara Muniasamy",
      "position": "Co-Founder & Director",
      "profile_link": "https://www.linkedin.com/in/radha-kasthuri-a5433339/",
      "team_member_2": "KMS. Radha Kasthuri",
      "revenue_stream": "E-commerce - product, E-commerce - service, Fee-for-service, Subscription ",
      "customer_group": "E-Commerce,  Enterprise Tech,  HealthTech / MedTech / Healthcare,  FinTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Global,  Asia Pacific,  Europe,  North America  "
  },
  {
      "startup_name": "Finanshels",
      "website": "https://www.finanshels.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1N8tRZIXp5lOJDAita1C6NzBaJJ_QvMr-",
      "profile_summary": "Finanshels mission is to empower entrepreneurs and small business owners to reach their full potential. They understand the unique challenges and opportunities that come with growing a business, and they are dedicated to providing the resources and support you need to succeed. Whether you're just starting out or looking to take your business to the next level, they are here to help you every step of the way.",
      "legally_known_as": "Finanshels Accounting Technologies LLC",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Muhammed Shafeekh",
      "position": "Co-Founder & CXO",
      "profile_link": "https://www.linkedin.com/in/shafeeqmohd/",
      "revenue_stream": "Subscription ",
      "customer_group": "E-Commerce,  Travel/Hospitality,  Hyperlocal,  SaaS",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Stock Alarm",
      "website": "https://stockalarm.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NBdmXf0VX3kS_LmoNt45o6HYip6JjQK2",
      "profile_summary": "Never again settle for setting limit orders before going to bed and hoping for the best. Stop checking your phone during work to find yet another lost opportunity. Developed by traders for traders, Stock Alarm allows day/swing traders from all backgrounds to set conditional alarms to be triggered from live stock price movement.",
      "legally_known_as": "Stock Alarm",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Morgan Howell",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/yahia-bakour/",
      "team_member_2": "Yahia Bakour",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "SpotSpreads",
      "website": "https://www.spotspreads.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1JsmzoqiXWZJg0yEim9nhvA2a-xKTgg-s",
      "profile_summary": "SpotSpreads is Crypto Market Maker and Liquidity Provider. SpotSpreads works with crypto exchanges, token projects, VC funds and institutional investors to create more efficient and liquid markets by deploying proprietary algorithmic trading and high frequency execution capabilities.",
      "legally_known_as": "SpotSpreads",
      "industry": "Fintech",
      "based_out_of": "Singapore, Global",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Keshav Saraogi",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/shraddha-agarwal-isb/",
      "team_member_2": "Shraddha Agarwal"
  },
  {
      "1": "Accel",
      "startup_name": "Lower",
      "website": "https://www.lower.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GPFLZFq8kB2v653tUrPrQvOq4wc276Q_",
      "profile_summary": "Lower is a full-stack lender, as well as a platform for buying, selling, refinancing, and insuring homes. The company was founded in December 2018 by Dan Snyder and is headquartered in Columbus, Ohio, United States.",
      "legally_known_as": "Lower",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "251 to 500",
      "team_member_1": "Robert Tyson",
      "position": "Chief Executive Officer",
      "team_member_2": "Mike Baynes",
      "profile_link": "https://www.linkedin.com/in/snyderdan/",
      "team_member_3": "Grayson Hanes",
      "team_member_4": "Dan Snyder",
      "revenue_stream": "Commission on transaction, Interest, Lending",
      "total_funding_millions": "100"
  },
  {
      "1": "Vintage Investment Partners",
      "2": "Naval Ravikant",
      "3": "Citi Ventures",
      "4": "Durable Capital Partners",
      "5": "Akkadian Ventures",
      "6": "Norwest Venture Partners",
      "startup_name": "HoneyBook",
      "website": "http://www.honeybook.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GOH9nVwdjwO4cwtwsgyhtUDBsInpASkw",
      "profile_summary": "HoneyBook is the leading clientflow management platform that makes it easy for independent business owners to sell and deliver their services online. Offering powerful tools for communication, contracts, invoicing, payments, and more, the platform puts independent professionals in control of their process and client experience. HoneyBook is trustedby over 100,000 service-based businesses in the United States and Canada that have booked more than $9 billion in business on the platform. The company has offices in San Francisco and Tel Aviv, with remote staff worldwide. Learn more at HoneyBook.com HoneyBook investors include Tiger Global Management, Norwest Venture Partners, Aleph, Hillsven Capital, OurCrowd, Durable Capital Partners LP, Vintage Investment Partners, Battery Ventures, Citi Ventures, Zeev Ventures, and 01 Advisors.",
      "legally_known_as": "HoneyBook, Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2013",
      "employees_headcount": "151 to 250",
      "team_member_1": "Oz Alon",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/naama-alon/",
      "team_member_2": "Dror Shimoni",
      "team_member_3": "Naama Alon",
      "revenue_stream": "Subscription ",
      "total_funding_millions": "49.8"
  },
  {
      "startup_name": "BizSimpl Consultancy Services",
      "website": "https://www.bizsimpl.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1S-kHi1Wu7ma3w-zkOw14iCtc2iULx-0J",
      "profile_summary": "BCS is a professional services firm providing legal, compliance, and financial services with a focus on the startups. Their multidisciplinary team supports busy entrepreneurs to handle their startup legalities, documentation, bookkeeping, payroll and compliance work so that they can worry less and focus more on growing their business.",
      "legally_known_as": "BizSimpl Consultancy Services Private Limited",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Mohammed Muyeen",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mohammed-muyeen--/",
      "revenue_stream": "Other",
      "customer_group": "SaaS",
      "client_business_structure": "Startup",
      "geographical_segment": "Global, India",
      "age_group": "18 to 25,  26 to 34  ",
      "income_bracket": "High Income",
      "user_belongs_to": "Global, India "
  },
  {
      "startup_name": "MTFX",
      "website": "https://www.mtfxgroup.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GLiZYH2WNbXVGF-MxyGYnqnyYk4nEkSt",
      "profile_summary": "MTFX Group was launched in 1996 with an aim to provide safe and secure payment solutions to thier clients globally and that's the reason they have become now a largest and most respected international foreign exchange company in Canada. MTFX has been able to built their reputation beyond their expectations due to amazing people with their absolutely matched technology mindset. This is the reason MTFX has become one of the most reliable online foreign exchange company for their clients by offering both personal and business services and solutions. MTFX has a huge list of clientele's that include individuals, small to medium sized companies, large multi national corporations, educational institutions, law firms, travel firms, charitable institutions, credit unions and banks. They always feel proud in helping their clients of every industries.",
      "legally_known_as": "MTFX Group",
      "industry": "Fintech",
      "based_out_of": "Canada, Global",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "1996",
      "employees_headcount": "61 to 100",
      "team_member_1": "Hanif Harji",
      "position": "Founder & MD",
      "profile_link": "https://www.linkedin.com/in/hanif-harji-5a887b40/",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "Administrative Services,  DeepTech,  E-Commerce,  FinTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global,  Europe,  North America,  South America  "
  },
  {
      "startup_name": "Taxly.AI",
      "website": "https://taxly.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GGTaSKDEEkPx6mPmgvY4KnhurOcDt1x1",
      "profile_summary": "Say goodbye to tax stress and hello to hassle-free filing with Taxly.AI. This cutting-edge tax assistant is designed to transform your tax experience in Australia, making it effortless and efficient. Taxly.ai offers a range of powerful features to streamline your tax process. From intelligent tax filing and real-time guidance to secure data handling and seamless integration, Taxly.AI has everything you need to file your taxes confidently. Save time with tax automation, increase tax accuracy with advanced algorithms, enjoy cost efficiency without compromising quality, and experience peace of mind. Taxly.AI handles your taxes with precision and expertise.",
      "legally_known_as": "Taxly.Ai",
      "industry": "Fintech",
      "based_out_of": "Australia, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Usman Samad",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/nayyarsamad/",
      "team_member_2": "Nayyar Samad",
      "revenue_stream": "Commission on transaction, Freemium, Subscription ",
      "customer_group": "FinTech,  SaaS",
      "client_business_structure": "Startup",
      "geographical_segment": "Asia Pacific ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Asia Pacific "
  },
  {
      "startup_name": "ORIENT EXCHANGE",
      "website": "https://www.orientexchange.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GFlXgr0ULqntXMXzG5hfCiNwXNorQGJt",
      "profile_summary": "ORIENT EXCHANGE AND FINANCIAL SERVICES PVT. LTD. An Authorized Dealer-II license holding company, registered at Bangalore, India and is part of Orient Exchange Co.(L.L.C.),Dubai, group of companies. Orient Exchange Co. (L.L.C.), established in 1923, is one of the first few exchange Houses to operate from the Middle East.In its 100 years of history the company has been serving millions of customers worldwide and has built a reputation of a customer friendly organisation.The company is into both foreign exchange (retail, whole sale) and remittances business.",
      "legally_known_as": "Orient Exchange and Financial Services Private Ltd",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2013",
      "employees_headcount": "151 to 250",
      "team_member_1": "Bhaskar Rao",
      "position": "Founder & MD",
      "profile_link": "https://www.linkedin.com/in/bhaskar-rao-p-71760917/",
      "revenue_stream": "Commission on transaction ",
      "customer_group": "Collaboration,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "GoldPe",
      "website": "https://www.goldpe.app/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GDBFv6maxAJPkzVPyWFPlMLfp-ZC6Hx0",
      "profile_summary": "GoldPe aims to revolutionize the way young Indians perceive saving by making it an exciting, responsible, and rewarding experience. GoldPe is a prize-linked savings app where you can save in 24K digital gold and win prizes up to 10L each week.",
      "legally_known_as": "Zerobalance Technologies Private Limited",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Parth Shah",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/yaagniraolji/",
      "team_member_2": "Yaagni Raolji",
      "revenue_stream": "Commission on transaction, Interest, Lending ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": " India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "CarinaBot",
      "website": "https://www.carinabot.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1R7Y5EafDaNrWNaHkQ2uGRHpa9vQwCvGN",
      "profile_summary": "CarinaBot is a leading provider of AI-powered automated trading solutions in the financial industry. Their company was founded with the vision of revolutionizing the way individuals trade and invest in the ever-growing cryptocurrency market. With a focus on simplicity, reliability, and profitability.",
      "legally_known_as": "CarinaBot",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "21 to 40",
      "team_member_1": "Damien Reaver",
      "position": "Co-Founder",
      "revenue_stream": "Freemium, Interest, Subscription ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  "
  },
  {
      "1": "XMi High Growth Development Fund",
      "2": "Council & Enhanced Tennessee Fund",
      "3": "Mainsail Partners",
      "startup_name": "Ncontracts",
      "website": "https://www.ncontracts.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NHxLEoqQbACoIjuc6nQx6AX8LpyvoS9i",
      "profile_summary": "Ncontracts mission is to continually improve their clients’ ability to manage risk and compliance. They combine their deep domain knowledge with the latest technology to provide the industry’s most effective and comprehensive integrated risk management and compliance platform, backed by unmatched service and support.",
      "legally_known_as": "Ncontracts LLC",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2009",
      "employees_headcount": "251 to 500",
      "team_member_1": "Michael Berman",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/michael-bermanceo/",
      "revenue_stream": "Subscription ",
      "customer_group": "FinTech",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "North America  ",
      "total_funding_millions": "1.1"
  },
  {
      "startup_name": "BalanceViewer",
      "website": "https://www.balanceviewer.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NKLaFKM-UtsFhYw_OXc7yPS4HxeRPDi9",
      "profile_summary": "BalanceViewer shows your entire financial picture at a glance. Get control over your net worth in less time by tracking only account balances on a monthly basis. Observe your assets, liabilities, stock and crypto portfolios in one app.",
      "legally_known_as": "BalanceViewer",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Artem Lanin",
      "position": "Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/artemlanin/",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "Payaid Payments",
      "website": "https://www.payaidpayments.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NLM6Uq6rst8ZiEj80U-p6rDA1VVHE4WZ",
      "profile_summary": "Payaid Payments core team, comprises with professionals from merchant acquiring background and Industry expertise to help and support your businesses. They unite all payment gateways and all payment modes onto their PayAid platform to give their client’s a seamless Enterprise-level experience.",
      "legally_known_as": "PayAid Payments Private Limited",
      "industry": "Fintech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Alka Mehta",
      "position": "Co-Founder & Director",
      "profile_link": "https://www.linkedin.com/in/phaniteja9/",
      "team_member_2": "Phani Teja",
      "revenue_stream": "Commission on transaction, E-commerce service, Subscription ",
      "customer_group": "FMCG,  Travel/Hospitality,  Services,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Third Rock Techkno",
      "website": "https://www.thirdrocktechkno.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NWXdeIu4Hz-fo26n7o-mogexStaBiGjJ",
      "profile_summary": "Third Rock Techkno solutions are based on a systematic design process. They try and keep the design process simple yet efficient. Each step in the process is clear and transparent. They encourage their clients to be on board with them throughout the process of product design. While working on fixed cost and dedicated resource models, they formulate strategic solutions that help their clients’ businesses thrive in the volatile market.",
      "legally_known_as": "Thirdrock Techkno\n",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2015",
      "employees_headcount": "41 to 60",
      "team_member_1": "Krunal Shah",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/tapantrt/",
      "team_member_2": "Tapan P.",
      "revenue_stream": "Other",
      "customer_group": "Blockchain,  EdTech/Education,  Technology,  Services  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India Tier 1  "
  },
  {
      "startup_name": "UniCash",
      "website": "https://theunicash.co.uk/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Nkf_92x3rEoL_MgSfBZBjthtZmzgDKuT",
      "profile_summary": "UniCash is a UK based app that helps Indian students as well as students from other nationalities to find accommodations, jobs and other necessities once they enroll in a university in the UK. It connects students with local businesses to get them the best deals on food, beverages, clothing and groceries. Moreover, it helps students get accommodation at reasonable prices as well jobs both full-time and part-time.",
      "legally_known_as": "UniCash Ltd",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Mujtaba Khan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mujtaba-khan-6a070a87/",
      "revenue_stream": "Affiliate Marketing, Commission on transaction, Subscription, Fee-for-service ",
      "customer_group": "Direct-To-Consumer Brands,  Real Estate,  Clothing & Apparel  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India Tier 1,  India Tier 2,  Global,  Europe  \n\n",
      "age_group": "18 to 25,  26 to 34  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India Tier 1,  India Tier 2,  Global,  Europe  "
  },
  {
      "startup_name": "Endive Software",
      "website": "https://www.endivesoftware.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Np9CpxpG1Lf9IGkR_JVwW_yH0bk5KCKE",
      "profile_summary": "Endive, have a well-established team of adroit professionals who have expertise in the latest innovative technologies and are capable of delivering state of the art solutions for your every business-specific need. Owing to their optimized and result-oriented IT solutions they have helped many organizations as their valuable technology partners, to scale up the ladder of success. Endive Software has crowned as the best IT service provider and also partnered with top brands such as Salesforce, HubSpot, Intel, Amazon Web Services, and Microsoft.",
      "legally_known_as": "Endive Software Private Limited",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2007",
      "employees_headcount": "11 to 20",
      "team_member_1": "Ajay Goyal",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/endivesoftwares/",
      "revenue_stream": "Other",
      "customer_group": "FinTech,  HealthTech / MedTech / Healthcare,  Technology,  Services  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Nextbigbox",
      "website": "https://www.nextbigbox.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NsbiUrpHM7bR8_eXC4Mh97RkIbmwtPnA",
      "profile_summary": "Nextbigbox play the role of the creator because we build the website and associate them with the SEO services that are the life or energy of the website. All IT and digital marketing services are provided for the exponential growth of the business. They facilitate with different software from their IT experts and help you to accomplish your journey from business to brand.",
      "legally_known_as": "Nextbigbox Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "61 to 100",
      "team_member_1": "Avisekh Sharma",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/deepesh-kashyapa-60a90442/",
      "team_member_2": "Deepesh kashyapa",
      "revenue_stream": "Advertising, E-commerce service, Subscription ",
      "customer_group": "SaaS,  FinTech  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India",
      "age_group": "26 to 34,  35 to 45  \n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Banking Mitra",
      "website": "https://www.bankingmitra.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NvEYDtI-VcXLafHDkLHmKqXC564UC6JK",
      "profile_summary": "Banking Mitra provides their Web platform, Mobile App & POS devices to their registered partner, they use these platform to offer Banking & Financial services, Digital services, e-Governance Services to walk-in customer and also they earns money when they use these services because they have tied up with some banks and financial companies they offer a certain amount on every successful transaction.",
      "legally_known_as": "Orianna India Payments Private Limited",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Rahul Dixit",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/rahul-dixit-12b833167/",
      "revenue_stream": "Advertising, Subscription, Commission on transaction ",
      "customer_group": "FinTech,  Technology  ",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "CentiPenny",
      "website": "https://www.centipenny.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1NxFticSjAbNT-KwA4toQ1pHbE5_rArnj",
      "profile_summary": "CentiPenny is a micropayment processor designed for ease of use by both businesses and end users. It can process transactions as small as $0.0001 without using cryptocurrencies. CentiPenny doesn’t have transaction fees, so businesses can charge less than $1 without losing significant amounts to CC transaction fees. We are cheaper than traditional CC processors up to $2.50.",
      "legally_known_as": "Centipenny LLC",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Bart Melton",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/bart-melton-centipenny/"
  },
  {
      "startup_name": "FinPadi",
      "website": "https://myfinpadi.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Nyue4_-aP43dELeBfUYzWhPJ9Zt1Vwch",
      "profile_summary": "FinPadi was founded in 2021 by Julius Ijidola. FinPadi is a fintech company. It increases access to financial tools while eliminating predatory fees, empowering customers across Africa.",
      "legally_known_as": "FinPadi Technologies Ltd",
      "industry": "Fintech",
      "based_out_of": "Nigeria, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "\n11 to 20",
      "team_member_1": "Julius Ijidola",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/juliusijidola/",
      "revenue_stream": "Commission on transaction, Lending, Fee-for-service ",
      "customer_group": "Consumer Electronics,  E-Commerce,  Events,  Hardware  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Africa ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  \n",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Africa "
  },
  {
      "startup_name": "ScrapUncle",
      "website": "https://scrapuncle.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RGLS43tR8z9pEmxAR5h1wkJRViZwWLEl",
      "profile_summary": "ScrapUncle's digital recycling platform (App/Web) enables users to dispose of their recyclables (“Kabad”) in a responsible and rewarding manner. Users (Households & Enterprises) can simply schedule a doorstep pickup for their recyclables (Paper, Plastics, Metals, Ewaste) & get paid for them.",
      "legally_known_as": "ScrapUncle - Online Kabadiwala",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Mukul Chabbra",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mukul-chhabra-430a18124/",
      "revenue_stream": "Other",
      "customer_group": "Others",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Addus Technologies",
      "website": "https://www.addustechnologies.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RHDeSn6F4NNyLSOW9_gYROno2IXaVQ_t",
      "profile_summary": "Addus have successfully helped many entrepreneurs and startups to create their white label cryptocurrency exchange platform instantly and helped them to generate huge revenue. It is time for you to reach their blockchain experts and cryptocurrency exchange development professionals to get your crypto exchange platform immediately. They provide a completely secure, reliable, and business specific cryptocurrency exchange development software equipped with all the essential features and security options.",
      "legally_known_as": "Addus Technologies",
      "industry": "Fintech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "41 to 60",
      "team_member_1": "Priya Balan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/priya-balan/",
      "revenue_stream": "Other",
      "customer_group": "FinTech,  SaaS,  Software,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Origin Ventures",
      "website": "https://originventures.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RNYInWDd3d6jeYYqV7P-2Qvn9CMMAJK8",
      "profile_summary": "Origin Ventures teams have autonomy to move fast and do what’s right for their clients, making them more personal and nimble than traditional agencies. They care deeply about helping you tackle your biggest challenges and turn your vision into reality. With them, it’s never just about the project at hand. It’s about building trust and enabling your long-term success. They meet you where you are, embed their people with yours, and share their skills every step of the way.",
      "legally_known_as": "Origin Ventures",
      "industry": "Fintech",
      "based_out_of": "Jharkhand, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Atik Singh",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/iamanuragkumar/",
      "team_member_2": "Anurag Kumar",
      "revenue_stream": "Commission on transaction, Fee-for-service, Subscription ",
      "customer_group": "Administrative Services,  FinTech,  Logistics,  SaaS  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Asia Pacific  "
  },
  {
      "startup_name": "Holo",
      "website": "http://www.holo.ae/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1JFT4Ez2kukt7C2r4moHzF4noKyT_1OlN",
      "profile_summary": "Holo is the first digital mortgage platform in the Middle East. The Holo platform selects an optimum mortgage product for a customer based on their personal requirements. The application can be done quickly and simply, 24/7. Apply, upload documents, track online. All from any connected device Participated on the Wamda X cohort for 2020 andnow busy pushing ahead in revolutionizing how the UAE applies for Home Loans.",
      "legally_known_as": "Holo Mortgage Consultant",
      "industry": "Fintech",
      "based_out_of": "United Arab Emirates, Global",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Arran Summerhill",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/michael-hunter-cemap-461666a5",
      "team_member_2": "Michael Hunter"
  },
  {
      "startup_name": "VIPS Finstock",
      "website": "https://vipsfinstock.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1O0HTTEa0k6l7LXfnJQYSGYY1i_bEF0e2",
      "profile_summary": "VIPS Finstock were so fascinated by the scope of the constantly growing ecosystems and the vast opportunities it has to offer in the future that it became their goal to be able to give the same to you. The dream became their reality in 2021 when they decided to launch an exchange platform that their users can fully trust and rely on. They truly believe that digital assets and blockchain technology are the future, and on that basis, they are set to deliver their most prized possession- VIPS Finstock.",
      "legally_known_as": "VIPS Trade Finance",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Kiran Anarase",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/santosh-khute-b1b339232/",
      "team_member_2": "Santosh Khute",
      "revenue_stream": "Commission on transaction, Interest, Fee-for-service ",
      "customer_group": "Blockchain,  Collaboration,  Cryptocurrency,  Marketplace  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "India  "
  },
  {
      "startup_name": "MoneyPlant Fintech ",
      "website": "https://moneyplantfintech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1O-Zx_yP9E4_Xdqxs4GiYBbjaeXHJ7Bcq",
      "profile_summary": "MoneyPlant provides best loans from our partnered banks and NBFC's on one go. The process is completely digital. They will help you to get loan offers from multiple lending partners by just applying from single application form.",
      "legally_known_as": "Pixera Media Labs",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Ashok Saini",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ashoksaini509/",
      "revenue_stream": "Commission on transaction, selling data",
      "customer_group": "FinTech",
      "client_business_structure": "Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Solar Staff",
      "website": "https://solar-staff.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1O9GkwkIphyfGEgQtdg_JVms3VxFJmUt1",
      "profile_summary": "Solar Staff allows more than 1,700+ businesses to interact with 700,000 freelancers. The company’s service offers talent onboarding, task management, security checks, payments madeto 220 countries and territories, copyright rights transactions, and tax payments for freelancers from different jurisdictions.",
      "legally_known_as": "Solar Staff",
      "industry": "Fintech",
      "based_out_of": "Cyprus, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "61 to 100",
      "team_member_1": "Pavel Shynkarenko",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/vspavel/",
      "revenue_stream": "Commission on transaction ",
      "customer_group": "Services",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Europe,  North America  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global,  Europe,  North America  "
  },
  {
      "startup_name": "Acctimize",
      "website": "https://acctimize.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RmrR64bDCvvIpLx-zPGE04olM18xBdPj",
      "profile_summary": "Acctimize provide deep accounting expertise and vast business knowledge in a timely, accurate and cost-effective manner. They support their clients with the information they need to make sound business decisions. they pride themselves in becoming your most trusted advisors to fulfill the promise you made when you went into business for yourself. That is to gain the type of control and agency over your job that lends itself to setting up your business for long term success and profitability.",
      "legally_known_as": "Acctimize",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Radu Bartan",
      "position": "Co-Founder",
      "revenue_stream": "Other",
      "customer_group": "Administrative Services,  Construction,  Consumer,  Services,  Travel/Hospitality  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "North America  "
  },
  {
      "startup_name": "Bright DiGi Gold",
      "website": "https://www.brightdigigold.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1OGSOlG96XXIFzeNQcgmsaILuSQ7NN5fR",
      "profile_summary": "Bright DiGi Gold experts design innovative ideas to make your Gold and Silver investments easier, safer and profitable. They assure you with 24K 999 purity Gold and 999.9 fine Silver, as they procure the Gold and Silver directly from the manufacturers and not mediators avoiding additional cost. As a team, they aim at making the process of investment 100% secure and with ease.",
      "legally_known_as": "Bright Digital Gold Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Ashish Gupta",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/ashish-gupta-578117263/",
      "revenue_stream": "E-commerce product ",
      "customer_group": "E-Commerce,  FinTech,  Services  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45 ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3"
  },
  {
      "startup_name": "Cryptoshare",
      "website": "https://www.cryptoshareapp.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1O4x4ABMXAprQPdIP24fGDTfaJPkHIMfD",
      "profile_summary": "Cryptoshare is a Blockchain Fintech Development and International Patent Holding Company. Our Softwares include: “CryptosharePay” is a Private Label Crypto Credit Card Payment System & Cryptocurrency Payment Service. “CryptoshareATM” is a Software that allows Customers to withdraw their Crypto into “Local Currency” and Exchange their Cryptoto “Local Currency” at an ATM machine. “CryptoshareCredit\" is a Peer to Peer Decentralized Credit System that runs on Blockchain instead of Fiat Currency.",
      "legally_known_as": "Cryptoshare",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Anade Davis",
      "position": "Founder and Data Scientist",
      "profile_link": "https://www.linkedin.com/in/anadedatascientist"
  },
  {
      "startup_name": "Seedwill Consulting",
      "website": "https://www.seedwill.co/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1OI38yL2CpT8lIlG6h3hnNbrDoIMTEu3W",
      "profile_summary": "SeedWill provides its customers with research-based 'Vision to Reality' property solutions. As the name suggests, like a seed, we yield remarkable Realty solutions and ensure Financial security based on their customers' desires. They offer valuable, research-based, and data-backed insights on how you can maintain financial affairs and achieve personal wealth goals. They serve you with astonishing investment outlooks and top property options based on the emerging economic trends so that you can have an amazing experience throughout the Real Estate journey.",
      "legally_known_as": "Seedwill Consulting Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "151 to 250",
      "team_member_1": "Manav Bhatia",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/manavbhatia-mb/",
      "revenue_stream": "Commission on transaction, Licensing, Renting/Leasing ",
      "customer_group": "FinTech,  Real Estate,  SaaS",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise",
      "geographical_segment": "India, Global",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India, Global"
  },
  {
      "startup_name": "Marvel Solutions",
      "website": "https://www.marvelsolutionsllc.com/form",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1IIP_3eqX8qd_WuMsvhv7Gvc2S8T4CHFU",
      "profile_summary": "Marvel Solutions offers a variety of services to help people get back on their feet, such as securing personal and business funding at zero percent for two years. They also help those with bad credit by repairing their credit score. Marvel Solutions has a team of experienced professionals who are knowledgeable in the areas of personal finance, debt management, and credit repair. With their help, you can become more financially independent and secure a better future for yourself.",
      "legally_known_as": "Marvel Solutions LLC",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "<10",
      "team_member_1": "Gennadiy Nadirov",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/geno-nadirov-msee-mba-171a812b/",
      "revenue_stream": "Commission on transaction ",
      "customer_group": "FinTech",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "North America ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income ",
      "user_belongs_to": "North America "
  },
  {
      "startup_name": "Inventam Tech Solution",
      "website": "https://www.inventam.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1OiBO8pb3kO5f1a1LupeRyl_7qTtXTheV",
      "profile_summary": "Inventam Tech Solution is the team of SaaS developers, experienced in delivering the SAAS solutions with multi-tenant support, configurability, scalability, security and monitoring. Their vision is to become outstanding consultancy firm who deliver the wonderful impect on people. Their mission is to create value and make a difference by building products & solutions to increase effectiveness.",
      "legally_known_as": "Inventam Tech Solution (opc) Pvt. Ltd.",
      "industry": "Fintech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Ishani Hareen Desai",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/hareen-desai-a763109a/",
      "team_member_2": "Hareen Alkesh Desai",
      "revenue_stream": "Other",
      "customer_group": "E-Commerce,  EdTech/Education,  FinTech,  Gaming  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  Global,  North America,  South America  "
  },
  {
      "startup_name": "Pistats.io",
      "website": "http://pistats.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1OP4JXEXomdFvL2bDrkEg2RCWngeCCtrK",
      "profile_summary": "Pistats.io helps you in a simple, private, and beautiful way keep track of your transactions, and analyze them based on a powerful tagging system.",
      "legally_known_as": "Pistats.io",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2015",
      "employees_headcount": "<10",
      "team_member_1": "Tomas Hertus",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/tomashertus/",
      "customer_group": "Subscription, E-commerce service "
  },
  {
      "1": "100X.VC",
      "startup_name": "Dolf",
      "website": "https://dolf.finance/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Ons7Mq7pvbusPQ-xsLh-cKcrJpbF8jSK",
      "profile_summary": "At Dolf, we are building a DeFi discovery platform where anyone can discover the best DeFi and cross-Dapps / arb strategies across chains, assess their risk ratings, transact (invest/borrow/trade) with utmost ease in a ‘gasless’ and ‘keyless’ self-custodial manner We use our proprietary risk assessment framework to risk-rate 100+ crypto assets, 200+ DeFi protocols and 10+ chains in real-time. These ratings not only help users to discover best DeFi protocols, but also helps keep an eye on portfolio health in real-time through early alerts.",
      "legally_known_as": "Dolf Limited",
      "industry": "Fintech",
      "based_out_of": "Saint Vincent and the Grenadines, Global",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Kushal Kumar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ullahsaif/",
      "team_member_2": "Ravi Chaurasia",
      "team_member_3": "Saif Ullah"
  },
  {
      "1": "100X.VC",
      "startup_name": "Credwise",
      "website": "https://credwise.co.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1OqX2d2IhwGYJMk6S6vegj-LH2mRzivtZ",
      "profile_summary": "Bangalore based, Credwise is a mobile app that allow credit owners to share credit with their loved ones and bring all expenses on single credit line. Credwise is a VC-funded fintech startup founded by IIT alumni. It aims to democratize how people use credit in India for their family expenses.",
      "legally_known_as": "Credwise Solutions Private Limited",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Archit Sharma",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/dheeraj-kumar-b0481b119/",
      "team_member_2": "Dheeraj Kumar",
      "revenue_stream": "Affiliate Marketing, Commission on transaction ",
      "series_name": "CONVERTIBLE-NOTE",
      "date": "21st Jan 2023",
      "funds_raised_usd_mns": "0.15"
  },
  {
      "1": "100X.VC",
      "startup_name": "Alpyne Labs",
      "website": "https://alpyne.tech/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1OwP5jzodUSevSXGpxjSX_Y73qo8UGeJD",
      "profile_summary": "Alpyne Labs is a collaborative of product strategists, designers, and engineers motivated by the chance to develop a more accessible Web3 universe. Dabbling in fintech, our payments infrastructure assures resilient and smooth transaction processing both on and off chain.",
      "legally_known_as": "ALSD Technologies Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Durgaprasad Mujumdar",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/shabahat-m-ayubi/",
      "team_member_2": "Shabahat Ayubi",
      "revenue_stream": "Interest, Lending ",
      "total_funding_millions": "165"
  },
  {
      "startup_name": "HisabKaro",
      "website": "https://www.hisabkaro.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1OwL3gsfmvMQJnGzAtlNGGOvqtTNkZWj3",
      "profile_summary": "HisabKaro is founded by Viral Pathak, who is an alumni of IIM Lucknow and has 10 years of experience in business development and management consulting. Pallavi is Co founder, and has a strong technology experience of 9 years. HisabKaro intends to give a 360 degree solution to MSMEs across the geography. With the help of tech they intend to give a qualitative analysis of the business to the business owners including lending opportunity.",
      "legally_known_as": "Vanik Analytics Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Viral Pathak",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/pallavi-pathak-a835293b/",
      "team_member_2": "Pallavi Pathak",
      "revenue_stream": "Lending, Subscription, Fee-for-service ",
      "customer_group": "Analytics & BI,  Enterprise Tech,  FinTech,  SaaS  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  \n",
      "income_bracket": "Lower Income,  Lower-middle Income  \n",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "1": "Shankar vailaya",
      "2": "Ketan Parekh",
      "3": "Nitin Gupta",
      "4": "Bipin Kumar",
      "5": "Sanjay B",
      "startup_name": "Octanom Tech",
      "website": "https://octanom.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1PC_Knr8z2kRvIZxnrPd5F05bZ0rP5HgX",
      "profile_summary": "Octanom Tech is constantly looking to grow our team until we reach a combined experience of a 1000 years and able to make a large enough difference through their reliable advice, radical technology and Rive through tools. The Investech firm has the vision of being \"India's largest retail movement\" for traders and Investors through its proprietary tools, rive through tech and innovation investment avenues.",
      "legally_known_as": "Octanom Tech Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Rahul Ghose",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/rahul-ghose-9219a83b/",
      "revenue_stream": "Subscription ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60 ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Sanction Scanner",
      "website": "https://sanctionscanner.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1HvI36Hk2mVaNLgJfkK9lZWzDjwsvnTGZ",
      "profile_summary": "Sanction Scanner is an anti-money laundering solutions provider which provide cost-efficient AML solutions businesses of all sizes can use to protect them from financial crimes. Sanction Scanner provides name screening and transaction monitoring solutions, continuously monitoring the sanction lists of 220+ countries with innovative methods, too many domestic and foreign institutions.",
      "legally_known_as": "Sanction Scanner",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Fatih Coşkun",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/fcoskun/",
      "revenue_stream": "E-commerce product ",
      "customer_group": "FinTech,  Insurance,  Machine Learning,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "1": "Katapult Accelerator",
      "2": "Flat6Labs",
      "startup_name": "Kredium",
      "website": "https://www.kredium.ae/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1PFAv2v244Mpy0SWgzRBeI5DWMxmBw06e",
      "profile_summary": "Kredium helps you find your dream home or future investment, and bring affordable mortgages with the most favorable conditions on the market straight to you. They offer you a growing selection of residential and investment properties in Abu Dhabi and Dubai. They provide you with personalized bank offers within 24 hours, with just a quick registration and some basic information from you.",
      "legally_known_as": "Kredium",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Misa Lazovic",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/misalazovic1/",
      "revenue_stream": "Fee for service ",
      "customer_group": "Real Estate,  Financial Services  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "PermuteHQ",
      "website": "https://www.permutehq.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1PBY3B4ZOoEdD5Yk915H3yA8UT0JDPLzY",
      "profile_summary": "PermuteHQ Business Management Platform enables organizations to scale up their operations without worrying about tech capabilities. PermuteHQ delivers you a complete Tech department, where every aspect of the technical domain is taken care of, including C-Level management, technical specification writing, software design, development, quality assurance and devops. Visit our website to know more: www.permutehq.com",
      "legally_known_as": "Permute Solutions Pvt. Ltd",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sandeep Nanda",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/sarita-dua-82155835/",
      "team_member_2": "Sarita Dua",
      "revenue_stream": "Fee for service, Subscription ",
      "customer_group": "FinTech,  Insurance,  SaaS,  Software  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India Tier 1  "
  },
  {
      "startup_name": "Payvost",
      "website": "https://payvost.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1PQa4mWAXfumheQtybxeam5h-wdORPcsu",
      "profile_summary": "Payvost was founded by Pamilerin Coker, a Nigerian-based digital entrepreneur with the purpose of addressing the challenge of business and consumer access to payments, investment, banking, and cryptocurrency on a single platform. Payvost launched its mobile and web-based application for payment, banking, crypto, investment, and stock.",
      "legally_known_as": "Payvost Inc",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Pamilerin Coker",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/pamilerincoker/",
      "revenue_stream": "Subscription ",
      "customer_group": "Blockchain,  FinTech  ",
      "client_business_structure": "Small Enterprise",
      "geographical_segment": "North America  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "Global  "
  },
  {
      "1": "Axel Wieandt",
      "2": "Bruce Wallace",
      "3": "Guillaume Cabane",
      "4": "Molten Ventures",
      "5": "Speedinvest",
      "startup_name": "Friday Finance",
      "website": "https://www.fridayfinance.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1P_5rTEu9n-3oPP-EBy_VAuBUIQoeRQyt",
      "profile_summary": "Friday Finance streamline the tedious task of finance management, giving you back time and money to focus on growing your business. Features include: all bank accounts in one place, KPI dashboard, cash flow planning, one-click bulk payments, automated pre-accounting, smart company cards with spending limits, and fast access to revenue-based financing. They seek to help entrepreneurs save time and money by drastically simplifying their finance operations so that they can focus on running their business.",
      "legally_known_as": "Friday Finance",
      "industry": "Fintech",
      "based_out_of": "Germany, Global",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "41 to 60",
      "team_member_1": "Christopher Zemina",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/patrick-de-castro-neuhaus/",
      "team_member_2": "Patrick de Castro Neuhaus",
      "revenue_stream": "Subscription ",
      "customer_group": "AgriTech,  Construction,  E-Commerce,  Real Estate  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "Europe  ",
      "total_funding_millions": "23"
  },
  {
      "startup_name": "Ultimez Technology",
      "website": "https://ultimez.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1HYjLgE_yeCwXY4IgmFbYG9aLIzC16PIl",
      "profile_summary": "Ultimez Technology is specialized at transforming the creative business idea into interactive web solutions. They offer full fledged web services from web design, development, interactive ecommerce solutions, graphic design, logo design, digital marketing and online brand reputation that make them stand unique amongst their competitors. Ultimez as the interactive web development company in Bangalore provides the fresh, creative and interactive web resolution for your business.",
      "legally_known_as": "Ultimez Technology pvt.ltd",
      "industry": "Fintech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2012",
      "employees_headcount": "61 to 100",
      "team_member_1": "Qadir A.K",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/qadirak/",
      "revenue_stream": "E-commerce - product, E-commerce - service, Fee-for-service ",
      "customer_group": "Blockchain,  FinTech,  Software,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India, Global",
      "age_group": "26 to 34,  35 to 45,  46 to 60  \n",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India, Global"
  },
  {
      "startup_name": "Multiply",
      "website": "https://multiply.ltd/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Q4gbkkQTIbBfkZnJwu-DeepEYBerHWqQ",
      "profile_summary": "Multiply brings a fresh approach and a passion for investing. They focus on investment research that generates superior risk adjusted returns and deliver alpha. They are in the process of building a category defining company in the hedge fund industry. Building a more trustworthy hedge fund. They aim to harness a fundamentally driven, research focused investment process in both long and short books to generate alpha and to hedge risks accordingly.",
      "legally_known_as": "multiply",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Ravi K G",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/alphaman/",
      "revenue_stream": "Commission on transaction ",
      "customer_group": "FinTech,  Services  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global,  Europe,  North America  "
  },
  {
      "startup_name": "Drivio",
      "website": "https://drivio.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1PSAJ4RhBD-V-3RRE3msoqVEeCmybmB8s",
      "profile_summary": "DrivioTech allows buyers to choose amongst a range of the bikes and immediately processes quotes from the banks and financial institutions. Once buyers select the quote, DrivoTech delivers instant approval from the financier to furnish the 2W purchase. DrivioTech is resolved to disrupt the existing financial service market for two-wheelers by offering an innovative tech based financing service to avail quick and competitive loans within a few clicks.",
      "legally_known_as": "Drivio Technologies Private Limited",
      "industry": "Fintech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "41 to 60",
      "team_member_1": "Saurabh Tripathi",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/itsankurbhardwaj/",
      "team_member_2": "Ankur Bhardwaj",
      "revenue_stream": "Commission on transaction, Interest, Lending, Fee-for-service ",
      "customer_group": "Automotive,  FinTech,  Mobility / Transportation",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Tractorkarvan",
      "website": "https://tractorkarvan.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1HGTGlAvROnoNvXQN6Pr7aQ1-ZT_kWi8J",
      "profile_summary": "Tractorkarvan is India's leading website to purchase, sell, service, finance & insure new or second-hand farm tractors and farm machinery. We provide you with detailed information on all tractor brands in India. You can find true information about all brands like Swaraj, Mahindra, Massey Fergusson, John Deere, Sonalika, Shaktiman, Fieldking etc. You can compare and learn about tractor features, price, specifications, and much more to enquire about your required tractor & implements. We assist all farmers with our 24x7 support service to purchase, sell or finance their tractors & implements.",
      "legally_known_as": "Tractorkarvan",
      "industry": "Fintech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Manik Mital",
      "position": "Business Head",
      "profile_link": "https://www.linkedin.com/in/manikmital/",
      "age_group": "26 to 34,  35 to 45,  46 to 60,  18 to 25  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "AnCrypto",
      "website": "https://www.ancrypto.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1PuJcxK-50fHKeMxST2leKVBn2UeaGZPq",
      "profile_summary": "AnCrypto is one of the world’s first multi-chain decentralized crypto wallets and does not store the private keys of users. It does not have any control over the user activity and yet delivers a lightning fast transaction experience. The wallet has been purposefully designed to ensure a thorough going reform of traditional finance and uplift the consumer with better opportunities.",
      "legally_known_as": "AnCrypto",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Siddharth Gupta",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/siddharth-gupta-25a98471/",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "Lalaji Invest",
      "website": "https://www.lalajiinvest.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1PnoV3YPtv8S7SYBF8hPClf2cxYxG__Wr",
      "profile_summary": "Lalaji Invest initiated operations since decade with a goal of breaking all barriers that traders face in India in terms of cost, support, latest technology and income generation. We founded Lalaji Invest in 2018 to overcome the hurdles we faced during our decade long stint as a trader. A vision to generate fair returns on a yearly basis for every individual with an aim of protecting their capitals.",
      "legally_known_as": "Lalaji Invest",
      "industry": "Fintech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B, B2C",
      "founded_in": "2010",
      "employees_headcount": "41 to 60",
      "team_member_1": "Kalpesh Saraf",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/kalpesh-saraf-09b279210/?originalSubdomain=in",
      "revenue_stream": "Interest, Lending "
  },
  {
      "startup_name": "Instalite Go Digital",
      "website": "https://instalites.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1QCEjjgkYiNmfWstnHScCP5cqjX8IbX6J",
      "profile_summary": "Instalite Go Digital gives you a fast, flexible & transparent way to bank with a strong focus on innovation, easily accessible products, and progressive technology. Built for businesses of all sizes Empowering millions of users, They are on a mission to help businesses of all sizes and types simplify their financial journey with our tech-led business banking solutions. Millions of businesses of all sizes trust Instalite Go Digital's full-featured Banking & Marketplace that combines payments, collections, expense management, and to manage their businesses efficiently.",
      "legally_known_as": "Instalite go digital & co",
      "industry": "Fintech",
      "based_out_of": "Bihar, India",
      "business_model": "B2B, B2C",
      "founded_in": "2017",
      "employees_headcount": "41 to 60",
      "team_member_1": "A k Mehra",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/dr-a-k-mehra-4263981a4/",
      "revenue_stream": "Renting/Leasing ",
      "customer_group": "FinTech",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India"
  },
  {
      "startup_name": "ViTWO",
      "website": "https://vitwo.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GtdKcBRuCSu0ABGbwMRFRmBfMiTUvQKQ",
      "profile_summary": "ViTWO’s best-in-class virtual CFO services have been highly instrumental in scaling up business growth by managing the financial operations with ease and processing any amount of data, projected through detailed analytics. ViTWO offers a wide range of plug-and-play financial solutions, tailored specifically to your business objectives.",
      "legally_known_as": "6 Livo Technologies Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2B",
      "founded_in": "2016",
      "employees_headcount": "61 to 100",
      "team_member_1": "Neeraj Bhuwalka",
      "position": "COO",
      "profile_link": "https://www.linkedin.com/in/laxminarayan-dutta-839417132/",
      "team_member_2": "Laxminarayan Dutta",
      "revenue_stream": "Subscription ",
      "customer_group": "Construction,  Logistics,  Manufacturing  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "PayMEE",
      "website": "https://www.paymee.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GscJbcBEUYQ6ff5TswcVcfhJMEQR9q82",
      "profile_summary": "PayMEE solution helps to mitigate potential risks by applying predictive analytics on customer behavior and sentiments. Algorithms they create from available database are then applied to predict default rate and further helps in recommendation for optimal delinquency strategies that are individually tailored to each account. Their machine learning platform enables accounts receivable managers to increase their yield in the cash process!",
      "legally_known_as": "PayMEE FinTECH Ltd",
      "industry": "Fintech",
      "based_out_of": "United Arab Emirates, Global",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Arunn Cchawla",
      "position": "CTO",
      "profile_link": "https://www.linkedin.com/in/mohit-kokil-33407b38/",
      "team_member_2": "Saeed Kashkash",
      "team_member_3": "Mohit Kokil",
      "revenue_stream": "Subscription ",
      "customer_group": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise",
      "client_business_structure": "Financial Services,  SaaS,  PaaS,  Insurance,  Services,  FinTech  ",
      "geographical_segment": "India,  India Tier 1,  Global  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  Global  "
  },
  {
      "startup_name": "Crypto Chiefs",
      "website": "https://cryptochiefs.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1QBwYOEeWr2JwrVUaQLyycuPPxbb3VTbn",
      "profile_summary": "The Crypto Chiefs have developed a reputation for being the best crypto signals telegram group in the industry. After gaining trust from their members for providing the best cryptocurrency trading signals in the market, their group has gone from strength to strength. Their binance futures signals, which can be traded on any exchange of your choice are seen as the best crypto trading signals worldwide with an average monthly accuracy rate of over 92%.",
      "legally_known_as": "Crypto Chiefs",
      "industry": "Fintech",
      "based_out_of": "United Kingdom, Global ",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Chiefra",
      "position": "Co-Founder",
      "revenue_stream": "Fee-for-service ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income  ",
      "user_belongs_to": "Europe "
  },
  {
      "startup_name": "Algonauts Advisory",
      "website": "https://algonautsadvisory.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RnXIHJvF_E1YPrt7FCybXhXsW0_2MsJn",
      "profile_summary": "Algonauts Advisory system’s focus is to maximize your stock market returns while protecting capital. It has a total of 40 portfolios that cater to various risk profiles. Select long term if you require your money back only after three years while short term is meant for those who may want to invest for a shorter time period. Once you select the portfolio, you can fill in your investment amount in the dashboard. The investment amount could go as low as Rs.5000. Algonauts AI system will manage your portfolio through emails that will instruct you on what stocks to buy and sell along with their quantities.",
      "legally_known_as": "Algonauts Advisory Services Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Sharad Sankaran",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/sharad-sankaran-a5520a37/",
      "revenue_stream": "E-commerce service, Subscription ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60 \n\n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India Tier 1,  India Tier 2  "
  },
  {
      "startup_name": "Zenland",
      "website": "https://zen.land/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1QPE6jZOfzkkPlrjY4d3nXDhCohD_yzPX",
      "profile_summary": "Zenland is home to freelancers, OTC participants, and anyone looking to safely buy or sell services or goods with the guarantee of the formal agreement but with no middleman involved. It is a ready solution for online buyers and sellers, small businesses, solopreneurs, freelancers, and independent contractors dealing with clients online. Their goal is to make buying and selling all kinds of products or services simpler, cheaper, and safer leveraging blockchain immutability and transparency in smart contracts.",
      "legally_known_as": "Zenland",
      "industry": "Fintech",
      "based_out_of": "Uzbekistan, Global",
      "business_model": "C2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Dior Khasanov",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/diornov/",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "Blockchain,  Cryptocurrency,  FinTech,  E-Commerce",
      "client_business_structure": "Startup",
      "geographical_segment": "Global",
      "age_group": "18 to 25,  26 to 34,  35 to 45",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Instantbeema",
      "website": "https://www.instantbeema.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Ghulr9JjFMfXAtMiYAWip-DqvO2XBiTs",
      "profile_summary": "Instantbeema is associated with many insurance companies and they help their customers to select the best suitable insurance policy. With this digital platform their aim is to connect with the entire Pan India Audience including villages. In today's time the information about insurance policies is limited to metro cities only, whereas our aim is to spread this useful information among the rural areas too.",
      "legally_known_as": "Atelier Insurance Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "41 to 60",
      "team_member_1": "Hitesh Thakuria",
      "position": "Co-Founder & Managing Director",
      "profile_link": "https://www.linkedin.com/in/anoopkatta/",
      "team_member_2": "Anoop Katta",
      "revenue_stream": "Commission on transaction ",
      "customer_group": "HealthTech / MedTech / Healthcare,  Insurance",
      "client_business_structure": "Medium Enterprise",
      "geographical_segment": "India",
      "age_group": "26 to 34,  35 to 45,  46 to 60",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "ZtartUp",
      "website": "https://ztartup.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1Gf0M6Lyh8DCpWgCWB4hGtlvLxPcSlvlF",
      "profile_summary": "Dubai based, ZtartUp Network is a multi sector business incubator. It is one of Dubai's leading business incubation, acceleration & coworking space provider. You can secure your Trade License hassle free with incubator program - start business at state-of-the-art co-working/office infrastructure in Dubai, UAE.",
      "legally_known_as": "ZtartUp",
      "industry": "Fintech",
      "based_out_of": "United Arab Emirates, Global",
      "founded_in": "2020",
      "employees_headcount": "41 to 60",
      "team_member_1": "Dr. V Francis John",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/vfrancisjohn/"
  },
  {
      "startup_name": "EBIGSHOP",
      "website": "https://www.ebigshop.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RpOWTwH6pVJyTOfgsSBZ3e-BNt8r-ibB",
      "profile_summary": "EBIGSHOP is proving to be a boon for retailers and shopkeepers working in small villages and towns. Because EBIGSHOP is the only company that does not charge any kind from the retailer and provides the best customer support. We offer the best commissions in AEPS, Money Transfer, Bill Payment and Recharge Services. Which leads to a good increase in the income of the retailer brothers. We believe in providing excellent income tools for retailer brothers. We are committed to introducing new services in our portal. we decide to make simple, easy payment and transactions available to everyone, everywhere!",
      "legally_known_as": "EBIGSHOP INDIA PRIVATE LIMITED",
      "industry": "Fintech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "101 to 150",
      "team_member_1": "Rama Nand",
      "position": "Director",
      "revenue_stream": "Interest, Lending, Subscription "
  },
  {
      "startup_name": "Frazor",
      "website": "https://frazor.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RpddoscioFHYOstOn8RvdGozkkl87Eq-",
      "profile_summary": "Frazor is a SAAS Startup recognized by DPIIT, IStart Rajasthan, and (Ministry of Information and Technology) Meity Startup India. We bridge invention and innovation to help manage change and disruption. Frazor empowers companies with Enterprise Grade Software and Platform to redefine their customer experience and increase In-house productivity with Automation and Integration, Improving Business Efficiency by Fusing technology to fulfill their business needs. Frazor started its Journey in 2021 and since then have worked on developing some interesting Solutions for its various clients across Finance, FMCG, and other domains. Frazor is currently working on its No Code Integration Platform named “Frazor Open Connect” which would fast-track the integration for any Business and would save them in terms of the cost of Development, Time and Expertise required to execute the Enterprise level Integrations.",
      "legally_known_as": "Frazor Enterprise Solutions LLP",
      "industry": "Fintech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Abhinav Jain",
      "position": "Director",
      "team_member_2": "Abhay Sanjay Jidge",
      "revenue_stream": "Subscription, Fee-for-service "
  },
  {
      "startup_name": "Traas World",
      "website": "https://traasworld.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1QoWZb4ROBSPekk1YDlIe6AdTx7brQpOv",
      "profile_summary": "Traasworld.com is a product of TRAAS FINTECH PRIVATE LIMITED. Traasworld serve recharge bill payment, money transfer, aeps, bbps, pan and many more unique service to members(retailers, distributors, master distributor).",
      "legally_known_as": "TRAAS FINTECH PRIVATE LIMITED",
      "industry": "Fintech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Harish Kumar Tailor",
      "position": "Director",
      "team_member_2": "Mukesh Kumar Tailor",
      "revenue_stream": "Interest, Lending "
  },
  {
      "startup_name": "Evable India",
      "website": "https://evableindia.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1QgZ-2x7ehooDrfq6k7iXbla7_cSNBCNQ",
      "profile_summary": "Evable India is a privately held group of companies working towards enabling digital India in various fields of work. They work on various digital solutions like web development, mobile applications and fintech products like rural banking are a few to name. Their team has a great composition of young blood and experienced minds. They are looking forward to onboard more of innovative, creative and determined people in their team.",
      "legally_known_as": "Evable India Private Limited",
      "industry": "Fintech",
      "based_out_of": "Odisha, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "61 to 100",
      "team_member_1": "Pushpak Hota",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/gurubachan-singh-520b463b/",
      "team_member_2": "Gurubachan Singh",
      "revenue_stream": "Commission on transaction, Subscription, Physical commerce, Fee-for-service ",
      "customer_group": "FinTech,  Community,  Hyperlocal ",
      "client_business_structure": "Startup,  Small Enterprise",
      "geographical_segment": "India,  India Tier 3"
  },
  {
      "startup_name": "SRK Money",
      "website": "https://srkmoney.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GaXu8Dl7pDfAWWHNezgyoQ5h6YeZM1i1",
      "profile_summary": "SRK Money is committed to changing the status quo and providing a better experience for the customer as well as for the retailers. So here are 5 good reasons why you should choose them to work with as their partner. Theior vision is to attain globally best standards and become a world-class financial service for the Consumers by providing Safe Secure and reliable Payment Services.",
      "legally_known_as": "SRK FINTECH PVT LTD",
      "industry": "Fintech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Lavkesh Singh",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/lavkesh-singh-21b75496/",
      "revenue_stream": "E-commerce -Service, Fee-for-service, Licensing, Subscription ",
      "customer_group": "FinTech,  Retail,  Services,  Technology",
      "client_business_structure": "Small Enterprise,  Medium Enterprise",
      "geographical_segment": "India"
  },
  {
      "startup_name": "Solarcollab",
      "website": "https://solarcollab.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GbB0qFnlVpz8lJBd5jKcZWXFlA5oVss2",
      "profile_summary": "South Carolina based, Solarcollab Global TM (SCG) is building confederation of National Distributed Energy Communities (DEC) democratizing ownership, production & distribution of solar energy around the world. They are developing Impact Investment Programs (IIPs) for implementation of DEC in emerging, as well as competitive markets, and growing structured portfolios of solar energy assets, shared in our global community.",
      "legally_known_as": "Solarcollab",
      "industry": "Fintech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2018",
      "employees_headcount": "41 to 60",
      "team_member_1": "Craig Donnelly",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/craigjdonnelly/"
  },
  {
      "startup_name": "Scofit",
      "website": "https://scofit.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GZn3WDwuWNU0kuYshb_UBnPRqTWoiu5l",
      "profile_summary": "Scofit is not financial gurus or personal wealth managers who will tell you what to do with your money, but we sure will keep you updated on your credit score, along with handy tips and tricks on how to improve it. In other words, they help you improve your creditworthiness. Every Scofit report grants an overview of your loans, credit cards, and active EMIs, to give you an insight into your credit behavior. The credit score improvement tips are personalized to accommodate your specific requirements, payment history, and purchase patterns.",
      "legally_known_as": "Scofit Fintech Services Private Limited",
      "industry": "Fintech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Himanshu Tiwari",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/himanshutiwariceo/",
      "revenue_stream": "Subscription ",
      "customer_group": "FinTech",
      "client_business_structure": "Large Enterprise",
      "geographical_segment": "India",
      "age_group": "26 to 34  ",
      "income_bracket": "Upper-middle Income",
      "user_belongs_to": "India Tier 2,  India Tier 3"
  },
  {
      "1": "Emkan Capital",
      "2": "Mad'a Investment Company",
      "3": "MetLife Foundation",
      "4": "Signal Peak Ventures",
      "startup_name": "Hubpay",
      "website": "https://www.hubpay.ae/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1RrafVt-wfDVEw8sCrQCV6lmALwSqUxbJ",
      "profile_summary": "Hubpay is an independently regulated provider of cross-border money services designed to increase financial inclusion in emerging markets. Hubpay helps with more than just sending money abroad. You can easily make international bill payments and even recharge mobile airtime overseas.",
      "legally_known_as": "Hubpay Limited",
      "industry": "Fintech",
      "based_out_of": "United Arab Emirates, Global ",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Kevin Kilty",
      "position": "Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/kevinkilty",
      "revenue_stream": "Interest, Subscription, Lending ",
      "total_funding_millions": "22"
  },
  {
      "startup_name": "BizeeBuy",
      "website": "https://www.bizeebuy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1GW_Gxp7C0saiJAs_eNNnHHDOzHEzbUlK",
      "profile_summary": "A Platform driven, Comprehensive B2B Ecosystem for Sourcing & Procurement Process Management. Bringing all B2B Stakeholders to a Common cloud platform. Digitizing Procurement Function for Buyers and Sales, Leads & Order Management for Vendors. Cloud Platform to transform end-to-end Sourcing Transactions-Operationally & Financially: Driven by Data, ML & Analytics.",
      "legally_known_as": "BizAugmentor Global Services Pvt Ltd",
      "industry": "Fintech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B",
      "founded_in": "2013",
      "employees_headcount": "11 to 20",
      "team_member_1": "Ravi Tiwari",
      "position": "Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/ravitiwariweb/",
      "revenue_stream": "Commission on transaction, Freemium, Subscription ",
      "customer_group": "Direct-To-Consumer Brands,  FMCG,  Hardware,  Manufacturing  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise",
      "geographical_segment": "India,  Global  "
  },
  {
      "startup_name": "Vidya Sarthi Institute of Digital Marketing",
      "website": "https://vidyasarthi.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=17ZR3yEXS1Xzv8fzr1tskrnM-MYKPfa_X",
      "profile_summary": "Welcome to Vidya Sarthi Institute of Digital Marketing, where passion meets proficiency in the ever-evolving world of digital marketing. As a leading institute in the field in South Delhi and Faridabad, they empower individuals with the knowledge, skills, and practical expertise necessary to thrive in the digital landscape. At Vidya Sarthi Institute of Digital Marketing, they believe that digital marketing is more than just a skill; it’s a transformative force that has the potential to revolutionize businesses and drive success in the digital age. That’s why their comprehensive programs are designed to equip their students with a deep understanding of the core principles, strategies, and tools required to excel in the digital marketing industry.",
      "legally_known_as": "Vidya Sarthi",
      "industry": "Edtech",
      "based_out_of": "Haryana, India",
      "business_model": "B2C",
      "founded_in": "2017",
      "employees_headcount": "<10",
      "team_member_1": "Anurag Bhatia",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/anurag-bhatiadotin/",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "Design,  Direct-To-Consumer Brands,  E-Commerce,  Enterprise Tech  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  Global  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "myGuider",
      "website": "https://myguider.online/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=17BABxgrKg7fyELpcn9QCAqLNXV0YTpxW",
      "profile_summary": "Empower your professional growth through an all-in-one hub for education, mentorship, and upskilling. - Discover 🌎, explore 🔍, and unlock🔓 new opportunities for career advancement through their innovative portal. - Access expert guidance, resources, and personalized support to shape your educational path and career trajectory. - No longer struggle with navigating the complexities of career development. Their platform is designed to provide seamless and tailored solutions for your professional growth. - Achieve your goals and aspirations with confidence, as their dedicated team of professionals is committed to your success.",
      "legally_known_as": "myGuider Pvt Ltd",
      "industry": "Edtech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Udhay D",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/udhaygame/",
      "revenue_stream": "Advertising, Affiliate marketing, Subscription ",
      "customer_group": "EdTech/Education",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global  "
  },
  {
      "1": "3one4 Capital",
      "startup_name": "Pupilfirst",
      "website": "https://www.pupilfirst.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=178n8TRCPNXiaMA36oKW9LFzvAwdMD6d2",
      "profile_summary": "Pupilfirst is tapping into the opportunities that the New Education Policy (NEP) is throwing up. They plan to work with the regulators and the schooling system in India to start deploying new age courses like computer programming, AR and VR, Electric Vehicles, requirements for building SAAS products - starting from grade 6 and some of the specialized courses at the undergraduate level.",
      "legally_known_as": "Pupilfirst",
      "industry": "Edtech",
      "based_out_of": "Kerala, India",
      "founded_in": "2011",
      "employees_headcount": "41 to 60",
      "team_member_1": "Sanjaya Vijayakumar",
      "position": "Co-Founder",
      "team_member_2": "Gautham"
  },
  {
      "startup_name": "GLOBAL SOFT18",
      "website": "https://globalsoft18.com/azure-data-bricks/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=173nd7xRLKQ3Nn4-nacfwPCQI12qhhdwp",
      "profile_summary": "At GlobalSoft18, they offer comprehensive Azure Data Factory courses in Hyderabad, catering to the growing demand for skilled data engineers. Their training program covers all aspects of Azure Data Factory, equipping participants with the knowledge and practical skills required to excel in their careers. Azure Data Factory Training in Hyderabad: Join their expert-led training program in Hyderabad to master Azure Data Factory. Learn data integration, transformation, and orchestration techniques, and gain hands-on experience in building data pipelines using Azure Data Factory.",
      "legally_known_as": "Global Soft18",
      "industry": "Edtech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Krish",
      "revenue_stream": "Renting/Leasing, Sponsorship, Subscription ",
      "customer_group": "EdTech/Education,  Enterprise Tech,  Social Media,  Software  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India "
  },
  {
      "startup_name": "Embrace Coaching Academy",
      "website": "https://eltsindia.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1733_yo7axhlwMH8yTamCcp89kh4Nssh2",
      "profile_summary": "The Embrace Coaching Academy is started with an aim to provide necessary academic assistance to the students from class 8th onwards of both State and Central board. Apart from regular tutoring, personal mentoring will be provided to the students to overcome their difficulties in studies and help them to achieve their goals. The Academy also focuses exclusively in supporting students of Higher education such as Engineering, Arts and Science etc., in their academics as well as training them for competitive exams, getting placements and many more.",
      "legally_known_as": "EMBRACE LEARNING AND TECHNO SOLUTIONS",
      "industry": "Edtech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "M.C ANAND CHAKARAVARTHI",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/anand-chakaravarthi-m-c-a00aa09a/",
      "revenue_stream": "Fee-for-service ",
      "age_group": "Less than 18,  18 to 25  \n",
      "income_bracket": "Lower Income,  Lower-middle Income  ",
      "user_belongs_to": "\nIndia,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "LEAP",
      "website": "https://leapindia.net/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=171efSWqp3QZ3cyee5SPnx_Y0EWt5gUkf",
      "profile_summary": "LEAP is an intelligent learning platform powered by AI that transforms the way working professionals and fresh graduates manage their careers. By intelligently identifying skill gaps, it offers personalized learning paths tailored to each individual's career aspirations. Not only does LEAP guide users to acquire the necessary skills for their desired roles, but it also empowers them to understand the job market's dynamics better. This tailored and self-guided learning journey, designed to meet the needs of today's rapidly evolving professional landscape, is the unique value proposition that LEAP brings to its users.",
      "legally_known_as": "Edvanta Technologies Private Limited",
      "industry": "Edtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2017",
      "employees_headcount": "21 to 40",
      "team_member_1": "Navin Dutta",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/pandeyyogesh/",
      "team_member_2": "Yogesh Pandey",
      "revenue_stream": "Freemium ",
      "age_group": "18 to 25,  26 to 34,  35 to 45 ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global,  North America"
  },
  {
      "startup_name": "Indian Tech School",
      "website": "https://indiantechschool.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1711gdKrqScft_qdz-MuQ5nN_WIUqC_CX",
      "profile_summary": "Indian Tech School, located in Jaipur, is a leading IT School offering training in web design, web development, and digital marketing. With experienced faculty and a practical curriculum, they have helped numerous students kickstart their careers in the IT industry. Their supportive learning environment, state-of-the-art facilities, and industry-aligned programs make us the top choice for aspiring professionals. Join them today and unlock your potential in the world of information technology.",
      "legally_known_as": "Jaipur, Rajasthan, India",
      "industry": "Edtech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sumit Sharma",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/sumit-sharma-764679137/",
      "revenue_stream": "Fee-for-service ",
      "age_group": "18 to 25,  26 to 34  \n",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "1": "Techstars",
      "startup_name": "Floop Edu",
      "website": "https://www.floopedu.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=17-nFteocrsBkEZEf5GRh_0VtqODakB2E",
      "profile_summary": "Floop is a web app that helps teachers give meaningful feedback faster and empowers students with lifelong feedback literacy. As teachers, we knew feedback is the #1 driver of student learning. However, we were frustrated by the hours we spent giving feedback, only to find students throwing their feedback in the trash. So, we created Floop. Floop is an app that helps teachers give meaningful feedback faster. Unlike other assessment tools, Floop uses research-based strategies to teach students how to use their feedback to learn. Students submit work through the app. Teachers give feedback 4x faster. Students act on their feedback through dialog and revision. And, they engage in peer review and self-assessment, guided to build feedback literacy. With Floop, not only do teachers reclaim their time, every student develops the feedback skills they need to succeed, in school and in life.",
      "legally_known_as": "Floop Edu, Inc",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2015",
      "employees_headcount": "<10",
      "team_member_1": "Melanie Kong",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/mjkong/",
      "revenue_stream": "Subscription ",
      "total_funding_millions": "0.12"
  },
  {
      "1": "TNB AURA",
      "2": "BAce Capital",
      "startup_name": "Vuihoc",
      "website": "https://vuihoc.vn/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16ufnn6-eiuuK8NX29XCws-5o8qpevRtS",
      "profile_summary": "Vuihoc.vn is an online school, born with the mission of providing equal access to high-quality online educational products for students from grades 1 to 12 across the country. Established in early 2019, in a short time, Vuihoc.vn has risen strongly, entered the TOP Companies in the field of EdTech, and become the leading online learning platform for students. Vuihoc.vn has been receiving investments from well-known domestic and foreign investment funds.",
      "legally_known_as": "Hanoi, Ha Noi, Vietnam",
      "industry": "Edtech",
      "based_out_of": "Vietnam, Global",
      "founded_in": "2019",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Lam Do",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/lam-do-07b88b179/"
  },
  {
      "startup_name": "Skolarli",
      "website": "https://skolar.li/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16nSOEhEJwd5u0SriTRfYWLx1lifRRPp2",
      "profile_summary": "Skolarli is a Learning Experience Platform as a Service which aims to revolutionize Learning and Development in Organisations. It's an all encompassing tool for all your training and Learning & Development needs which empowers you to Create and deliver training programs at scale in digital format. Skolarli has been designed ground-up to drive positive learning experience by incorporating learners changing needs and habits, and assists Trainers and Coaches in delivering impactful training programs.",
      "legally_known_as": "Skolarli Edu Labs",
      "industry": "Edtech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Jayalekshmy Nair",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/vinaykannan/",
      "team_member_2": "Vinay Kannan",
      "revenue_stream": "Subscription ",
      "customer_group": "MarTech,  Media,  Retail,  SaaS  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "LSKDM",
      "website": "https://www.lskdm.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16jfYveE3-TELmnXfWvDa-da5Zbv77ofy",
      "profile_summary": "LSKDM is the #1 digital marketing institute established in 2015 and located in CP, Delhi NCR. The institute is well-known for providing top-notch digital marketing training to students and professionals. The institute provides three levels of courses Basic, Advanced, and Diploma, with 100% placement assurance. Each course offered by LSKDM covers all the necessary modules and materials, including practical classes, to equip students with the required knowledge and skills. Overall, LSKDM is a well-rounded institute that provides top-notch digital marketing training, a supportive learning environment, and excellent placement opportunities to its students.",
      "legally_known_as": "LSK Digital Marketing Institute",
      "industry": "Edtech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2015",
      "employees_headcount": "21 to 40",
      "team_member_1": "Rahul Kumar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/digitalrahulkum/",
      "revenue_stream": "Fee-for-service ",
      "age_group": "Less than 18",
      "income_bracket": "Lower-middle Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Mittcons Solution",
      "website": "https://eduold.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16agGfQUuqrrMXCVJPU0Vx3yeWBCESc0s",
      "profile_summary": "Mittcons Solution Provide Technology Services and also marketing & finance services and also campaign services. Their company provide many services at all.",
      "legally_known_as": "Mittcons Solution",
      "industry": "Edtech",
      "based_out_of": "Punjab, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "team_member_1": "Girish Mittal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/girish-mittal-72ab1a173/",
      "revenue_stream": "Advertising, Subscription, E-commerce - product, E-commerce - service ",
      "customer_group": "Administrative Services,  AgriTech,  Analytics & BI,  Blockchain  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  Asia Pacific  ",
      "age_group": "Less than 18",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India  "
  },
  {
      "startup_name": "FirstWork",
      "website": "https://firstworkapp.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16Y0KAwbo44Fiyw6QaZ-6ZOylgJ4KCjJC",
      "profile_summary": "FirstWork was founded in 2022 to respond to an observation that the transition to a remote learning model left learners with special needs behind. Kids with Autism benefit greatly from early intervention, however, it is hard for every child to get the services they need. We make an at-home smart device based learning tool that enables kids to start working on their early verbal goals outside of the traditional therapy session.",
      "legally_known_as": "FirstWork LLC",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Patrick Faga",
      "position": "Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/patrickfaga/"
  },
  {
      "startup_name": "Robert Cavelier University",
      "website": "https://rcu.education/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16XwbuCBTyX-GTGovutpydOaUjOp_nIh5",
      "profile_summary": "Robert Cavelier University (RCU), founded in August 2021, is a distinguished online higher education institution situated in New Orleans, Louisiana. RCU was established with the vision of offering convenient and affordable degree programs while adhering to the most rigorous regulatory standards governing educational institutions. Recognizing the growing demand for accessible higher education, RCU was conceived as a US-based distance learning institution to cater to the needs of a diverse student population. By providing flexible online programs, RCU aims to bridge the gap between educational aspirations and real-world commitments, enabling students from all walks of life to pursue their academic goals.",
      "legally_known_as": "Robert Cavelier University Inc",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Bala Kumar Thambiah",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/bala-kumar-thambiah-6a537915/",
      "revenue_stream": "Subscription ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global "
  },
  {
      "startup_name": "Scribblers and Scribes",
      "website": "https://www.linkedin.com/company/scribblers-scribes/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16R6E2dXDsq048lMQiV82LvxAzDQbQzVT",
      "profile_summary": "Scribblers and Scribes is a part of the ITES-enabled academic publishing services industry. They provide women the skill set required for the industry and provide them the flexibility to work from home at their convenient timings.",
      "legally_known_as": "Scribblers & Scribes Pvt Ltd.",
      "industry": "Edtech",
      "based_out_of": "Jharkhand, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Deveshwar Dayal",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/shweta-bharti-dayal-aa3aa7147/",
      "team_member_2": "Shweta Bharti Dayal\n",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "Content",
      "client_business_structure": "Large Enterprise",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Zonduo Technology",
      "website": "https://zonduo.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16Qlq8WYtx2PpvWqh8QKmdnRB0cG-9xfn",
      "profile_summary": "Zonduo Technology is a Nagercoil, Tamil Nadu based company. It was established in 2020. Zonduo Technology is a leading and Best Ph.D. assistance in India, offering a wider range of high-quality Ph.D article, thesis, and research proposal writing.",
      "legally_known_as": "Zonduo Technology",
      "industry": "Edtech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Mani",
      "revenue_stream": "other ",
      "customer_group": "Education",
      "client_business_structure": "Startup",
      "geographical_segment": "India, Global",
      "age_group": "26 to 34  ",
      "income_bracket": "Lower-middle Income  ",
      "user_belongs_to": "India, Global"
  },
  {
      "startup_name": "CampX",
      "website": "https://campx.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16O3mp7pGGiWbi5Lmc-voJ5Hh9kN60yAA",
      "profile_summary": "CampX is a leading provider of innovative solutions for higher education institutions. With a deep understanding of the challenges faced by colleges and universities, they have developed a comprehensive suite of tools and technologies to empower institutions in their digital transformation journey. From campus management systems to learning management platforms, their solutions are designed to streamline operations, enhance student experiences, and drive institutional success. With a focus on cutting-edge technology, seamless integration, and personalized support, CampX is dedicated to empowering educational institutions to thrive in the digital age and shape the future of education.",
      "legally_known_as": "CampX Edutech Private Limited",
      "industry": "Edtech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Venkat Yellapragada",
      "position": "Co-Founder & Director",
      "profile_link": "https://www.linkedin.com/in/ketansrisai-kondabathula/",
      "team_member_2": "Srikanth Yellagragada",
      "team_member_3": "Ketansrisai Kondabathula",
      "revenue_stream": "Commission on transaction, Subscription, Fee-for-service ",
      "customer_group": "Administrative Services,  Communication,  EdTech/Education,  SaaS  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "eWebinar",
      "website": "https://ewebinar.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16MSXjqRMzv0UhieJF-5djmcGond2wVUe",
      "profile_summary": "eWebinar turns any video into an interactive, automated webinar you can set on a recurring schedule. Designed for customer success and marketing teams, eWebinar delivers the perfect webinar again and again, without you being there. By combining pre-recorded video with live chat and real-time interactions, eWebinar delivers an engaging experience for attendees. We save you from doing the same presentation over and over again. With eWebinar, you can repurpose your time towards other revenue generating activities that cannot be automated. Get started today!",
      "legally_known_as": "eWebinar™ Labs Inc",
      "industry": "Edtech",
      "based_out_of": "Canada, Global ",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Melissa Kwan",
      "position": "Marketing Director",
      "profile_link": "https://www.linkedin.com/in/kiana-saidah/",
      "team_member_2": "Todd Parmley",
      "team_member_3": "Kiana Saidah",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "Enlearn",
      "website": "http://www.enlearn.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=16JnGmd8AdKIdjvuvbGFuIWn1KD_tzmyS",
      "profile_summary": "Enlearn was founded in 2012 as a not-for-profit corporation with funding from the Bill and Melinda Gates Foundation to support data-driven optimization of the learning ecosystem, including optimization of student learning, teacher effectiveness, and courseware efficacy. We’re developing the Enlearn Platform as a curriculum-agnostic platform that provides personalized and adaptive pathways. The data from this optimization process will provide a continuous formative assessment loop to help teachers target and refine their instruction to best meet individual student learning needs. The data also helps prioritize and shape product enhancements for curricula to maximize the learning impact of their products. Five years of research and development at the University of Washington’s Center for Game Science (CGS) lead to the initial Enlearn Platform. The CGS research is focused on the optimization of the interactive experience towards optimal learning outcomes, and was the basis for the game Foldit (among others) which produced world-class experts who, in under three years, published five research papers. The Enlearn Platform will further this work by creating an open adaptive platform for educational content and courseware.",
      "legally_known_as": "Enlearn",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2012",
      "employees_headcount": "21 to 40",
      "team_member_1": "Zoran Popovic",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/zoran-popovic-3628815/",
      "revenue_stream": "Sponsorship "
  },
  {
      "startup_name": "EDUonGo",
      "website": "https://eduongo.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=169oscdd1iIjNVlT44aioKLlwrGAaG3f9",
      "profile_summary": "EDUonGo is a cloud-based learning platform that empowers companies and institutions to create professional and corporate training courses. Companies save time and money by using EDUonGo as their custom eLearning solution. The platform’s flexibility and development support makes it a great alternative to traditional LMSs like Moodle. For more information, visit www.eduongo.com.",
      "legally_known_as": "EDUonGo, Inc",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2012",
      "employees_headcount": "21 to 40",
      "team_member_1": "Ridvan Aliu",
      "position": "Chief Operating Officer",
      "profile_link": "https://www.linkedin.com/in/stevencyyee",
      "team_member_2": "Steven Yee"
  },
  {
      "1": "Matrix Partners",
      "startup_name": "Educative",
      "website": "https://www.educative.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=169EMZHJGxhsumN91wjei5AvktBkDsrtp",
      "profile_summary": "Educative provides interactive courses for software developers. We are changing how developers continue their education and stay relevant by providing pre-configured learning environments that adapt to match a developer’s skill level. For instructors, Educative provides the best authoring platform for creating interactive content with just a few clicks. Join 1.5 Million developers working at top organizations like Amazon, Netflix, Google, and Stripe. Try Educative’s FREE Trial, which gives you unlimited access to nearly 500 interactive, world-class courses spanning JavaScript and Front-End Development, to System Design and Machine Learning.",
      "legally_known_as": "Educative, Inc",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2015",
      "employees_headcount": "251 to 500",
      "team_member_1": "Fahim ul Haq",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/fahimulhaq/",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "AI ML etc",
      "website": "https://www.aimletc.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=163mepUFz27T73G-g9zMKzL9edH9kPwiq",
      "profile_summary": "AI & other technologies are deeply affecting their professional, personal and social lives. And the least they could do is to understand them. So, when I was looking to learn AI/ML, most of the content was focused on Developers, Data scientists, and AI experts. Basically on Tech people. There was no space for “non-technical” people like me. And that's the reason, I thought of initiating the space where non-technical people can learn cutting-edge technologies like Artificial Intelligence in simple no-jargonish language.",
      "legally_known_as": "WinSaga InfoSolutions Pvt Ltd",
      "industry": "Edtech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Nikhilesh Tayal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/nikhileshtayal/",
      "revenue_stream": "E-commerce - product ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income",
      "user_belongs_to": "India Tier 1,  Global,  Europe,  North America  "
  },
  {
      "startup_name": "ZuAI",
      "website": "https://www.zuapp.co/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=163LktY21vbzj_hlGwEu1C668fU8rtZKc",
      "profile_summary": "ZuAI is on a mission to help every learner have access to the same level of intelligent resources to ace and learn any concept. We believe that AI can fundamentally revolutionize learning by giving an affordable & personalized tutor who is available 24x7.",
      "legally_known_as": "Zu Technologies Pvt Ltd",
      "industry": "Edtech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Arpit Jain",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/anubhavmishra-lm/",
      "team_member_2": "Anubhav Mishra",
      "series_name": "SEED",
      "date": "5th Jul 2023",
      "funds_raised_usd_mns": "0.487",
      "name_of_investors": "prime venture partners "
  },
  {
      "startup_name": "Axiom Education",
      "website": "https://www.axiombd.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=15qa4G33yaiXX-HE-TKKL5efYfV4EYMdR",
      "profile_summary": "At Axiom Education Group, they are more than a study abroad consultant in Dhaka. They are a team of passionate individuals who believe in the power of education to transform lives. Their journey began with a simple vision – to help students achieve their dreams of studying abroad and experiencing new cultures.",
      "legally_known_as": "Axiom Education",
      "industry": "Edtech",
      "based_out_of": "Bangladesh, Global ",
      "business_model": "B2C",
      "founded_in": "2005",
      "employees_headcount": "<10",
      "team_member_1": "Wakil Ahamed",
      "position": "Co-Founder",
      "revenue_stream": "physical commerce ",
      "age_group": "18 to 25,  26 to 34",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "Asia Pacific  "
  },
  {
      "startup_name": "GeeksforGeeks",
      "website": "https://www.geeksforgeeks.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=15oid_6D_IvceeYg6cegX_2RhSFYOh-3M",
      "profile_summary": "GeeksforGeeks is a leading platform that provides computer science resources and coding challenges for programmers and technology enthusiasts, along with interview and exam preparations for upcoming aspirants. With a strong emphasis on enhancing coding skills and knowledge, it has become a trusted destination for over 12 million plus registered users worldwide. The platform offers a vast collection of tutorials, practice problems, interview tutorials, articles, and courses, covering various domains of computer science. Our exceptional mentors hailing from top colleges & organizations have the ability to guide you on a journey from the humble beginnings of coding to the pinnacle of expertise. Under their guidance watch your skills flourish as we lay the foundation and help you conquer the world of coding. Our brand is built on the pillars of expertise, accessibility, and community. We strive to empower individuals to enhance their programming skills, to bridge the gap between academia and industry, and provide a supportive community to the learners. GeeksforGeeks is committed to promoting technological advancement and providing opportunities for growth in the ever-evolving field of computer science.",
      "legally_known_as": "GeeksforGeeks",
      "industry": "Edtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2C",
      "founded_in": "2009",
      "employees_headcount": "1000+",
      "team_member_1": "Sandeep Jain",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/sandeep-jain-/",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "Mahe Venture",
      "website": "https://maheventure.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=15cDlor8GIwa-bdObaJvWkdOlAp97sPS8",
      "profile_summary": "Mahe Venture is an e-commerce skill development training company, which started in the year 2020 with a vision to train unemployed people and to educate them about significance of e-commerce business.",
      "legally_known_as": "Mahe Venture",
      "industry": "Edtech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2020",
      "employees_headcount": "11 to 20"
  },
  {
      "startup_name": "e-Definers Technology",
      "website": "https://www.edtech.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=15_oAoyDv20hRn0UdXhepKbgHIzCX9edz",
      "profile_summary": "Based in Delhi NCR, e-Definers Technology is a leading Ecommerce Website Design and Development Company in Delhi, India. engaged in offering professional services such as ecommerce website designing, ecommerce website development, search engine optimization, social media marketing, pay per click management, online reputation management, app development, website designing and development.",
      "legally_known_as": "e-Definers Technology Private Limited",
      "industry": "Edtech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2005",
      "employees_headcount": "11 to 20",
      "team_member_1": "Rajeev Gaur",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/edefiners/",
      "revenue_stream": "Advertising, E-commerce - service ",
      "customer_group": "Technology",
      "client_business_structure": "Startup",
      "geographical_segment": "India"
  },
  {
      "startup_name": "SmartED",
      "website": "https://www.smarted.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=15TeAGEcdf-YDzSdb-tr-3g7n76hweYM5",
      "profile_summary": "Bengaluru-based startup Company was founded by Uppathi Saisreekar and N Gokul in 2023. Wheather it is product, growth, design, Management, Business, Tech and Data SmartED is the place to learn and create from top experts in field.",
      "legally_known_as": "USNG LEARNSPACE EDTECH PRIVATE LIMITED",
      "industry": "Edtech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "21 to 40",
      "team_member_1": "Uppathi Saisreekar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/nagiri-gokul-b29b20211",
      "team_member_2": "N Gokul",
      "revenue_stream": "other",
      "age_group": "18 to 25  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Judicial Adda",
      "website": "https://curiointeractive.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=15LxXxyHkuLZY-Bg7DhF6SQ8SD0Otmu43",
      "profile_summary": "Judicial Adda, registered in the form of a Private Limited Company under the Ministry of Corporate Affairs, New Delhi having its Corporate Identification Number U80902DL2020PTC366864, is one of the India’s leading online platform which facilitates online coaching classes for CLAT, NET/JRF, Judicial Services Competitive Examination like Additional District Session Judge Examination, Civil Judge Junior Division PCS (J), Assistant Prosecution Officer Exams APO, Assistant District Attorney ADA, Judicial Magistrate, Special Public Prosecutor for CBI, and Law Officer for various states i.e. Madhya Pradesh (MP PCS-J), Uttar Pradesh (UP PCS-J), Delhi (DJS), Himachal Pradesh (HPJ), Rajasthan (RJS), Tamil Nadu, Jharkhand, Haryana (HJS), Jammu & Kashmir, West Bengal, Punjab, Bihar (BJS), Uttarakhand, Karnataka, Chhattisgarh, Gujarat, Mizoram, Odisha, Nagaland etc.",
      "legally_known_as": "JUDICIAL ADDA ONLINE PRIVATE LIMITED",
      "industry": "Edtech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "MOHD IRFAN KHAN",
      "position": "Co-Founder",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "EdTech/Education",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Data Science Dojo",
      "website": "http://datasciencedojo.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=15BMm6CTfRzbx1QJUr3VutbReauC7KSI5",
      "profile_summary": "Data Science Dojo is an e-learning company that is redefining the data science education landscape with a simple-to-understand, digestible, and not to brag ---- engaging curriculum. We believe that data science is for everyone. So, we invest our time in simplifying and educating data science and machine learning concepts that our attendees can actually apply to generate business value in their respective job functions. Our love of data science is infectious. We have upskilled more than 10,000+ graduates in over 2,500 companies. Our footprint spans over 150 countries. We offer training in different formats: in-person, virtual live instructor-led, and self-paced training for both individuals and corporates. Our tiger team of industry experts deliver the curriculum that is current, and competitive. Our core values of curiosity, purpose, and excellence-seeking mentality are empowering business leaders to make an impact using data every day.",
      "legally_known_as": "Data Science Dojo",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2013",
      "employees_headcount": "61 to 100",
      "team_member_1": "Raja Iqbal",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/rajaiqbal/",
      "revenue_stream": "Subscription, Fee-for-service "
  },
  {
      "startup_name": "Curio Interactive",
      "website": "https://curiointeractive.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=157p7rGpHkFRk-AXvpQmAeZUQo0X6YsVg",
      "profile_summary": "Curio Interactive provides a variety of engaging online STEAM-based learning activities. The company is based in Seattle, Washington. Curio Interactive is developing revolutionary products to engage kids with science. With the Curio Classroom, kids draw and illustrate their own science lessons using colored pencils, markers, and crayons, then make their drawings spring to life using toylike science.",
      "legally_known_as": "Curio Interactive, LLC",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2017",
      "employees_headcount": "<10",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "MasterClass",
      "website": "https://www.masterclass.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=157_3hZETz51t8jSm940eCPgAfR9CwQSQ",
      "profile_summary": "Launched in 2015, MasterClass is the streaming platform where anyone can learn from the world's best. With an annual membership, subscribers get unlimited access to instructors and classes across a wide range of subjects including Arts & Entertainment, Business, Design & Style, Sports & Gaming, Writing and more.",
      "legally_known_as": "Yanka Industries, Inc",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2015",
      "employees_headcount": "251 to 500",
      "team_member_1": "Aaron Rasmussen",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/drogier/"
  },
  {
      "startup_name": "StudyMEDIC",
      "website": "https://studymedic.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=156qcO8ZlgJVnqwoBvBIUapOQh7bSfHks",
      "profile_summary": "StudyMEDIC is an unprecedented training platform for diversified global medical courses (MRCOG, EFOG-EBCOG, MRCPI, FCPS, MRCS, FRCS, NEET SS, VAGINAL SURGERY, OBG, PLAB, REPRO, HRO, FMGE, ULTRASOUND, MRCEM, MRCP) under one brand with an inimitable training structure and globally recognized mentor panel with decades of clinical and teaching experience. They design their courses with the aid of advanced technologies and methods to cater the needs of all their students to drive their own path of success by creative an innovative, interactive, and informative learning system. They value and uphold their students in all ways they can to benefit them with the most updated and imperative information which transforms their students to best, renowned, and reputed clinicians across the world.",
      "legally_known_as": "StudyMEDIC Academy Pvt Ltd",
      "industry": "Edtech",
      "based_out_of": "Kerala, India",
      "business_model": "B2B, B2C",
      "founded_in": "2016",
      "employees_headcount": "151 to 250",
      "team_member_1": "Dr. Sowmya NS",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/dr-sowmya-n-s/",
      "revenue_stream": "E-commerce - product, E-commerce - service, Subscription, Fee-for-service ",
      "customer_group": "EdTech/Education,  HealthTech / MedTech / Healthcare,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific,  Europe  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global,  Asia Pacific,  Europe,  Africa  "
  },
  {
      "startup_name": "EdMech Skill",
      "website": "http://www.edmech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1511x7LXibYb2dUkzVxNpL64oCh5koOSE",
      "profile_summary": "EdMech Skill Pvt. Ltd came into existence with a mission of providing highly skilled and educated professionals in Logistics.",
      "legally_known_as": "EdMech Skill Private Limited",
      "industry": "Edtech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2021",
      "employees_headcount": "151 to 250"
  },
  {
      "startup_name": "Poonia Edutech",
      "website": "https://www.linkedin.com/in/suresh-poonia-82134a173/?trk=public_profile_samename_mini-profile_title&originalSubdomain=in",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14w2Y3qFC5aXhBVq0f8FGYkHwH1ZXEyx3",
      "profile_summary": "Poonia Edutech is classified as a Non-govt company and is registered at Registrar of Companies, Jaipur. It is involved in Other education. It was incorporated on 11 June 2019.",
      "legally_known_as": "Poonia Edutech Private Limited",
      "industry": "Edtech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2019",
      "employees_headcount": "11 to 20",
      "team_member_1": "Suresh Poonia",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/suresh-poonia-82134a173/?trk=public_profile_samename_mini-profile_title&originalSubdomain=in",
      "team_member_2": "Sayar Poonia"
  },
  {
      "startup_name": "BookMyEssay",
      "website": "https://www.bookmyessay.com.au/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14vLImric-BHstagLDh40t17Dh3XVPArB",
      "profile_summary": "BookMyEssay is a trusted online platform offering expert assignment help in various subjects. Their team of professionals delivers high-quality and personalized solutions tailored to meet academic requirements. With their reliable services, BookMyEssay helps students excel in their studies and achieve academic success.",
      "legally_known_as": "BookMyEssay",
      "industry": "Edtech",
      "based_out_of": "Australia, Global ",
      "business_model": "B2B, B2C",
      "founded_in": "2015",
      "employees_headcount": "151 to 250",
      "team_member_1": "Deepak Singh",
      "position": "Co-Founder",
      "age_group": "18 to 25,  26 to 34  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Consider.it",
      "website": "http://consider.it/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14uWUQXgAMVeZM7z-rS1hGSm79R_SwVHM",
      "profile_summary": "Want to understand what your community thinks? Engage them in a dialogue using Consider.it. Consider.it is the only forum to visually summarize what your community thinks and why. Conversations stay civil and focused even if hundreds participate. With Consider.it, you'll be able to hear diverse voices, learn together, and gain buy-in. And if you want help designing your engagement, we can help you with that too. Consider.it is 100% open source as well.",
      "legally_known_as": "Consider.it",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2013",
      "employees_headcount": "<10",
      "team_member_1": "Travis Kriplean",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/travis-kriplean/"
  },
  {
      "startup_name": "Connect2Classes",
      "website": "http://connect2classes.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14foEnIi01Py91v54cdpU_1J6KTLIk1lB",
      "profile_summary": "Connect2Classes™ (C2C) is an online community for listing and sourcing local offerings of continuing education, personal interest and professional development events: classes, conferences, lectures, and seminars. Career upgrading, lifelong learning, perfecting a skill, or just for fun - Connect2Classes is the place to find the class that meets your needs, schedule and budget.",
      "legally_known_as": "Connect2Classes Inc",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2013",
      "employees_headcount": "<10",
      "team_member_1": "Dallas Jasper",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/dallasjasper/"
  },
  {
      "startup_name": "CollegeMapper",
      "website": "http://www.collegemapper.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14cha6hVctEkeFhDHJyPdKPVLkQvZuChr",
      "profile_summary": "CollegeMapper provides free online college guidance counseling to high school students and parents worldwide. Using our personalized system, students are guided through custom tasks on a monthly basis starting as early as 8th grade. We guide students through every part of the process of preparing for college admissions, choosing colleges, and applying to colleges.",
      "legally_known_as": "CollegeMapper",
      "industry": "Edtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2011",
      "employees_headcount": "<10",
      "team_member_1": "Susanna de Chenoncea",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/susanna-de-chenonceau-4605a319/"
  },
  {
      "startup_name": "C3D",
      "website": "https://c3d.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14as9vNx1Rrhu2oGS3Zj6IbGl2VcYkamZ",
      "profile_summary": "C3D is a browser-based VR coding platform that helps educators teach programming in fun and engaging way. Students will create their own VR world while learning to code, then with one click, they can experience their creation in 360-degree VR using a phone. C3D provides a really fun way for kids to learn programming. With a smooth learning curve and engaging process, students can construct an entire virtual world and then explore it using any VR headset. Teachers get a dashboard to track student progress and detailed lesson plans. Designed by veteran comp sci educators, it matches best in class curriculum and teaches the most important principles of programming to children as young as 5 years old. C3D makes it fun for kids and easy for teachers.",
      "legally_known_as": "Codeca Educational Technology and Services Inc.",
      "industry": "Edtech",
      "based_out_of": "Canada, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Jason Madar",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/jason-madar-2b07966b/",
      "team_member_2": "Tomoko Okochi",
      "revenue_stream": "Subscription "
  },
  {
      "startup_name": "QuoDeck",
      "website": "https://www.quodeck.com/welcome/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14_p-GUfgMGbUYJCDaX54g8nxx_v04MAd",
      "profile_summary": "QuoDeck is a B2B SaaS Digital Learning and Engagement Platform that enables organizations to use interactivity and games to engage audiences such as employees, value chain and gig workforces. QuoDeck caters to the growing need for organizations to build digital engagement for these audiences, an inherently scalable approach. The QuoDeck Platform specializes in creating pull through the use of games and gamification mechanics, driving higher adoption and greater retention among the audience. Furthermore, by using interactivity and games, QuoDeck helps capture valuable data in real-time and analyze it through big data analytical tools. The platform is highly scalable and equipped to handle large user bases and extended ecosystems such as sellers, agents, vendors, distributors, partners etc. Today, QuoDeck is the market leader in its category, catering to over 5 million users across an enterprise client list that includes Unilever, Vodafone, Flipkart, Dabur, etc.",
      "legally_known_as": "QuoDeck Technologies Pvt Ltd",
      "industry": "Edtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2017",
      "employees_headcount": "101 to 150",
      "team_member_1": "Kamalika Bhattacharya",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/arijitlahiri/",
      "team_member_2": "Arijit Lahiri\nCo-Founder and CPO"
  },
  {
      "startup_name": "Propliners Realty",
      "website": "https://propliners.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14VQQOOLcPGD-3knBnF8d39NaeXxUdAeZ",
      "profile_summary": "Propliners reality takes complete care of their investors and buyers as we are taking the properties for their own selves thus they take care of all aspects related to the properties like legal-check, proper documentation as per the local authority norms and most important guaranteed best price/rentals. They are working in this industry from long time hence most of their developers are well known to us hence we have the largest and updated property portfolio for all your real estate needs.",
      "legally_known_as": "Propliners Realty",
      "industry": "Edtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Achal Agarwal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/himanshu-propliners-realty-noida/",
      "team_member_2": "Himanshu Propliners",
      "revenue_stream": "Advertising, Lending, E-commerce - service, Affiliate marketing ",
      "customer_group": "Administrative Services,  Consumer,  Services,  Media,  E-Commerce  ",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "India"
  },
  {
      "startup_name": "Diksha Arora",
      "website": "https://dikshaarora.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14SeOYTyMWynYF3A_5g39-4yKmPuU8Y_Z",
      "profile_summary": "It’s time to understand how to succeed in the job interview so that you are ever closer to your goal of obtaining one or more job offers. The key to success is the quality and delivery of your responses. Preparation includes being ready for anything. Rather than worrying about off-the-wall questions. The more effort you put into your interview preparation, the more success you’ll see in obtaining job offers, especially if you remember and follow these job interview tips.",
      "legally_known_as": "Diksha Arora",
      "industry": "Edtech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "11 to 20",
      "team_member_1": "Diksha Arora",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/dikshaarora-careercoach/",
      "revenue_stream": "Interest",
      "age_group": "Less than 18,  18 to 25  \n",
      "income_bracket": "Lower Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "IndEdu Consultancy",
      "website": "https://www.indeduconsultancy.com/home",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14QVFcxEiKsKhrg7L12D2sw0gQttS6hbt",
      "profile_summary": "IndEdu Consultancy provides you with the best and most comprehensive admission counselling service for all your educational dreams in India and abroad. Comprehensive admission guidance their Total Career Counselling & Admission Guidance program is designed to help you choose the best course and college for your career aspirations. They provide in-depth information on all disciplines of Engineering, Medical, Paramedical, and Management. Expert counselling they understand the complexities of the admission process and have expert counsellors to guide you through every step, right from choosing the right course to filling out the application forms. Their team of experts will help you make informed decisions that are tailored to your unique needs. Affordable packages At IndEdu Consultancy, they offer affordable packages that include detailed guidance on every step of the admissions process. They also provide post-admission services such as visa assistance and relocation support.",
      "legally_known_as": "IndEdu Consultancy",
      "industry": "Edtech",
      "based_out_of": "Uttarakhand, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Kunal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/kunalgkv/",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "EdTech/Education",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India, Global",
      "age_group": "Less than 18,  18 to 25,  26 to 34  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India, Global "
  },
  {
      "startup_name": "TurantMART",
      "website": "https://turantmart.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=14L4PoMPs40dw0TmiLB4c_IM6rsHePPNn",
      "profile_summary": "TurantMART as www.turantmart.com is a free online search engine classified directory in India, Business-tech portal which provides all the basic requirements of searching Business in a single platform from Travels, Online Food, Packers and Movers, Jewellery, Restaurants, Repair and Services, Hotels, Resorts, Cake Shops & Wedding Venue to your Honeymoon Getaway. One can check prices, booking availability, reviews of service providers and their experience of work with a single click. Here at turantmart.com, you can decide which venue to choose, which decoration will look good, who should be the photographer, which car for the wedding, which makeup artist to hire, and where to plan your honeymoon getaway. TurantMART is your one-stop-shop for your precious life & endless wedding shopping at the best prices available.",
      "legally_known_as": "Turantmart Internet Ecom LLP",
      "industry": "Edtech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "team_member_1": "Waseem Khan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/waseem-khan-741036221/",
      "revenue_stream": "Advertising, Subscription, Affiliate marketing, E-commerce - service ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Solely verified",
      "website": "https://www.solelyverified.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=146ngh8q-8UrmuZBbHfshKlGuZHak5qTh",
      "profile_summary": "To provide correct & legitimate details about businesses and other facts, Solely Verified has taken a step ahead. Solely Verified is a trusted online search website where you can find legitimate information about facts and companies such as real estate industries, places, restaurants, hotels, attractions, etc.",
      "legally_known_as": "Solelyverified",
      "industry": "Edtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "revenue_stream": "Advertising, E-commerce - service ",
      "customer_group": "AdTech,  E-Commerce,  Real Estate,  EdTech/Education  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "GoLab",
      "website": "http://www.golabsolutions.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12mkgaUvGFaucXDqEKa0wETouKlUqYjwF",
      "profile_summary": "GoLab is the first certified mobile laboratory for FDA-EUA authorized COVID-19 testing—with Same-Day PCR results, and Rapid Molecular or Antigen test results in as soon as 15 minutes. We bring fast, reliable testing directly to workplaces, teams, care facilities, and private events. For every test we perform, we donate a portion of proceeds to community charities.",
      "legally_known_as": "Go Lab LLC",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Della Tall",
      "position": "Co-Founder and Managing Director",
      "profile_link": "https://www.linkedin.com/in/dellatall/"
  },
  {
      "startup_name": "Perfect Smile Super Speciality Dental Clinic",
      "website": "https://perfectsmiledentalclinics.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12c2GHzqU2ytpL1LjNQp9ITFqi1ex-tbs",
      "profile_summary": "Perfect Smile Super Speciality Dental Clinic best dental clinic in South Kolkata, best dentist in south kolkata, dentist in south kolkata, dental hospitals in kolkata,best dental clinic in Kolkata, best dental implant clinic in Kolkata, Cosmetic dentist in Kolkata, Root canal specialist in Kolkata, best orthodontist in Kolkata, best dental braces clinic in Kolkata Visit - https://perfectsmiledentalclinics.com/ Call us to book your appointment at 7044066486",
      "legally_known_as": "Perfect Smile Super Speciality Dental Clinic",
      "industry": "Healthtech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2005",
      "employees_headcount": "<10",
      "team_member_1": "Dr. Shresth Kumar Bhagat",
      "position": "Co-Founder",
      "revenue_stream": "other ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "india"
  },
  {
      "startup_name": "Cure My Knee",
      "website": "https://curemyknee.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12XepfzsHP5ihACA0wYsk7GRYkUHeVWZi",
      "profile_summary": "CMK offers specialized spine-related treatments delivered by a team of skilled orthopedic experts headed by Dr. DK Das. Their focus is on patient-centered care, giving freedom from pain.",
      "legally_known_as": "Cure My Knee",
      "industry": "Healthtech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2C",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Dr. DK Das",
      "position": "Co-Founder",
      "revenue_stream": "Fee-for-service ",
      "age_group": "35 to 45  ",
      "income_bracket": "Lower Income  ",
      "user_belongs_to": "india"
  },
  {
      "startup_name": "Gravid",
      "website": "https://gravidparenting.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12QnwpmJM7jhSlTRb7IpKuUaaqTfmqp-c",
      "profile_summary": "Gravid is your companion on the pregnancy, parenting & personal growth journey. With a vision to touch and transform a million lives, they are a Health tech platform designed to support mothers at all stages of pregnancy and parenting with a focus on the darker side of Pregnancy and real-life issues like PPD & Breastfeeding/Lactation Challenges. They are able to do this with over service offerings which include holistic wellness programs and packages, Workshops & Events, Expert Consultation...all through an intuitive app and robust community support.",
      "legally_known_as": "Gravid Mother and Baby Pvt. Ltd.",
      "industry": "Healthtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Pratiksha Ratna",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/prashanthpoovaiah/",
      "team_member_2": "Prash Poovaiah",
      "revenue_stream": "E-commerce - product, Freemium, Subscription, Fee-for-service ",
      "customer_group": "HealthTech / MedTech / Healthcare,  Travel/Hospitality,  Technology,  Software  ",
      "client_business_structure": "Medium Enterprise,  Large Enterprise ",
      "geographical_segment": "India,  India Tier 1  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Purec Hygiene",
      "website": "https://www.purechygiene.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12OHBZGkOJCiYN3jqAyq0pWDfRMjkRqqA",
      "profile_summary": "Purec Hygiene is a reputable company at the forefront of the hygiene industry, dedicated to providing innovative solutions for clean and healthy environments. With a strong commitment to ensuring a fresh and safe experience for all, Purec Hygiene offers a wide range of products and services tailored to meet the diverse needs of its customers. At the core of Purec Hygiene's offerings are its cutting-edge hygiene products. The company develops and manufactures advanced solutions such as Automatic Toilet Seat Cover, Sanitary Pad Vending Machine, Automatic Dispensers & much more. By leveraging their expertise, the company offers comprehensive hygiene solutions specifically tailored to each sector's unique requirements.",
      "legally_known_as": "Purec Hygiene International LLP",
      "industry": "Healthtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Tapasya Sushil Raika",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/niket-shah-590542147/",
      "team_member_2": "Ruchit Rajesh Desai",
      "team_member_3": "Niket Rajan Shah",
      "revenue_stream": "E-commerce - product, Renting/Leasing ",
      "customer_group": "Administrative Services,  Consumer,  Entertainment,  Services,  Travel/Hospitality  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  "
  },
  {
      "1": "Primavera Capital Group",
      "2": "Alibaba Group",
      "startup_name": "OptoMedic",
      "website": "https://www.optomedic.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12IhRBWdqKyVhcoOTyowLqKclZdu4BYbo",
      "profile_summary": "OptoMedic, founded in 2013, is a pioneering and leading manufacturer in China. They are developing high-end products and solutions for Minimally Invasive Surgery (MIS). OptoMedic is an optical medical device developer and maker.",
      "legally_known_as": "OptoMedic Technologies, Inc",
      "industry": "Healthtech",
      "based_out_of": "China, Global",
      "founded_in": "2013",
      "employees_headcount": "151 to 250",
      "team_member_1": "Xin An",
      "position": "Co-Founder & CEO"
  },
  {
      "startup_name": "Valar Aesthetic",
      "website": "https://www.valarmd.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12EuHjrjcqgHHnUrr4nOqc6NfEl-R0NlW",
      "profile_summary": "At Valar, they have a professional team consisting of a physician, registered nurse, esthetician, and medical assistant who partner with you to develop your individualized treatment plan.",
      "legally_known_as": "Valar Aesthetics",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Dr. William Bauer",
      "position": "Co-Founder",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income ",
      "user_belongs_to": "\nNorth America,  South America  \n"
  },
  {
      "startup_name": "Dental Square",
      "website": "https://www.digitaldentalsquare.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12DkpJuBRM-Sgf7hOLYxlikhLIw1IWnzo",
      "profile_summary": "Dental Square is an innovative startup providing digital dentistry solutions to clients Their experienced technicians design and deliver a range of high quality dental products, including Braceless ™clear aligners, DMLS, zirconia crowns, mouthguards, removables dental implants, nightguards, models, and more their commitment to quality and customer satisfaction has helped us establish a reputation as a reliable partner in the dental industry Their vision is to become a pioneer in the industry by exploring new and cutting edge technologies to continuously improve their products and services They are passionate about staying ahead of the curve and setting new standards to better serve their clients and their patients They are committed to providing exceptional customer support and the highest level of quality, and they are excited to be at the forefront of the digital dentistry revolution.",
      "legally_known_as": "DENTAL SQUARE",
      "industry": "Healthtech",
      "based_out_of": "Uttarakhand, India",
      "business_model": "B2B, B2C",
      "founded_in": "2011",
      "employees_headcount": "<10",
      "team_member_1": "Dr. Sanjeev Kumar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/sanjeev-kumar-4b189b220/",
      "revenue_stream": "Advertising, physical commerce",
      "customer_group": "HealthTech / MedTech / Healthcare  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India, Global "
  },
  {
      "startup_name": "Saralpath",
      "website": "https://www.saralpath.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12Ct_7gFr5F5rmgOFpCdVu-RAj9vLZl4b",
      "profile_summary": "Saralpath pathology reporting software manages pathology labs. manage patient test records, and improve workflow. Now SaralPro Technologies Pvt. Ltd. is an established name in the field of healthcare IT services and provides the complete solution to all size pathology labs & hospitals. We have our presence across all over India with more than 2500 clients.",
      "legally_known_as": "SaralPro Technologies Pvt. Ltd",
      "industry": "Healthtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2C",
      "founded_in": "1994",
      "employees_headcount": "61 to 100",
      "team_member_1": "Arjun Singh",
      "position": "Co-Founder",
      "revenue_stream": "Fee-for-service ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "India"
  },
  {
      "1": "Iron Pillar",
      "startup_name": "Fold Health",
      "website": "https://www.fold.health/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12BqaI2Tbuuj7viksyZu0Zawa96Ypj9UD",
      "profile_summary": "Fold Health was established in November, 2021 in San Francisco, California. Fold Health combines existing investments in people and resources. Fold is here to help you expand and enhance your healthcare business virtually. Through the coordination of the people, technology, and events that drivecare campaigns, the company's platform assists you in designing, providing, and carrying them out.",
      "legally_known_as": "Fold Health, Inc",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2021",
      "employees_headcount": "41 to 60",
      "team_member_1": "Kaustubh Kabra",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/patelbhaveshashok/",
      "team_member_2": "Bhavesh Patel",
      "total_funding_millions": "12",
      "series_name": "SEED",
      "date": "12th Jul 2023",
      "funds_raised_usd_mns": "6",
      "name_of_investors": "Iron pillar, Rushika Fernandopulle, Christine Cassel, Molly Coye, Sridhar Krishnan, Anurag jain"
  },
  {
      "startup_name": "Pain Pill Shop",
      "website": "https://painpillshop.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=129x_mDvNqENp2W-EZfAK5bpwgdftoUMS",
      "profile_summary": "The best medications and healthcare supplies are available at Pain Pill Shop. They maintain a constant stock of medical equipment, prescription drugs, and other medications. Their healthcare professionals and chemists have years of experience and are very knowledgeable in the pharma industry. Additionally, they are constantly accessible to offer pertinent information and respond promptly regarding the products they offer. You can find the best services here, along with special discounts. If you have any questions regarding their services or products, please get in touch with their customer service. The best customer service is something they take great pride in at Pain Pill Shop. Their main priority is upholding their dedication to quality and customer satisfaction. They make an effort to guarantee that you always receive the best products.",
      "legally_known_as": "Pain Pill Shop",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Lucas Werner",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/lucas-werner-633593157/",
      "revenue_stream": "E-commerce - service ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "North America "
  },
  {
      "startup_name": "My Dental Group",
      "website": "https://www.mydentalgroup.com.au/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=128drs0QNUANaurlbOMyYih2_8VrdSbLP",
      "profile_summary": "My Dental Group offers ultimate dental care to enrich your life with healthy teeth and mouth. They aim at providing the right care at right time with their accurate diagnosis, care, and treatment. Keeping abreast with the new advancement in dentistry, they help people to prevent and treat various oral health and dental issues. They have earned recognition in spreading awareness in Brunswick dental care. They offer a variety of cosmetic and corrective treatments including teeth whitening, extraction, invisalign in Northcote and other regions.",
      "legally_known_as": "My Dental Group",
      "industry": "Healthtech",
      "based_out_of": "Australia, Global",
      "business_model": "B2B",
      "founded_in": "2014",
      "employees_headcount": "<10",
      "team_member_1": "Dr. Samradni Deshpande",
      "position": "Co-Founder",
      "revenue_stream": "other",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Buy ModafinilRx",
      "website": "https://buymodafinilrx.net/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=126AfkP9V54RIZzArm1K72q-pAmKerFms",
      "profile_summary": "Mumbai-based startup Company was founded by Priya Poojari in 2022. BuyModafinilRx was established with the intention of obtaining life-improving medications, generic Modafinil and Armodafinil, from its original makers and delivering them to customers’ front doors quickly and efficiently.",
      "legally_known_as": "Buy ModafinilRx",
      "industry": "Healthtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Priya Poojari",
      "position": "Co-Founder",
      "revenue_stream": "E-commerce - product ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "North America,  South America  "
  },
  {
      "startup_name": "Propliners reality",
      "website": "https://propliners.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1stwbMqXVTZrCMvnsxu9WOpLWh_zKHBk4",
      "profile_summary": "Propliners reality takes complete care of their investors and buyers as we are taking the properties for their own selves thus they take care of all aspects related to the properties like legal-check, proper documentation as per the local authority norms and most important guaranteed best price/rentals. They are working in this industry from long time hence most of their developers are well known to us hence we have the largest and updated property portfolio for all your real estate needs.",
      "legally_known_as": "Propliners reality",
      "industry": "Healthtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Achal Agarwal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/himanshu-propliners-realty-noida/",
      "team_member_2": "Himanshu Propliners",
      "revenue_stream": "Advertising, Affiliate Marketing, E-commerce - Service, Lending",
      "customer_group": "Administrative Services,  Consumer,  Services,  Media,  E-Commerce  ",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "India  "
  },
  {
      "startup_name": "PhysioNEEDS Nigeria",
      "website": "https://physioneedsng.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=122v6zamG_Bczgln1Oq4nxhiSJwYEPyiu",
      "profile_summary": "PhysioNEEDS Nigeria is a Ife, Nigeria based company. It was established in 2016. They engage in sales, supply and repair of Medical, Physiotherapy and Exercise equipment.",
      "legally_known_as": "PhysioNEEDS Nigeria",
      "industry": "Healthtech",
      "based_out_of": "Nigeria, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2016",
      "employees_headcount": "<10",
      "team_member_1": "Folake",
      "revenue_stream": "E-commerce - product, E-commerce - service, physical product ",
      "customer_group": "HealthTech / MedTech / Healthcare  ",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Divoti",
      "website": "https://divotiusa.com/necklaces/medical-alert-necklaces/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=121vF6QtjlsxT6R09tu6mDwcaI48sC1Cq",
      "profile_summary": "Medical Alert Necklaces \\| Medical Alert ID Jewelry \\| Divoti: Choose from their variety of medical alert necklaces that are both effective plus fashionable. Medical alert systems worn as necklaces can help you reach help in the event of an emergency.",
      "legally_known_as": "DivotiUSA",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2000",
      "employees_headcount": "11 to 20",
      "team_member_1": "Jack",
      "revenue_stream": "Advertising ",
      "age_group": "18 to 25  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "Europe "
  },
  {
      "startup_name": " Eyeglass Factory",
      "website": "http://eyeglassfactory.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11x9KA3XG8ojuu7kKZMQXGj6F5h3zSaVC",
      "profile_summary": "The Santa Barbara Eyeglass Factory started out as a chain of retail optical “superstores.” Those stores offer an extensive line of optical products and contain state-of-the-art production laboratories that are capable of providing Real 1-Hour Service on most RXs. Their over thirty (30) years of retail experience put us in a unique position to create a business that can provide you with the lowest possible prices on the highest quality optical products. Their hundreds of thousands of satisfied retail customers are a testament to their ability to guarantee your absolute satisfaction.",
      "legally_known_as": "Eyeglass Factory",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "1994",
      "employees_headcount": "11 to 20",
      "team_member_1": "Gregg Feldman",
      "position": "Founder & President",
      "profile_link": "https://www.linkedin.com/in/gregg-feldman-1b96a515/",
      "revenue_stream": "other",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global,  North America,  South America  "
  },
  {
      "1": "3one4 Capital",
      "2": "Mirae Asset Global Investments",
      "3": "Hummingbird Ventures",
      "4": "Aditya Birla Ventures",
      "startup_name": "eka.care",
      "website": "https://www.eka.care/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11tu8yvz4UVNXDPnofirjZJVj3CDZraq5",
      "profile_summary": "eka.care has built a digitally-enabled and connected healthcare platform that connects healthcare providers and patients. For individuals, it provides with AI-based symptom checker, storage of health records etc. For health care providers, it provides digital prescriptions, appointment booking, integrated payments etc. eka.care becomes the first healthtech platform to integrate the CoWin app for Covid-19 vaccination bookings in India.",
      "legally_known_as": "eka.care",
      "industry": "Healthtech",
      "based_out_of": "Karnataka, India",
      "founded_in": "2020",
      "employees_headcount": "151 to 250",
      "team_member_1": "Vikalp Sahni",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/vikalpsahni/"
  },
  {
      "startup_name": "Zia Dental Care",
      "website": "https://ziadentalcare.pk/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11m96IgTh00iyszFFYU1r2uJVvpG3ocUS",
      "profile_summary": "Zia Dental Care, also known as a Best Dental Clinic in Karachi where team of dentists aids in providing oral health services in affordable prices, Who specializes in dentistry, the analysis, prevention, handling of infection, diseases of the teeth, and diseases of the soft tissues of the mouth. Their dentists always concerned with the improvement of oral health services. Your teeth need the same care as you care for your skin, your smile is your most prized possession and they should not be ignored at any cost. Various Dental Care Clinics around you claim to be the best but the one that they have picked for you is Dentist in Karachi a place you can trust blindly when it comes to Dental Services.",
      "legally_known_as": "Zia Dental Care",
      "industry": "Healthtech",
      "based_out_of": "Pakistan, Global ",
      "business_model": "B2C",
      "founded_in": "1990",
      "employees_headcount": "<10",
      "team_member_1": "Dr. Aimen Zia",
      "position": "Co-Founder",
      "revenue_stream": "Interest",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Asia Pacific  "
  },
  {
      "startup_name": "Enquiry Pharmacy",
      "website": "https://enquirypharmacy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11ipI9-QVuTbDCvdEMxH8KV_6QD98Ef8a",
      "profile_summary": "They coffer guidance on how to improve your mental and physical health in order to improve your overall well-being. Taking care of your body is important for many reasons, not the least of which is that it will improve your health and productivity in the classroom. Both your physical health and your manner of life will have an impact on your mental health. Their mental health was significantly predicted by how well they slept, how much they exercised, and whether or not they used medications. Consuming a balanced diet on a regular basis, particularly the recommended amount of fresh fruits and vegetables, is linked to better mental health.",
      "legally_known_as": "Enquiry Pharmacy",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Priya Poojari",
      "position": "Co-Founder",
      "revenue_stream": "E-commerce - product ",
      "customer_group": "HealthTech / MedTech / Healthcare  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Global",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Single Kits",
      "website": "https://singlekits.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11d-yACMzqyNiQTR281iPMLxQ4fteMN-q",
      "profile_summary": "singlekits.com is a pharmacy where they share information with their visitors. Their data offers consumers with useful information that is brief and to the point. A huge number of websites provide information to its readers concerning drugs and their use. However, they fail to present them with a fact that can work as a motivator for patients to purchase the drug. The primary goal of every website should be to deliver accurate information to its visitors. Before opting to introduce the medicine into everyday usage, singlekits.com provides patients with healthcare advice and educates them about the medicines.",
      "legally_known_as": "Single Kits",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Lucas Werner",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/lucas-werner-36a782229/",
      "revenue_stream": "E-commerce - product, E-commerce - service ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "North America  "
  },
  {
      "startup_name": "Discovery Health",
      "website": "http://www.discoveryhealthmd.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11ZHloizmY4OAwYskrsg1sf_8odr-2YGP",
      "profile_summary": "Discovery Health is a medical risk management service for the commercial maritime industry, serving the men and women who make their living at sea. We have the most comprehensive suite of services to help protect the health and livelihoods of your mariners – while mitigating financial risk for your company. Our team of highly experienced physicians in maritime, emergency and travel medicine has a deep understanding of the medical, regulatory and legal issues affecting the commercial maritime industry. Have peace-of-mind as we help manage your most precious resource – your officers and crew. We are not affiliated with Discovery Health Medical Scheme Administrators in South Africa or Discovery Health Partners. Please visit our website for more information.",
      "legally_known_as": "Discovery Health, LLC",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2016",
      "employees_headcount": "61 to 100",
      "team_member_1": "Ann Jarris",
      "position": "Chief Medical Officer",
      "profile_link": "https://www.linkedin.com/in/ray-jarris-655a499/",
      "team_member_2": "Ray Jarris"
  },
  {
      "startup_name": "Shibasram Old age home kolkata",
      "website": "https://shibasram.org.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11YI27F7A3UXiJKyZ8SOdngWPfJD_4YA4",
      "profile_summary": "Shibasram Old age Home Kolkata, they are all about providing the best services to its residents. They believe that every senior deserves to live their golden years in peace and comfort, surrounded by people who care about them. That’s why they offer a variety of services tailored to meet the unique needs of each resident. From delicious and nutritious meals prepared by their skilled chefs to fun and engaging activities designed to keep minds sharp and spirits high, they strive to create an environment that feels like home.",
      "legally_known_as": "Shibasram Old age home kolkata",
      "industry": "Healthtech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2013",
      "employees_headcount": "41 to 60",
      "team_member_1": "Jayitri Das",
      "position": "Co-Founder",
      "revenue_stream": "Donations",
      "age_group": "18 to 25  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America  "
  },
  {
      "startup_name": "SmartCDKeys",
      "website": "https://smartcdkeys.com/en/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11WaNtdEIKy36H3linLA1JMZih1oHVX3s",
      "profile_summary": "SmartCDKeys is a Ras Al-Khaimah, United Arab Emirates based company. It was established in 1998. With thousands of providers on the internet, it becomes complicated to get PlayStation digital gift card and pay the cheapest price. To start your journey safer and in a more informed way, they offer you to search for the products you need within theirs.",
      "legally_known_as": "SmartCDKeys",
      "industry": "Healthtech",
      "based_out_of": "United Arab Emirates, Global ",
      "business_model": "B2B",
      "founded_in": "1998",
      "employees_headcount": "11 to 20",
      "team_member_1": "Marina",
      "position": "Co-Founder",
      "revenue_stream": "Affiliate Marketing, Subscription ",
      "customer_group": "Gaming",
      "client_business_structure": "Startup",
      "geographical_segment": "South America  "
  },
  {
      "startup_name": "Special Care Australia ",
      "website": "https://specialcareaust.com.au/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11LfggjVNlGqMav3WcYWIKKefFnH1hBmR",
      "profile_summary": "Special Care Australia Pty Ltd is a registered NDIS support service provider which provides support to people with special needs in their day to day activities and Supported Independent Living. However they provide people with special needs to realise their full potential as well as provide much needed support to the families by providing them respite.",
      "legally_known_as": "Special Care Australia Pty Ltd",
      "industry": "Healthtech",
      "based_out_of": "Australia, Global ",
      "business_model": "B2B",
      "founded_in": "1988",
      "employees_headcount": "41 to 60\n",
      "team_member_1": "Harry Jake",
      "revenue_stream": "other",
      "age_group": "46 to 60  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "Global  "
  },
  {
      "startup_name": "Aeon Remedies",
      "website": "https://aeonremedies.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12rV__Nf2pd_KUavb9HxX3qlUpnt7zpDh",
      "profile_summary": "Aeon Remedies, is the most trustworthy name of medical industry of the nation. They are the best PCD Pharma Franchise Company in India and Third party pharma manufacturer, which has never let down any of its customers whenever it comes to winning and retaining the trust. Pantaprazole Injections, Diclofenac Diethyamine Linceed Oil and Deflezacort Cefixime Tablet are some of the products which they add to the list of their offerings. For a company like us, it is essential to work in a genuine manner. Understanding that they cater Pharmaceutical Tablets, Capsules and many such compositions which are consumed to treat ones health, they promise that their works will be executed with utmost sincerity, be it the procurement of ingredients or the delivery of medicines.",
      "legally_known_as": "Aeon Remedies Pvt. Ltd",
      "industry": "Healthtech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B",
      "founded_in": "2000",
      "employees_headcount": "41 to 60",
      "team_member_1": "Niraj Singla",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/neeraj-singla-72106a278/",
      "revenue_stream": "Licensing ",
      "customer_group": "HealthTech / MedTech / Healthcare  ",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "India"
  },
  {
      "startup_name": "Wi4 Corporation",
      "website": "https://wi4.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11FHZkrxNyM6KrdTktu09MDvsmAZmVoYN",
      "profile_summary": "Wi4 is a leading not-for-profit organization creating broad impact through technology, research & science in the areas of healthcare, wellness, government & societal benefit. They are passionate about leveraging technology to enhance the quality of life for humans. They believe in doing right things – for the right reasons – and for the right people. They do Practical Research & Analytics, Program Management & Implementation, Providing Software Solutions, Dealing with Data, Platform & Solutions, and Providing Cyber Security",
      "legally_known_as": "Wi4 Corporation",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "41 to 60",
      "team_member_1": "Vineet Agrawal",
      "position": "Founder & Chief Innovation Officer",
      "profile_link": "https://www.linkedin.com/in/vineet76/",
      "revenue_stream": "Fee-for-service ",
      "customer_group": "HealthTech / MedTech / Healthcare  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global,  Europe,  North America  "
  },
  {
      "startup_name": "Genericpharmamall",
      "website": "https://www.genericpharmamall.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11DLLJ6TVi_H_etSiwPubqkosdMFo7Sz6",
      "profile_summary": "Genericpharmamall is cheaper than other brands because brands cost hundreds of millions of dollars. If the company could make money on every brand name drug, it would have invested millions of dollars. Although the generic drug manufacturer does not require additional investment, does not need growth, and does not need advertising, but it has a large number of active ingredients in their business and maintains quality standards for products. This is the main reason why it is cheap.",
      "legally_known_as": "Genericpharmamall",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "21 to 40",
      "age_group": "26 to 34  ",
      "income_bracket": "Lower-middle Income  \n",
      "user_belongs_to": "South America "
  },
  {
      "startup_name": "Summa Pain Care",
      "website": "https://summapaincare.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=113EZAK1Z6zfd0w8Wh7bix9hp6C0u2kX7",
      "profile_summary": "Summa Pain Care - Arizona Pain Treatment Centers. Arizona based Pain Treatment Center specialists can diagnose and treatment of pain with advanced pain management techniques in Phoenix, Scottsdale and Peoria. Get schedule your appointment today to get back to life.",
      "legally_known_as": "Summa Pain Care",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2009",
      "employees_headcount": "11 to 20",
      "team_member_1": "Paul Pannozzo",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/paul-pannozzo-63085169/",
      "revenue_stream": "Physical commerce ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income ",
      "user_belongs_to": "India,  North America,  South America  "
  },
  {
      "1": "Sectoral Asset Management",
      "startup_name": "CurvaFix",
      "website": "http://www.curvafix.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11165Uj70ckEFjQFoAobiDcskdU8u-1gj",
      "profile_summary": "CurvaFix is developing implantable products to improve fracture repair in curved bones. The company is focusing on Fragility Fractures of the Pelvis (FFP) and high-impact pelvic fractures. The CurvaFix® IM Implant is the only intramedullary device capable of following the natural bone shape and filling the space within curved bones such as the pelvis.",
      "legally_known_as": "CurvaFix, Inc",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2013",
      "employees_headcount": "21 to 40",
      "team_member_1": "Steve Dimmer",
      "position": "VP Research & Development",
      "profile_link": "https://www.linkedin.com/in/carly-thaler-5b7791/",
      "team_member_2": "VJ Somasegaran",
      "team_member_3": "Bart Balkman",
      "team_member_4": "Nicole Carleo ",
      "team_member_5": "Carly Thaler"
  },
  {
      "startup_name": "StudyMEDIC",
      "website": "https://studymedic.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=11-7kR2X3f-otJYjwOquDpWviTdXtor6O",
      "profile_summary": "StudyMEDIC is an unprecedented training platform for diversified global medical courses (MRCOG, EFOG-EBCOG, MRCPI, FCPS, MRCS, FRCS, NEET SS, VAGINAL SURGERY, OBG, PLAB, REPRO, HRO, FMGE, ULTRASOUND, MRCEM, MRCP) under one brand with an inimitable training structure and globally recognized mentor panel with decades of clinical and teaching experience. They design their courses with the aid of advanced technologies and methods to cater the needs of all their students to drive their own path of success by creative an innovative, interactive, and informative learning system. They value and uphold their students in all ways they can to benefit them with the most updated and imperative information which transforms their students to best, renowned, and reputed clinicians across the world.",
      "legally_known_as": "StudyMEDIC Academy Pvt Ltd",
      "industry": "Healthtech",
      "based_out_of": "Kerala, India",
      "business_model": "B2B, B2C",
      "founded_in": "2016",
      "employees_headcount": "151 to 250",
      "team_member_1": "Dr. Sowmya NS",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/dr-sowmya-n-s/",
      "revenue_stream": "Fee-for-service, Subscription, E-commerce - product, E-commerce - service ",
      "customer_group": "EdTech/Education,  HealthTech / MedTech / Healthcare,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific,  Europe  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global,  Asia Pacific,  Europe,  Africa  "
  },
  {
      "startup_name": "60 PLUS CARE",
      "website": "https://www.60pluscare.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10xhdI6T1B25EN3vTp1pyeyIbylqCk8KK",
      "profile_summary": "60 PLUS CARE is the present-day version of the decade old Elder Care Services of Kolkata initiated by Dr Dipankar Debnath, Geriatric Medicine specialist along with other Doctors & Elder Care Professionals in 2008. Over the past 12 years their passion to keep the Elders Healthy and Happy brought us to the present state where they have ALL Types of MEDICAL along with Non-Medical Services that provide 360 degree of care to the Elders. All their Members feel safe at Home as their Care Managers are very vigilant and are always Responsive for All of their needs 24x7. Their Geriatricians constantly monitor their Health and are always ready for providing service as per need.",
      "legally_known_as": "60 Plus Care",
      "industry": "Healthtech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2008",
      "employees_headcount": "11 to 20",
      "team_member_1": "Dr. Dipankar Debnath",
      "position": "Founder & Director",
      "profile_link": "https://www.linkedin.com/in/dr-dipankar-debnath-a1787616/",
      "revenue_stream": "Fee-for-service ",
      "age_group": "46 to 60,  More than 60  \n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global,  North America  "
  },
  {
      "startup_name": "HDU Healthcare",
      "website": "https://hduhealthcare.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10vrR1UqHOMnDrx-_eC5MmlCk1gA-EHJr",
      "profile_summary": "HDU Healthcare Pvt. Ltd. is a leading healthcare service provider offering ICU Care at Home Service, medical equipment on rent/sale (wheelchairs, hospital beds, oxygen concentrators, bipap-cpap machines, oxygen cylinders), and a skilled team of ICU trained nurses, medical attendants, physiotherapists, and critical care doctors. With offices in Delhi and Mumbai, we ensure high-quality care and convenience for patients across India. Trust HDU Healthcare for comprehensive healthcare solutions at your doorstep.",
      "legally_known_as": "HDU HEALTHCARE PVT LTD",
      "industry": "Healthtech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2C",
      "founded_in": "2018",
      "employees_headcount": "1000+",
      "team_member_1": "Ambrish Mishra",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ambrish-mishra-07372051/",
      "revenue_stream": "E-commerce - product ",
      "customer_group": "Community,  Enterprise Tech,  HealthTech / MedTech / Healthcare,  Software  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "india",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "india"
  },
  {
      "startup_name": "Zen8Labs",
      "website": "https://www.zen8labs.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10vD7Vb5J3k1dX3jHWwUPlVscMg4VYyhG",
      "profile_summary": "Zen8Labs adopts cutting edge technology practices and tools to ensure alignment of winning business solutions and impactful optimization. A wealth of experience driving efficiencies and innovation allows us to bring that perspective into executing strategic outcomes and building high-performing delivery capability. Putting their customers at the center of our strategy and design helps differentiate the experiences across business domains, geographies and cultures.",
      "legally_known_as": "Zen8 Vietnam Co. Ltd",
      "industry": "Healthtech",
      "based_out_of": "Vietnam, Global ",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "41 to 60",
      "team_member_1": "Dave Bui",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/hiennvn/",
      "team_member_2": "Billy Pham",
      "team_member_3": "Hien Nguyen",
      "revenue_stream": "Fee-for-service, E-commerce - service ",
      "customer_group": "EdTech/Education,  HealthTech / MedTech / Healthcare,  Software,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "india"
  },
  {
      "startup_name": "Concure Oncology",
      "website": "http://www.breastmicroseed.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10lTfEUx3v0xNnFl6B2Ca814Xei2ow5Du",
      "profile_summary": "Concure Oncology specializes in early-stage breast cancer treatment and related services. Concure Oncology provides the technology, protocol, and equipment used by medical partners to perform the Breast Microseed Treatment (BMT), our early-stage, post lumpectomy breast cancer procedure. BMT combines a medical device, technology, and treatment procedure to deliver radiation therapy to patients with early-stage breast cancer. With Breast Microseed Treatment, side effects are less severe, and because it is administered in a one-time, one-hour treatment, a quicker, simpler return to health and wellness becomes possible. Concure Oncology is committed to easing the burden for women facing early-stage breast cancer by enhancing and simplifying radiation treatment so they can get back to living their lives.",
      "legally_known_as": "Concure Oncology",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2014",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sandra F. Rorem",
      "position": "Founder & Director",
      "profile_link": "https://www.linkedin.com/in/sandra-rorem-43295714"
  },
  {
      "startup_name": "Hayes Canada",
      "website": "https://hayescanada.ca/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10lNh2_CHxEy1YETYdmyJPLtxIbDLusJe",
      "profile_summary": "At Hayes Canada, They are the world leader in dental handpiece repairs with over 85 locations in 5 countries. They have over 25 years of experience in the Dental Industry and have been working in Canada for over 10 years. Hayes Canada provides a big variety of professional dental handpiece repair Services for brands like Midwest, Star, Kavo Handpiece Repairs, NSK/Brasseler, and Bien-Air.",
      "legally_known_as": "Hayes Canada",
      "industry": "Healthtech",
      "based_out_of": "United Kingdom, Global ",
      "business_model": "B2B, B2C",
      "founded_in": "1998",
      "employees_headcount": "41 to 60",
      "revenue_stream": "other",
      "age_group": "26 to 34,  35 to 45  \n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income",
      "user_belongs_to": "North America  "
  },
  {
      "startup_name": "Medix Global",
      "website": "https://medix-global.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10jn7EDJhVzrx20Q4rwD-dh36BE5kQ5Il",
      "profile_summary": "Medix is a groundbreaking medical science & tech company engineered to direct the full potential of leading expertise, treatment & technology to people's most critical health needs. Since 2006, Medix has been changing the course of health for millions of customers in over 90 countries, by delivering impactful medical responses that are borderless, personalised and data-driven. We embolden insurers, employers, financial groups, consumer facing retailers, distributors and governmental institutions to transcend conventional models & methods, moving beyond transactional care to better health, human, and financial outcomes.",
      "legally_known_as": "Medix Global",
      "industry": "Healthtech",
      "based_out_of": "United Kingdom, Global ",
      "founded_in": "2006",
      "employees_headcount": "101 to 150",
      "team_member_1": "Sigal Atzmon",
      "position": "President and CEO",
      "profile_link": "https://www.linkedin.com/in/sigalatzmon/"
  },
  {
      "startup_name": "Cerevast",
      "website": "http://www.cerevast.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10hpLBJ-QP4Ag8QVsnUKHNd53XifsFRZA",
      "profile_summary": "Founded in 2015, Cerevast Medical, Inc. is a clinical-stage medical device company based in Bothell, Washington, USA. Leveraging our core expertise in the fields of ultrasound and microsphere technologies, we are committed to developing novel, first-in-class therapeutic solutions to restore health and improve the quality of life for patients that suffer from major diseases. The company’s lead clinical stage programs are for the treatment of ischemic stroke and retinal vein occlusion, two devastating diseases with limited treatment options that effect millions of patients worldwide each year.",
      "legally_known_as": "Cerevast",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2015",
      "employees_headcount": "<10",
      "team_member_1": "Bradford Zakes",
      "position": "Chief Financial Officer",
      "profile_link": "https://www.linkedin.com/in/kendallstever/",
      "team_member_2": "Kendall Stever"
  },
  {
      "startup_name": "Kinetix Medical Weight Loss and Wellness",
      "website": "https://kinetixweightloss.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10fBYbou6mAEs4fZBE2445M2FqOBYYk0k",
      "profile_summary": "Kinetix is a leading medical weight loss and wellness clinic in Chicago, dedicated to helping people live healthier, happier lives. They offer numerous effective, evidence-based, and personalized weight loss solutions that not only help their patients lose weight but also manage and improve their chronic health conditions, like diabetes, hypertension, gastrointestinal reflux, joint pain, sleep apnea, and more. Specially crafted keeping your health and weight loss goals in mind, our tailored weight loss programs are safe, effective, affordable, and adaptable to your specific needs and budget. Their board-certified Weight Loss specialist, Dr. Lala, uses her knowledge, skills, experience, and years of expertise to equip and empower you with natural and safe weight loss techniques that ensure quick, effective weight loss and a higher level of health and well-being.",
      "legally_known_as": "Kinetix Medical Weight Loss and Wellness",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Mona Lala",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mona-lala-74000923/",
      "revenue_stream": "Fee-for-service ",
      "age_group": "35 to 45  ",
      "income_bracket": "Lower-middle Income  \n",
      "user_belongs_to": "North America  "
  },
  {
      "startup_name": "Annalise.ai",
      "website": "https://annalise.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10dyYCs_e6sfZ_iNZdnbvGfn9ibs0xGgz",
      "profile_summary": "Annalise.ai is on a mission to elevate the standard of healthcare for millions of patients every day. By employing AI technologies in medical imaging, the company aims to enhance diagnosis and treatment, revolutionizing patient care and the practice of medicine. Founded as a joint venture between I-MED, Australia's largest diagnostic chain and Harrison.ai Annalise.ai has emerged as one of the largest players in the industry. The company's AI solutions* are cleared by the FDA, TGA, and CE, ensuring their adherence to rigorous quality and safety standards.",
      "legally_known_as": "Annalise.ai",
      "industry": "Healthtech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "151 to 250",
      "team_member_1": "Aengus Tran",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/dimitrytran/",
      "team_member_2": "Dimitry Tran",
      "revenue_stream": "Subscription ",
      "customer_group": "HealthTech / MedTech / Healthcare  ",
      "client_business_structure": "Startup",
      "geographical_segment": "Global  "
  },
  {
      "startup_name": "Dazzle Beauty",
      "website": "https://www.dazzlebeauty.net/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10VGmiQlwo5O7bZ-Qpq3LrKHh9-I-HcLy",
      "profile_summary": "At Dazzle Beauty, they have a large selection of professional beauty brands, from health products to cosmetics to skin and body products. Founded in 2017, Dazzle Beauty dreamed of connecting with those who love everything beauty related. They offer all things beauty - products from the biggest professional health & beauty brands.",
      "legally_known_as": "Dazzle Beauty",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2017",
      "employees_headcount": "11 to 20",
      "team_member_1": "Akib Jabed",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/akib-jabed-marketerman/",
      "revenue_stream": "Advertising ",
      "age_group": "18 to 25  ",
      "income_bracket": "Lower Income  ",
      "user_belongs_to": "India  "
  },
  {
      "startup_name": "Younoia",
      "website": "https://linktr.ee/younoia",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10bfUpcBZX7FvlCC76WCxcbNiU8C84USr",
      "profile_summary": "Younoia is a transformative platform that empowers individuals to prioritize their mental well-being. Through their innovative community and personalized support, they provide a safe and inclusive space for people to connect, share experiences, and access the resources they need to thrive. Their mission is to break the stigma surrounding mental health and foster a supportive environment where everyone can find the help and support they deserve.",
      "legally_known_as": "GenZ Ai & Labs",
      "industry": "Healthtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Tharun Balaji",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/tharunbalaji/",
      "revenue_stream": "Advertising, Freemium, Subscription, Commission on transaction ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  Global  "
  },
  {
      "1": "Prof. Wojtek Wojakowski",
      "startup_name": "CardioCube (acquired by Voxel)",
      "website": "https://cardiocube.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10TOGMlVzvwEJdrvmdW1IIDAkFK46dzXN",
      "profile_summary": "CardioCube (acquired by Voxel) was a physician-led startup on a mission to make healthcare affordable and accessible through technology. Our membership-based virtual healthcare service provided employees at companies of all sizes with unlimited virtual access to doctors and care management powered by Voice AI.",
      "legally_known_as": "CardioCube (acquired by Voxel)",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "21 to 40",
      "revenue_stream": "Fee-for-service ",
      "total_funding_millions": "0.25"
  },
  {
      "startup_name": "Cardiac Insight",
      "website": "http://www.cardiacinsightinc.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10Q-MJvpC0um5oFUei71QZUAqEyMITgo_",
      "profile_summary": "Cardiac Insight, Inc. is a privately held medical device company based in Seattle, WA. Cardiac Insight is developing advanced, body worn ECG sensing technology aimed at the early diagnosis of atrial fibrillation (AF) and other conditions. Earlier last year, the company announced a strategic collaboration with Welch Allyn, now part of the Hill-Rom family of companies, for the distribution rights of certain products and technologies offered by Cardiac Insight. In September, 2015, Cardiac Insight completed the acquisition of Cardea Associates, Inc., a company dedicated to cardiovascular research and product innovation. CardeaScreen™, developed and produced by Cardea, is a proprietary, handheld 12-lead ECG device and software that provides a major breakthrough to improve the reliability of the electrocardiogram (ECG) for the identification of cardiovascular issues associated with sudden cardiac arrest (SCA) in young athletes.",
      "legally_known_as": "Cardiac Insight Inc",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2010",
      "employees_headcount": "21 to 40",
      "team_member_1": "Dan Tylutki",
      "position": "Chief Financial Officer",
      "profile_link": "https://www.linkedin.com/in/mike-schuh-a9396a50/",
      "team_member_2": "Mike Schuh"
  },
  {
      "startup_name": "Outcall Massage Godalming",
      "website": "https://www.outcallmassagegodalming.co.uk/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10JhFC7klT08WhfY5j2e2OSnGpt-8vXCL",
      "profile_summary": "Delivered in the comfort of your own home or hotel room, outcall and mobile massage therapy always for a more personalised experience, as you can choose your preferred environment and create a relaxing atmosphere in their own surroundings",
      "legally_known_as": "Outcall Massage Godalming",
      "industry": "Healthtech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Fifi Khan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/fifi-khan-622b41256/",
      "revenue_stream": "Donations ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Europe  "
  },
  {
      "startup_name": "Dr Satish Titoria",
      "website": "https://www.drtitoriaclinics.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10CQ97HAE6u4WxEucEaXKRUGMnSf8epEb",
      "profile_summary": "Being the most trusted Skin and Hair clinic in Noida, Dr. Titoria’s Clinics have a knack in satisfying the patients. With more than 10 years of excellence and trust, Dr. Titoria’s Dermatology Clinic has become the most trusted skincare clinic in Noida given to its world-class dermatological services in a cost-effective manner. Dr. Titoria’s Clinics provides people with advanced dermatology treatments like fillers, mesotherapy, PRP, botoxlium for skin, hair, and nail disorders including acne, acne scarring, vitiligo, psoriasis, wrinkles, hair loss/fall, dandruff, hair removal, skin allergies, fungal infection, nail infections, etc. They specialize in laser treatments, skin rejuvenation, and antiaging treatments. All these treatments are provided by their certified practitioners to ensure safety and effectiveness. The well-being of patients is their topmost priority, so they only provide high-quality pre-treatment as well as post-treatment care to them.",
      "legally_known_as": "Dr Satish Titoria",
      "industry": "Healthtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2C",
      "founded_in": "2017",
      "employees_headcount": "11 to 20",
      "team_member_1": "Satish Titoria",
      "position": "Founder & Owner",
      "profile_link": "https://www.linkedin.com/company/drtitoriaclinics/",
      "revenue_stream": "Fee-for-service ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "india"
  },
  {
      "1": "B Capital Group",
      "2": "Seven Peaks Ventures",
      "3": "Oregon Venture Fund",
      "4": "Concord Health Partners",
      "startup_name": "Bright.md",
      "website": "http://bright.md/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=10AdiDwMpZh6qa2mOiy0BMaHdzfWmNDym",
      "profile_summary": "Making Healthy Happen Faster. Bright.md is the leading virtual care solution trusted by health systems to automate clinical workflows and administrative tasks, improving patient and provider engagement and driving operational efficiency. With its pioneering technology, Bright.md improves how health systems deliver care, from patient acquisition through clinical interview and treatment, to reduce 90% of administrative workflows, lower patient wait-times to 6 minutes on average, and drive patient loyalty with industry-leading satisfaction ratings. Founded in 2014 and based in Portland, Oregon, Bright.md has been named a Gartner Cool Vendor in Healthcare, a Vendor to Watch by Chilmark Research, and is the preferred choice of AVIA’s Virtual Access cohort. Bright.md is backed by B Capital Group, Seven Peaks Ventures, Pritzker Group Venture Capital, Philips Health Technology Ventures, UnityPoint Health, and Concord Health Partners.",
      "legally_known_as": "Bright.md",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2014",
      "employees_headcount": "41 to 60",
      "team_member_1": "Ray Costantini",
      "position": "Co-Founder and Chief Strategy Officer",
      "profile_link": "https://www.linkedin.com/in/swinth/",
      "team_member_2": "Mark Swinth",
      "revenue_stream": "Fee-for-service ",
      "total_funding_millions": "29.2"
  },
  {
      "1": "Acumen",
      "2": "Greycroft",
      "3": "First Round Capital",
      "4": "Goodwater Capital",
      "5": "Alumni Ventures Group",
      "6": "Tusk Venture Partners",
      "startup_name": "Boulder Care",
      "website": "https://boulder.care/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=12v43Cgqj7S9ccKdYXnCt0vj1YNadQf6I",
      "profile_summary": "Telehealth addiction treatment on your terms and timeline - grounded in kindness, respect, and unconditional support. At Boulder Care, we help people with substance use disorders reach their goals through judgement-free recovery support and evidence-based addiction treatment, rooted in the principles of harm reduction. Patients can access their Care Team — a Clinician, a Care Advocate, and a Peer Recovery Specialist — through secure messaging, video visits, and phone support from an app on their phone. By championing low-threshold access to medications for addiction treatment, Boulder improves clinical and functional outcomes — sharing in the cost savings with health plan and employer partners under a value-based model. We are proud to offer millions of Americans access to Boulder as an affordable in-network provider and affordable self-pay options to those who need it.",
      "legally_known_as": "Boulder Care, Inc",
      "industry": "Healthtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2017",
      "employees_headcount": "61 to 100",
      "team_member_1": "Stephanie Strong",
      "position": "Chief Operating Officer",
      "profile_link": "https://www.linkedin.com/in/rose-bromka/",
      "team_member_2": "Rose Bromka",
      "total_funding_millions": "50.1"
  },
  {
      "startup_name": "Domopan",
      "website": "https://domoqatar.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-cLoMKrrONMW0hnaZdj9S2oxzATn4RMz",
      "profile_summary": "Domopan launched activities in response to the market needs of sustainable development. Therefore the heart of our work is providing turnkey design-build solutions for residential, commercial and industrial developments. Their vision is to be nothing short of the best. They envision ourselves to be the undisputed leader and partner in providing quality projects to both public and private sector clients.",
      "legally_known_as": "Domopan",
      "industry": "Agritech",
      "based_out_of": "Qatar, Global",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "1979",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Amin Al Cheikh",
      "position": "Engineering Manager",
      "profile_link": "https://www.linkedin.com/in/cheikhamin/",
      "revenue_stream": "Commission on transaction, Interest, Licensing, Fee-for-service ",
      "customer_group": "Administrative Services,  Art & Design,  AgriTech,  Animation & Filmography  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific  ",
      "age_group": "26 to 34  ",
      "income_bracket": "Lower-middle Income  ",
      "user_belongs_to": "India Tier 1,  India Tier 2,  Global,  Asia Pacific,  Europe,  North America,  South America  "
  },
  {
      "startup_name": "Ganaz",
      "website": "https://ganaz.com/about-us/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-b-6v_9nv9xggjvRtULwQ6t3pxVgaw_c",
      "profile_summary": "The Ganaz platform simplifies people management through software tools designed to help agriculture and food processing employers save time, effort, and money. Our features enable paperless onboarding, training, communication, analytics, and overall workforce retention. HR Onboarding: No pen, no paper. Quickly scan workers' IDs to autofill all forms. Employee files are saved digitally for compliance. Workforce Communication: Auto-translated bulk texting, targeting current and past employees for communication and recruiting. Workforce Training: Easy-to-use training app delivering your content and quizzes. Deployable in the field without an internet connection. Training records are saved digitally for compliance. Workforce Analytics: Text-based surveys help employers understand the needs of the workforce and improve recruiting and retention.",
      "legally_known_as": "Ganaz, Inc",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2017",
      "employees_headcount": "21 to 40",
      "team_member_1": "Hannah Freeman",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/kyle-johnson-02a130a/",
      "team_member_2": "Kyle Johnson"
  },
  {
      "startup_name": "Platform Commons",
      "website": "https://platformcommons.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-YeHSBTvHqM55F5_I3uPTKfLPHSZa4KH",
      "profile_summary": "Digital Platforms have radically disrupted societies and economies. Platform companies such as Amazon, Uber, Udemy, and Airbnb have grown significantly faster than anyone else in their sector. While the innovative model of aggregation and disintermediation has progressed private businesses, a large part of society is left untouched by these innovations. Based on their experience of building digital platforms in the social space, they have found that platform models are equally applicable in the societal space. They can help reduce inequality, provide a livelihood to a billion people, make a positive impact on climate, and make citizens engage deeply with societal issues! And this is what they do at Platform Commons! They build digital platforms for societal problems. Their platforms are today serving about 5 million beneficiaries in the space of Agriculture, Education, and Civic Engagement, and we are launching a Climate Platform soon.",
      "legally_known_as": "Platform Commons Services Pvt. Ltd",
      "industry": "Agritech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "41 to 60",
      "team_member_1": "Satyam Gambhir",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/farmermehra/",
      "team_member_2": "Aashish Aadarsh",
      "team_member_3": "Prashant Mehra"
  },
  {
      "1": "TNB AURA",
      "2": "Genting Ventures",
      "3": "AgFunder",
      "4": "SBI Ven Capital",
      "startup_name": "Eratani",
      "website": "https://www.eratani.co.id/en",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-YTOZn8Pv1SlskT42vWbG5qzRrJY4ngn",
      "profile_summary": "Eratani is an Agri-Tech startup from Indonesia that focus on building a strong seed to market ecosystem by enabling and digitizing the upstream (channel micro financing, provide farm & agri input for farmers) to downstream (harvest distribution & supply chain) processes. They strive to provide farmers ease of access with their one stop solution app to improve their productivity and the ecosystem well being.",
      "legally_known_as": "Eratani",
      "industry": "Agritech",
      "based_out_of": "Indonesia, Global ",
      "founded_in": "2021",
      "employees_headcount": "151 to 250",
      "team_member_1": "Andrew S",
      "position": "Co-Founder & CSO",
      "profile_link": "https://www.linkedin.com/in/anglesgani/",
      "team_member_2": "Kevin Juan Tanggo Laksono",
      "team_member_3": "Angles Gani"
  },
  {
      "1": "Incubate Fund India",
      "2": "Partners Fund Japan",
      "startup_name": "Green Fortune",
      "website": "https://greenfortune.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-XsrAJc3ZeL3TVjzv1itFzw8mxhAX4OA",
      "profile_summary": "Green Fortune is about urban cultivation. Our mission is to bring nature back to cities with green innovation. We started this journey in 2004 as one of the first plantwall pioneers and have been innovating ever since. We are specialists in plantwalls, vertical green and plantwires and have installed more than 500 projects internationally. We believe that large scale vertical green is a unique solution for greener cities and buildings because it combines impressive looks with tangible functional value - better room climate, acoustic benefits and stress reduction. With our unique products and solution driven approach we managed to establish close partnerships with well-known architect firms, building companies and international brands. Urban Cultivation stems from a fictive future where plants and cultivation have become a more integrated part of modern urban life. Both for various functions, as well as soul replenishment. We use the Urban Cultivation vision to extract products and projects as stepping stones towards the future.",
      "legally_known_as": "Green Fortune",
      "industry": "Agritech",
      "based_out_of": "Sweden, Global",
      "founded_in": "2004",
      "employees_headcount": "21 to 40",
      "team_member_1": "Hans Andersson",
      "position": "Co-Founder",
      "team_member_2": "Johan Svensson",
      "total_funding_millions": "1",
      "series_name": "SEED",
      "date": "12th Jul 2023",
      "funds_raised_usd_mns": "1.04",
      "name_of_investors": "Incubate Fund India, Titan Capital, Superb Capital, Varun Alagh, Partners Fund Japan"
  },
  {
      "1": "Powerhouse Ventures",
      "startup_name": "Hectar Global",
      "website": "https://www.hectar.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-OUB1b-9JNcHEQTPrBVDo3EhNb80Z2G1",
      "profile_summary": "Founded by National University of Singapore alumni Srinath Srinivasan, Sibhi Kumaran and Ashwath Balaji, Hectar Global helps food buyers around the world buy container volumes of agricultural commodities with e-commerce-level convenience. With global agricultural commodities trade exceeding US$ 3 trillion annually, Hectar Global aims to be at the forefront of digital transformation in the industry. The company's platform digitizes complex cross-border supply chain processes, making them more efficient, transparent and sustainable. It leverages advanced data models and machine learning algorithms to provide real-time market information and actionable insights, helping customers plan their purchases for maximum profit.",
      "legally_known_as": "Hectar Global",
      "industry": "Agritech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Srinath Srinivasan",
      "position": "Co-Founder",
      "team_member_2": "Sibhi Kumaran",
      "team_member_3": "Ashwath Balaji",
      "total_funding_millions": "2.1",
      "series_name": "SEED",
      "date": "12th jul 2023",
      "funds_raised_usd_mns": "2.1",
      "name_of_investors": "Powerhouse Ventures, WEH Ventures, All in Capital, Indian Silicon Valley, Anant Sarda, Adhish Ladha"
  },
  {
      "1": "Kima Ventures",
      "startup_name": "HappyVore",
      "website": "https://happyvore.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1tQcZ48htm20-P5CzN7Zsaop42s_oSuna",
      "profile_summary": "HappyVore is producer of plant-based meat products intended to reinvent traditional recipes. The company's products are developed from plant-based sources and are rich in proteins, consumes less water, as well as are free from the genetically modified organism, enabling consumers to consume less meat to conveniently go vegan.",
      "legally_known_as": "Les Nouveaux Fermiers",
      "industry": "Agritech",
      "based_out_of": "France, Global",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Guillaume Dubois",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/cedricmeston/",
      "team_member_2": "Cedric Meston",
      "total_funding_millions": "40"
  },
  {
      "1": "CPT Capital",
      "2": "Vorwerk Ventures",
      "3": "Entrepreneur First",
      "4": "Redalpine",
      "startup_name": "Better Dairy",
      "website": "https://betterdairy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1tIDhdBSgsRrTiVJuIdphc4Pi9Ak05q0R",
      "profile_summary": "Dairy production is currently hugely unsustainable and also highly inefficient. At Better Dairy we are using precision fermentation to produce the same cheeses, yoghurts, ice creams that customers currently enjoy without having to use animals.",
      "legally_known_as": "Better Dairy",
      "industry": "Agritech",
      "based_out_of": "United Kingdom, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Jevan Nagarajah",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/jevan-nagarajah/",
      "total_funding_millions": "26.9"
  },
  {
      "1": "Astanor Ventures",
      "startup_name": "MiiMOSA",
      "website": "https://miimosa.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-1yWaHWhiyML2KDXNFyBg7vVwGBzHIWZ",
      "profile_summary": "MiiMOSA is the 1st financing platform exclusively dedicated to agriculture and food. The mission is to accelerate, through financing, the agricultural, food and energy transition. Through donations with counterpart or remunerated loans, MiiMOSA is revolutionizing access to financing for 21st century heroes and inviting citizens and businesses to engage in high-impact projects (social, economic, societal and environmental). With more than 4,500 projects supported since 2015, MiiMOSA is THE European benchmark for its theme.",
      "legally_known_as": "MiiMOSA",
      "industry": "Agritech",
      "based_out_of": "France, Global",
      "founded_in": "2015",
      "employees_headcount": "41 to 60",
      "team_member_1": "Florian BRETON",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/florianbreton/"
  },
  {
      "1": "Union Square Ventures",
      "2": "Future Positive Capital",
      "3": "Haatch",
      "startup_name": "Brilliant Planet",
      "website": "https://www.brilliantplanet.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-1UHwvBMYh2a7zkthmVh3OsAVPoCXB6R",
      "profile_summary": "Brilliant Planet is unlocking the power of algae as an affordable method of permanently and quantifiably sequestering carbon at the gigaton scale. The company’s innovative process enables vast quantities of microalgae to grow in open-air pond-based systems on costal desert land. This is achieved without using fresh water, by harnessing a natural process that contributes to the health of oceans and air.",
      "legally_known_as": "Brilliant Planet Limited",
      "industry": "Agritech",
      "based_out_of": "United Kingdom, Global",
      "founded_in": "2013",
      "employees_headcount": "21 to 40",
      "team_member_1": "Raffael Jovine",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/keith-coleman-a303b427/",
      "team_member_2": "Keith Coleman",
      "total_funding_millions": "26.7"
  },
  {
      "1": "AQTON SE",
      "2": "Verve Ventures",
      "3": "Swisscom Ventures",
      "4": "Capagro",
      "startup_name": "ecoRobotix",
      "website": "https://ecorobotix.com/en/our-philosophy/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lRrsyTHKZt4k4q0BMfFapdVzP9i09Opk",
      "profile_summary": "Ecorobotix offers precise, reliable and affordable robotic solutions, which make farmers’ lives easier in producing healthy food. They develop, manufacture and commercialise innovating, energy-saving farming machines, which allow both the ecological impact of modern farming and its costs to be reduced. We thus contribute to the emergence of farming which respects the environment, focused on preserving soils and hydrological resources, by using a minimal amount of energy.",
      "legally_known_as": "Ecorobotix SA",
      "industry": "Agritech",
      "based_out_of": "Switzerland, Global",
      "business_model": "B2B",
      "employees_headcount": "61 to 100",
      "team_member_1": "Aurelien G. Demaurex ",
      "position": "CTO and Co-Founder",
      "profile_link": "https://www.linkedin.com/in/steve-tanner-86a70617/",
      "team_member_2": "Steve Tanner",
      "total_funding_millions": "80.6"
  },
  {
      "1": "Giant Ventures",
      "2": "Kinnevik AB",
      "3": "HV Capital",
      "startup_name": "Agreena",
      "website": "https://agreena.com/about/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-0Xq-hrkVpKbDtd44HzXOpK9cuzLpPCq",
      "profile_summary": "Agreena was founded by visionary farmers and fintech-savvies in 2018. What originated as an online commodity trading marketplace has since become one of the first soil carbon certification programmes in the pan-European landscape. Using a set of core practices, Agreena's platform supports farmers in their transition to regenerative agriculture with the issuance of third-party verified carbon certificates - which can be kept by the farmer or sold onto the voluntary carbon market (VCM) for offset purposes. Accredited to ISO 14064-2 standard, the AgreenaCarbon programme is based on a yearly harvest cycle, with the data capture process giving farmers the opportunity for payouts each year.",
      "legally_known_as": "Agreena ApS",
      "industry": "Agritech",
      "based_out_of": "Denmark, Global",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "101 to 150",
      "team_member_1": "Simon Haldrup",
      "position": "Co-founder & COO",
      "profile_link": "https://www.linkedin.com/in/juliekfahler/",
      "team_member_2": "Ida Boesen",
      "team_member_3": "Julie Koch Fahler",
      "total_funding_millions": "77.6"
  },
  {
      "startup_name": "Sensonomic",
      "website": "https://sensonomic.com/about-us/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-0-RawwDHGVnAMK6N4Hc1tIxqz-_GwUe",
      "profile_summary": "Sensonomic is a specialised agriculture technology company based out of Norway and UK. We create value for farmers, growers, and the food supply chain through improving operational efficiency at farm level, and enabling high-fidelity data flows and analytics for input providers, buyers, exporters and importers. Through this we make agriculture more sustainable, profitable and resilient. They work closely with forward-looking growers across crops and geographies, and to date we have delivered our services in 30+ countries.",
      "legally_known_as": "SENSONOMIC AS",
      "industry": "Agritech",
      "based_out_of": "Norway, Global",
      "founded_in": "2015",
      "employees_headcount": "<10",
      "team_member_1": "Anders Gundersen",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/andersgundersen/"
  },
  {
      "startup_name": "Key Crops",
      "website": "https://keycrops.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1644liVb8U-1rREtlWy842HQZnL_5wifq",
      "profile_summary": "Key Crops is one of the best Agriculture food Exporter from India. They at Key Crops understand the issues one can face during the import, so they are here to help! Key Crops has an extensive experience in the food export industry. Their executives are trained to support you through the entire import process and resolve any issues which might arise. If you are looking for import from India, Contact Key Crops now!",
      "legally_known_as": "Keycrops",
      "industry": "Agritech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B",
      "founded_in": "1996",
      "employees_headcount": "<10",
      "team_member_1": "Dhruv Khandelwal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/dhruv-khandelwal-88278014a/",
      "revenue_stream": "Physical commerce ",
      "customer_group": "AgriTech,  FoodTech,  Retail  ",
      "client_business_structure": "Small Enterprise",
      "geographical_segment": "Europe "
  },
  {
      "startup_name": "Digicides",
      "website": "http://digicides.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zwjBLYK2ylm1BW_h4o1QdwyFPOdvh9-g",
      "profile_summary": "Digicides is a SAAS communication platform for the Agri Industry. They create analytics based communication products that help a company connect with farmers, know and save details about their demographics, engage with them through multiple channels from a single interface and finally convert them into paid customers.",
      "legally_known_as": "Digicides Marketing Services Pvt. Ltd",
      "industry": "Agritech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2017",
      "employees_headcount": "11 to 20",
      "team_member_1": "Dipanjan Dean Dutta",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/saswatibag/",
      "team_member_2": "Saswati Bag",
      "revenue_stream": "Fee-for-service, Advertising ",
      "customer_group": "AgriTech,  FinTech,  FoodTech,  FMCG  ",
      "client_business_structure": "Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  Asia Pacific  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower Income,  Lower-middle Income  ",
      "user_belongs_to": "India Tier 3,  Asia Pacific  "
  },
  {
      "startup_name": "Lab2Land",
      "website": "http://lab2land.co.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zueC15dHcSwxTmqATgjaX4Ikp-gUpZ0U",
      "profile_summary": "Lab2Land Technologies is working towards a sustainable value growth by re-inventing agriculture to promote high yield crop protection solutions to the farming community in India and beyond. Lab2Land's vision is to become a crop protection hero in the next few years. At Lab2Land they believe in synergy and hence are partnering with companies and retailers to offer the best solutions and practices to improve the efficiency and sustainability of agriculture across the world.",
      "legally_known_as": "Lab2Land AgriTech Solutions Pvt Ltd",
      "industry": "Agritech",
      "based_out_of": "Telangana, India",
      "founded_in": "2020",
      "employees_headcount": "41 to 60",
      "team_member_1": "Aditya Deshpande",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/aditya-deshpande-753044159/"
  },
  {
      "startup_name": "A-THON",
      "website": "https://a-thonallterrain.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zsZ5SXSz71NL_k3XG9Et9kyrKwJ_Uz39",
      "profile_summary": "Kolar-based startup Company was founded by Harshit Modi in 2022. They engineer, develop and manufacture all terrain vehicles (UTVs & SxS) and attachments for primarily for farming and agriculture additionally for adventure and special purpose as well.",
      "legally_known_as": "A-THON ALLTERRAIN PRIVATE LIMITED",
      "industry": "Agritech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2022",
      "employees_headcount": "61 to 100",
      "team_member_1": "Harshit Modi",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/harshit-modi-48a914188/",
      "revenue_stream": "Physical commerce ",
      "customer_group": "AgriTech,  Automotive,  Government & Military,  Manufacturing  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Dewey Scientific",
      "website": "https://www.deweyscientific.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zqNHk5OVy0DmSexIF4jkxMWZB9w0-bdI",
      "profile_summary": "Dewey Scientific is pioneering this traditional method of agriculture development to create the cannabis genetics of the future. Headquartered in the heart of the fertile Palouse in Pullman, Washington, Dewey was founded by three Ph.D. plant biologists from Washington State University. Our scientific team is made up of experts in the fields of terpene production, trichome development, genetics, and plant breeding. You can learn more about our published research here.",
      "legally_known_as": "Dewey Scientific, LLC",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2015",
      "employees_headcount": "11 to 20",
      "team_member_1": "Jordan Zager",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/jordan-zager-67025920/"
  },
  {
      "startup_name": "EEKI Foods",
      "website": "https://www.eeki.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1-qFN8ZIH8sUzzg8eoRk5nfstuZ5vG-4u",
      "profile_summary": "Eeki grows pesticide-free veggies on barren land and sells them profitably at wholesale prices. Eeki Foods’ vision is a world where pesticide-free vegetables are affordable and accessible to everyone. So they have developed their patented growing chambers that provide an ideal growing environment for the plant’s root zone without using any growing media. As a result, they can grow tasty and nutritious, pesticide-free vegetables that are sold at Indian market prices.",
      "legally_known_as": "EEKI Foods",
      "industry": "Agritech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Abhay Singh",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/amit-kumar-5aa8ab17/",
      "team_member_2": "Amit Kumar"
  },
  {
      "startup_name": "Imperial Machineries",
      "website": "https://www.imperialmachineries.com/jcb/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zmTDnUZrXuUNum_GDXNvXVM1DsHwW6qU",
      "profile_summary": "Imperial Machineries is a renowned and trusted name in the field of industrial machinery and equipment. With a strong presence in the market, Imperial Machineries has established itself as a leading supplier and distributor of a wide range of machinery and spare parts. The company has a vast inventory that caters to various industries, including manufacturing, construction, mining, agriculture, and more. Imperial Machineries prides itself on offering high-quality products sourced from reputable manufacturers. They understand the importance of reliability and durability in industrial equipment, and thus, they carefully select their suppliers to ensure that customers receive top-notch machinery and spare parts. The company's product portfolio includes heavy machinery such as excavators, bulldozers, loaders, cranes, and forklifts. They also offer a comprehensive range of industrial equipment, including compressors, generators, pumps, welding machines, and power tools.",
      "legally_known_as": "Imperial World Trade Pvt. Ltd",
      "industry": "Agritech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Rishiraj Shah",
      "position": "Managing Director",
      "profile_link": "https://www.linkedin.com/in/rishiraj-shah-3b894086/",
      "revenue_stream": "E-commerce product ",
      "customer_group": "AgriTech,  Manufacturing  ",
      "client_business_structure": "Medium Enterprise",
      "geographical_segment": "India Tier 2  "
  },
  {
      "1": "KTB Ventures",
      "2": "GGV Capital",
      "3": "Infotech Ventures",
      "4": "Wangsu Science & Technology",
      "5": "Tiger Management Corporation",
      "6": "CMC Capital Group",
      "startup_name": "Clobotics",
      "website": "http://www.clobotics.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zXfYafK1SJ6kVcfKJ1u_I4wVU6XCvfC4",
      "profile_summary": "Clobotics is a global leader in intelligent computer vision solutions for the retail and wind. Clobotics’ end-to-end solutions combine computer vision, artificial intelligence/machine learning, and data analytics software with different hardware form factors, including autonomous drones, mobile applications and other IoT devices to help companies automate time-intensive operational processes, increase efficiencies and boost the bottom line through the use of real-time, data-driven insights and analysis.",
      "legally_known_as": "Clobotics",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2016",
      "employees_headcount": "61 to 100",
      "team_member_1": "George Yan",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/yanke1/",
      "team_member_2": "Claire Chen",
      "team_member_3": "Yan Ke",
      "total_funding_millions": "63"
  },
  {
      "startup_name": "Little Chilies",
      "website": "https://littlechilies.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zNQb7idzlXrmvwr-eEiWCsDaf-j3yf3d",
      "profile_summary": "Little Chilies Manufacturing is renowned for producing a diverse range of pickles, all prepared using the finest quality olive oil and homemade quality Spices. With a commitment to taste and quality, they craft pickles that are both delicious and healthy. From tangy and spicy varieties to mild and savory options, Little Chilies Manufacturing offers an array of choices to satisfy every pickle lover. Their meticulous process ensures that each jar is packed with the goodness of fresh produce and the distinct aroma of premium olive oil, making their pickles truly exceptional.",
      "legally_known_as": "Little Chilies Foods And Spices Pvt. Ltd",
      "industry": "Agritech",
      "based_out_of": "Jharkhand, India\n",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Abhishek Srivastava",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/abhishek-srivastava-65276227b/",
      "revenue_stream": "E-commerce product, Physical commerce ",
      "customer_group": "E-Commerce,  Manufacturing,  Retail,  Marketplace  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "1": "Nexus Program Management Group",
      "2": "Mark Cuban Companies",
      "startup_name": "Chapul",
      "website": "https://www.chapul.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zJIqCI89DPjAjLTA0Vg1sUj_TaCZrgiY",
      "profile_summary": "Chapul Farms Brings Biology Back to Food Systems. We use black soldier fly larvae (BSFL) microbiology to close agricultural production loops. The insects consume and upcycle nutrients previously destined for landfills, restoring biodiversity to soils and gut microbiomes. We solve organics waste challenges by building BSFL farms on-site. The insects upcycle would-be-waste into two valuable byproducts: the larvae themselves (premium poultry, aqua, and pet feed ingredient) and frass (insect manure; a soil-healing synthetic fertilizer alternative). Our solution is simple - we are using nature's best bioprocessing technology, the black soldier fly, to introduce circularity into agricultural production. Our \"secret sauce\" is how we've developed and packaged all the ingredients needed for success: the insect feed and fertilizer markets, optimized farming technology, leading global BSFL experts, microbial feedstock treatments, and a high-impact partner project network to bring our solution to commercial scale.",
      "legally_known_as": "Chapul",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Patrick Crowley",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/entomp/",
      "team_member_2": "Aly Moore",
      "team_member_3": "Michael Place",
      "total_funding_millions": "2.5"
  },
  {
      "startup_name": "CultEasy",
      "website": "https://culteasy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zClPs3VICWVIKi2Jkx43CiVDv5Tf_4r1",
      "profile_summary": "At CultEasy they are passionate about revolutionizing the agricultural and horticultural industries with their comprehensive range of agricultural services, horticultural expertise, veterinary care, and animal husbandry services, they aim to empower farmers, growers, and animal caretakers to achieve remarkable success. With a team of industry experts and a technology-driven platform, they are committed to providing innovative solutions, knowledge-sharing, and unmatched support. Their mission is to provide comprehensive and accessible services that cater to the diverse needs of farmers, horticulturists, veterinarians, and animal caretakers. They are committed to delivering innovative solutions, top-notch expertise, and exceptional customer support. By leveraging technology, fostering partnerships, and promoting sustainable practices, they strive to enhance productivity, profitability, and the overall well-being of their customers.",
      "legally_known_as": "CULTEASY TECHNOLOGY ENABLED COMPLETE FARMING SERVICES",
      "industry": "Agritech",
      "based_out_of": "Odisha, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Laxmidhar Pany",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/laxmidhar-pany-58a55a73/",
      "revenue_stream": "Fee-for-service ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Bio Fiber Industries",
      "website": "https://www.biofiberindustries.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1zC8tzygLgLXk7YFC9_rI2X378OrrrCw9",
      "profile_summary": "Bio Fiber Industries™ is a social purpose corporation focused on agro-technology delivering innovative, responsible, sustainable and regenerative solutions from diverse crops including hemp cannabis biomass for full cycle delivery. We function much like a b-corp. We are a purpose driven and environmentally focused team, committed to creating US jobs through producing the highest quality non toxic raw material needed to revive the US industrial, housing and agriculture industries. We are a carbon neutral company.",
      "legally_known_as": "Bio Fiber Industries",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Ashley Stallworth",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/nahelebailey/",
      "team_member_2": "Nahele Joshi Bailey"
  },
  {
      "1": "National Science Foundation",
      "2": "Innova Memphis",
      "3": "Cavallo Ventures",
      "4": "Lewis & Clark AgriFood",
      "startup_name": "Beta Hatch ",
      "website": "http://betahatch.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1z18HZaZ1ZjNuyao3EX9gD2JFdg-GEi8P",
      "profile_summary": "Beta Hatch is industrializing insect agriculture within a regenerative food system. We make protein for plants and animals in a zero waste system using at our mealworm ranch in Cashmere, WA. Our circular production system uses waste heat from a neighboring data center to condition our insect grow rooms, in collaboration with the Washington Clean Energy Fund.",
      "legally_known_as": "Beta Hatch ",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2015",
      "employees_headcount": "21 to 40",
      "team_member_1": "Virginia Emery",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/virginia-emery-46526351/",
      "total_funding_millions": "42"
  },
  {
      "startup_name": "Grow Billion Trees",
      "website": "https://growbilliontrees.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1z-el-VwjUyXJXhne2dwaDLc-pv93mIqs",
      "profile_summary": "Grow Billion Trees is a global initiative dedicated to combating climate change through large-scale tree plantation and conservation. Co-founded by Indian Army Veterans, the initiative harnesses the power of community action and technology to create a significant environmental impact. Besides planting native tree species, the team is engaged in protecting existing forests and boosting local biodiversity. The organization offers a unique way to celebrate personal milestones and festivals by planting trees and issuing an e-certificate for each tree planted. They also provide corporates a tangible way to offset carbon footprints and utilize their CSR budgets efficiently. Guided by the principle of being \"low on funds but high on josh\", the initiative aims to create a sustainable world for future generations.",
      "legally_known_as": "Brand Gateway Technologies Private Limited",
      "industry": "Agritech",
      "based_out_of": "Maharashtra, India",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Bishambhar Sharma",
      "position": "Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/surendra-singh-army-veteran/",
      "team_member_2": "Surendra Singh",
      "revenue_stream": "Affiliate Marketing, Commission on transaction, Sponsorship, E-commerce - service ",
      "customer_group": "AgriTech",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "\nIndia  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global  "
  },
  {
      "startup_name": "Gramik",
      "website": "https://www.gramik.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yzC-FypOv-QEz8AyiWsUe_qdBl5oaR3a",
      "profile_summary": "Gramik(formerly known as AgriJunction) working to strengthen and transform rural communities through access of quality farm inputs, expert knowledge, technical innovations and livelihood, business skills for farmers, women and rural youth.",
      "legally_known_as": "Gramik",
      "industry": "Agritech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "41 to 60",
      "team_member_1": "Raj Yadav",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/rajyadav/",
      "revenue_stream": "E-commerce - product, E-commerce - service "
  },
  {
      "startup_name": "Agromall",
      "website": "https://www.agromalls.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yz8UVAU767QfR7LCvrFBvr41RJuOZLZ9",
      "profile_summary": "Agromall is a digital marketplace for fair trade.",
      "legally_known_as": "AGROMALL PVT. LTD.",
      "industry": "Agritech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2020",
      "employees_headcount": "251 to 500",
      "team_member_1": "Vikram Panchariya",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/vikram-panchariya-6ba833106/"
  },
  {
      "startup_name": "Yotta Agro Ventures",
      "website": "https://www.instagram.com/yottaagroventures/?hl=en",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ytalN0fgNSn-mRWMBInV3_Lb08pZknbk",
      "profile_summary": "Yotta Agro Ventures is classified as a Non-govt company and is registered at Registrar of Companies, Jaipur. It is involved in Growing of crops; market gardening; horticulture. It was incorporated on 15 April 2020.",
      "legally_known_as": "Yotta Agro Ventures Private Limited",
      "industry": "Agritech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Krishna Sharma",
      "position": "Director",
      "team_member_2": "Rituraj Sharma"
  },
  {
      "startup_name": "High Rise Horticulture",
      "website": "https://highrisehorticulture.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yr3Bv1FKI7dxke9jokFCCZRwqnZuZf7-",
      "profile_summary": "At High Rise Horticulture, they are dedicated to promoting the joys of gardening in urban spaces. Their mission is to empower apartment dwellers and urban gardeners to create beautiful and sustainable gardens on their balconies. They provide a wealth of resources, tips, and guidance tailored specifically for balcony gardening, making it accessible and enjoyable for everyone.",
      "legally_known_as": "High Rise Horticulture",
      "industry": "Agritech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Paul L",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/paul-l-a91189242/",
      "revenue_stream": "Advertising, Affiliate Marketing ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Global,  North America "
  },
  {
      "startup_name": "Orgasatva Foods",
      "website": "https://orgasatva.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ypbSwFcYOlXw0Yge1-3iGdPbz10qYZV2",
      "profile_summary": "OrgaSatva Foods is purpose-led Agriculture business group founded with the intention to empower the farmers who are growing organic food and provide healthy food to end customers, removing the chain of middlemen. They understand the pain farmers go through and also customers are craving for quality food. It is the small effort from us to bridge these two ends together, simply because they have played both the roles. The founders of this company are farmers and also worked in Information Technology and in Defense for long years of their career. This experience has triggered to start OrgaSatva and help both farmers and customer. Their products are directly supplied from the farm to your doorstep under their own brand \"OrgaSatva Organic\". It is available on various eCom platforms and renowned offline stores. They follow ethical practices as they are dealing with food which is directly impacting your health. They care for you & your family's health.",
      "legally_known_as": "Orgasatva Food Pvt Ltd",
      "industry": "Agritech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Sachin Shaha",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/sunil-bagul-54208614/",
      "team_member_2": "Sunil Bagul",
      "revenue_stream": "E-commerce - Product",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Tikkly Agro Solutions",
      "website": "https://www.linkedin.com/company/tikkly-agro-solutions-private-limited/about/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ypBuf62ghN2L18096-tZBsYOc8p1l97b",
      "profile_summary": "Tikkly Agro Solutions is dedicated to work towards flourishing the agriculture in developing nations using cutting edge technological solutions for advancement for different stakeholders. Segritech is a key project under the company which aims to make the post harvest processes easy and efficient for farmers and traders using AI.",
      "legally_known_as": "Tikkly Agro Solutions Private Limited",
      "industry": "Agritech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2020",
      "employees_headcount": "41 to 60",
      "team_member_1": "Hetendra Singh Rathore",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/vijay-pratap-singh-agri/",
      "team_member_2": "Vijay Pratap Singh"
  },
  {
      "1": "T. Rowe Price",
      "2": "Temasek Holdings",
      "3": "Kleiner Perkins",
      "4": "DBL Partners",
      "5": "Fidelity Management and Research Company",
      "6": "GV (Google Ventures)",
      "startup_name": "Farmers Business Network",
      "website": "https://www.fbn.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ynq7r7aC81aQ_lz4uiirKx9ET5XhMYLB",
      "profile_summary": "Farmer's Business Network, Inc. is an independent agricultural tech and commerce platform and farmer-to-farmer network that helps its members make confident decisions to ensure the economic viability of their farms and work toward a sustainable future. FBN is an advocate for family farmers and ranchers, and does whatever it takes to helpfarmers become more prosperous - whether it's through data science-driven agronomic insights about their fields, providing farm inputs through its e-commerce store, helping farmers sell their crop more intelligently and independently, or providing them with health or crop insurance. FBN arms farmers with the tools they need to make the best decisions for their business, their families and the planet. FBN members farm more than 80 million acres in the U.S., Canada and Australia. The company has over 800 personnel and offices in San Carlos, CA., Chicago, Ill., Sioux Falls, S.D., a Canadian headquarters in High River, Alberta, and an Australian headquarters in Perth.",
      "legally_known_as": "Farmer's Business Network, Inc",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2014",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Charles Baron",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/john-vaske-67439721",
      "team_member_2": "John Vaske",
      "total_funding_millions": "917.9"
  },
  {
      "startup_name": "Gromally",
      "website": "https://gromally.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yjqt2i3_JE78zfgfqPYMgnbI1mg8roYM",
      "profile_summary": "Gromally enables the largest last-mile delivery, the sabziwalas, through a full-stack solution catering to all their needs. From bulk procurement directly from the farms to formal credit(on the back of AA framework) for their varying needs. Down the line Gromally will enable D2C brands and various other businesses to leverage this traditional distributional network in the form of a simple tech offering to grow. At scale, they will feed precise geography wise fresh-produce data back to the farm layer to curb spoilage and price volatility.",
      "legally_known_as": "Gromally Grocery Supplies Private Limited",
      "industry": "Agritech",
      "based_out_of": "Punjab, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Abhishek Saini",
      "position": "Founder & Chief Sabziwala",
      "profile_link": "https://www.linkedin.com/in/abhishek-saini-527898131/",
      "revenue_stream": "Fee-For-Service",
      "customer_group": "AgriTech,  Consumer,  Services,  E-Commerce,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "The Kute Group",
      "website": "https://thekutegroup.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yi5CRKqS4XwtFb1RtYRbSZE94EPS0dey",
      "profile_summary": "The Kute Group is a renowned multidisciplinary entity that has been dedicated to \"Keeping Mankind Happy\" since 1950. With over 70 years of service to society, the group comprises more than 50 companies spanning various industries such as Edible Oils, Refineries, Automobile Engineering, IT Solutions, Oil Cakes, Ginning, Pressing, Clothing and Garments, Logistics, Dairy, and Coconut Oil. The core purpose of the group is to enhance the quality of life globally and spread happiness through its value-for-money products, ensuring customer satisfaction, social responsibility, and accountability. The group's Oil Mills and Refineries strictly adhere to standard operations and procedures, employing a highly skilled team. Most of the manufacturing takes place in Maharashtra, India. The Kute Group caters to both domestic and international markets with its branded products. Under the brand name \"Tirumalla,\" the group offers a range of Edible Oils.",
      "legally_known_as": "The Kute Group",
      "industry": "Agritech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "1950",
      "employees_headcount": "1000+",
      "team_member_1": "Archana Suresh Kute",
      "position": "Managing Director",
      "profile_link": "https://www.linkedin.com/showcase/archana-suresh-kute/",
      "revenue_stream": "E-commerce - Service",
      "customer_group": "AgriTech,  Automotive,  EdTech/Education,  Manufacturing  ",
      "client_business_structure": "Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "FarmersFZ",
      "website": "https://www.farmersfz.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yehlss2_AbEzy40hWECEyoM-93VFSO3v",
      "profile_summary": "Provider of online marketplace designed for selling fresh and safe vegetables. The company's platform allows farmers to sell their fresh vegetables and agriproducts directly to customers by removing the middlemen and also working on an algorithm to help farmers predict income and yield, enabling farmers to get the best price for their products and customers get healthy veggies.",
      "legally_known_as": "FarmersFZ",
      "industry": "Agritech",
      "based_out_of": "Kerala, India",
      "business_model": "B2C",
      "founded_in": "2015",
      "employees_headcount": "41 to 60",
      "team_member_1": "Pradeep P S",
      "position": "Head of Operations",
      "profile_link": "https://www.linkedin.com/in/george-thomas-k-1b177070/",
      "team_member_2": "George Thomas K",
      "revenue_stream": "E-commerce - Product",
      "series_name": "SEED",
      "date": "2018",
      "funds_raised_usd_mns": "0.304",
      "name_of_investors": "Malabar Angel Network, Nativelead Foundation, Indian Angel Network, P K Gopalakrishnan, Nagaraja Prakasam"
  },
  {
      "startup_name": "Profacgen",
      "website": "https://www.profacgen.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ye33j9sNCEBk4YeSwu60u1HFTwx7snAV",
      "profile_summary": "They provide custom protein services in the biological sciences, enabling access to the latest tools, techniques, and expertise with competitive pricing and rapid turnaround time. They serve a broad spectrum of industrial and academic clients with a commitment to delivering high-quality data and customer services.",
      "legally_known_as": "Profacgen",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "61 to 100",
      "team_member_1": "Ada Ford",
      "position": "Co-Founder",
      "revenue_stream": "Interest",
      "customer_group": "AgriTech,  BioTech/BioPharmaceutical  ",
      "client_business_structure": "Medium Enterprise  ",
      "geographical_segment": "Global",
      "age_group": "35 to 45  \n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Kaavyanam Organics",
      "website": "https://www.facebook.com/kaavyanam/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ye1I9XxKCSYbCd39Ph1-p4HK-pMI_ihH",
      "profile_summary": "Kaavyanam Organics is classified as a Non-govt company and is registered at Registrar of Companies, Jaipur. It is involved in Business activities n.e.c. It was incorporated on 24 November 2017.",
      "legally_known_as": "Kaavyanam Organics Private Limited",
      "industry": "Agritech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2017",
      "employees_headcount": "11 to 20",
      "team_member_1": "Abhishek Kumar",
      "position": "Director",
      "team_member_2": "Umaima Ehtasham"
  },
  {
      "1": "Toyo Kanetsu Corporate Venture Investment Partnership",
      "2": "GROW Agrifoodtech Accelerator",
      "3": "Artesian Investment",
      "startup_name": "LYRO",
      "website": "https://lyro.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yZTD0PYa2ZTMxxsPVt5AYkoLYLalfiO_",
      "profile_summary": "LYRO is an Australian robotics and AI company creating intelligent robots. We design, develop, and deploy next-gen robots that are trusted by farmers and packing operators all across Australia. Our robots have been working thousands of hours and packing tens of thousands of fresh produce items to alleviate labour pool constraints across the foodsupply chain. LYRO is combining computer vision, state-of-the-art machine learning algorithms and world-leading robotic grasping technology to create robots that can pick any fruit or vegetable no matter how variable in form and colour they are.",
      "legally_known_as": "LYRO Robotics Pty Ltd",
      "industry": "Agritech",
      "based_out_of": "Australia, Global",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Norton Kelly-Boxall",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/juxileitner/",
      "team_member_2": "Juxi Leitner",
      "total_funding_millions": "1"
  },
  {
      "1": "Innovation Saskatchewan",
      "2": "99 Tartans",
      "3": "Reinforced Ventures",
      "4": "Harvest Returns",
      "startup_name": "Barnstorm Agtech",
      "website": "http://www.barnstormag.tech/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1yIvHW89KNnpNaAjHtnGmHfkCbkmcX8Iz",
      "profile_summary": "Barnstorm Agtech is a robotic swarm-farming venture providing an open platform for precision and powered deployment of farm technology. The company was co-founded by Parker Wells a 4th generation farmer in Saskatchewan Canada who operates a 6,000 acre farm, and Mitchell London (who previously founded Astrobotic Technology, Venture Foundry,ConnectSoft, and Headroom Learning).",
      "legally_known_as": "Barnstorm Agtech, Inc",
      "industry": "Agritech",
      "based_out_of": "Canada, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Parker Wells",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/mlondon",
      "team_member_2": "Mitchell London",
      "total_funding_millions": "2"
  },
  {
      "startup_name": "NeuPeak Robotics",
      "website": "https://www.neupeak.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1y-M5jZoXMYpM2wyOZ3Xbg7tj29XeyzR9",
      "profile_summary": "Here at Neupeak, we're revolutionizing the strawberry growing industry for a more sustainable future through strawberry harvesting robotics. Our goal is to keep food affordable and available amidst labour shortages and growing food demand through the use of robotic harvesters. With some 9 billion humans expected on our planet by 2030, the agriculture sector is in dire need of more hands to get food from farm to table. Increasing labour shortages, particularly in strawberry fields, is making it more difficult for farmers to get their products on the shelves. Our mission to increase farm yields and reduce food waste, all while working towards a sustainable future for feeding our planet.",
      "legally_known_as": "NeuPeak Robotics",
      "industry": "Agritech",
      "based_out_of": "Canada, Global",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "11 to 20",
      "team_member_1": "Anshul Porwal",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/div-gill-5a31a019/",
      "team_member_2": "Div Gill"
  },
  {
      "startup_name": "Katif",
      "website": "https://www.katif.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xzl5gkyDlfLjEmfugy3hK4S9M8wq_3Q5",
      "profile_summary": "Katif is developing a revolutionary growing system, capable of feeding the future. This innovative and patent-pending system grows locally and vertically, is clean and resource-optimized, and grows nutritious crops – all while increasing the local farmers’ bottom line. Its climate-agnostic system produces 5-25 times more than existing farms. The system utilizes 90% less water, and also decreases electricity use (in controlled greenhouses) by 30% via pod circulation. A robotic prototype has already demonstrated 50% automation, and the company will gradually work towards full autonomous operations – seeds in, crops out, with no farming experience required. Katif has a unique business model, designed to impact agriculture at scale: 1. Open-source hardware: its farm design is open-source, and most components can be found or manufactured locally at unbeatable prices. 2. Farm-as-a-Service: a recurring licensing fee for the A.I. Katif Cloud operating system. Its cloud monitors and directs all tasks across the farm, from hydroponic pumps and artificial lighting to rover storage and human duties. Earnings are based on the additional profits made for the local farmer, as yields are considerably higher due to the stacked layout, and human labor is minimized as a result of robotic automation.",
      "legally_known_as": "Katif Tech Ltd",
      "industry": "Agritech",
      "based_out_of": "Israel, Global",
      "founded_in": "2019",
      "employees_headcount": "<10"
  },
  {
      "1": "Carthona Capital",
      "2": "BridgeLane Capital",
      "startup_name": "Agerris",
      "website": "https://agerris.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nT0P3uideFumX0Bp3LtGl598_aZd8jOS",
      "profile_summary": "Agerris is a field robotics and AI start-up for the agriculture industry, based on technology created at the Australian Centre for Field Robotics, the University of Sydney, where they have been developing air and ground robotic solutions for the agriculture industry since 2005.",
      "legally_known_as": "Agerris Pty Ltd",
      "industry": "Agritech",
      "based_out_of": "Australia, Global",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "total_funding_millions": "6.5"
  },
  {
      "startup_name": "Aeroespacial",
      "website": "https://aeroespacial.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xrDuyGvVi3SBLsDNCV072Vt344s44MIx",
      "profile_summary": "AEROESPACIAL TECHNOLOGIES is a company providing innovative solutions in the field of agricultural drones, Agriculture Spraying, vegetation mapping, crop health monitoring , mining services, The technology areas catered under the ambit of autonomous and aerial systems include design and development of flight control systems (autopilot), payload control mechanisms, three axis gimbal control systems, drone based geospatial mapping and surveillance, mathematical modelling, simulation and system identification of various complex systems.",
      "legally_known_as": "Aeroespacial Technologies Pvt Ltd",
      "industry": "Agritech",
      "based_out_of": "Andhra Pradesh, India",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Naresh Vasamsetti",
      "position": "G.M Technical",
      "team_member_2": "Adarsh Kodhanda",
      "team_member_3": "Hariprasad Jain"
  },
  {
      "startup_name": "Instant Custom Packaging",
      "website": "https://instantcustompackaging.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xr6WE6Ga2ddX5oUtlangHgro9U12s4m9",
      "profile_summary": "Instant Custom Packaging is a leading provider of tailor-made packaging solutions designed to meet the unique needs of businesses across various industries. With a commitment to excellence and innovation, they specialize in producing high-quality, customized packaging products that enhance brand visibility, protect products, and leave a lasting impression on customers.",
      "legally_known_as": "Instant Custom Packaging LLC",
      "industry": "Agritech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2005",
      "employees_headcount": "21 to 40",
      "team_member_1": "Adam West",
      "position": "Co-Founder",
      "revenue_stream": "Donations, Commission on transaction, Affiliate Marketing, Advertising",
      "customer_group": "Administrative Services,  AdTech,  Aerospace & Defense  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  North America  "
  },
  {
      "startup_name": "Tikkly",
      "website": "http://www.tikkly.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nD1YVzL7clr8PLkXhbWdBF6BmGh8CK_U",
      "profile_summary": "Tikkly Agro Solutions is dedicated to work towards flourishing the agriculture in developing nations using cutting edge technological solutions for advancement for different stakeholders. Segritech is a key project under the company which aims to make the post harvest processes easy and efficient for farmers and traders using AI.",
      "legally_known_as": "Tikkly Agro Solutions Private Limited",
      "industry": "Agritech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Vijay Pratap Singh",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/hetendra-singh-rathore/",
      "team_member_2": "Hetendra Singh Rathore"
  },
  {
      "1": "Gravity Payments",
      "2": "Flying Fish Partners",
      "3": "Craft Ventures",
      "startup_name": "Joe Coffee",
      "website": "http://joecoffeeapp.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1s4CfdujUGiZ_3gaiYdLKE34FxlhMsFzo",
      "profile_summary": "Joe is leveling the playing field for independent coffee shops to compete against national corporate chains. By bringing independent coffee together, customers never have to settle for coffee they don't love. We partner directly with independent coffee shops and invest in your business to provide unique customer experiences that bring themback to your business to purchase more items, more often. Joe is built by baristas for baristas and is building the world's largest independent coffee network.",
      "legally_known_as": "Joe Coffee",
      "industry": "Foodtech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Nick Martin",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/nicholasbmartin/",
      "total_funding_millions": "5"
  },
  {
      "1": "NAVER D2 Startup Factory",
      "startup_name": "Beyond Honeycomb",
      "website": "https://www.beyondhoneycomb.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ry7ppiD8wLvmPJcocpG5u8wqFj-rUEVP",
      "profile_summary": "Beyond Honeycomb develops AI sensing and robotics solution for kitchen devices, as well as the next generation cooking equipment that minimizes nutrient and moisture loss. Singularity is a food-tech startup developing artificial intelligence kitchen solution that learns and recreates world-class chef menus. Founded in 2020, Singularity has raised seed funding from Naver D2SF and Postec Holdings.",
      "legally_known_as": "Beyond Honeycomb",
      "industry": "Foodtech",
      "based_out_of": "Korea South, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Steve Jung",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/steve-jung-521315208/"
  },
  {
      "startup_name": "Natures Box",
      "website": "https://naturesbox.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rv1b196Mh3KfiNuPEI_tgnJ6LqsYYKJ6",
      "profile_summary": "Food items from Nature's Box are raised without the use of chemicals and with love, care, and the utmost attention. They go beyond organic to deliver you deeply-rooted deliciousness of nutrient-dense and medicinally-rich food by taking inspiration from Subhash Palekar Natural Farming (SPNF) practices. Nature's Box, supported by a sizable group of individuals who are enthusiastic about farming, combines organic agricultural practices with eco-friendly desi cow dung and plant extracts to improve soil health, which is essential for growing pure and healthy food. By encouraging individuals to eat food that is naturally nourishing to avoid health risks, our efforts are intended to make a positive effect in the community.",
      "legally_known_as": "Natures Box",
      "industry": "Foodtech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2000",
      "employees_headcount": "21 to 40",
      "revenue_stream": "Advertising, Interest",
      "customer_group": "FoodTech",
      "client_business_structure": "Medium Enterprise  ",
      "geographical_segment": "India  "
  },
  {
      "1": "Kima Ventures",
      "startup_name": "HappyVore",
      "website": "https://happyvore.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1tQcZ48htm20-P5CzN7Zsaop42s_oSuna",
      "profile_summary": "HappyVore is producer of plant-based meat products intended to reinvent traditional recipes. The company's products are developed from plant-based sources and are rich in proteins, consumes less water, as well as are free from the genetically modified organism, enabling consumers to consume less meat to conveniently go vegan.",
      "legally_known_as": "Les Nouveaux Fermiers",
      "industry": "Foodtech",
      "based_out_of": "France, Global",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Guillaume Dubois",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/cedricmeston/",
      "team_member_2": "Cedric Meston",
      "total_funding_millions": "40"
  },
  {
      "1": "CPT Capital",
      "2": "Vorwerk Ventures",
      "3": "Entrepreneur First",
      "4": "Redalpine",
      "startup_name": "Better Dairy",
      "website": "https://betterdairy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1tIDhdBSgsRrTiVJuIdphc4Pi9Ak05q0R",
      "profile_summary": "Dairy production is currently hugely unsustainable and also highly inefficient. At Better Dairy we are using precision fermentation to produce the same cheeses, yoghurts, ice creams that customers currently enjoy without having to use animals.",
      "legally_known_as": "Better Dairy",
      "industry": "Foodtech",
      "based_out_of": "United Kingdom, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Jevan Nagarajah",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/jevan-nagarajah/",
      "total_funding_millions": "26.9"
  },
  {
      "1": "Rohit Kilam",
      "2": "Bhushan Prabhakar",
      "startup_name": "Arroy Foods",
      "website": "https://www.arroyfoods.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1tGec1MofNYA0oUGE4G0-P5Xp9tjiqr-V",
      "profile_summary": "Arroy Foods is a frozen gourmet food company currently servicing the organised food sector in the Delhi/NCR region plus a few northern states. They focus on the ready to cook category of frozen products and have now partnered with over 30+ accounts (such as Oberoi, trident, Hyatt, Le Meridien and others) where they supply their products. They are a profitable company and have 7MT+ manufacturing capacity with 18 team members. They have recently forayed into B2C positioning our products across 6 retail stores and would soon begin our D2C journey as well.",
      "legally_known_as": "Arroy Foods P Ltd",
      "industry": "Foodtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Deepa Arora",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/ajayarora081/",
      "team_member_2": "Ajay Arora",
      "revenue_stream": "E-commerce - Product, Physical Commerce",
      "customer_group": "Entertainment,  FMCG,  Travel/Hospitality,  Marketplace  ",
      "client_business_structure": "Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India Tier 1,  India Tier 2,  India Tier 3,  North America,  Europe  ",
      "series_name": "ANGEL",
      "date": "4th Aug 2022",
      "funds_raised_usd_mns": "0.03"
  },
  {
      "startup_name": "Tiffinshala",
      "website": "https://tiffinshala.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1tFS8aBSemALXZCYqd5fkgpRXJCWAPcjV",
      "profile_summary": "Tiffinshala is more than just a marketplace for tiffin services! Tiffinshala Kushal Kitchen App helps kitchens to track and manage listing, orders, statistics and deliveries! It is a Technology Driven Platform As a Service that helps Tiffin delivery services and home cloud kitchens to build online presence, source raw materials and get microcredits. Their mission is to use technology to help India optimize it's food supply chain and bring it's hunger index below 9.9! Their vision is to: help HORECA industry to Analyze, track and manage orders, Plan and source raw materials and get value driven microcredits for smooth operations on a technology-driven smart platform to implement farm to fork strategy in India.",
      "legally_known_as": "EZHORECA SOLUTIONS PVT LTD",
      "industry": "Foodtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Aradhya Saxena",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ria-tomar-bb8490146/",
      "team_member_2": "Ria Tomar",
      "revenue_stream": "Commission on transaction, Interest, Lending",
      "customer_group": "FoodTech,  Hyperlocal,  PaaS  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2  "
  },
  {
      "startup_name": "Aarambha College",
      "website": "https://aarambhacollege.edu.np/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1t9VNkWCiwsov_Zfdq5CKWwped3Re-p02",
      "profile_summary": "Aarambha College is an educational institution specializing in Business Information Management (BIM) and Hotel Management (BHM). The college is dedicated to providing students with high-quality education, practical training, and excellent career opportunities in their respective fields. With a focus on innovation and student success, Aarambha College is committed to producing industry-ready professionals who can excel in today's dynamic job market.",
      "legally_known_as": "Aarambha College",
      "industry": "Foodtech",
      "based_out_of": "Nepal, Global",
      "business_model": "B2B, B2C, C2C",
      "founded_in": "2023",
      "employees_headcount": "61 to 100",
      "team_member_1": "Suraj Shiwakoti",
      "position": "Founding Principal",
      "revenue_stream": "Advertising, Fee-For-Service",
      "age_group": "18 to 25  ",
      "income_bracket": "Lower-middle Income",
      "user_belongs_to": "Asia Pacific  "
  },
  {
      "startup_name": "T4 Cafe",
      "website": "http://t4cafe.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1t4xrO4KO7vwgWp7eGCD9AFcLCBaEM1fg",
      "profile_summary": "T4 Cafe is a sister concern brand of Yummy Divine Foods. Company is engaged in the activities of manufacturing some prepared food material with a unique recipe for making and promoting some own various successful Brands in India. Franchise of T4 Cafe gives you opportunity to do very profitable business and just like that with T4 Cafe. Cafe brand you get amazing fast food categories with mouth watering cuisines.",
      "legally_known_as": "T4 Cafe",
      "industry": "Foodtech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B",
      "founded_in": "1996",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sankar Mukati",
      "position": "Co-Founder",
      "customer_group": "FoodTech",
      "client_business_structure": "Large Enterprise ",
      "geographical_segment": "\nIndia  "
  },
  {
      "startup_name": "Twezztato Hub",
      "website": "https://twezztatohub.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1t3QEw9i2n_EdqOlVbND8jZ1dwNrCpBxL",
      "profile_summary": "Twezztato Hub is an Iconic Potato Tornado Brand that specializes in serving varieties of Potato Tornado with amazing taste. They also offer up a wide variety of dishes, ranging from Blooming Onion, French Fries and Garlic Bread to Maggie and Pizza. But the star of the show is their signature item, the Tornados.",
      "legally_known_as": "Twezztato Hub",
      "industry": "Foodtech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "PRITHVIRAJ SINGHANIA",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ajay-raj-singhania-1b9625212/",
      "team_member_2": "AJAY RAJ SINGHANIA",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Upper-middle Income,  High Income  \n",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Crowd Cow",
      "website": "https://www.crowdcow.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1t04HpZ_9tmEnzK3PrZK-ZWF0ffB9HEzb",
      "profile_summary": "Taste, transparency and convenience shouldn’t be mutually exclusive. This has been our founding belief since we started in 2015, and it continues to be the force that drives us. It’s why we partner with ethical farms, fisheries and producers around the world. Source delicious, high-quality and more sustainable food. Share the stories of the producers we carry. And make our products easy to access, no matter where you live in the country. This is the recipe for a great meal at home — food that’s better for you, better for the earth, better for the animals and better for the hardworking farmers, fishermen and producers behind it all. So come join the food transparency movement and taste the difference, because the difference tastes better.",
      "legally_known_as": "Hack Things, LLC",
      "industry": "Foodtech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2015",
      "employees_headcount": "41 to 60",
      "team_member_1": "Joe Heitzeberg",
      "position": "COO and CFO",
      "profile_link": "https://www.linkedin.com/in/saba-kalaimani-8613175/",
      "team_member_2": "Elizabeth Liu",
      "team_member_3": "Ethan Lowry",
      "team_member_4": "Saba Kalaimani",
      "revenue_stream": "E-commerce - Service"
  },
  {
      "startup_name": "Delta Sales app",
      "website": "https://deltasalesapp.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1sywgvZvECkVaKSZ_cIUueqkjbIp9Il99",
      "profile_summary": "Delta Sales App is a field sales force automation app designed for FMCG companies, sales managers, distributors, manufacturers who have field employees and want to automate their field sales process. With features like GPS-based employee location tracking, order automation, distributor management, beat planning, attendance and leave management, this field sales force app can be very useful for field sales team management. Delta Sales App is one the best field sales automation & salesman/employee location tracking app and caters to various businesses which include FMCGs, CPGs, Pharma, Field Services, Garments, Automobiles and others with many field sales employees. With this sales tracking software installed in handsets of your field sales reps, you as a sales manager/admin can monitor your sales team accurately without depending on manual records. Also pre-define salesman routes, tasks, track salesman locations using GPS, which makes this perfectly suitable for any business.",
      "legally_known_as": "Delta Tech Pvt Ltd",
      "industry": "Foodtech",
      "based_out_of": "Nepal, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2016",
      "employees_headcount": "41 to 60",
      "team_member_1": "Ekta Golchha",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ektagolchha/",
      "revenue_stream": "Fee-For-Service",
      "customer_group": "AgriTech,  Clothing & Apparel,  Enterprise Tech,  FMCG  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Gift Boxed Wines",
      "website": "https://giftboxedwines.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1svB5HfmSYZzMdIPL5eKmUFJjQfL_-rEk",
      "profile_summary": "Gift Boxed Wines is an online wine retailer that offers a unique and convenient way to purchase and gift premium wines. The company's mission is to provide a hassle-free and elegant gifting experience for wine enthusiasts, whether for personal or corporate occasions. Their website features a curated selection of high-quality wines from around the world, each elegantly packaged in a sleek and stylish gift box.",
      "legally_known_as": "Gift Boxed Wines",
      "industry": "Foodtech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2C",
      "founded_in": "2003",
      "employees_headcount": "61 to 100",
      "revenue_stream": "E-commerce - Product",
      "age_group": "18 to 25  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "Europe"
  },
  {
      "startup_name": "Sendha Namak India",
      "website": "https://sendhanamak.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1sv-aO-eXy_MThAQZmJQ-sk6D6x2_VoJh",
      "profile_summary": "Gandhinagar based, Sendha Namak India is one of the biggest sendha namak (Himalayan Pink Salt) supplier in India.",
      "legally_known_as": "Sendha Namak India",
      "industry": "Foodtech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Narendra Singh",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/narendra962/",
      "revenue_stream": "E-commerce - Product",
      "age_group": "18 to 25  \n",
      "income_bracket": "Lower Income",
      "user_belongs_to": "India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Propliners reality",
      "website": "https://propliners.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1stwbMqXVTZrCMvnsxu9WOpLWh_zKHBk4",
      "profile_summary": "Propliners reality takes complete care of their investors and buyers as we are taking the properties for their own selves thus they take care of all aspects related to the properties like legal-check, proper documentation as per the local authority norms and most important guaranteed best price/rentals. They are working in this industry from long time hence most of their developers are well known to us hence we have the largest and updated property portfolio for all your real estate needs.",
      "legally_known_as": "Propliners reality",
      "industry": "Foodtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Achal Agarwal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/himanshu-propliners-realty-noida/",
      "team_member_2": "Himanshu Propliners",
      "revenue_stream": "Advertising, Affiliate Marketing, E-commerce - Service, Lending",
      "customer_group": "Administrative Services,  Consumer,  Services,  Media,  E-Commerce  ",
      "client_business_structure": "Small Enterprise  ",
      "geographical_segment": "India  "
  },
  {
      "startup_name": "Fragrant Spirits",
      "website": "https://fragrantspirits.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1stDV7zTI8RceNVkpWorx5rNPXW4lI2o2",
      "profile_summary": "Founded in 2019, Fragrant Spirits specialize in the distribution and retailing of alcohol. The Group has over 40 years of experience in the alcohol industry, providing customers with a wide range of Indian and imported alcoholic beverages. Premium Brandy by Fragrant Spirits comes from the lovely nation of Armenia. This brandy was expertly created utilising traditional techniques. Even the most discriminating connoisseur will be impressed by the smooth and velvety texture of Cronus Brandy, which is made from the finest grapes.",
      "legally_known_as": "fragrantspirits",
      "industry": "Foodtech",
      "based_out_of": "Kerala, India",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Arun",
      "revenue_stream": "Advertising",
      "age_group": "18 to 25  ",
      "income_bracket": "High Income  ",
      "user_belongs_to": "India"
  },
  {
      "1": "Trinity Ventures",
      "2": "CAVU Consumer Partners",
      "3": "Belive",
      "startup_name": "Bulletproof 360",
      "website": "https://www.bulletproof.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ssAM5Yw_YEX4IEQxQ7en6YUVAuHursGb",
      "profile_summary": "Founded by Dave Asprey, Bulletproof 360 is a leading health and wellness company offering integrated mind-body nutrition products designed to help consumers unleash their full potential. The Bulletproof portfolio offers a wide range of products ranging from clean coffee, MCT oil, collagen protein, snack bars to supplements. Founded in 2014, Bulletproof always uses the highest quality, science-backed, clean ingredients to create keto-friendly products. Bulletproof products are sold in leading retailers nationwide, as well as online at Bulletproof.com and Amazon.com",
      "legally_known_as": "Bulletproof 360",
      "industry": "Foodtech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2014",
      "employees_headcount": "101 to 150",
      "team_member_1": "Dave Asprey",
      "position": "Chief Financial Officer",
      "profile_link": "https://www.linkedin.com/in/matthew-amigh-6b2a598/",
      "team_member_2": "Larry E. Bodner",
      "team_member_3": "Chris Gregg",
      "team_member_4": "Matthew Amigh",
      "revenue_stream": "E-commerce - Product",
      "total_funding_millions": "83.5"
  },
  {
      "1": "Global Founders Capital",
      "2": "Quiet Capital",
      "3": "Lead Edge Capital",
      "4": "Bullpen Capital",
      "5": "FF Venture Capital",
      "6": "Raine Ventures",
      "startup_name": "Barn2Door",
      "website": "https://www.barn2door.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1sYFPsQbTWs2G35qgmua_Y_DD9H2K3aRC",
      "profile_summary": "Barn2Door emerged from the idea that Farmers should profit more from the products they sell—without a middleman marking up prices. That’s why we provide the software and the services to help Farmers grow and manage their business. Barn2Door powers the experience Buyers expect when purchasing from Farmers, including one-click access order anytime, anywhere across the web, social, mobile, and email.",
      "legally_known_as": "Barn2Door, Inc",
      "industry": "Foodtech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2015",
      "employees_headcount": "41 to 60",
      "team_member_1": "Janelle Maiocco",
      "position": "Chief Operations Officer",
      "profile_link": "https://www.linkedin.com/in/james-maiocco/",
      "team_member_2": "James Maiocco",
      "total_funding_millions": "16.3"
  },
  {
      "startup_name": "ZERO MEAT",
      "website": "https://www.linkedin.com/company/zero-meat/?originalSubdomain=in",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1sMnqN26U3zozlQH2kEVOszd3W4Y5m_3-",
      "profile_summary": "Zero Meat is a plant based meat startup in its R&D stage based out of Chennai, currently incubated at SIIEC that offers delicious clean-label plant based meat products in our regional cuisines.",
      "legally_known_as": "ZERO MEAT PVT LTD",
      "industry": "Foodtech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Hubert Roshan Raj",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/geetha-priya-681766247/",
      "team_member_2": "Geetha Priya",
      "revenue_stream": "E-commerce - Product",
      "customer_group": "Collaboration,  FMCG,  Marketplace,  Travel/Hospitality  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Asia Pacific,  Europe  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Asia Pacific,  Europe  "
  },
  {
      "startup_name": "Digilocal",
      "website": "https://digilocal.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1s82_YhwMThyEys_k53szXe-bfIWFw6Ak",
      "profile_summary": "We empower hungry business owners of Restaurants, QSR, Cloud kitchens, Cafes, Hotels, Resorts, Food brands & Food Events to achieve exceptional profitability and sustainable growth through a digital transformation. With a deep understanding of the unique challenges and opportunities within the food, beverage, and hospitality industry, our founder brings a specialised approach that sets businesses apart from the competition. SERVICES PROVIDED: ✔ Social Media Marketing for the Food, Beverage & Hospitality Industry ✔ Online Advertising Campaigns ✔ Email Marketing Campaigns ✔ Landing Page Creation for Enhanced Conversions TYPES OF BUSINESSES: We've had the privilege of assisting a diverse range of businesses including: ⭐ Restaurant, Cafes, QSRs & Fast Food chains ⭐ Cloud kitchens & Caterers ⭐ Hotels & Resorts ⭐ Direct-to-Consumer (D2C) Food & drink brands ⭐ Food Events & Exhibitors",
      "legally_known_as": "Digilocal",
      "industry": "Foodtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2015",
      "employees_headcount": "<10",
      "team_member_1": "Jitendra Gursingh",
      "position": "Operations Head",
      "profile_link": "https://www.linkedin.com/in/sangeetha-raghunandan-8b667118/",
      "team_member_2": "Sangeetha Raghunandan",
      "revenue_stream": "Advertising, Fee-For-Service",
      "customer_group": "FMCG,  FoodTech,  Retail,  Travel/Hospitality  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "\nIndia,  India Tier 2,  India Tier 3 "
  },
  {
      "startup_name": "AMAZING VIBES",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1vuxJYi9ESXJ75g0LdeY7R2nlRVsZ-bRw",
      "profile_summary": "Amazing Vibes is a coffee cafe in Asansol, India, founded in 2023. They are committed to providing high-quality coffee, cold beverages, tea, and a range of food items such as pizza, burgers, sandwiches, and snacks. Their cozy and inviting cafe offers a relaxing ambiance, comfortable seating, and friendly baristas dedicated to exceptional customer service. They prioritize using fresh and top-quality ingredients in all their products, ensuring a delightful experience for their customers. At Amazing Vibes, they strive to create a welcoming and inclusive environment where everyone feels valued. With their focus on quality, creativity, and customer satisfaction, they are becoming a favorite destination for coffee lovers in India.",
      "legally_known_as": "AMAZING VIBES",
      "industry": "Foodtech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "SAYANDEEP BAGCHI",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/sayandeep-bagchi-b60273230/",
      "revenue_stream": "Selling data",
      "customer_group": "Food & Beverages  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Europe  "
  },
  {
      "1": "L Catterton",
      "startup_name": "Drools",
      "website": "https://drools.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1t_xJ8fug7A9XzFbcKlMZH7AK2tJs_4CS",
      "profile_summary": "At Drools® we realise your intense passion for pets and the importance of a healthy and nutritious diet for them. Therefore our team of nutritionists and veterinarians have created a superior quality food, specific to the growing needs of pets. Not just that, extensive research has ensured a consistent taste, better digestibility and absorption across all products of Drools®. These products are nutritionally balanced and undergo rigorous quality checks to ensure that the best quality food reaches your pet’s bowl. While we aim at providing just the right balance of nutrients, the entire process from assessing the raw material to processing of Drools® happens in-house, leading to a hygienic and consistent product that is highly recommended by Vets. Drools® is produced under the IB Group umbrella, a leading ISO certified protein business led conglomerate in Central India.",
      "legally_known_as": "Drools Pet Food Pvt. Ltd",
      "industry": "Foodtech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2C",
      "founded_in": "2009",
      "employees_headcount": "251 to 500",
      "team_member_1": "Shashank Sinha",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/shashank-sinha-9113068/",
      "revenue_stream": "E-commerce - Product",
      "total_funding_millions": "60",
      "series_name": "OTHER",
      "date": "20th Jun 2023 ",
      "funds_raised_usd_mns": "60",
      "name_of_investors": "L Catterton"
  },
  {
      "1": "Bill Gates",
      "2": "Sailing Capital",
      "3": "UBS",
      "4": "Temasek Holdings",
      "5": "Khosla Ventures",
      "6": "Mirae Asset Global Investments",
      "startup_name": "Impossible Foods",
      "website": "http://impossiblefoods.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1w-XoHdb4dNRvKDoWGm0w-rYQux3oS6u6",
      "profile_summary": "Impossible Foods focuses on developing plant-based substitutes for meat, dairy, and fish products. It selects specific proteins and nutrients from greens, seeds, and grains to recreate the experience of meats and dairy products. Founded by Patrick Brown and Monte Casino in 2011, Impossible Foods is headquartered in Redwood City, California.",
      "legally_known_as": "Impossible Foods Inc",
      "industry": "Foodtech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2011",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Patrick O. Brown",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/monte-casino-202571172/",
      "team_member_2": "Monte Casino",
      "total_funding_millions": "1900"
  },
  {
      "startup_name": "ShyamG Snacks",
      "website": "https://www.shyamg.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1w1dFACg0gjhN9waOelO8cSe7zEUIzu-J",
      "profile_summary": "Shree Shyam Snacks Food Pvt. Ltd. is recognized as a star export house, by the directorate general of trade, a department working under the ministry of commerce, Govt. of India. This dream was realized in the embodiment of Shree Shyam Snacks Food Pvt. Ltd. We have been branded as The No-1 brand in the ready-to-eat Snacks food category and as India's most trusted brand.",
      "legally_known_as": "Shree Shyam Snacks Food Pvt. Ltd",
      "industry": "Foodtech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2007",
      "employees_headcount": "251 to 500",
      "team_member_1": "Parveen Singal",
      "position": "Co-Founder",
      "revenue_stream": "Other",
      "customer_group": "Consumer,  Services,  E-Commerce,  FoodTech,  Social Media  ",
      "client_business_structure": "Medium Enterprise",
      "geographical_segment": "India Tier 1",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower Income  ",
      "user_belongs_to": "India  "
  },
  {
      "1": "Compass Group",
      "startup_name": "Kitchen Robotics",
      "website": "https://www.k-robo.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1w86fOefpcl05C_laid0ahwNvCxRutv1a",
      "profile_summary": "Kitchen Robotics™ specializes in the development of robotic dark kitchens solutions, also known as ghost or cloud kitchens. Our vision is to reshape the way restaurants design, prepare, manage and deliver dishes in multiple cuisines. The company was founded by a group of experienced entrepreneurs from various fields and disciplines who joined forces to achieve this unique challenge. Our suite consists of our hardware robotic product Beastro™, supported by our Dark Kitchen OS, Cuismo™.",
      "legally_known_as": "Kitchen Robotics Ltd",
      "industry": "Foodtech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2019",
      "employees_headcount": "11 to 20",
      "team_member_1": "Yair Gordin",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/yairgordin/"
  },
  {
      "1": "2b AHEAD Ventures",
      "startup_name": "DaVinci Kitchen",
      "website": "https://davincikitchen.de/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1wAkmPW4pwiUGtbZezFIyzZ2VzGwqhN-P",
      "profile_summary": "DaVinci Kitchen is going to revolutionize food franchise. DaVinci Kitchen develops the first robotic chef which can prepare organic quality dishes. The first robotic chef in the world that creates high class organic pasta dishes in under 3 minutes without human interaction.",
      "legally_known_as": "DaVinci Kitchen GmbH",
      "industry": "Foodtech",
      "based_out_of": "Germany, Global",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Vick De Froz Jorge Manuel",
      "position": "Co-Founder and CTO",
      "team_member_2": "Ibrahim Elfaramawy",
      "profile_link": "https://www.linkedin.com/in/elfaramawy/",
      "total_funding_millions": "0.5"
  },
  {
      "startup_name": "Petadore",
      "website": "https://petadore.co/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1toHtyY9WPNvqzcl66Lme2nmZt8ivqT6G",
      "profile_summary": "PetAdore is an innovative step in Animal Health Industry. They would like to introduce a super synergistic combination of modern botanical phytogenic actives & traditional Ayurvedic knowledge to bring down the adverse side effects during the treatment of various diseases. Their mission is to make pet treatment hassle-free, harmless and moreover comfortable for pets as well as pet parents. The pet care range includes a Phytoactive range of supplements & grooming products. 100% plant-based ingredients in their products offer a smooth recovery from illness by supporting body systems & functions stressed by the primary infection. All their grooming products are free from parabens, SLES, SLS, AOS, Sulphate & toxic chemicals which may harm their skin by causing recurrent infections. They take pride in calling themselves “The Phytogenic People” for comfortable companionship.",
      "legally_known_as": "Petadore",
      "industry": "Foodtech",
      "based_out_of": "Kerala, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Muhammed Kunju",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mkunju/",
      "revenue_stream": "Advertising, E-commerce - Product",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Reines",
      "website": "https://reines.co.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1wB0eYgG5VHs6tJwUO520iY3O24WZf6EU",
      "profile_summary": "The name Reines and the vision behind their Reines company originated from the desire to provide non-toxic, wholesome food for everyone, both today and for generations to come. They believe in a holistic approach to farming that goes beyond conventional practices. At Reines, they prioritize the well-being of their animals and aim to create a sustainable and healthier food system. Their commitment to sustainability extends beyond the use of non-toxic alternatives. Reines strives to contribute to a more environmentally friendly and socially responsible food industry. They prioritize the reduction of harmful chemicals in their farming practices, minimizing the impact on ecosystems and promoting the health of their consumers. They are proud to announce that nearly 10 of their specialized products are readily available to support the growth and well-being of their livestock.",
      "legally_known_as": "Reines Safe Chicken",
      "industry": "Foodtech",
      "based_out_of": "Kerala, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Muhammed Kunju",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/mkunju/",
      "revenue_stream": "E-commerce - Product",
      "age_group": "26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Twekesbury",
      "website": "https://twekesburyhospitality.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1wD09K8kJic5ztOvV0NTMUp7BLWUCsTKo",
      "profile_summary": "Twekesbury is revolutionizing virtual restaurant brands, with 30 internet restaurants and 5 cloud kitchens across 4 cities. Through delivery partnerships with Swiggy and Zomato in Kanpur, Delhi, Lucknow, and Noida, Twekesbury has established 6 successful brands. The company's strategic expansion includes upcoming operations in Bangalore with two new kitchens. , With the growing demand for online food delivery and the popularity of virtual restaurants, Twekesbury is poised for significant growth and aims to become a dominant player in the industry.",
      "legally_known_as": "Twekesbury Hospitality Pvt. Ltd",
      "industry": "Foodtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2C",
      "founded_in": "2016",
      "employees_headcount": "21 to 40",
      "team_member_1": "Archit Gupta",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/suyashgupta28/",
      "team_member_2": "Suyash Gupta",
      "revenue_stream": "E-commerce - Product",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Upper-middle Income  \n",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2  "
  },
  {
      "startup_name": "Oilo",
      "website": "https://oilo.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1u0WOdNqa7HvI_nNvauBFGlLvxvbEzRV1",
      "profile_summary": "Oilo is a Startup India registered company that believes in the power of healthy, fresh cooking oil. They understand that the process of buying oil from the market can be difficult, with concerns about adulteration and lack of transparency. With Oilo, you can finally have complete control over the oil that you use to cook your meals. Their cloud manufacturing technology allows you to create your own oil of your choice by choosing from a wide range of raw materials. You can supervise the entire process, from selecting the ingredients to receiving the final product. Their state-of-the-art technology ensures that your oil is free from any adulteration, and is of the highest quality.",
      "legally_known_as": "Oilo Healthy Foodtech Ventures Pvt Ltd",
      "industry": "Foodtech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Kiresh Thyagaraj",
      "position": "Co-Founder & CMO",
      "profile_link": "https://www.linkedin.com/in/ranjithchakkath/",
      "team_member_2": "Ranjith Chakkath",
      "revenue_stream": "Licensing, Physical Commerce, Subscription, E-commerce - Product",
      "customer_group": "FMCG,  Hyperlocal,  Manufacturing,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Asia Pacific  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60  \n",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Tea Paradise",
      "website": "https://chairai.com.ua/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1uLOeOSsMPXVCIzZs5Hb8mascuI3Fs-4E",
      "profile_summary": "The Tea Paradise team thanks you for visiting their website! They are proud to offer you the widest range of teas of the highest quality, created with love and care for each customer. At Tea Paradise, they strive to make your immersion in the world of tea unforgettable. Their team is dedicated to the art of tea drinking and passionately believes in its beneficial effects on the body and soul. They carefully select the best varieties of tea from around the world to satisfy the most refined taste of each of their customers.",
      "legally_known_as": "Tea Paradise",
      "industry": "Foodtech",
      "based_out_of": "Ukraine, Global",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Bogdan Kravets",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/bogdan-kravets-seo/",
      "revenue_stream": "E-commerce - Product",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Europe"
  },
  {
      "startup_name": "Billingko",
      "website": "https://billingkopos.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1wZNa_DXHCE19JPkzzImPHa_PExzl_Ony",
      "profile_summary": "Kochi-based startup Company was founded by Vivek kn in 2023. Billingko uses a simple and easy-to-use dashboard so that anyone will be able to use their POS software easily it has bigger buttons for easy and prolonged use of software the interface of POS is easy to use that any one without prior experice will be able to use the software.",
      "legally_known_as": "Billingko",
      "industry": "Foodtech",
      "based_out_of": "Kerala, India",
      "business_model": "B2B",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Vivek kn",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/vivek-kn-68b4a1169/",
      "revenue_stream": "Subscription",
      "customer_group": "Software",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  Europe  "
  },
  {
      "startup_name": "Mobile Chakki",
      "website": "http://www.mobilechakki.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1wa3hy7tBS5AoyYgdmO1qjnExR8Ytf34C",
      "profile_summary": "Mobile Chakki, by Aatawala Technologies Private Limited, is an app based on demand where their EV will grind wheat for customers. This happens after the pre-approval of customers regarding the quality of wheat. It takes 4 minutes for 10 kilograms of wheat flour to be ground at their doorstep. The platform receives payment either digitally or by cash as per the customer’s comfort.",
      "legally_known_as": "Aatawala Technologies Private Limited",
      "industry": "Foodtech",
      "based_out_of": "Rajasthan, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Sunil Gattani",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/sandeep-gattani-66153967/",
      "team_member_2": "Sandeep Gattani",
      "revenue_stream": "E-commerce - Product, E-commerce - Service",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income ",
      "user_belongs_to": "India Tier 1,  India Tier 2,  Global  "
  },
  {
      "startup_name": "Fresh food and caterers",
      "website": "https://www.linkedin.com/in/rajendra-ghadi-6a6a99277/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1uTl7WUqzdlxKlfZHPk1t7Y3nKGjXG7IQ",
      "profile_summary": "Mumbai-based startup Company was founded by Rajendra Ghadi and Jagannath Ghadi in 2018. Cloud kitchen mfgs all types Gujarati, Punjabi, Maharashtrian, Rajasthani etc food serving to companies.",
      "legally_known_as": "Fresh food and caterers",
      "industry": "Foodtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Rajendra Ghadi",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/rajendra-ghadi-6a6a99277/",
      "team_member_2": "Jagannath Ghadi",
      "revenue_stream": "E-commerce - Service, Fee-For-Service",
      "customer_group": "FMCG,  FoodTech  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income  ",
      "user_belongs_to": "India  "
  },
  {
      "startup_name": "Mangal Saffron",
      "website": "https://mangalsaffron.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1uVQ-xc2aYlGHcowDdb64h8I-x2q7ukvd",
      "profile_summary": "Mangal Saffron, a cherished brand of Anand Mangal Company originates from India’s heart of hospitality, Ratlam. A city known for its cultural aura in the whole world. Since it’s very foundation, Anand Mangal and the founder, Shri Mangilal Ji Chopra has chosen the path of happiness, prosperity and good health which is why the name titles to ‘Anand’ which means happiness and contentment ; And ‘Mangal’ which means prosperity, tranquility and good health. And so does its part ‘Mangal Saffron’ continues to strive the motive. The soul & sole of our brand are socio-cultural divinity and good health with a salubrious unit. We have endured the very farms of our own Kashmir, that belongs to the Indianness in the nature of our culture. A very scrupulous process that is approached by the smooth and caring hands of the people of that region, which then comes to units or we’ll say in the hands of Mangal Saffron, freshly-pluck, red and authentic Saffron. And from here it is indeed ready to get nurtured into the universe of our culture, hence making it the finest quality saffron in the whole world.",
      "legally_known_as": "Mangal Saffron",
      "industry": "Foodtech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2C",
      "founded_in": "1995",
      "employees_headcount": "41 to 60",
      "team_member_1": "Bohrauddin Bohra",
      "position": "Director",
      "revenue_stream": "E-commerce - Product"
  },
  {
      "startup_name": "Carmel Organics",
      "website": "https://www.carmelorganics.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1udLUIhE3ir2dRaw1e0bCjzxX8uMqudu7",
      "profile_summary": "Carmel organics works with more than thousand of small & marginal farmers in India by supporting them with agriculture training, seeds, inputs, guidance & organic certification. By doing this Carmel Organics get certified organic herbs and spices from them in premium prices. Carmel Organics has a certified organic processing unit where we do cleaning, grinding and packing of organic herbs and spices. The facility is situated in central India and is certified by global quality standards of organic & food. Carmel Organics offers premium quality organic products and we ensure the quality of the products from the farm itself as by educating farmers about the right handling procedures. We work with world's leading third party laboratory for ensuring the MRLs compliances in our products. Carmel organics maintained inventory from season to season and give commitments on the basis of our inventory. Our quality and lead time commitments are based on actual inventory which ensure smooth operations throughout the year.",
      "legally_known_as": "Carmel Organics Private Limited",
      "industry": "Foodtech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2012",
      "employees_headcount": "21 to 40",
      "team_member_1": "Rajesh Sagitl",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/dr-arjun-dhakad-04b68b5a/",
      "team_member_2": "Shailendra Dhakad",
      "team_member_3": "Arjun Dhakad"
  },
  {
      "startup_name": "Vezlay Foods",
      "website": "https://vezlay.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1uiJqZGiibTOdxWrlowdOefcE-k1e-vA9",
      "profile_summary": "Vezlay Foods Pvt. Ltd. Is formed to provide the nutritious, healthy and delicious vegetarian foods in hygienic and cost effective manner. Soya products market in India is very big and they are one of the first organisation to introduce Soya food on non-veg texture as they are on of the leading soya product suppliers in India. They have very innovative ready to eat food products made of soya, Which have texture same as non-veg. Most of their valuable products are first time in India. They have introduced Soya Seekh Kabab, Shawarma, Shami Kabab, Nugget, Soya Chop, Soya Leg Pieces, Soya Noodlee(Gluten-Free), Soya Vegget TVP (Gluten-Free), Soya Chikka Etc. They are getting very healthy response from their customers.",
      "legally_known_as": "Vezlay Foods Pvt. Ltd",
      "industry": "Foodtech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2010",
      "employees_headcount": "21 to 40",
      "team_member_1": "Amit Bajaj",
      "position": "Co-Founder",
      "team_member_2": "Laxman Dass",
      "revenue_stream": "Other",
      "customer_group": "FoodTech",
      "client_business_structure": "Large Enterprise",
      "geographical_segment": "India",
      "age_group": "26 to 34,  35 to 45  ",
      "income_bracket": "High Income",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Singhal Industries",
      "website": "https://www.singhalglobal.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1uf2NdF8DnR7a81MgaXjbJ5wdvuQAnv3S",
      "profile_summary": "Singhal Industries Private limited is a leading manufacturer of polymer products. They have established themselves as a trusted supplier to the global market by providing quality and economical solutions to their customers. Their company has been manufacturing high quality PP & HDPE products since 20 years. They are committed to providing their customers with the best quality of service and products at affordable prices. Their goal is to provide innovative products like bags products, sheets products, film products etc that meet the needs of their customers by offering high-quality products at competitive prices. They strive to be the best in class when it comes to innovation, design and service.",
      "legally_known_as": "Singhal Industries Private Limited",
      "industry": "Foodtech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2C",
      "founded_in": "1980",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Tushar Agrawal",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/tushar-singhal-1690b320/",
      "revenue_stream": "Advertising, E-commerce - Product",
      "customer_group": "Aerospace & Defense,  Clothing & Apparel,  Manufacturing,  Marketplace  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India"
  },
  {
      "startup_name": "Ghanshyam Honey",
      "website": "https://www.ghanshyamhoney.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xDOgmkyLdBqfEfFYIM5m9U3jISksz_KC",
      "profile_summary": "Ghanshyam Honey is a honey exporter in India with high-quality, raw, and organic honey that is sourced from a small family-owned farm in Ahmedabad. Ghanshyam honey has pioneered honey production for many years. We are one of the major suppliers of pure honey in India.",
      "legally_known_as": "Ghanshyam Honey",
      "industry": "Foodtech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2C",
      "founded_in": "1983",
      "employees_headcount": "<10",
      "team_member_1": "Shyam",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/tanu-gangul-761b2280/"
  },
  {
      "startup_name": "Loomstay",
      "website": "http://loomstay.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xF1ON4iYwyvXi1oOWzptkuwMCEQlqtrI",
      "profile_summary": "Loomstay core value differentiator is delivery of the fastest and the most trusted user experiences, be it in terms of quickest search and booking, fastest payments, settlement and refund processes. 70% of hotel bookings take place on Loomstay mobile app. They are a team of young and dynamic people aiming to provide you the best in hospitality! They help you to find hotel rooms for few hours. Starting off with just five cities, we have grown by leaps and bounds to be presently operating in 10 major cities of India! With them your rescue to find hotel rooms for few hours, is no longer a problem! They help you to get rooms for 3 hours, 6 hours and12, hours and pay accordingly. Be it a flight delay, a brief layover or a short meet with clients and you need a room for a few hours, they will always have a solution. Besides providing rooms by the hour, they also take bookings for more than 24 hours as per your purpose- and everything in super affordable prices.",
      "legally_known_as": "Ink Haven Pvt Ltd",
      "industry": "Foodtech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "61 to 100",
      "team_member_1": "Komal Jaiswal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/komal-jaiswal-52a23b231/",
      "revenue_stream": "Fee-For-Service",
      "customer_group": "Administrative Services,  Events,  Tourism,  Travel/Hospitality  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India",
      "total_funding_millions": "0.1"
  },
  {
      "startup_name": "Fexmy Food",
      "website": "http://fexmy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xOkM6c1rL8YQlO8_JRW-iAt918I9Ynpl",
      "profile_summary": "Spice company Patna, Spice Company Bihar, Spices Company Jharkhand, spice company in india. Fexmy Food Manufacturing Industries Pvt. Ltd. grinds and blends spices together to perfection to yield an exciting and aromatic pure mix that brings the right color and enjoyable taste to a variety of dishes in curried or fried form. So they provide the highest-quality food and most reliable product; you find what you're looking for, and they are available at any time. They will continue to help every customer find the pure products that they’re looking for on the market.",
      "legally_known_as": "Fexmy Food Manufacturing Industries Pvt. Ltd",
      "industry": "Foodtech",
      "based_out_of": "Bihar, India",
      "business_model": "B2C",
      "founded_in": "2005",
      "employees_headcount": "11 to 20",
      "team_member_1": "Vinod Kumar",
      "position": "Co-Founder",
      "revenue_stream": "Selling data",
      "customer_group": "FMCG,  FoodTech  ",
      "client_business_structure": "Small Enterprise",
      "geographical_segment": "India"
  },
  {
      "startup_name": "REDMONTA",
      "website": "https://redmonta.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1v-fm6pIrKho2HKJYzROUvFOiwbzRwM04",
      "profile_summary": "Redmonta Company is a prominent and highly respected business entity based in India. Known for its innovative approach and exceptional product offerings, Redmonta has established itself as a leader in various industries. The company's commitment to quality and customer satisfaction has garnered it a loyal clientele and a strong reputation in the market. Redmonta's diverse portfolio spans across sectors such as technology, manufacturing, and services. The company excels in the development and production of cutting-edge technology solutions, including software applications, hardware devices, and telecommunications equipment. Their state-of-the-art manufacturing facilities adhere to the highest standards, ensuring the production of reliable and superior quality products. Redmonta's service division is renowned for its exceptional customer support and after-sales service, providing clients with prompt assistance and tailored solutions to meet their specific needs.",
      "legally_known_as": "REDMONTA FOODS AND BEVERAGES PRIVATE LIMITED",
      "industry": "Foodtech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Mathan kumar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/kmathankumar/",
      "revenue_stream": "Physical Commerce",
      "customer_group": "FMCG,  Retail  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India Tier 2,  India Tier 3,  Global  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Meatigo by Prasuma",
      "website": "https://www.meatigo.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xIq8sdTCxu0IIhQnMKfwYSep_lZ96a6H",
      "profile_summary": "It all started with Prasuma, back in 1985, when they set up shop as India's first Premium Delicatessen Brand, present in leading Hotel chains in Delhi. Over the years, they pioneered the Cold Cuts category in India, building the business and expanding distribution to the entire country. After their Cold Cuts and Meats were well received by the Indian market, they decided to push the envelope. They rolled up their sleeves and got to work, by 2018, they channelled their love and passion of creating sensational food with the best ingredients by creating a new category & launching India's favourite Ready To Eat Momos, a product that was welcomed and loved by the Indian market! They also launched, for the first time in India, Bao Buns with 6 fillings - savoury and sweet. They have lots of exciting frozen treats in the innovation pipeline.",
      "legally_known_as": "Meatigo",
      "industry": "Foodtech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "151 to 250",
      "team_member_1": "Siddhant Wangdi",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/siddhant-wangdi-21b38614/",
      "revenue_stream": "Advertising, E-commerce - Product, Affiliate Marketing, E-commerce - Service",
      "customer_group": "Consumer,  Services,  Direct-To-Consumer Brands,  E-Commerce,  FoodTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Ariso",
      "website": "https://www.arisoapp.com/about-us",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xJrUSLYS8kpMjMviv81cPsznGgr_8LPR",
      "profile_summary": "Ariso helps people make smarter and nutritionally conscious food decisions. They provide a blunt yet fair view on food items and their suitability to individual users using an AI driven food recommendation algorithm.",
      "legally_known_as": "Ariso Technologies Private Limited",
      "industry": "Foodtech",
      "based_out_of": "Gujarat, India\n",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Rohan Patel",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/sharva/",
      "team_member_2": "Sharva Jethwa",
      "revenue_stream": "Commission on transaction, Subscription",
      "customer_group": "Direct-To-Consumer Brands,  FMCG,  Retail,  Marketplace  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  Global  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  Global  "
  },
  {
      "startup_name": "Edugyan Infotech",
      "website": "https://www.myedugyan.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1xOFdMJuIJrx2VhvAGygJ39p6W5T0QQ7o",
      "profile_summary": "Edugyan Infotech Pvt Ltd, with 15+ years of rich experience, has an excellent track record of serving in consulting, documentation, project management, cluster development, skill development, entrepreneurship development, sustainable rural development and 360' business solutions on a PAN India basis.",
      "legally_known_as": "Edugyan Infotech Pvt Ltd",
      "industry": "Foodtech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2017",
      "employees_headcount": "<10",
      "team_member_1": "Slok Kumar",
      "position": "Director Corporate Alliance",
      "profile_link": "https://www.linkedin.com/in/anand-rai-737904162/",
      "team_member_2": "Anand Singh Rajawat",
      "team_member_3": "Sudhir Jain",
      "team_member_4": "Anand Rai",
      "revenue_stream": "Fee-For-Service, Subscription, Commission on transaction",
      "customer_group": "Administrative Services,  AgriTech,  Collaboration,  Public policy  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 2,  India Tier 3,  Asia Pacific  "
  },
  {
      "startup_name": "Earthy Origins",
      "website": "https://earthyorigins.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1vWeueMwf74Ec_yTCE5IK3xT7a5ms8XUB",
      "profile_summary": "Earthy Origins is on a mission to provide the healthiest, most sustainable food possible. They believe that everyone deserves access to fresh, nutritious food, and their farm is dedicated to providing just that. Their chickens and goats are raised free-range on organic pasture, which means they’re able to roam and forage for their natural diet of seeds, grasses, insects, and more.",
      "legally_known_as": "Earthy Origins",
      "industry": "Foodtech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2C",
      "founded_in": "2019",
      "employees_headcount": "41 to 60",
      "team_member_1": "Madhesh Kumar",
      "position": "Head of Production",
      "profile_link": "https://www.linkedin.com/in/madeshkumar-shanmugam/",
      "revenue_stream": "E-commerce - Product",
      "age_group": "26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Cake Plaza",
      "website": "https://www.cakeplaza.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1vVT_7WxbqCbZ1o0HgaiW4_mS8lwaet7k",
      "profile_summary": "What started 5 years ago as a Sunday cake bake challenge by a cousin visiting home soon turned into baking cakes for family and friends on special occasions. As time passed, they knew this was their true calling and hence, Cake Plaza was born out of an unexpected and an unbelievable cake bake challenge that took place in the home kitchen years ago.",
      "legally_known_as": "Cake Plaza",
      "industry": "Foodtech",
      "based_out_of": "Haryana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2017",
      "employees_headcount": "41 to 60",
      "team_member_1": "Sonu Singh",
      "position": "Co-Founder",
      "revenue_stream": "Lending, Advertising",
      "customer_group": "FoodTech",
      "client_business_structure": "Medium Enterprise  ",
      "geographical_segment": "India Tier 1,  India Tier 2,  India Tier 3  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier "
  },
  {
      "startup_name": "Propliners reality",
      "website": "https://propliners.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lZbiygitlJh6PIoJfQptaTduvGboP0R0",
      "profile_summary": "Propliners reality takes complete care of their investors and buyers as we are taking the properties for their own selves thus they take care of all aspects related to the properties like legal-check, proper documentation as per the local authority norms and most important guaranteed best price/rentals. They are working in this industry from long time hence most of their developers are well known to us hence we have the largest and updated property portfolio for all your real estate needs.",
      "legally_known_as": "Propliners reality",
      "industry": "Spacetech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Achal Agarwal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/himanshu-propliners-realty-noida/",
      "team_member_2": "Himanshu Propliners",
      "revenue_stream": "Advertising, Affiliate Marketing, E-commerce - Service, Lending",
      "customer_group": "Administrative Services,  Consumer,  Services,  Media,  E-Commerce  ",
      "client_business_structure": "Small Enterprise",
      "geographical_segment": "India"
  },
  {
      "startup_name": "rentNet",
      "website": "https://rentnet.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rnD9K35eIvZWZvdeB2Q5D5M6Z2-9haW9",
      "profile_summary": "rentNet: Free Property Management & Maintenance Software for 5 Properties. Simplify Real Estate Management using the best application. Collect Rent and Manage Properties with Ease!",
      "legally_known_as": "rentNet",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2023",
      "employees_headcount": "21 to 40",
      "team_member_1": "Nikhil Adiga",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/nikhil-adiga-253503176/",
      "revenue_stream": "Fee-For-Service",
      "customer_group": "Real Estate  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global  "
  },
  {
      "startup_name": "Piscis Networks",
      "website": "https://piscisnetworks.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rqzbkDz2XImIjRj4UG7N-HwwCHIWZ3vF",
      "profile_summary": "Piscis Networks is an India-based OEM company that provides an SD-WAN solution to organizations of all sizes. Their SD-WAN solution is designed to help organizations improve their network performance, reliability, and security while reducing costs. Piscis Networks' SD-WAN solution is designed to be easy to deploy and manage, and it can be integrated with existing network infrastructure. The company also offers a range of professional services, including network assessment, design, and deployment, to help organizations get the most out of their SD-WAN investment. Overall, Piscis Networks is a reputable vendor that offers a comprehensive SD-WAN solution that can help organizations improve their network performance, reliability, and security.",
      "legally_known_as": "Piscis Networks Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Mandar Lokegaonkar",
      "position": "Founder & Director",
      "profile_link": "https://www.linkedin.com/in/mandar-lokegaonkar/",
      "revenue_stream": "Licensing",
      "customer_group": "E-Commerce,  Government & Military,  HealthTech / MedTech / Healthcare,  Logistics  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "ORBITX INDIA AEROSPACE",
      "website": "https://www.orbitx.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rfJMgFwb7Ebc-8cfSAmOz8Bcmm3GlWdM",
      "profile_summary": "Jaipur based, ORBITX INDIA AEROSPACE is developing reusable launch vehicle 'Atal-Yaan' to make space access more accessible and commercialize space travel.",
      "legally_known_as": "ORBITX INDIA AEROSPACE PVT LTD",
      "industry": "Spacetech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2020",
      "employees_headcount": "61 to 100",
      "team_member_1": "Diraja Singh",
      "position": "Director",
      "team_member_2": "Pankaj Singh"
  },
  {
      "startup_name": "Aone Packers And Movers",
      "website": "https://aonepacker.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rYkc8sCpdSmjmMTw-YV6sIqsenhit2o2",
      "profile_summary": "Aone Packers And Movers are one of the best Packers and Movers in Gurgaon. Their team of highly proficient and experienced professional packers and movers in Gurgaon. Their team of experts acknowledges the importance of your belongings and takes every measure to ensure that they are safely packed, loaded, and transported to your new destination.",
      "legally_known_as": "Aone Packers And Movers",
      "industry": "Spacetech",
      "based_out_of": "Haryana, India",
      "business_model": "B2C",
      "founded_in": "1980",
      "employees_headcount": "11 to 20",
      "team_member_1": "SHUBHAM PATEL",
      "position": "Co-Founder",
      "customer_group": "Logistics",
      "client_business_structure": "Medium Enterprise  ",
      "geographical_segment": "India",
      "age_group": "18 to 25  ",
      "income_bracket": "Lower-middle Income  \n",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Halfsquare Inc",
      "website": "https://halfsquare.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rS7zcwijmMBNCLmsEvoDPIeyhhIZ2boS",
      "profile_summary": "HalfSquare Inc an innovative cyber security and data security company, providing top-notch services to our clients. We specialize in preventing the unauthorized access of data by defending networks, systems, and database from cyber-attacks and vulnerabilities. We keep our clients data protected using the latest technologies, such as AI-driven dataprotection, Encryption, and Next-Generation Firewalls. Our experienced professionals provide custom solutions customized to the needs of each individual customer. As a team, we strive hard to make sure that each of our customers is provided with superior cybersecurity tailored to their specific circumstances.",
      "legally_known_as": "Halfsquare Inc",
      "industry": "Spacetech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "151 to 250",
      "team_member_1": "Rahul Vinodkumar Dadhich",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/yesiamdadhich/",
      "revenue_stream": "Subscription, Fee-For-Service"
  },
  {
      "startup_name": "Aeroin SpaceTech",
      "website": "https://www.spaceaero.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rQfjY9BmsuBoo9yj04F8Vg0hnt4ZkTY1",
      "profile_summary": "Aeroin SpaceTech is an “Aerospace Research Startup”.This establishment deals with all the projects related to Space Science and Technology. The main motto is to connect the world through SpaceTech. Aeroin focuses on building advanced Space Technology. It essentially consists of a collaboration that takes part in projects, research articles, and patent publications together. Aeroin currently working on Electric Propulsion System for Cubesat , Sounding rocket and dealing with the STEM engagement program. This collaboration mainly focuses to develop a brilliant and the most innovative space sector in INDIA itself. They are ready to collaborate with all SpaceTech companies. \"Lets fly to Space\" They not only build but also teach.",
      "legally_known_as": "Aeroin SpaceTech Pvt. Ltd",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "MS Ragul",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/ragulms/",
      "revenue_stream": "Other",
      "customer_group": "Aerospace & Defense,  Collaboration,  DeepTech,  SpaceTech  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India",
      "age_group": "\n26 to 34  ",
      "income_bracket": "Lower-middle Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Wavetech Telecom",
      "website": "https://www.wavetechtelecoms.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rB2qbF2039B4AjVFYUR6CpHA8q2yJlYx",
      "profile_summary": "Wavetech Telecom Pvt Ltd. takes pleasure in introducing itself as a prominent Manufacturer, Exporter, Importer, Supplier and Service Provider of the market for the comprehensive range of Products such as RF Telecom microwave & Optical components With a vision to provide world-class products such as all types of RF components for CDMA, WCDMA, 2G, 3G, 4G, 5G & WIFI. Apart from products, they are also known for offering End to End In Building Solutions, WIFI solutions for Indoor & Outdoor Spaces, they also take Annual Maintenance Contracts/Sub Contracts for our offered products; therefore, you get all solutions under one roof and at the most competitive prices. They are pleased to inform you that the company's phenomenal success is related to the fact that the organization operates in a customer-centric manner by supplying innovative, cost-effective & suitable technical solutions of superior quality with zero defects in the shortest possible time.",
      "legally_known_as": "Wavetech Telecom Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2012",
      "employees_headcount": "21 to 40",
      "team_member_1": "Srinivas Raju Chinda",
      "position": "Founder & MD",
      "profile_link": "https://www.linkedin.com/in/chinda-srinivas-raju-45138422/",
      "revenue_stream": "Fee-For-Service",
      "customer_group": "Government & Military,  Manufacturing,  SpaceTech,  Services  ",
      "client_business_structure": "Medium Enterprise  ",
      "geographical_segment": "India,  Asia Pacific  ",
      "age_group": "26 to 34,  35 to 45,  46 to 60 ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Asia Pacific  "
  },
  {
      "1": "TriplePoint Capital",
      "2": "Fifty Years",
      "3": "BlackRock",
      "4": "Venrock",
      "startup_name": "Astranis",
      "website": "https://www.astranis.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1rI6siBT5b_iC9S_IwauyKidfVkyIV-pc",
      "profile_summary": "Astranis is a developer of a new satellite technology that aims to transmit data down to specific terrestrial locations with each satellite it launches. The company's mission is to help get online the 4 billion people who are without internet access. It was founded in 2015 and is headquartered in San Francisco, California.",
      "legally_known_as": "Astranis",
      "industry": "Spacetech",
      "based_out_of": "United States of America, Global ",
      "founded_in": "2015",
      "employees_headcount": "251 to 500",
      "team_member_1": "Ryan McLinko",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/johngedmark/",
      "team_member_2": "John Gedmark"
  },
  {
      "startup_name": "Nile",
      "website": "https://nilesecure.com/enterprise-network/security/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1r4UQAqdKFh0MipYbOndQP5-A_WSR_3Dy",
      "profile_summary": "Nile understand that to take care of their customers, they must take care of their team members. To be the best company, they must be their best selves. Every team member has a voice at Nile, one that is always valued, respected, and appreciated. These values define each of them and all of them, with innovation at the center of all they do.",
      "legally_known_as": "Nile Secure",
      "industry": "Spacetech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Ali Burhan",
      "profile_link": "https://www.linkedin.com/in/abratul/",
      "revenue_stream": "Advertising, E-commerce - Service, Commission on transaction, E-commerce - Product",
      "age_group": "26 to 34,  More than 60  \n",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "Global,  North America,  South America  "
  },
  {
      "startup_name": "Vedasya Engineering Solutions",
      "website": "https://www.vedasyaengg.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1r1ZPbTwIpNDMbFnxapj5IGSA7oZlUsgk",
      "profile_summary": "Vedasya Engineering provides the Solvent Dip and Hot Melt based Prepreg manufacturing lines. Their State-of-the-art design ensures the superior quality of the Prepreg Fabrics. They have the capability to Design and Manufacture Composite Type-III and Type-IV cylinders wrapped with Carbon Fibers for CNG applications in automobile sector. They design and manufacture composite parts for aerospace and defense applications. They design creel stands for carbon fibres and tows.",
      "legally_known_as": "Vedasya Engineering Solutions Pvt. Ltd",
      "industry": "Spacetech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Abhishek Pulicherla",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/pulicherla-vijaya-bhaskara-rao-75443014/",
      "team_member_2": "Shiva Goutham Pattapu",
      "team_member_3": "Vijaya Bhaskara Rao Pulicherla",
      "revenue_stream": "Physical Commerce",
      "customer_group": "Aerospace & Defense,  Construction,  Energy,  Manufacturing  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Europe,  North America  "
  },
  {
      "startup_name": "Infinity Radar",
      "website": "https://infinityradar.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1r-gqvDois01wY0FzmkLP96ktN2tWVOKR",
      "profile_summary": "Infinity Radar have been honoured with three national awards, and their company is ISO-certified. altogether to help Defence forces elevate their value through Electronics warfare Systems, Product development, Product design, & services. They help Defence forces elevate their value through Electronics Support Systems, Product development, Product design, QA and services.",
      "legally_known_as": "Infitron Advanced Systems Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Himanshu Dave",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/himanshu-dave999/",
      "revenue_stream": "Physical Commerce, Fee-For-Service",
      "customer_group": "Aerospace & Defense,  Security,  Technology,  Services  ",
      "client_business_structure": "Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  South America,  Africa "
  },
  {
      "startup_name": "OrbitAID Aerospace",
      "website": "https://orbitaid.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qupP3VjfMHff3a9EYwSHDjzBSLl2hqCK",
      "profile_summary": "OrbitAID is seeks to be first India's indigenous company in the emerging field of On-orbit servicing (OOS). They are developing in-orbit refueling technology for satellite life extension and building orbital Fuel Station in Space. On-orbit satellite servicing entails servicing, refueling, repairing, and even upgrading satellites that are in orbit. OrbitAID mainly focuses on “On-Orbit Refueling” for satellites.",
      "legally_known_as": "OrbitAID Aerospace Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Sakthikumar R",
      "position": "Co-Founder & CMO",
      "profile_link": "https://www.linkedin.com/in/mano-balaji-k-358631249/",
      "team_member_2": "Mano Balaji K"
  },
  {
      "startup_name": "Inspecity",
      "website": "https://www.inspecity.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qrDfKivqvlg8G13jOBWpubtVcbDXEaoG",
      "profile_summary": "Inspecity is a spacetech company founded by two engineers crazy about space. They are developing solutions for attaining sustainability in the operations of the newspace economy.",
      "legally_known_as": "Inspecity Space Laboratories Pvt. Ltd",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Arindrajit Chowdhury",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/arindrajit-chowdhury/",
      "team_member_2": "Tausif Shaikh"
  },
  {
      "startup_name": "SpaceFields",
      "website": "http://www.spacefields.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qpFHrPN51qa7w1wXs9nRzdm7fWu8oIW2",
      "profile_summary": "SpaceFields custom-design and develop next generation dual-use rocket propulsion systems for commercial spaceflight & national security. SpaceFields is funded by Govt of India, Govt of Karnataka, Govt of Odisha and incubated at the Indian Institute of Science, Bengaluru.",
      "legally_known_as": "SpaceFields Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Apurwa Masook",
      "position": "Co Founder",
      "profile_link": "https://www.linkedin.com/in/sudarshan-samal-0267686b/",
      "team_member_2": "Bodhisattwa Sanghapriya",
      "team_member_3": "Om Prakash",
      "team_member_4": "Sudarshan Samal",
      "revenue_stream": "Fee-For-Service"
  },
  {
      "startup_name": "Caliche",
      "website": "https://www.calicheglobal.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qoGo2CwscRv3IS_ZAe3xlmglAbrO87mH",
      "profile_summary": "Caliche is present at 3 locations in the country Delhi, Guwahati and Ahmedabad. They have MoUs with reputed entities such as ISRO and DGH (MoPNG, Govt. of India. In past 4 years, Caliche have filed 13 patents (massive IP base). Probably, largest as compared to any other startup oil and gas in the country.",
      "legally_known_as": "Caliche Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Assam, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "21 to 40",
      "team_member_1": "Amit Priyadarshan",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/anandhmathew/",
      "team_member_2": "Anandh Mathew",
      "revenue_stream": "Physical Commerce,Renting / Leasing",
      "customer_group": "BioTech/BioPharmaceutical,  Energy,  SpaceTech,  Technology  ",
      "client_business_structure": "Large Enterprise  ",
      "geographical_segment": "India,  Global  "
  },
  {
      "startup_name": "InnoMick",
      "website": "https://innomick.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qiPqBjA4yjfW84kuJhRvXny_wYwdLHKY",
      "profile_summary": "Innomick have experience of serving around 100+ customers across the globe. They are having an expert team to guide you through success. They are driven by the values that push them to act with integrity and conduct business with honesty. They provide end-to-end product development to thrive on changing business dynamics by leveraging the power of digital technologies such as Web, Mobility, Cloud, and Analytics, etc.",
      "legally_known_as": "InnoMick Technology Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "41 to 60",
      "team_member_1": "Kapil Kumar Chawala",
      "position": "Co-Founder & MD",
      "profile_link": "https://www.linkedin.com/in/kapilkumarchawala/",
      "team_member_2": "Priya Chawala",
      "revenue_stream": "Fee-For-Service",
      "customer_group": "Services,  Software,  Technology,  SpaceTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Collab24",
      "website": "https://www.collab24.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qfFWN7hwUblVNJpQFE_ncyFJxaTAmMjr",
      "profile_summary": "COLLAB24 take over the lease, invest the capital, and create flexibility that traditional leases can't match. Whether you are working in large groups or with your colleagues, our private squads offer the perfect setting to collaborate. Collaborate in office spaces large enough for all of you, while taking advantage of conference rooms, lounge areas, pantry access, and other amenities.",
      "legally_known_as": "VARMINE SPACES PVT LTD",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Saurav Kumar Roy",
      "position": "Co-Founder",
      "revenue_stream": "Renting / Leasing",
      "customer_group": "SaaS,  Software,  SpaceTech,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Xovian Aerospace",
      "website": "https://xovian.co.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qdvxWqXqc7kjLfJKtBWeZku5jWAKjz79",
      "profile_summary": "Bangalore-based, Xovian Aerospace is Providing Sustainable Solutions in Satellite Technologies. The startup is providing Radio based Geospatial & Signal Intelligence to cut down the human & economic toll and bring operational efficiency across industries.",
      "legally_known_as": "Xovian Aerospace",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Ankit Bhateja",
      "position": "Founder",
      "profile_link": "https://www.linkedin.com/in/raghav009/",
      "team_member_2": "Raghav Sharma"
  },
  {
      "startup_name": "Vrisva",
      "website": "https://www.vrisva.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qdvHH-f6WmGejDlazSgVDQgTxHHoZLer",
      "profile_summary": "Vrisva Space design, build and sell products created in pursuit of Space. All their products are engineered for use in Space while solving problems on Earth. They are building a sustainable rocket company that will open up the cosmos by creating value at every step of building an SSTO.",
      "legally_known_as": "Vrisva Space Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Gadhadar Changalaraya Reddy",
      "position": "Director",
      "team_member_2": "Vivek Changalaraya Reddy"
  },
  {
      "1": "Amateur Radio Digital Communications",
      "2": "Mercatus Center",
      "startup_name": "PSSRIndia",
      "website": "https://pssrindia.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qbYlA2DpOUkDUNJH_VLBD707--1nzvOE",
      "profile_summary": "PSSRIndia specializes in developing low-cost modular and reliable subsystems for small satellites. They design and deliver modular and affordable solutions for small satellite missions, either standardized or optimized in performance and size tailored to the mission needs. In addition to that, the company supports space capability building through training programs, knowledge transfer as well as CubeSat component sales.",
      "legally_known_as": "Paradox Sonic Space Research Association",
      "industry": "Spacetech",
      "based_out_of": "Jammu and Kashmir, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Onkar Singh",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/onkar-singh-b26b89248/",
      "revenue_stream": "Donations, Selling data, Subscription, Sponsorship",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India Tier 1,  India Tier 2,  Global,  Europe,  North America,  Africa  ",
      "total_funding_millions": "0.025"
  },
  {
      "startup_name": "EtherealX",
      "website": "https://www.etherealx.space/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qYw_kFSToMQCr4Jd0RJ8_N9p4fv1cy2f",
      "profile_summary": "EtherealX is a startup founded in 2022 to significantly reduce the cost of space transportation, enable quick scientific growth, and foster a spacefaring civilization. Headquartered in Bangalore, we design, develop, and build next-generation launch vehicles and spacecraft. Currently, we are building India's and APAC's first, and the world’s most powerful and frugal medium-lift reusable launch vehicle/rocket - the Razor Crest Mk-1. With this launch vehicle, EtherealX will be capable of sending 24.8 tonnes and 22.8 tonnes to the Lower Earth Orbit (up to 400 km) in expendable and reusable configurations, respectively. The Razor Crest Mk-1 will house 9 of the most powerful to-be operational reusable liquid rocket engines in all of Asia, Europe, Australia, Africa, and South America - Stallion.",
      "legally_known_as": "Ethereal Exploration Space and Defence Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Manu J. Nair",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/shubhayu-sardar/",
      "team_member_2": "Prashanth Sharma",
      "team_member_3": "Shubhayu Sardar",
      "customer_group": "Aerospace & Defense,  Government & Military,  SpaceTech,  Communication,  AgriTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global"
  },
  {
      "startup_name": "Astrophel Aerospace",
      "website": "https://astrophelaerospace.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qSo25AeBuuwIpLGS3fgpKKdjx-bXBoE0",
      "profile_summary": "Astrophel build rockets of future. Astrophel Aerospace aims to revolutionize indian space sector by easing acess to space based launch services. The world is going back to space, and they intend to compete in the space race of the 21st century with the ultimate vision to develop India’s aerospace sector and make it self-reliant in space technology.",
      "legally_known_as": "Astrophel Aerospace pvt ltd",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B, B2C",
      "founded_in": "2022",
      "employees_headcount": "<10",
      "team_member_1": "Suyash Bafna",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/md-taj/",
      "team_member_2": "Immanuel Louis",
      "team_member_3": "Taj Baba",
      "revenue_stream": "Other",
      "customer_group": "EdTech/Education,  SpaceTech,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Asia Pacific,  Europe,  North America,  Africa  \n",
      "age_group": "18 to 25,  26 to 34,  35 to 45  \n",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global,  Asia Pacific,  Europe  "
  },
  {
      "startup_name": "SuperAliensCo",
      "website": "https://superaliens.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qMLZs-LIhj81FYYKh08X6dXA1eV4x8-8",
      "profile_summary": "We are bringing a revolutionary technology i.e. Ultramodern and high tech UAV/UAS systems for Geospatial survey, Mapping and Data management that can be used to serve various important sectors. These systems can be used by the sectors like Mining and Minerals, Construction and Infrastructure, Agriculture, oceanography, forestry and many more for their specific requirements. Over the period when these services will be widely and regularly in use across the planet by the target clients like corporate companies, government and semi government organizations, farmers etc; our next target would be SPACE where we shall be using the same systems to explore minerals in outer space. SPACE MINING IS THE NEXT BIG REVOLUTION AND WE DO NOT WISH TO MISS THE BOAT.",
      "legally_known_as": "SuperAliensCo Technologies India LLP",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India\n",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Prateek Pandey",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/prateek-pandey-3594a464/",
      "revenue_stream": "Physical Commerce, Licensing, Selling data, Subscription"
  },
  {
      "startup_name": "ScienceOverse",
      "website": "https://linktr.ee/ScienceOverse",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qKt5l79waHbDMbQmZci61deH4oGYwLmT",
      "profile_summary": "ScienceOverse create a scientific environment to provide enough resources & awareness for all the science enthusiasts & upcoming generation to pursue their career in any field of science. One of its objectives is to build an educated society by enhancing the scientific mindset among the New-generation as well as to develop human race and nature in a scientific manner.",
      "legally_known_as": "ScienceOverse",
      "industry": "Spacetech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Manjeet Barman",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/manjeet-barman-48900122b/",
      "revenue_stream": "Commission on transaction, Donations, E-commerce - Service",
      "customer_group": "Aerospace & Defense,  SpaceTech,  Technology,  EdTech/Education  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific,  Europe  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "India,  Global,  Asia Pacific,  Europe  "
  },
  {
      "startup_name": "TechnoVision",
      "website": "http://technovision.org.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1qEN-REfypscL2imUW74nB5tPPyr6JRPe",
      "profile_summary": "Technovision provides an integrated suite of services to help customers plan, design, deploy, secure, and operate network operations - while making distributed computing and information management more productive, cost-effective and responsive. System integration services provided by technovision reduce considerable operating costs of its NYC based financial client & fortune 50 manufacturing client.",
      "legally_known_as": "TechnoVision",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Mark Milton",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/markmilton05/",
      "revenue_stream": "Physical Commerce, Fee-For-Service, Licensing",
      "age_group": "18 to 25  ",
      "income_bracket": "\nLower Income,  Lower-middle Income",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "COSMOSCITY",
      "website": "https://cosmoscity.avsk.xyz/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1q8WQY343M5vS2lRarMANDP3FFEWR1uR7",
      "profile_summary": "In Cosmoscity, new habitat designs will be needed to support the crews doing long duration exploration outside Low Earth Orbit (LEO). The designs will have to cope with increased radiation and space debris levels and provide more volume and facilities as the crew will not be able to rely on immediate Earth based supplies. New concepts using inflatable modules are under development.",
      "legally_known_as": "COSMOSCITY",
      "industry": "Spacetech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "11 to 20",
      "team_member_1": "Pankaj Kumar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/scientistpankaj/",
      "revenue_stream": "E-commerce - Product,Fee-For-Service",
      "customer_group": "SpaceTech,  Aerospace & Defense,  Technology  ",
      "client_business_structure": "Startup",
      "geographical_segment": "Global",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Science Elite Group",
      "website": "https://seg.avsk.xyz/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1q4NSajZ_9KuR1iLv2kiZtY4cqN1ksaya",
      "profile_summary": "Science Elite Group is keen to develop scientific temper in every student of India and to develop a scientific-technical environment in the community. Their main objective is to promote scientific education and give a new platform to the students. Where they can use their talent properly. Science Elite Group is striving to provide every help for their development, which they need very much.",
      "legally_known_as": "Science Elite Group",
      "industry": "Spacetech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Pankaj Kumar",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/scientistpankaj/",
      "revenue_stream": "Donations, Fee-For-Service",
      "customer_group": "AdTech",
      "client_business_structure": "Startup",
      "geographical_segment": "Global",
      "age_group": "18 to 25,  26 to 34  ",
      "income_bracket": "Lower Income,  Lower-middle Income  ",
      "user_belongs_to": "Global"
  },
  {
      "startup_name": "Okhlapost",
      "website": "https://okhlapost.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1q-HZ1y63_ZntVIztItEI0qZSn2Ct495g",
      "profile_summary": "Okhla Post has taken the initiative to introduce itself to all social, political and business personalities in its limited resources. Okhla Post is working since the first day of its establishment considering Jamia Nagar, Okhla Delhi as its first Markaz. Many shows and posters of Okhla Post are very popular among the public.",
      "legally_known_as": "Okhlapost",
      "industry": "Spacetech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Ansar Imran SR",
      "position": "Co-Founder",
      "team_member_2": "Sahil Razvii",
      "profile_link": "https://www.linkedin.com/in/sahilrazvii/",
      "revenue_stream": "Advertising",
      "customer_group": "Administrative Services  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India"
  },
  {
      "1": "NeoITO",
      "startup_name": "Vashishtha Research",
      "website": "https://www.vashishtha-research.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pwyU63URT71Mnxh4rMaRqGYM57uSp7yc",
      "profile_summary": "Vashishtha Research Pvt Ltd is founded in Thiruvananthapuram with an aim to support and develop indigenous machine and instrument technology. They believe that the research and development in indigenous manufacturing and inspection technology is very much necessary for the rise of a high-tech industrial ecosystem that is not completely dependent on imported machines. This will contribute to the industry 4.0 and the vision of 'Atmanirbhar Bharat' or a self reliant nation.",
      "legally_known_as": "Vashishtha Research Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Kerala, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Saurabh Chatterjee",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/saurabh-chatterjee-097a3722/",
      "revenue_stream": "Physical Commerce",
      "total_funding_millions": "0.031"
  },
  {
      "startup_name": "The Nebula Space Organisation",
      "website": "https://www.nebulaspace.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1puegyn6AKNahLmdxc-KCeA0gw5m8gKvG",
      "profile_summary": "The Nebula Space Organisation is the world's first space organisation founded by students, with the aim of further exploration of space with the help of innovative materials built by the common people of the world. They want to solve all the problems related to space travel and technology, so that they can find a more feasible and efficient solution to everything present, in the cosmos.",
      "legally_known_as": "The Nebula Space Organisation",
      "industry": "Spacetech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2C",
      "founded_in": "2021",
      "employees_headcount": "41 to 60",
      "team_member_1": "Snehadeep Kumar",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/lukas-ginter-a65360226/",
      "team_member_2": "Lukas Ginter",
      "revenue_stream": "Donations,Sponsorship"
  },
  {
      "startup_name": "Enytially",
      "website": "https://enytially.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1psT1WLUbeJgW5KmDO8TfXUPbJUR5k_FO",
      "profile_summary": "Enytially is an Indian company founded in 2020 that develops computer software and on-line services. Its initial core product is a search engine on the Internet, with revenue obtained thanks to advertising.",
      "legally_known_as": "Enytially",
      "industry": "Spacetech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Anwarul Islam",
      "position": "Co Founder",
      "team_member_2": "Anan Islam",
      "profile_link": "https://www.linkedin.com/in/mdananislam/",
      "revenue_stream": "Advertising, Interest",
      "customer_group": "Security,  SpaceTech,  Software  ",
      "client_business_structure": "Large Enterprise  ",
      "geographical_segment": "India,  Global  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  More than 60,  46 to 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Global  "
  },
  {
      "1": "National Grid Partners",
      "2": "Benhamou Global Ventures",
      "3": "G2VP",
      "startup_name": "AiDash",
      "website": "https://www.aidash.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pr9DxZ0Jft5f0k9Ggn0afS7pPiySlxrr",
      "profile_summary": "AiDash obtains high-resolution, multispectral SAR imagery from leading satellite constellations and couples it with its proprietary AI models to facilitate timely predictions for efficient operations and maintenance activities of core industries with geographically distributed assets. They offer cutting-edge SaaS products for vegetation management, sustainability management, remote monitoring & surveying, and disaster & disruptions management that help industries transform their O&M activities and operate sustainably.",
      "legally_known_as": "AiDash Inc",
      "industry": "Spacetech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "151 to 250",
      "team_member_1": "Abhishek Vinod Singh",
      "position": "Co-Founder & & Chief AI Officer",
      "profile_link": "https://www.linkedin.com/in/nitindas1/",
      "team_member_2": "Rahul Saxena",
      "team_member_3": "Nitin Das",
      "revenue_stream": "Licensing",
      "customer_group": "Construction,  Energy,  Mobility / Transportation  ",
      "client_business_structure": "Large Enterprise,  Medium Enterprise  ",
      "geographical_segment": "Global",
      "total_funding_millions": "33"
  },
  {
      "startup_name": "Objectify Technologies",
      "website": "http://www.objectify.co.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pgFaKDFgf2DgoLYe1Ub6EUaj9T2urLzU",
      "profile_summary": "New Delhi based Objectify Technologies is the only 3D printing company in India, providing complete range of Additive manufacturing solutions in Plastics, as well as Metals including Titanium (Ti6Al4V), Aluminum (AlSi10Mg), Maraging Steel 1 (MS1), Stainless Steel 316L (SS-316L), etc. all in-house through state of the art, manufacturing set-up. With in-house equipment which can facilitate the manufacturing of components with ease, they are the only Metal and Plastic Additive Manufacturing firm with Key Competencies in Rapid Prototyping (Polymer/Metal), Small Series Production, Designing Conformal Cooling Channels, Topology Optimization, Material Development and much more. Their Partners; TVS, TATA ELXSI, Ather, Maruti Suzuki, Mahindra Rise, Bosch, Coca Cola, Havells, Homeywell, Godrej, Hero, L&T, Kirloskar and Royal Enfield.",
      "legally_known_as": "Objectify Technologies Private Limited",
      "industry": "Spacetech",
      "based_out_of": "New Delhi, India",
      "business_model": "B2B",
      "founded_in": "2013",
      "employees_headcount": "41 to 60",
      "team_member_1": "Ankit Sahu",
      "position": "Co Founder",
      "profile_link": "https://www.linkedin.com/in/arpit-sahu-32a39259/",
      "team_member_2": "Arpit Sahu",
      "revenue_stream": "Physical Commerce"
  },
  {
      "startup_name": "Recters Consultancy Services",
      "website": "https://recters.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pfjJ_DKUa7bcjHxCJZYEzkx7qRdIbXZj",
      "profile_summary": "Bettiah, Bihar based Recters Consultancy Services is an IT firm specialised in App Development, Web Development and AI Development. They can develop every type of Website, Apps, AI based platform. From Android to iOS, From Windows to Mac Apps, they can develop apps for any platform. They provide services for new startup companies as well. For new start-up’s, they provide free websites, domain, server and GST registration all in one package. And they also provide employees if the company requires from them. Their Services: AI Development - If you are looking for AI developers, They provide the best AI services at cheapest rate and 6-month free support; Drone Development - They are currently working on to setup drone development for Indian Defense industry; Social Media Development - They can develop any types of social network websites according to the clients’ requirement.",
      "legally_known_as": "Recters Consultancy Services Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Bihar, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Sachin Kumar",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/sagar-kumar-seo-expert/",
      "team_member_2": "Sagar Kumar",
      "revenue_stream": "Advertising, Commission on transaction, E-commerce - Service, Sponsorship",
      "customer_group": "Aerospace & Defense,  Social Media,  Services",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  More than 60,  46 to 60",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  "
  },
  {
      "1": "DCM Ventures",
      "2": "SoftBank",
      "3": "Moore Strategic Ventures",
      "4": "Innovation Endeavors",
      "5": "The Boeing Company",
      "startup_name": "Skylo",
      "website": "https://www.skylo.tech/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pe1NQ-slYt2xgDsSs93GAcvLX3ZADzVF",
      "profile_summary": "Skylo’s app is easy to download, install, and customize for immediate use in the field or in the command center. Fishermen know in real time, for example, the fuel levels in all tanks, location of ships deployed, fishing zones, weather and wind, available/non-available ports, crisis on boat deployed, international borders, buyer and seller meets, and more. Fishermen can solve problems in real time, know with certainty their yield for the day/week/month/, and even save lives. Furthermore, with Skylo, organizations, entrepreneurs, and governments around the world can use their data in endlessly transformative ways: to expand operations, create new opportunities, open new industries, and even save lives.",
      "legally_known_as": "Skylo",
      "industry": "Spacetech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2016",
      "employees_headcount": "151 to 250",
      "team_member_1": "Andrew Nuttall",
      "position": "Co-Founder",
      "team_member_2": "Parthsarathi Trivedi",
      "profile_link": "https://www.linkedin.com/in/tarunkgupta/",
      "team_member_3": "Tarun Gupta",
      "revenue_stream": "E-commerce - Product, E-commerce - Service",
      "customer_group": "AgriTech,  Construction,  Logistics,  Energy  ",
      "client_business_structure": "Large Enterprise,  Medium Enterprise,  Small Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific,  India Tier 1  ",
      "total_funding_millions": "116"
  },
  {
      "startup_name": "Tamil Astronomy",
      "website": "https://tamilastronomy.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pZx8r5yPjlzyMMZtv6VtChPC_XKpk_n-",
      "profile_summary": "Tamil Astronomy is an encyclopedia of information on the facts, history, myths, and current scientific knowledge of the planets, moons, and other objects in their solar system and beyond. Readers can find something amazing every day with stunning photos of guides looking at the sky and night sky with news coming from rocket launches and other planetary sights. They are working towards offering an exciting journey that celebrates space exploration, innovation, and factual information. They believe that space exploration, which describes the continuous expansion of humanity across the final frontier, is the primary source of innovation and astronomical news.",
      "legally_known_as": "Tamil Astronomy",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "C2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Ramesh Mohanasundaram",
      "position": "Co-Founder",
      "revenue_stream": "Subscription",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower Income  \n",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Abyom SpaceTech and Defence",
      "website": "https://www.abyom.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pVYkIqwJT53P7vZEacjbOtT7wyxIb2DI",
      "profile_summary": "Abyom is an aerospace organisation with the vision of Make India's space sector fully Reusable and Save the earth from space debris. They are mainly focused on the idea and mechanism of the Reusable Launch Vehicle (RLV), which is a revolutionary concept in the field of the Indian aerospace sector. Their organisation has been successfully incorporated under the Ministry of corporate affairs, Government of India. Currently, they are working on the Research and Development work of RLV, soon they would be involved in the manufacturing phase. They hope their project would be a revolutionary change for the Indian space sector. They have also opened their SpaceTech department which deals with teaching Rocket science to any student from any age group, who wants to learn or work in the field of Aerospace.",
      "legally_known_as": "Abyom SpaceTech and Defence Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Jainul Abedin",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/jainulabedin37/",
      "revenue_stream": "E-commerce - Product, E-commerce - Service",
      "customer_group": "Aerospace & Defense,  EdTech/Education,  Manufacturing,  SpaceTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Europe,  North America,  South America  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower-middle Income  \n",
      "user_belongs_to": "India,  Global,  Europe,  North America,  South America  "
  },
  {
      "startup_name": "Vellon Space",
      "website": "https://vellon.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pWaEq0AL3fDtyrF8X4Bfkho6tezWzjYt",
      "profile_summary": "Vellon Space Private Limited is an Indian private commercial small satellite launch service provider and space transportation company located at Coimbatore, Tamil Nadu, India. Vellon Space is India's first commercial launch vehicle company working on a series of Launch Vehicles based on High Altitude Launch Platforms for pico, nano, and microsatellites up to 25 kg.",
      "legally_known_as": "Vellon Space Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "J.K Ajay Kumar",
      "position": "Managing Director & CEO",
      "profile_link": "https://www.linkedin.com/in/j-k-ajay-kumar-babbbb136/",
      "revenue_stream": "Fee-For-Service",
      "customer_group": "Aerospace & Defense,  Blockchain,  Communication,  DeepTech  ",
      "client_business_structure": "Startup",
      "geographical_segment": "\nIndia,  Global  "
  },
  {
      "1": "Faad",
      "startup_name": "Navars Edutech",
      "website": "https://navarsedutech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pCrvP5M8ANDYUC9Ya7fckGZd9_6GCMEc",
      "profile_summary": "Navars Edutech helps students at K12 level to explore and engage with various astronomy concepts. Founded in 2019 by Sravan Varma Datla, the startup has served over 800 schools reaching out to 300,000 students across 13 countries. The startup aims to help students explore, engage, and experience astronomy-related concepts. The astronomy education of Navars includes online astronomy courses - instructor led with live experiments, NASO – Annual Astronomy Olympiad, participated by over 50,000 students across 13 countries, astronomy DIY Kits, portable planetarium show, and astronomy lab and experience centres. In June 2021, the startup raised an undisclosed amount in angel round of funding through Faad Network.",
      "legally_known_as": "Navars Edutech Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sravan Varma",
      "position": "Chief Product Officer",
      "profile_link": "https://www.linkedin.com/in/vikramlondhe/",
      "team_member_2": "Madhavi Arelli",
      "team_member_3": "Dinesh Prasad",
      "team_member_4": "Vikram Londhe",
      "revenue_stream": "Subscription",
      "customer_group": "Education",
      "client_business_structure": "Medium Enterprise,  Large Enterprise  \n",
      "geographical_segment": "India,  Global  ",
      "age_group": "Less than 18  ",
      "income_bracket": "Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  Asia Pacific,  Africa  "
  },
  {
      "startup_name": "Throttle Aerospace Systems Pvt ltd",
      "website": "https://dronedeliveryindia.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1pKQagbYlO9HwYf6U9OjWGnrG6MDQbWfm",
      "profile_summary": "Throttle Aerospace Systems Pvt. Ltd. is a deep tech startup and drone maker. They build and sell drones as a product as OTC, Lease models to civil or military segments. The biggest bottleneck for the drone industry is regulation, but they are super excited to be India's first DGCA approved OEM and also, they are approved by MOD for manufacturing military-grade drones. Another big problem in drone delivery is how to enable safe commercial drone delivery and they are leading in this segment too. They have signed partnership alliances with SpiceJet, Dunzo, Udaan, and Narayana health for their drone delivery operations. TAS has been bootstrapped for the last 5 years and has been building some amazing products and currently, they are on the scaling stage with lucrative tractions.",
      "legally_known_as": "Throttle Aerospace Systems Pvt ltd",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2016",
      "employees_headcount": "21 to 40",
      "team_member_1": "Nagendran Kandasamy",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/nagendran-kandasamy-3a5a21110/",
      "revenue_stream": "Physical Commerce,Renting / Leasing,Subscription"
  },
  {
      "startup_name": "PhotoAiD",
      "website": "https://photoaid.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1p6Av2I78PTsTilumImUo5IdNVvlpjwJV",
      "profile_summary": "PhotoAiD is the market leader in biometric photos. PhotoAiD has created mobile apps and a website that makes it easy, fast, and fun to take photos passports, or other documents. Using AI and the latest technology, the platform helps thousands of people every day to conveniently take passport and ID photos. Thanks to PhotoAiD taking photos is child's play. Additionally, PhotoAiD allows clients to order photo prints and delivers them within 3 business days to any address.",
      "legally_known_as": "PhotoAiD",
      "industry": "Spacetech",
      "based_out_of": "Poland,Global",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Tomasz Młodzki",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/tomasz-mlodzki/",
      "revenue_stream": "Subscription",
      "customer_group": "Travel/Hospitality",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Europe"
  },
  {
      "startup_name": "Aerospacex",
      "website": "https://www.aerospacex.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1p2Uy5kt-Sizgbahq87rou519f-k_465z",
      "profile_summary": "Aerospacex is an aerospace and defense startup where they design and manufacture UAV, UGV, USV, and green propulsion technologies for purposes such as aviation and triphibian vehicles. The Aerospacex company was started with the mission to \"connect, protect and inspire the world through their aerospace innovations.\" The company additionally has the aspiration of becoming the leading A-Z aerospace solutions provider ranging from drones to space vehicles.",
      "legally_known_as": "MeghVaahan India Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Prashanth Miryala",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/p-m-9764a619b/",
      "revenue_stream": "E-commerce - Product, Physical Commerce, Renting / Leasing",
      "customer_group": "Aerospace & Defense,  SpaceTech  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  North America,  South America,  Asia Pacific  ",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income  \n",
      "user_belongs_to": "India,  North America,  South America,  Asia Pacific  "
  },
  {
      "startup_name": "Spacetech",
      "website": "https://www.spacetech-i.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1p1HCGzNUTjKRVHwsz1kTyrxZ7cXo_Lku",
      "profile_summary": "SpaceTech offers a wide spectrum of products and services for space missions - from earth observation, navigation and science to new space mega-constellations. Their main capability is the design, development and production of innovative, high quality and cost effective space equipment and subsystems. They offer smart product solutions for satellite platforms as well as for photonic and quantum technology space payloads. SpaceTech is also a competent partner for the definition and optimisation of satellite systems and space missions. They are able to provide complete design, production, verification, and launch solutions for small satellite systems.",
      "legally_known_as": "Spacetech",
      "industry": "Spacetech",
      "based_out_of": "Germany, Global",
      "founded_in": "2018",
      "employees_headcount": "151 to 250",
      "team_member_1": "Wolfgang Pitz",
      "position": "Co-Founder",
      "team_member_2": "Loganathan Muthusamy",
      "profile_link": "https://www.linkedin.com/in/loganathan-muthusamy-aa694b13/",
      "team_member_3": "Ramesh Kumar V"
  },
  {
      "startup_name": "Apex of Design",
      "website": "http://apexofdesign.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ox_62scRIrsZugR8KK6p7xuFW7aK47wL",
      "profile_summary": "Founded in 2018, Apex of Design offers a comprehensive range of engineering services. These include rendering, CAD conversion, jigs and fixture design, tooling design for SPMs, computational fluid dynamics, concept designing and 3D modelling, and structural and thermal analysis. They deliver their solutions across industry verticals including Automotive, General Engineering, Heavy Engineering, Oil & Gas, Power Generation, Aerospace, and Marine. It works in collaboration with its clients across the product development lifecycle by constantly innovating its processes, upgrading team skill-sets and creating resources of well-trained engineers to meet the scalability required for the growing demands of the industry.",
      "legally_known_as": "Apex of Design",
      "industry": "Spacetech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Ankush Khemesara",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ankushkhemesara/",
      "revenue_stream": "Fee-For-Service"
  },
  {
      "startup_name": "Smarterz Lab Private Limited",
      "website": "https://www.linkedin.com/in/pragadeesh-shanmuganathan-b0707415/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1oun54Dws0paoJvD9JRLzl3rgxwYuKScC",
      "profile_summary": "Smarterz Lab Private Limited is an innovative additive manufacturing startup trying to make its technology accessible to everyone. They are developing solar-based 3D printing technology for building houses on the moon, Mars and earth. The startup aims to break the barrier between innovation and the customer by delivering 3D printers at their doorstep without any initial payment and with a simple verification process. They also allow clients to rent or finance any 3D printer on the website, buy any filaments, resin, and consumables within the rental period. They have two core revolutionary aspects including patentable template-based variable nozzle and spiral coil Extruder for ultra-fast printing. They have product development such web-app for controlling 3D printer functions, IoT based PCB Design to control printer functionality for ease of use. They are also working on a groundbreaking novel and patentable tubular channeling system for high-temperature solar-based 3D printing homes in terrestrial and extraterrestrial applications.",
      "legally_known_as": "Smarterz Lab Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Pradeepkumar Sundarraj",
      "position": "Founder",
      "profile_link": "https://www.linkedin.com/in/pragadeesh-shanmuganathan-b0707415/",
      "team_member_2": "Pragadeesh Shanmuganathan",
      "revenue_stream": "E-commerce - Product, Renting / Leasing"
  },
  {
      "startup_name": "SAVIC",
      "website": "https://www.savictech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1otQp68IxBtk_8E69ODfutGexjzrDF1R7",
      "profile_summary": "SAVIC Technologies is a global IT & ERP consulting company, run by a team of technocrats having in-depth industry and technology experience. Their vision is to manifest business challenges into practical solutions, mentor those solutions into practices, and mature those practices into the organisational culture.",
      "legally_known_as": "SAVIC",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2016",
      "employees_headcount": "251 to 500",
      "team_member_1": "Senthilkumar",
      "position": "Co-Founder & VP",
      "profile_link": "https://www.linkedin.com/in/yuvraj-thombre-55367529/",
      "team_member_2": "Harish Konakanchi",
      "team_member_3": "Yuvraj Thombre",
      "revenue_stream": "Licensing"
  },
  {
      "startup_name": "ClimateXos",
      "website": "https://climatexos.earth/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1opgXbmXzE66DRHi1sjaosYHsYMeF0NqY",
      "profile_summary": "ClimateXos is an integrated decision support platform - MaaS i.e. Model as a Service - that provides urban climate intelligence, to the property, construction, financial, insurance and government sectors. ClimateXos utilises a variety of environmental and climatic data sources, from IoT, satellite imagery, and open/proprietary data to monitor, analyse and visualise climate changes risks, impacts and to monitor the performance of climate-resilient infrastructure. They can provide monitoring and analysis in real-time at a hyper-local scale from IoT sensors all the way to precinct and regional resolutions through on going satellite monitoring. Their software helps ensure communities, businesses, researchers and government decision-makers have access to the best available information and tools to understand how climate change may impact them and how resilient their investments are into the future.",
      "legally_known_as": "JAYA PS AERO SPACE TECHNOLOGIES PVT LTD",
      "industry": "Spacetech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "<10",
      "team_member_1": "Akiraa PS",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/nameisakiraa/",
      "revenue_stream": "Licensing, Subscription, Selling data"
  },
  {
      "startup_name": "Geospoc",
      "website": "https://geospoc.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1op-EiOI6CB9DPSKngyR3W_2twim7vSq1",
      "profile_summary": "Geospoc is a geospatial platform and solutions company that is providing location based data and tools for data driven decision making. They operate in three key verticals - BFSI, Retail and Agriculture. They bring increased revenue and cost saving opportunities to enterprise customers through a deep understanding of location economics.",
      "legally_known_as": "Geospoc Geospatial Services Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "61 to 100",
      "team_member_1": "Dhruva Rajan",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/krishna-rajan-951b65/",
      "team_member_2": "Johann San Francisco",
      "team_member_3": "Krishna Rajan",
      "revenue_stream": "Licensing, Subscription, Selling data"
  },
  {
      "startup_name": "AccionLAND",
      "website": "http://accionland.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1oiUH0SokX3W6c6XlRCKhqYvo75h0oGX-",
      "profile_summary": "AccionLAND Private Limited is an ambidextrous organisation that synergies wide spectra of knowledge domains. AccionLAND specialises in fast track consultancy, infrastructure planning, advisory, and related services in variety of sectors. AccionLAND is headed by a team of technocrats having who believe in providing action-oriented solutions. Together, the startup is a living laboratory that induces innovation and disruption in consulting industry.",
      "legally_known_as": "Accion Land Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "11 to 20",
      "team_member_1": "Mukesh Patel",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/manish-vadanere/",
      "team_member_2": "Mayank Singh Sakla",
      "team_member_3": "Manish Vadanere",
      "revenue_stream": "Commission on transaction, Renting / Leasing, Fee-For-Service"
  },
  {
      "startup_name": "Supplier4buyer.com",
      "website": "https://www.supplier4buyer.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ogQVCNGyXmQv52Dm3eONFmCdCbQ9FBrz",
      "profile_summary": "Supplier4buyer.com enables business growth and profitability for small and midsized organisations by streamlining their procurement processes, through an efficient digital solution. The company's product Vendor Discovery helps buyers and suppliers to associate. Buyers on the company's portal have free lifetime subscriptions and suppliers are paid customers. The portal also offers packages that have several qualified leads to the customer in addition to multiple features related to digital marketing and brand positioning are offered in each package depending upon the cost. The portal also offers multiple advertisement locations on which buyers or suppliers can purchase to promote their businesses online.",
      "legally_known_as": "Rcentric Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Amit K",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/maneesh-manchanda-5454ab47/",
      "team_member_2": "Maneesh Manchanda",
      "revenue_stream": "Affiliate Marketing, Advertising, E-commerce - Service, Subscription"
  },
  {
      "startup_name": "Guru Vidhya",
      "website": "https://guruvidhyahp.business.site/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1og0ISR0tnsxwuSHXUG13aDBnGvFB2vTV",
      "profile_summary": "Palampur, Himachal Pradesh based Guru Vidhya is an Ed-Tech nestled in Himalayas trying to give wings to children's curiosity. They believe unless student invest in difficult task of studying and learning new things instead of copying the way from others, it will become very difficult for student to achieve their goal no matter how much efforts they put today. Their team at Guru Vidhya tries to teach student new and creating ways of learning everyday which will eventually help him/her to achieve their goals and make their parents, teachers and society proud. Whether a student wants to be Engineer, Doctor, CA, Lawyer or want to join IIT, IIM, NIT, NEET, this is their one-stop to helps them for preparation for all in single place. Their motto is to bring some change in the tier 3 cities, generate curiosity and give wings to dreams and goals of Children. Their team of Qualified and experienced teachers shared the same idea and are working tirelessly to bring the desired result.",
      "legally_known_as": "Guru Vidhya",
      "industry": "Spacetech",
      "based_out_of": "Himachal Pradesh, India",
      "business_model": "B2C",
      "founded_in": "2018",
      "employees_headcount": "21 to 40",
      "team_member_1": "Rahul Rana",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/rahul-rana-356040190/",
      "revenue_stream": "Licensing,Subscription,Fee-For-Service",
      "age_group": "18 to 25  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  "
  },
  {
      "startup_name": "TorqBolt Pvt Ltd",
      "website": "https://torqbolt.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1oe-7brjVl8nV749OkLGgkne7WGdmRyc-",
      "profile_summary": "TorqBolt Pvt Ltd is engaged in research, development, design and Manufacturing of Exotic Alloy Fasteners suitable for Spacecrafts & equipments meant for use in Space & Defence Feilds. The Space competetion gets aggressive day by day and recent developments by renowned space agencies have triggered the race for commercial flights as well . TorqBolt has a vision and experience of manufacturing critical fasteners meant for use by companies involved in space exploration.",
      "legally_known_as": "TorqBolt Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Gujarat, India",
      "business_model": "B2C",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Mitul Mehta",
      "position": "Managing Director",
      "profile_link": "https://www.linkedin.com/in/imitulmehta/",
      "revenue_stream": "Other"
  },
  {
      "1": "Vijay Shekhar Sharma",
      "2": "Speciale Invest",
      "startup_name": "Kawa Space",
      "website": "https://kawa.space/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1oUe4Aapy6-NAPu94V3tFOnt14vpCIXjI",
      "profile_summary": "Founded by Kris Nair in 2018, Kawa Space designs and operates most advanced earth observation satellite constellations for its customers in financial services, government jobs, strategic risk management, and agricultural sector. Kawa Space’s flagship product, the Kawa Platform is an intelligence platform that provides geo-spatial insights through an API to technology teams worldwide. They are designing space systems in a way that solution can be deployed by the customers easily without worrying about a huge capital investment or lead time. In September 2019, Vijay Shekhar Sharma, Founder of Paytm, Angellist India, Speciale Invest Fund, and other angels invested an undisclosed amount in the Mumbai-based startup in its Pre-Series A funding.",
      "legally_known_as": "Spacekawa Explorations Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "41 to 60",
      "team_member_1": "Bala Menon",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/krisnair/",
      "team_member_2": "Kris Nair",
      "revenue_stream": "Subscription",
      "total_funding_millions": "0.6"
  },
  {
      "startup_name": "i-KRAB esol Pvt Ltd",
      "website": "http://www.ikrabesol.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1oHcK076z-ajyAS7w_dRHuIqdelsTWIb1",
      "profile_summary": "i-KRAB is focused on making human lives easier by providing e-commerce, e-payment solutions, development and support. i-KRAB was established by a group of core professionals in the field of technology, sales and finance. i-KRAB offers a vast range of products and solutions in the digital world. The company’s expertise lies in digital marketing, digital branding website development and digital e-wallet ( B2B & B2C), e-commerce solutions, phone-a-friend service call centre and BPO solutions. i-KRAB e-sol Pvt. Ltd. aims to continuously explore the possibilities and technologies related to e-commerce.",
      "legally_known_as": "i-KRAB esol Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "West Bengal, India",
      "business_model": "B2B, B2C",
      "founded_in": "2015",
      "employees_headcount": "61 to 100",
      "team_member_1": "Ranadip Bose",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/ranadipbose/",
      "revenue_stream": "Advertising, E-commerce - Product, E-commerce - Service, Subscription",
      "customer_group": "Communication,  E-Commerce,  Marketplace,  Real Estate  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India",
      "age_group": "18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "startup_name": "Grahaa Space",
      "website": "https://www.grahaa.space/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1oFmGKXIiRom5jngjVg7CnRvNcyY-iZrc",
      "profile_summary": "Started in 2018, Grahaa is working on designing and developing a cluster of earth observation nano satellites to be launched into low earth orbit. The nano satellites are intended to stream near real-time, high resolution videos of client-defined areas-of-interest. The videos are processed in real time and the analysed feed will be made available to the clients through an AI powered interactive web platform.",
      "legally_known_as": "zSpaze Technologies Pvt. Ltd",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Loganathan Muthusamy",
      "position": "Co Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/rameshkumar4u/",
      "team_member_2": "Ramesh Kumar V",
      "revenue_stream": "Selling data",
      "customer_group": "SpaceTech,  Aerospace & Defense  ",
      "client_business_structure": "Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Asia Pacific,  Global  "
  },
  {
      "startup_name": "Torus Robotics Pvt Ltd",
      "website": "http://www.torusrobotics.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1oFbTXlyQFs8hgpcH37kgL5ZAlObU5alu",
      "profile_summary": "Torus Robotics is a Startup India recognised tech startup specialised in offering unmanned ground vehicles, robotics and indigenous powertrain system for defence and commercial applications. The startup was formed by founders M.Vignesh, K.Abbhi Vignesh, and Vibhakar Senthil Kumar. Torus Robotics indigenously designs, develops, and delivers fully electric unmanned ground vehicles for the Indian defence services equipped with a 6 DOF (degree of freedom) including robotic arm for detection, identification and disposal of life menacing unidentified objects. The startup was recognised by IDEX-DIO as a “Pioneer Defence Innovator”. The startup has recently signed an MOU with BEML (Bharat Earth Movers Limited) at the Aero India 2021 Expo to develop AI based unmanned ground vehicle for the Indian defence services.",
      "legally_known_as": "Torus Robotics Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "<10",
      "team_member_1": "Vignesh M",
      "position": "Co-Founder & CTO",
      "profile_link": "https://in.linkedin.com/in/abbhi",
      "team_member_2": "Vibhakar Senthil Kumar",
      "team_member_3": "K. Abbhi Vignesh",
      "revenue_stream": "Licensing,Physical Commerce",
      "customer_group": "Aerospace & Defense,  Automotive,  Mobility / Transportation,  Technology",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  Global,  Asia Pacific,  Europe  "
  },
  {
      "1": "Shasta Ventures",
      "2": "Founders Fund",
      "3": "U.S. Department of Defense",
      "4": "Boeing HorizonX Ventures",
      "5": "Founder Collective",
      "startup_name": "Accion Systems",
      "website": "https://accion-systems.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1o9V9X_AcOAmmb4wP3I3p4cfY-MGv8aT9",
      "profile_summary": "Accion Systems control mission with the most efficient propulsion system in space. It reinvented propulsion for New Space, and created a more efficient ion thruster: the Tiled Ionic Liquid Electrospray (TILE) engine.",
      "legally_known_as": "Accion Systems Inc",
      "industry": "Spacetech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2014",
      "employees_headcount": "41 to 60",
      "team_member_1": "Louis Perna",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/peterkant4/",
      "team_member_2": "Natalya Bailey",
      "team_member_3": "Peter Kant",
      "total_funding_millions": "26.5"
  },
  {
      "1": "IMKAN",
      "2": "Future Food Asia",
      "3": "Cisco",
      "startup_name": "SatSure",
      "website": "https://satsure.co/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1o6VKPeLovzpNwM3WvHDGrnJOuL3kWNmZ",
      "profile_summary": "SatSure’s bespoke geospatial big data platform combines satellite imagery, proprietary algorithms, with weather, IoT, drone imagery, social and economic datasets, and cadastral amongst others to generate near real time location specific insights. It is an innovative decision analytics leveraging advances in satellites remote sensing, machine learning and big data analytics to provide answers to large area questions across the domains of Agriculture, Banking & Financial Service, Infrastructure and Climate Change Mitigation. In India, SatSure has demonstrated a strong correlation between satellite-derived proprietary crop-related parameters and ground data, which can be exploited by the agri-insurance sector. To facilitate these solutions at a national scale, the organisation is building a 15-year archive of remotely sensed satellite images over the Indian subcontinent, using images from multiple satellites that have been in operation since 2002.",
      "legally_known_as": "SatSure",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2017",
      "employees_headcount": "61 to 100",
      "team_member_1": "Prateep Basu",
      "position": "Co-founder & Managing Director",
      "profile_link": "https://www.linkedin.com/in/abhishekraju/",
      "team_member_2": "Rashmit Singh Sukhmani",
      "team_member_3": "Abhishek Raju",
      "series_name": "SEED",
      "date": "7th Feb 2021",
      "name_of_investors": "ADB Ventures, Baring Private Equity India, INDIGOEDGE, Toch, Force Ventures, Flowstate Ventures, Luckbox Ventures, Nishchay Goel, Saikiran Krishnamurthy ",
      "funds_raised_usd_mns": "5"
  },
  {
      "1": "Next Capital",
      "startup_name": "Vestaspace Technology",
      "website": "https://www.vestaspacetech.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1o6BICv08pj8hvFWvwIJCTixJeNJcMWm0",
      "profile_summary": "VestaSpace builds small satellite platforms for commercial and scientific applications. The spacecraft developed by this company caters the needs of various Governments, Multinational Companies, Universities & Research Institutions for applications such as technology demonstrations, communications, & earth observation missions. It offers products like Mission Control Small Satellite, Launch-Assist, Nano Satellite, Cube Satellite etc.",
      "legally_known_as": "Vestaspace Technology Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Maharashtra, India",
      "founded_in": "2018",
      "employees_headcount": "21 to 40",
      "team_member_1": "Vishwanathgouda Patil",
      "position": "Founder & MD",
      "profile_link": "https://www.linkedin.com/in/arun-kumar-sureban/",
      "team_member_2": "Arun Kumar Sureban",
      "total_funding_millions": "10"
  },
  {
      "startup_name": "Hyoristic Innovations",
      "website": "https://hyoristic.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1o-uhv-UnNn4usYEm703IlT4n8vTl1QOg",
      "profile_summary": "Hyoristic Innovations was started in 2017 that works on solutions to do away with space debris. It is headquartered in Chennai, which is the heart of innovation and technology. It works in Machine Learning & Data Analytics, CubeSat, Rocket Prototyping, Deep Neural Networks, and Drones.",
      "legally_known_as": "Hyoristic Innovations Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Tamil Nadu, India",
      "founded_in": "2017",
      "employees_headcount": "<10",
      "team_member_1": "Hari Shankar RL",
      "position": "Chief Operating Officer",
      "profile_link": "https://www.linkedin.com/in/swetha-mohanachandran-8b7a47a3/",
      "team_member_2": "Murugesan Vadivel",
      "team_member_3": "Swetha Mohanachandran"
  },
  {
      "1": "Ryan Johnson",
      "2": "Lightspeed India Partners",
      "3": "Google",
      "4": "Inventus Capital India",
      "5": "growX ventures",
      "6": "Sparta Group LLC",
      "startup_name": "Pixxel",
      "website": "https://www.pixxel.space/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nxeaGsK5ulczkTQgrQ_JQpWX-V9IuoFE",
      "profile_summary": "Pixxel is a space technology company building a constellation of cutting-edge earth imaging satellites and a software platform to extract imagery insights that the satellites beam down. Pixxel's constellation will provide global coverage every day once fully deployed. The data and insights will help organisations detect, monitor and predict global phenomena in agriculture, oil and gas, climate change, forestry, urban planning and other sectors. The first satellite of this constellation is getting readied to be launched at the end of this year on a Soyuz rocket.",
      "legally_known_as": "Syzygy Space Technologies Pvt. Ltd",
      "industry": "Spacetech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Awais Ahmed",
      "position": "Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/khandelwalkshitij/",
      "team_member_2": "Kshitij Khandelwal",
      "revenue_stream": "Licensing, Selling data",
      "customer_group": "Aerospace & Defense,  AgriTech,  FoodTech,  Government & Military  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global",
      "total_funding_millions": "71",
      "series_name": "SERIES-B",
      "date": "21st Jun 2023",
      "funds_raised_usd_mns": "36",
      "name_of_investors": "Google, Radical Ventures, Lightspeed Ventures Partners, growX Ventures, Blume Ventures, Sparta Group LLC, Athera Venture Partners "
  },
  {
      "startup_name": "Om's Drone Developers",
      "website": "https://omsdronedevelopers.business.site/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nxL8CDZYFimglhiblVmpdKbZPNXuPRtO",
      "profile_summary": "Established in 2018 by Indian Institute of Science student Abhay Singh Lodhi, Om’s Drone Developers is a manufacturer of drones for a variety of purposes including the likes of photoshoots and agriculture. The drones are also used to track plant growth by farmers in an inexpensive manner. Om’s Drone is based out of Umaria Pan, Madhya Pradesh.",
      "legally_known_as": "Om's Drone Developers",
      "industry": "Spacetech",
      "based_out_of": "Madhya Pradesh, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Abhay singh lodhi",
      "profile_link": "https://in.linkedin.com/in/abhay-singh-990805180",
      "revenue_stream": "E-commerce - Product, Fee-For-Service",
      "customer_group": "AgriTech,  Government & Military,  Robotics,  Technology  ",
      "client_business_structure": "Startup,  Small Enterprise  ",
      "geographical_segment": "India",
      "age_group": "35 to 45,  18 to 25,  26 to 34,  46 to 60  ",
      "income_bracket": "Upper-middle Income,  Lower-middle Income,  High Income  ",
      "user_belongs_to": "India"
  },
  {
      "1": "Kalaari Capital",
      "startup_name": "Digantara",
      "website": "https://www.digantara.co.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nlHJ8jVf868hIy1hrvjbzp40BeyADSWM",
      "profile_summary": "Digantara Research and Technologies Pvt Ltd is an Indian company that primarily focuses on a sustainable space environment and is designing an in-situ space debris detector that would focus on Space Debris Mapping and Analysis Services, Collision Avoidance and Orbit Determination. Also, with an aim to pioneer an ecosystem for small satellites design and development in India, Digantara has over eight international MoUs and has a sophisticated network of local partners. The company’s nanosatellite subsystems are in prototyping, validation or assembly phase. Digantara, a member of IAF from 2019 has been invited to general assembly of IAC2019 and under the mentorship of MSME-IISc (Govt of India) and Dr TGK Murthy, Digantara has been listed among the top eight companies at IAF Startup Pitch Session at IAC 2019.",
      "legally_known_as": "Digantara Research and Technologies Pvt Ltd",
      "industry": "Spacetech",
      "based_out_of": "Punjab, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "11 to 20",
      "team_member_1": "Anirudh Sharma",
      "position": "Co Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/tanveer-ahmed-digantara/",
      "team_member_2": "Rahul Rawat",
      "team_member_3": "Tanveer Ahmed",
      "revenue_stream": "Selling data, Licensing, Subscription",
      "customer_group": "SpaceTech,  Aerospace & Defense  ",
      "client_business_structure": "Medium Enterprise,  Small Enterprise,  Large Enterprise,  Startup  ",
      "geographical_segment": "North America,  Europe,  India,  Africa,  South America,  Asia Pacific  ",
      "age_group": "26 to 34,  35 to 45,  Less than 18,  18 to 25,  46 to 60  ",
      "income_bracket": "High Income",
      "user_belongs_to": "South America,  North America,  Asia Pacific,  India,  Africa,  Europe  ",
      "total_funding_millions": "2.6",
      "series_name": "SERIES-A",
      "date": "20th Jun 2023",
      "funds_raised_usd_mns": "10",
      "name_of_investors": "Kalaari Capital, Peak XV Partners, Global Brain Corporation, Campus Fund"
  },
  {
      "1": "Neeraj arora",
      "2": "Mukesh Bansal",
      "3": "Wami Capital",
      "4": "Sherpalo Ventures",
      "startup_name": "Skyroot Aerospace",
      "website": "https://skyroot.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nk6NilI0i3B2BykLZ__P0bM0e-wCkExB",
      "profile_summary": "Skyroot Aerospace is a spacetech start-up aims to address the rising concerns in the global space industry – to provide low-cost launch solutions to reach space in lesser time. The startup’s three launch vehicles – Vikram I, II, and III – can carry payloads ranging from 200 kg to 700 kg to low Earth orbit. Currently, the team is going to test a 3D printed liquid propellant engine and a fully composite (carbon fiber) and high-performance solid rocket motor. Besides, it is also in talks with satellite companies for signing up customers for its first few launches. According to Pawan, the start-up has exciting plans for new space products that will be announced early next year. Its rocket architecture is highly mass-producible and modular, where it can almost triple the payload capacity with few additional upgrades. The startup has received an undisclosed amount in funding from the Founders of CureFit, Mukesh Bansal and Ankit Nagori, who invested $1.5 million in June 2018.",
      "legally_known_as": "Skyroot Aerospace Private Limited",
      "industry": "Spacetech",
      "based_out_of": "Telangana, India",
      "business_model": "B2B, B2C",
      "founded_in": "2018",
      "employees_headcount": "151 to 250",
      "team_member_1": "Pawan Kumar Chandana",
      "position": "Lead - Strategy & Investments",
      "profile_link": "https://www.linkedin.com/in/kunalgupta0587/",
      "team_member_2": "Naga Bharath Daka",
      "team_member_3": "Kunal Gupta",
      "revenue_stream": "Physical Commerce",
      "customer_group": "Aerospace & Defense,  SpaceTech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "Global",
      "total_funding_millions": "15",
      "series_name": "SERIES-B",
      "date": "2nd Sep 2022",
      "funds_raised_usd_mns": "51",
      "name_of_investors": "Mahesh Kolli, Solar Industries, Anil Chalamalasetty, Wami Capital, Neeraj Arora"
  },
  {
      "1": "OMERS Ventures",
      "2": "Craft Ventures",
      "3": "Pacific Economic Development Canada",
      "4": "Tiger Global Management",
      "startup_name": "Klue",
      "website": "http://klue.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1k6PJItGxjZFPgw9II_AKQshBlFS8IdhA",
      "profile_summary": "Klue is an AI-powered Competitive Enablement platform designed to help product marketers collect, curate, and deliver actionable competitor insights to empower revenue teams to win more business. Klue enables enterprise sales teams to win more business by providing dynamic insights about competitors. The platform uniquely brings together external competitive intel and internal knowledge from your team in the field. Then, makes it easy for Sales to access the information wherever they are. Built by product marketers to give organizations the advantage in every competitive environment.",
      "legally_known_as": "Klue Labs Inc",
      "industry": "Deeptech",
      "based_out_of": "Canada, Global ",
      "business_model": "B2B",
      "founded_in": "2015",
      "employees_headcount": "251 to 500",
      "team_member_1": "Sarathy Naicker",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/onemoresmith/?originalSubdomain=ca",
      "team_member_2": "Jason Smith",
      "total_funding_millions": "83"
  },
  {
      "startup_name": "Keya Medical",
      "website": "https://www.keyamedical.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1k6xABozAjm9GnDasqJDFxREqALAB0Nhi",
      "profile_summary": "Keya Medical is an international medical technology company developing deep learning-based medical devices for disease diagnosis and treatment. Since 2016, the company has created products that empower healthcare providers by optimizing efficiency, improving accuracy, and increasing precision. Keya Medical's team of over 400 employees combines expertise in deep learning, computer vision, medical image processing, fluid dynamics, and medical robotics. Our multidisciplinary expertise allows us to provide solutions that address the most pressing challenges facing clinicians today. Keya Medical has developed solutions for specialties including cardiology, neurology, radiology, pathology, and surgery. We are proud to collaborate with over 725 hospitals worldwide to bring solutions to clinical use that improve diagnostics, care processes, and clinical outcomes.",
      "legally_known_as": "Keya Medical Technology Co., Ltd",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2016",
      "employees_headcount": "21 to 40"
  },
  {
      "startup_name": "Kavout",
      "website": "https://www.kavout.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1kAJi5hbKGibYmRmIjs64lCv3aqOlB_Ad",
      "profile_summary": "Kavout is a cutting edge Artificial Intelligence-driven investment platform built for investors of all levels who want to invest efficiently and intelligently. Unlike traditional investment platforms, Kavout is the first technology of its kind integrated with AI, big data and machine learning, empowering you to gain better insights and make smarter decisions. With Kavout's robust platform, you will have access to investment solutions that are more powerful than ever.",
      "legally_known_as": "Kavout Corporation",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2015",
      "employees_headcount": "21 to 40",
      "team_member_1": "Jing Zhou",
      "position": "Co-Founder and Board Director",
      "profile_link": "https://www.linkedin.com/in/keith-kline-53b894140/",
      "team_member_2": "Keith Kline",
      "team_member_3": "Alex Lu"
  },
  {
      "1": "Imagen Capital Partners",
      "2": "Bellingham Angel Investors",
      "startup_name": "ioCurrents",
      "website": "https://www.iocurrents.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1kCLyqH2tJE9NHNmGGos2x6VKfd6qZTKS",
      "profile_summary": "Digitization gives you the data. ioCurrents’ MarineInsight™ platform tells you the story. Through purposeful digitization we tell your team when, where, and why to pay attention to each asset on each unique vessel, offering the opportunity to gain efficiencies across fuel, maintenance, and voyages. Our third party data analytics platform, MarineInsight, applies AI and machine learning models to provide your crews with real-time, analyzed, actionable data, from anywhere. Employing the IoT device on board to run the analytics in real-time, MarineInsight frequently and efficiently sends the analyzed, compressed data from the device on board to a secure cloud, where the shoreside team can access it. Our commitment to providing actionable insight means that your team has a dedicated ioCurrents team member assisting them in setting KPIs prior to hardware installation, which is quick, easy, and non-invasive. These KPIs can (and often do) include failure prediction, fuel optimization, and improved maintenance. The IoT device onboard requires less than 24 hours to install, with no drydock required, and all configuration is completed remotely. Shoreside teams have live access to MarineInsight within a couple of days of install to avert any disruption in operations.",
      "legally_known_as": "ioCurrents, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2015",
      "employees_headcount": "21 to 40",
      "team_member_1": "Will Roberts",
      "position": "Chief Operating Officer",
      "profile_link": "https://www.linkedin.com/in/erikllogan/",
      "team_member_2": "Cosmo King",
      "team_member_3": "Jesse Brink",
      "team_member_4": "Erik Logan",
      "total_funding_millions": "6.3"
  },
  {
      "startup_name": "Inyore",
      "website": "https://www.inyore.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1kLCiQ0K99pOb93kA0tN9C7gXtUorrb3X",
      "profile_summary": "Inyore is an AI-powered platform that acts as an anonymous \"internal forum\" for employees, providing managers with sentiment analysis and insights. Our mission is to connect marketing teams directly with customers bridging the disconnection of being able to create user-generated content with their customers; with real-time two-way communication that will help marketing teams increase; loyalty, efficiency, and generate more revenue.",
      "legally_known_as": "Inyore Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10"
  },
  {
      "startup_name": "Theta Additive",
      "website": "https://thetaadditive.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ki-99T-1j6CMEUWNKSydq4oAAhOFUMfH",
      "profile_summary": "Tagline: Unleashing precision Purpose: Development of next-gen 3D printer and advanced biocompatible materials for full scale heart and its components Theta Additive is a new startup that is redefining the limits of 3D printing technology. Their R&D team is working on a solution to two of the biggest challenges faced by FDM technology: high printing accuracy and compatibility with a wide range of biocompatible flexible polymers. The idea is to leverage their expertise in developing micro-fabrication technology to create patient-specific full-scale heart models and components. These precise and detailed models enable surgical simulation, disease modeling, and drug testing, facilitating the development of new treatments and therapies for diverse medical conditions.",
      "legally_known_as": "Theta Additive Private Limited",
      "industry": "Deeptech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Amit Shukla",
      "position": "Founder & MD",
      "profile_link": "https://www.linkedin.com/in/shuklaxamit/",
      "revenue_stream": "Licensing, E-commerce - Product",
      "customer_group": "DeepTech,  Design,  HealthTech / MedTech / Healthcare,  Manufacturing ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Europe,  Asia Pacific,  North America,  South America,  Africa  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45,  46 to 60,  More than 60  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  "
  },
  {
      "1": "Y Combinator",
      "2": "Drive Capital",
      "3": "Zeal Capital Partners",
      "startup_name": "Humanly",
      "website": "http://humanly.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1kcs1xpHXgRitFsoXA4mFOC3-PC8GFsXD",
      "profile_summary": "The future of work for high-volume hiring and retention is changing rapidly. We know automation is part of the equation — yet it is not enough. This is why we believe direct candidate and employee conversations anchored in efficacy, equity, and empathy are the new gateway to measure people and business performance. At each stage of the candidate and employee lifecycle, Humanly.io's conversational AI bridges automated interactions, voice technology, and integrated systems. And through innovation, commitment, and fairness, Humanly.io guides hiring teams, candidates, and employees to achieve their success — ultimately hiring the most qualified, diverse applicant pool. Today, Humanly ranks as one of best AI recruiting, HR chatbots, and DEI-enabling tools — driven by a focus on UX design and a diverse team.",
      "legally_known_as": "HumanlyHR Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Bryan Leptich",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/premskumar/",
      "team_member_2": "Andrew Gardner",
      "team_member_3": "Prem Kumar",
      "revenue_stream": "Subscription",
      "total_funding_millions": "23.1"
  },
  {
      "startup_name": "Neuconomi",
      "website": "https://www.neuconomi.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1kYug201dyvQNS_1pXWicJkZ4Kho6yKx3",
      "profile_summary": "Hyderabad-based startup NEUCONOMI ® is a new-age Patent Marketplace with a focus on DeepTech innovations of Digital Transformation, to unlock the financial value of Patents via various licensing models. Their marketplace empowers both Licensors to monetize inventions and Licensees to benefit from integrating futuristic technologies in a fair, reasonable, and non-discriminatory (FRAND) way. Their team is compromises of professionals with the experience of 2 decades with a great track record of success stories and testimonials. Founded by Bharath B and Radhika R, who not only come from a corporate background in IT and Sales, but also are Entrepreneurs, serial inventors, and serial angel investors.",
      "legally_known_as": "1.618 Research & Development Pvt. Ltd",
      "industry": "Deeptech",
      "based_out_of": "Telangana, India",
      "founded_in": "2020",
      "employees_headcount": "11 to 20",
      "team_member_1": "Bharath B",
      "position": "CHIEF SALES OFFICER",
      "team_member_2": "Radhika Suresh Rembarsu and"
  },
  {
      "startup_name": "Ctruh",
      "website": "https://www.ctruh.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1kXiPfZXXBB6Cs62mEGEPwp56JfFoj20D",
      "profile_summary": "Ctruh, a no-code platform that makes it dead simple for anyone to create and ship 3D experiences without any prior technical knowledge. Imagine a powerful 3D no-code editor, which allows high-quality 3D object rendering in real-time, terrain building components, spatial audio integration, and many such advanced features which create a seamless experience in building extended reality environments.",
      "legally_known_as": "Ctruh Technologies Private Limited",
      "industry": "Deeptech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B, B2C",
      "founded_in": "2023",
      "employees_headcount": "21 to 40",
      "team_member_1": "A T Vinay Babu",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/vinayagastya/",
      "revenue_stream": "Other",
      "customer_group": "Clothing & Apparel,  Direct-To-Consumer Brands,  Manufacturing,  Real Estate  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise,  Large Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  Asia Pacific,  Europe,  North America,  South America  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Asia Pacific,  Europe,  North America,  South America  "
  },
  {
      "1": "Verve Ventures",
      "2": "AQTON SESwisscom Ventures",
      "3": "Capagro",
      "startup_name": "ecoRobotix",
      "website": "https://ecorobotix.com/en/our-philosophy/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1kQThNQazYOenEPtehS4rLqVSLJtTkNfG",
      "profile_summary": "Ecorobotix offers precise, reliable and affordable robotic solutions, which make farmers’ lives easier in producing healthy food. They develop, manufacture and commercialise innovating, energy-saving farming machines, which allow both the ecological impact of modern farming and its costs to be reduced. We thus contribute to the emergence of farming which respects the environment, focused on preserving soils and hydrological resources, by using a minimal amount of energy.",
      "legally_known_as": "Ecorobotix SA",
      "industry": "Deeptech",
      "based_out_of": "Switzerland, Global",
      "business_model": "B2B",
      "employees_headcount": "61 to 100",
      "team_member_1": "Aurelien G. Demaurex",
      "position": "CTO and Co-Founder",
      "profile_link": "https://www.linkedin.com/in/steve-tanner-86a70617/",
      "team_member_2": "Steve Tanner",
      "total_funding_millions": "80.6"
  },
  {
      "1": "Zoho",
      "2": "ecoRobotix",
      "startup_name": "Genrobotics",
      "website": "https://www.genrobotics.org/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lMDaGD4299aLmf5ITzZ32SEKyPM89VS0",
      "profile_summary": "Genrobotics was founded in 2015 by Vimal Govind MK, Arun George, Nikhil NP and Rashid Bin Abdulla Khan. The company’s flagship product is the Bandicoot robot which helps clean confined spaces such as sewer manholes, sewer wells, storm water manholes, oily water sewers and storm water sewers in refineries. The company has also ventured into the healthcare space with the launch of G Gaiter – a robot-assisted gait training solution for recovering paraplegics.",
      "legally_known_as": "Genrobotics Innovation Pvt Ltd",
      "industry": "Deeptech",
      "based_out_of": "Kerala, India",
      "founded_in": "2015",
      "employees_headcount": "61 to 100",
      "team_member_1": "Vimal Govind MK",
      "position": "Co-Founder & Director",
      "profile_link": "https://www.linkedin.com/in/nikhil-np-7539a5137/",
      "team_member_2": "Arun George",
      "team_member_3": "Rashid K",
      "team_member_4": "Nikhil NP",
      "total_funding_millions": "2.6"
  },
  {
      "1": "OMERS Ventures",
      "startup_name": "Falkon",
      "website": "https://www.falkon.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lME_VWDwhbOXwz0PbBjngVP3cYbl_We3",
      "profile_summary": "Maximize revenue by streamlining the entire revenue life cycle. Break down tool and team silos to understand the biggest opportunities and threats to generate pipeline, and acquire, expand, and retain customers. While automating non-revenue generating activities to dramatically increase team productivity and customer outcomes. Innovative companies like Zendesk, Redis, Delphix, Seclore, and Entrust trust Falkon to make their forecast a reality. With Falkon, customers see an increase in pipeline generated and net revenue retention. Hitting their revenue targets within 3% of accuracy.",
      "legally_known_as": "Falkon AI, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "41 to 60",
      "team_member_1": "Mona Akmal",
      "position": "Chief Operating Officer",
      "profile_link": "https://www.linkedin.com/in/rick-negrin/",
      "team_member_2": "Aakash Kambuj",
      "team_member_3": "Rick Negrin",
      "total_funding_millions": "16"
  },
  {
      "startup_name": "Element Data",
      "website": "https://elementdata.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lMVQNDMChoN0uIMQpP1uBio_cU_ZzvZ-",
      "profile_summary": "Decision Cloud is a platform that Element Data is developing that combines decision intelligence, machine learning, and artificial intelligence. By measuring decision-making, it reveals patterns and behaviours to forecast and affect future outcomes.",
      "legally_known_as": "Element Data",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2016",
      "employees_headcount": "21 to 40",
      "team_member_1": "Charles Davis",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/charles-davis-942818137/"
  },
  {
      "1": "SignalFire",
      "2": "National Science Foundation",
      "startup_name": "Docugami",
      "website": "https://www.docugami.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lPtMV1jyM3UUGjpAedGgdiflKZW-DifE",
      "profile_summary": "Docugami is a Seattle-area document engineering company that transforms how businesses create and execute critical business documents. Applying breakthrough artificial intelligence to unstructured and semi-structured information, Docugami enables organizations to radically improve productivity, compliance and insight. In February 2020 Docugami secured a $10 million seed funding round led by Silicon Valley venture fund SignalFire, with participation by NextWorld Capital, Grammarly and others. Docugami CEO Jean Paoli, a pioneer in the field of document engineering, is one of the inventors of XML and during his time at Microsoft started several billion-dollar businesses for the company. Founded in March 2018 by Paoli and four other senior engineering leaders from Microsoft, Docugami has created a SaaS solution that harnesses a wide range of artificial intelligence techniques, including natural language processing, image recognition, declarative markup, and other approaches. The technology enables businesses of all sizes to create documents with greater precision and efficiency and to derive greater productivity, compliance, and insight from document information. Learn more at our Open Letter. In February 2020, Bob Muglia, former CEO of Snowflake and former head of both Microsoft’s Office and Azure businesses joined the company as a major investor and board member. Ilya Kirnos, CTO and co-founder of SignalFire is also a member of Docugami’s board.",
      "legally_known_as": "Docugami Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2017",
      "employees_headcount": "21 to 40",
      "team_member_1": "Andrew Begun",
      "position": "Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/jeanpaoli/",
      "team_member_2": "Taqi Jaffri",
      "team_member_3": "Mike Palmer",
      "team_member_4": "Alan Yates",
      "team_member_5": "Jean Paoli",
      "total_funding_millions": "11.9"
  },
  {
      "1": "Evolution Equity Partners",
      "2": "SWAN Venture Fund",
      "startup_name": "DefinedCrowd",
      "website": "https://www.definedcrowd.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lRhHEhMlOJj53P_I1Dpy2jBt7tbyXW82",
      "profile_summary": "Defined.ai is enabling AI creators of the future, from those building their first boot-strapped models, to those fine-tuning complex AI projects. Defined.ai hosts the leading online marketplace where it's possible to buy, sell or commission off-the-shelf or custom-ordered AI data, tools and models, and offers professional services to help deliver success in complex machine learning projects. Defined.ai is a community of AI professionals working together to build fair, accessible, inclusive and ethical AI of the future.",
      "legally_known_as": "DefinedCrowd Corporation",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2015",
      "employees_headcount": "151 to 250",
      "team_member_1": "Daniela Braga",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/danielabragaspeech/",
      "total_funding_millions": "78.6"
  },
  {
      "1": "KTB Ventures",
      "2": "GGV Capital",
      "3": "Infotech Ventures",
      "4": "Wangsu Science & Technology",
      "5": "Tiger Management Corporation",
      "6": "CMC Capital Group",
      "startup_name": "Clobotics",
      "website": "http://www.clobotics.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lRrsyTHKZt4k4q0BMfFapdVzP9i09Opk",
      "profile_summary": "Clobotics is a global leader in intelligent computer vision solutions for the retail and wind. Clobotics’ end-to-end solutions combine computer vision, artificial intelligence/machine learning, and data analytics software with different hardware form factors, including autonomous drones, mobile applications and other IoT devices to help companies automate time-intensive operational processes, increase efficiencies and boost the bottom line through the use of real-time, data-driven insights and analysis.",
      "legally_known_as": "Clobotics",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2016",
      "employees_headcount": "61 to 100",
      "team_member_1": "George Yan",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/yanke1/",
      "team_member_2": "Claire Chen",
      "team_member_3": "Yan Ke",
      "total_funding_millions": "63"
  },
  {
      "startup_name": "Civet AI",
      "website": "https://civet.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lWqmiZw-q3iZrGxTb1YWr4w6VHSjk-2p",
      "profile_summary": "Civet AI helps product owners and CX leaders better understand their customers with an easy-to-use and effective analytics service for their open-ended customer feedback. With Civet AI, our clients can easily extract the valuable insights from open-ended customer feedback, and answer important questions about their customers, such as what do they care about most? How to be more efficient on improving NPS/CSAT? With latest AI technologies, Civet AI enables clients to extract the valuable insights in a matter of minutes.",
      "legally_known_as": "Civet AI",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10"
  },
  {
      "startup_name": "Udyog Technologies",
      "website": "https://udyogtech.netlify.app/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mkfOwh5BcFgSBOPxB9Az8Jut32Q8Ad_v",
      "profile_summary": "Udyog Technologies are a B2B Marketplace for all the Pharmacies, Hospitals, Nursing Homes and Doctors. They are enabling the Healthcare-Industry with the help of Tech. Bringing a revolution to the healthcare of India. Filling the severe gaps of this Industry with the help of their Technology. Making healthcare accessible and affordable for people.",
      "legally_known_as": "ANANTASIA PHARMA PVT. LIMITED",
      "industry": "Healthtech",
      "based_out_of": "Odisha, India",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "11 to 20",
      "team_member_1": "Dev Shah",
      "position": "Co-Founder & COO",
      "profile_link": "https://www.linkedin.com/in/aayush-raj-906562251/",
      "team_member_2": "Shouryjeet Gupta",
      "team_member_3": "Aayush Raj",
      "revenue_stream": "Commission on transaction, E-commerce - Product, E-commerce - Service, Fee-For-Service",
      "customer_group": "Blockchain,  Community,  E-Commerce,  Enterprise Tech  ",
      "client_business_structure": "Startup,  Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3  "
  },
  {
      "startup_name": "Propliners reality",
      "website": "https://propliners.in/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lZbiygitlJh6PIoJfQptaTduvGboP0R0",
      "profile_summary": "Propliners reality takes complete care of their investors and buyers as we are taking the properties for their own selves thus they take care of all aspects related to the properties like legal-check, proper documentation as per the local authority norms and most important guaranteed best price/rentals. They are working in this industry from long time hence most of their developers are well known to us hence we have the largest and updated property portfolio for all your real estate needs.",
      "legally_known_as": "Propliners reality",
      "industry": "Deeptech",
      "based_out_of": "Uttar Pradesh, India",
      "business_model": "B2B",
      "founded_in": "2018",
      "employees_headcount": "<10",
      "team_member_1": "Achal Agarwal",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/himanshu-propliners-realty-noida/",
      "team_member_2": "Himanshu Propliners",
      "revenue_stream": "Advertising, Affiliate Marketing, E-commerce - Service, Lending",
      "customer_group": "Administrative Services,  Consumer,  Services,  Media,  E-Commerce  ",
      "client_business_structure": "Small Enterprise",
      "geographical_segment": "India"
  },
  {
      "1": "Tao Capital Partners",
      "2": "Valor Siren Ventures",
      "startup_name": "Brightloom",
      "website": "https://www.brightloom.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1ldGr_1bnmlWne6auAJcV4dvAFo1CS-e_",
      "profile_summary": "Through our first-of-its-kind Customer Growth Platform, we provide consumer brands of all sizes with a turnkey personalized customer engagement solution that is optimized by machine learning and scaled through automation. Brightloom’s suite of cloud-based software services enables brands to unlock the power of the data they already have and transform it into personalized marketing that maximizes customer spend. Brightloom is headquartered in Seattle, with a satellite office in San Francisco.",
      "legally_known_as": "Keenwawa, inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2014",
      "employees_headcount": "41 to 60",
      "team_member_1": "Kellie Zimmerman",
      "position": "Chief Executive Officer",
      "profile_link": "https://www.linkedin.com/in/kelliezimmerman/",
      "total_funding_millions": "455.5"
  },
  {
      "1": "B Capital Group",
      "2": "Oregon Venture Fund",
      "3": "Seven Peaks Ventures",
      "4": "Concord Health Partners",
      "startup_name": "Bright.md",
      "website": "http://bright.md/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lbtcj4OEt8qn3zDZc_Vy1FivokWfAUlX",
      "profile_summary": "Making Healthy Happen Faster. Bright.md is the leading virtual care solution trusted by health systems to automate clinical workflows and administrative tasks, improving patient and provider engagement and driving operational efficiency. With its pioneering technology, Bright.md improves how health systems deliver care, from patient acquisition through clinical interview and treatment, to reduce 90% of administrative workflows, lower patient wait-times to 6 minutes on average, and drive patient loyalty with industry-leading satisfaction ratings. Founded in 2014 and based in Portland, Oregon, Bright.md has been named a Gartner Cool Vendor in Healthcare, a Vendor to Watch by Chilmark Research, and is the preferred choice of AVIA’s Virtual Access cohort. Bright.md is backed by B Capital Group, Seven Peaks Ventures, Pritzker Group Venture Capital, Philips Health Technology Ventures, UnityPoint Health, and Concord Health Partners.",
      "legally_known_as": "Bright.md, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global ",
      "business_model": "B2C",
      "founded_in": "2014",
      "employees_headcount": "41 to 60",
      "team_member_1": "Ray Costantini",
      "position": "Co-Founder and Chief Strategy Officer",
      "profile_link": "https://www.linkedin.com/in/swinth/",
      "team_member_2": "Mark Swinth",
      "revenue_stream": "Fee-For-Service",
      "total_funding_millions": "29.2"
  },
  {
      "1": "GTD Capital LLC",
      "startup_name": "BoldIQ",
      "website": "http://boldiq.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1leOU83DUrPdA6i6rD-peAbwIReUwNVdH",
      "profile_summary": "BoldIQ’s Solver optimizes scheduling and addresses disruption management challenges with instant availability checks, targeted disruption recovery, and configurable requests for business aviation, logistics and other complex industries. These customers free up to 30% of their resources and reduce operating costs by up to 25%, resulting in an increase in profitability of up to 20%. To automatically optimize schedules in real-time, all the time, BoldIQ’s unique solution intelligently incorporates all relevant business rules, constraints, and goals to support optimal utilization and enable seamless scalability across mission-critical personnel and physical assets. Moreover, BoldIQ’s unique approach to optimized scheduling mitigates disruptions and human errors while maintaining compliance requirements, demand goals and operational efficiencies that drive growth and profitability.",
      "legally_known_as": "BoldIQ, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2008",
      "employees_headcount": "21 to 40",
      "team_member_1": "Shelly Freeman",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/eugene-taits-a346156/",
      "team_member_2": "Eugene Taits",
      "total_funding_millions": "5.6"
  },
  {
      "1": "Qualcomm Ventures",
      "2": "Kernel Labs",
      "3": "Foothill Ventures",
      "startup_name": "Blue Canoe Learning",
      "website": "http://bluecanoelearning.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lf89nGxBaNn3K0ZwI3DgOUefJSHbilri",
      "profile_summary": "Award-winning Blue Canoe is the #1 app for spoken English recommended by ESL teachers around the world. Blue Canoe uses a proven brain-science based methodology and applies AI and speech recognition. Blue Canoe's mobile app exercises and games to help non-native English speakers improve their and confidence and clarity when speaking English in only 10 minutes a day. Download the app from the Apple App Store or Google Play store to start for free with great limited access with the option to upgrade to a Premium plan with access to thousands of words and hundreds of sentences to practice.",
      "legally_known_as": "Blue Canoe Learning, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2C",
      "founded_in": "2016",
      "employees_headcount": "<10",
      "team_member_1": "Riley Findley",
      "position": "Director of Global Business Development",
      "profile_link": "https://www.linkedin.com/in/andrewpsanders/",
      "team_member_2": "Karen Taylor de Caballero",
      "team_member_3": "Andrew Sanders",
      "revenue_stream": "Subscription",
      "total_funding_millions": "4.4"
  },
  {
      "1": "NeoTribe Ventures",
      "startup_name": "ThirdAI",
      "website": "https://www.thirdai.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lhNijBmiCcmTCplk8j_m9UmzGJX4pWHm",
      "profile_summary": "ThirdAI is on a mission to make sophisticated large language models (LLMs) and other cutting-edge AI technologies accessible for everyone. Our goal is to build customized, private AI that is trained on commodity hardware with ultra-low latency inference for every organization.",
      "legally_known_as": "ThirdAI",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2021",
      "employees_headcount": "21 to 40",
      "team_member_1": "Shrivastava Anshumali",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/tharunmedini/",
      "team_member_2": "Tharun Kumar Reddy Medini",
      "total_funding_millions": "6"
  },
  {
      "startup_name": "Bend.ai",
      "website": "https://bend.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1lvhGXwn2IXFLicv80K6c9DTk6zpfZcl9",
      "profile_summary": "Bend.ai is an esteemed, specialized research firm with a focused orientation towards the AI/ML and Data Science markets, renowned for delivering robust market research, deeply insightful market analyses, and meticulous marketing research. In this evolving digital landscape, where the interplay between AI, Machine Learning, and Data Science is crucial, Bend.ai is adept at interpreting complex market trends, identifying opportunities, and providing actionable insights that help clients navigate the increasingly data-driven world. Bend.ai provides market insights and it crafts strategic narratives to propel businesses forward in a world increasingly driven by artificial intelligence and data science.",
      "legally_known_as": "Bend.ai LLC",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Christina Stein",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/christina-stein-b2526b28/"
  },
  {
      "1": "Kingdom Capital",
      "2": "Ignition Partners",
      "startup_name": "AstrumU",
      "website": "http://www.astrumu.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1m1tD5MDGHmpurS8qGXEke_EoJj8d9TBI",
      "profile_summary": "AstrumU is leveling the playing field between learning + working. Using AI/ML our AI Translation Engine measures an individuals skills, maps these skills to in-demand job roles and recommends possible upskilling opportunities.",
      "legally_known_as": "AstrumU Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2017",
      "employees_headcount": "41 to 60",
      "team_member_1": "Adam Wray",
      "position": "Chief Revenue Officer",
      "profile_link": "https://www.linkedin.com/in/rob-gillespie-76603324/",
      "team_member_2": "Kaj Pedersen",
      "team_member_3": "Rob Gillespie",
      "total_funding_millions": "13.2"
  },
  {
      "startup_name": "Airship Industries",
      "website": "http://www.airshipvms.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1m8ZG7V5iBGjyFzx0pwNQozjVw0JNxDKF",
      "profile_summary": "Privately held and headquartered in Redmond, WA, Airship AI develops and provides AI driven video and sensor management software and services to a wide range of government, law enforcement, and commercial customers across the globe. Airship’s engineering, technical, and support teams are all US Citizens and US based, allowing Airship to provide NDAA compliant hardware and software solutions to customers operating in highly secure enterprise environments. Airship’s software ecosystem run on-premises, in hybrid environments, and in the cloud, allowing Airship to be wherever our customers’ needs us to be, with access provided through thick, thin, and mobile clients.",
      "legally_known_as": "Airship AI Holdings, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2010",
      "employees_headcount": "41 to 60",
      "team_member_1": "Victor Huang",
      "position": "President",
      "profile_link": "https://www.linkedin.com/in/paul-allen-25271773/",
      "team_member_2": "Paul Allen"
  },
  {
      "1": "Kent L. Johnson",
      "startup_name": "AI Dynamics",
      "website": "https://aidynamics.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1m8o0087ZujsNPpG6ZU5628jJqwKR9GIX",
      "profile_summary": "AI Dynamics Inc., formerly DimensionalMechanics Inc., is a global organization founded on the belief that everyone should have access to the power of artificial intelligence (AI) to change the world. The company's NeoPulse® Framework is a robust end to end AI platform for experts and non-experts alike, transforming how organizations innovate and grow, at a fraction of the cost, expertise, or resources traditionally associated with artificial intelligence. AI Dynamics has created the only AI platform that provides data governance and can be deployed on any on-premise device, cloud, or IoT environment. Founded in 2015 and based in Bellevue, WA, AI Dynamics is making AI attainable and scalable for leading research institutions and large enterprises in industries such as Biotech, Bioinformatics, Healthcare, Industry 4.0, and Pharmaceutical.",
      "legally_known_as": "AI Dynamics Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2015",
      "employees_headcount": "21 to 40",
      "team_member_1": "Rajeev Dutt",
      "position": "CEO and President",
      "profile_link": "https://www.linkedin.com/in/rajeevdutt/",
      "total_funding_millions": "10.1"
  },
  {
      "startup_name": "Keshcutai",
      "website": "https://www.keshcutai.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mNDE8AAIThh7TW9e09OYoEGnPpun5mUA",
      "profile_summary": "Keshcutai specializes in conscious AI for developing robots. Conscious AI refers to AI systems that have a sense of self-awareness and are capable of making decisions based on their own experiences and understanding of the world.",
      "legally_known_as": "Keshcutai",
      "industry": "Deeptech",
      "based_out_of": "Maharashtra, India",
      "business_model": "B2C",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Ashutosh Prabhakar",
      "position": "Co-Founder",
      "revenue_stream": "Physical Commerce,Subscription",
      "customer_group": "DeepTech,  Hardware,  Machine Learning,  Technology  ",
      "client_business_structure": "Startup",
      "geographical_segment": "India,  India Tier 1,  India Tier 2,  India Tier 3,  Global,  Asia Pacific,  Europe,  North America,  South America,  Africa  ",
      "age_group": "Less than 18,  18 to 25,  26 to 34,  35 to 45  ",
      "income_bracket": "Lower Income,  Lower-middle Income,  Upper-middle Income,  High Income  ",
      "user_belongs_to": "Africa,  Asia Pacific,  North America,  South America,  Europe,  India,  India Tier 1,  India Tier 2,  India Tier 3,  Global  "
  },
  {
      "1": "General Atlantic",
      "2": "Notion Capital",
      "3": "Seed Capital",
      "4": "Project A Ventures",
      "startup_name": "Dixa",
      "website": "http://dixa.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mVrkFVp2e_yRROnOZ4lhXt7e4EqQokPx",
      "profile_summary": "Dixa enables companies to deliver customer service as it is meant to be. We help customer service leaders to create effortless experiences for customers and teams that unlock loyalty. Dixa’s Conversational Customer Service Platform combines powerful AI with a human touch to deliver a highly-personalized service experience that scales. Our dedicated customer success team ensures you are up and running quickly. Dixa powers more than 30 million conversations a year and is trusted by leading brands such as Interflora, On, Wise, Deezer, Rapha, Too Good to Go, Hello Print, and Wistia. Learn more by visiting dixa.com.",
      "legally_known_as": "Dixa ApS",
      "industry": "Deeptech",
      "based_out_of": "Denmark, Global",
      "business_model": "B2B, B2C",
      "founded_in": "2015",
      "employees_headcount": "251 to 500",
      "team_member_1": "Mads Fosselius",
      "position": "Co-Founder and CTO",
      "profile_link": "https://www.linkedin.com/in/jakobnederby/",
      "team_member_2": "Krisztian Tabori",
      "team_member_3": "Jacob Vous Petersen",
      "team_member_4": "Jakob Nederby Nielsen",
      "total_funding_millions": "157.9"
  },
  {
      "1": "Act Venture Capital",
      "2": "Enterprise Ireland",
      "3": "Irrus Investments",
      "4": "Seroba Life Sciences",
      "startup_name": "Deciphex",
      "website": "http://deciphex.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mZ-KoklKdhYIlx2FXo-LrmmOE-BfgNCF",
      "profile_summary": "Deciphex is focused on the development of software applications for preclinical/toxicologic pathology that improve productivity and help pathologists focus on the critical content. The pathology industry is beset with increasing caseload and diagnostic complexity, against a globally declining number of pathologists to service the workload. We believe that digital pathology combined with computer aided diagnostic tools will help make pathologists work more efficiently in the future, and offers a solution to the pending crisis in supply/demand in pathology services.",
      "legally_known_as": "Deciphex Ltd",
      "industry": "Deeptech",
      "based_out_of": "Ireland, Global",
      "business_model": "B2B",
      "founded_in": "2017",
      "employees_headcount": "101 to 150",
      "team_member_1": "Donal O'Shea",
      "position": "Research Director",
      "profile_link": "https://www.linkedin.com/in/mark-gregson-b5655b9/",
      "team_member_2": "Mairin Rafferty",
      "team_member_3": "Mark Gregson",
      "total_funding_millions": "24.6"
  },
  {
      "1": "Aker",
      "2": "TCV",
      "3": "Saudi Aramco Energy Ventures",
      "4": "Accel",
      "startup_name": "Cognite",
      "website": "http://cognite.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1md0ROLED_RxMGSqhaeD6XSOKeC_hAe_F",
      "profile_summary": "Founded in 2016, Cognite quickly made a name for itself as a band of digital disruptors helping legacy industries transform. Our team united to take the industrial world by storm with technology, from robotics to 3D to advanced cloud computing. Working closely with our customers, we empowered industrial users with insightful access to contextualized data for the first time. Over and over we created customer value. Along with our leading Industrial DataOps platform and core technology, Cognite Data Fusion®, we've got a fast-growing team strong enough to weather a pandemic and continue to generate maximum impact with our products.",
      "legally_known_as": "Cognite AS",
      "industry": "Deeptech",
      "based_out_of": "Norway, Global",
      "business_model": "B2B",
      "founded_in": "2016",
      "employees_headcount": "501 to 1000",
      "team_member_1": "Stein Danielsen",
      "position": "Co-Founder & Principal Architect",
      "profile_link": "https://www.linkedin.com/in/fredrik-anfinsen/",
      "team_member_2": "Geir K. Engdahl",
      "team_member_3": "John M. Lervik",
      "team_member_4": "Fredrik Anfinsen",
      "total_funding_millions": "338.2"
  },
  {
      "1": "Kindred Capital",
      "2": "Lakestar",
      "3": "Capnamic Ventures",
      "startup_name": "Dexory",
      "website": "http://botsandus.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mnU_rWwoE5vXiH4IvG4odaAfYrMm8cr7",
      "profile_summary": "Dexory captures real-time insights of warehouse operations using fully autonomous robots and Artificial Intelligence. Dexory is paving the way for AI and robotics to completely transform the world of logistics, automating data collection and building digital twin technology that unlocks insights across all levels of warehouse operations. It is theonly platform that delivers real-time information for the receiving, storing, and dispatch stages, allowing companies unprecedented access to their operations from anywhere and at any time. The fully autonomous mobile and modular robots measure, track, and find goods across warehouses without workflow disruption. The data is fed real time into digital twins, allowing logistics and warehouses to quickly respond to operational challenges they face on a daily basis.",
      "legally_known_as": "Botsandus Ltd",
      "industry": "Deeptech",
      "based_out_of": "United Kingdom, Global",
      "founded_in": "2015",
      "employees_headcount": "41 to 60",
      "team_member_1": "Andrei Danescu",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/adrianleonardnegoita/",
      "team_member_2": "Oana Andreea Jinga",
      "team_member_3": "Adrian Negoita",
      "total_funding_millions": "19"
  },
  {
      "1": "Upsparks",
      "2": "Chirate",
      "startup_name": "Keploy",
      "website": "https://keploy.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mkfOwh5BcFgSBOPxB9Az8Jut32Q8Ad_v",
      "profile_summary": "Keploy is an AI-powered, open-source API test generation platform that enables developers to quickly achieve 90% test coverage and generate test data on the fly. It's like having a TestGPT at your disposal, empowering developers to save time and streamline their testing process.elopers to achieve 90% test coverage in 2 minutes.",
      "legally_known_as": "Hybridk8s Private Limited",
      "industry": "Deeptech",
      "based_out_of": "Karnataka, India",
      "business_model": "B2B",
      "founded_in": "2022",
      "employees_headcount": "11 to 20",
      "team_member_1": "Shubham Jain",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/neha-gup",
      "team_member_2": "Neha Gupta",
      "revenue_stream": "Freemium, Subscription",
      "customer_group": "DeepTech,  Technology,  SaaS,  Enterprise Tech  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise  ",
      "geographical_segment": "India,  North America  "
  },
  {
      "1": "Exfinity Venture Partners",
      "2": "IAN Fund",
      "3": "WestBridge Capital",
      "startup_name": "Skit.ai",
      "website": "http://skit.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mnYWy466L2KcXykLFVC4whMn2ki_PAGE",
      "profile_summary": "Skit.ai is the leading Conversational Voice AI solution provider in the ARM industry, empowering collection agencies to streamline and accelerate revenue recovery. By automating a million debt collection calls per week, Skit.ai is empowering debt collection agencies across the U.S. with automated, intelligent, and effective consumer conversations at scale. Combining the power of our proprietary technology and in-depth domain expertise with Generative AI, our solution delivers unmatchable business outcomes by solving all the core challenges debt collection agencies face.",
      "legally_known_as": "Cyllid Technologies Pvt Ltd",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2016",
      "employees_headcount": "251 to 500",
      "team_member_1": "Manoj Sarda",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/sourabhsg/",
      "team_member_2": "Akshay Deshraj",
      "team_member_3": "Sourabh Gupta",
      "total_funding_millions": "28.1"
  },
  {
      "1": "Ronny Conway",
      "2": "Addition",
      "3": "Betaworks",
      "4": "Lux Capital",
      "startup_name": "Hugging Face",
      "website": "https://huggingface.co/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1moLre9Q1xJBt3axzeRuIDmpvHWoV1vk-",
      "profile_summary": "Hugging Face is an open-source and platform provider of machine learning technologies. Hugging Face was launched in 2016 and is headquartered in New York City.",
      "legally_known_as": "Hugging Face, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2016",
      "employees_headcount": "151 to 250",
      "team_member_1": "Thomas Wolf",
      "position": "Co-Founder and CEO",
      "profile_link": "https://www.linkedin.com/in/clementdelangue/",
      "team_member_2": "Julien Chaumond",
      "team_member_3": "Clem Delangue",
      "total_funding_millions": "160.2"
  },
  {
      "1": "O'Shaughnessy Asset Management",
      "2": "Lightspeed Venture Partners",
      "3": "Coatue Management",
      "startup_name": "Stability.AI",
      "website": "https://stability.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mp9vFAv5SAOg1OrjV9ooMNEmRxHzU8LM",
      "profile_summary": "Stability AI is an AI-driven visual art startup that designs and implements an open AI tool to create images based on text input given. This tool executes text commands into images and other forms of media using collective intelligence and augmented technology to help clients develop cutting-edge open AI models for image, language, audio, video,3D, and biology.",
      "legally_known_as": "Stability AI Ltd",
      "industry": "Deeptech",
      "based_out_of": "United Kingdom, Global",
      "business_model": "B2B",
      "founded_in": "2019",
      "employees_headcount": "101 to 150",
      "team_member_1": "Emad Mostaque",
      "position": "Co-Founder",
      "total_funding_millions": "111"
  },
  {
      "startup_name": "Mankind Legacy",
      "website": "https://www.mankindlegacy.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mso_cv9ifgyyfup3chxo21YMBaDqiI6p",
      "profile_summary": "Mankind Legacy is a team that has set ourselves the goal of developing and creating the future heritage of humanity as a single entity. We are focused on maximizing development impact. Our first project is Argus. Argus is an autonomous emergency control system of any spectrum, based on drones. Argus is not intended to interfere with privacy or permanently record actions. Our project is focused on the \"Real Time\" system. The Argus network will be tied primarily to all special services (Police, doctors, Ministry of Emergency Situations), which will primarily monitor the situation throughout the patrolling location. Every year in every country in the world, dozens, if not hundreds of thousands of incidents occur, and in most cases, everything is aimed at eliminating the consequences, but our Argus system is designed to prevent these consequences in the first place and maximize the speed of response to eliminating the consequences if any to be. Tens of thousands of people die in all countries of the world every year, just because trouble catches them only in places where people are rarely found, or because of the indifference of passers-by. Our system will not allow itself this and will respond instantly by pinging the necessary service in different situations, as well as being nearby if necessary to control the situation. This is only a small part of our first project, we will introduce you to it in more detail during the development process.",
      "legally_known_as": "Mankind Legacy, Inc",
      "industry": "Deeptech",
      "based_out_of": "Ukraine, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Vladimir Serb",
      "position": "Co-Founder",
      "team_member_2": "Yevgeniy Adamovskiy"
  },
  {
      "startup_name": "Flukebox",
      "website": "https://flukebox.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mw24Qf0Y9JGYSk-XXqMDtgXgg7hmUlQ-",
      "profile_summary": "Flukebox caffeinated engineers are working on something BIG in the space of robotics, AI and automation.",
      "legally_known_as": "Flukebox, Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Ar Hakobyan",
      "position": "Technical Advisor",
      "profile_link": "https://www.linkedin.com/in/ar-hakobyan/"
  },
  {
      "startup_name": "Dapster AI",
      "website": "http://www.dapster.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mxF_YAZVXQwfwoXfGQeHogN4FD0V4HaP",
      "profile_summary": "Developer of AI-fueled touchless pick designed for the case-picking robot. The company's platform specializes in advanced picking capability, barcode-reading, and real-time inventory tracking, loading, and palletization, enabling clients with on-demand availability and maximizing productive hours.",
      "legally_known_as": "Dapster AI Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2020",
      "employees_headcount": "<10",
      "team_member_1": "Scott Thomas",
      "position": "Co-Founder",
      "profile_link": "https://www.linkedin.com/in/rameshsekhar",
      "team_member_2": "Ramesh Sekhar"
  },
  {
      "startup_name": "mvmtAi",
      "website": "https://mvmtai.com/health",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1mzFwpXdGnOflSEveNjtUDIm1-ePiPAAt",
      "profile_summary": "Solving for future of medicine and accessible technologies. Machine Learning, Computer Vision, Robotics, IoT, Mobile App, Information Technology, Computer Software, Health, Wellness, Healthcare.",
      "legally_known_as": "mvmtAi",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "founded_in": "2019",
      "employees_headcount": "<10"
  },
  {
      "startup_name": "Seventh Sense",
      "website": "http://www.seventhsense.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nJSuQ7cXi__lzxplOJ-QI_oorM3bZWCl",
      "profile_summary": "Seventh Sense specializes in encrypted, offline edge appliances, performing AI-based computer vision tasks for the defense, security, surveillance & retail markets. Our solutions make the world a safer and smarter place.",
      "legally_known_as": "Seventh Sense Artificial Intelligence Pvt Ltd",
      "industry": "Deeptech",
      "based_out_of": "Singapore, Global",
      "founded_in": "2017",
      "employees_headcount": "21 to 40",
      "team_member_1": "Varun Chatterji",
      "position": "Co-Founder & CTO",
      "profile_link": "https://www.linkedin.com/in/vchatterji/"
  },
  {
      "startup_name": "Skills Caravan",
      "website": "https://www.skillscaravan.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nNXBp6v9N19tYEJUsOgvlFsCXI2LiSv0",
      "profile_summary": "Skills Caravan, the cutting-edge Learning Experience Platform that provides an AI mentor to enhance your learning experience. With LMS, live classroom, offline training, external content, gamification, social learning, and analytics, Skills Caravan is the ultimate learning solution. The AI mentor feature augments and enhances your intelligence, providing you with hidden insights into your learning material. With a personalized search, you can easily access world-class content libraries from leading providers like Udemy, Skillsoft, and edX, etc. Whether you have highly specific needs or want to test your workforce's skill competency against industry standards, Skills Caravan has got you covered. Don't just learn; progress with Skills Caravan's AI mentor.",
      "legally_known_as": "Skills Caravan LXP",
      "industry": "Deeptech",
      "based_out_of": "Haryana, India",
      "business_model": "B2C",
      "founded_in": "2022",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sarita Chand",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/sarita-chand-42a57722/",
      "revenue_stream": "Subscription",
      "series_name": "SEED",
      "date": "12th June 2023",
      "name_of_investors": "Abhishek poddar, Deep Bajaj, Paras Berwala "
  },
  {
      "startup_name": "Tikkly",
      "website": "http://www.tikkly.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nD1YVzL7clr8PLkXhbWdBF6BmGh8CK_U",
      "profile_summary": "Tikkly Agro Solutions is dedicated to work towards flourishing the agriculture in developing nations using cutting edge technological solutions for advancement for different stakeholders. Segritech is a key project under the company which aims to make the post harvest processes easy and efficient for farmers and traders using AI.",
      "legally_known_as": "Tikkly Agro Solutions Private Limited",
      "industry": "Deeptech",
      "based_out_of": "Rajasthan, India",
      "founded_in": "2020",
      "employees_headcount": "21 to 40",
      "team_member_1": "Vijay Pratap Singh",
      "position": "Director",
      "profile_link": "https://www.linkedin.com/in/hetendra-singh-rathore/",
      "team_member_2": "Hetendra Singh Rathore"
  },
  {
      "startup_name": "Waanee AI",
      "website": "https://waanee.ai/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nGj95KmyXMAix4dDytN_IG-E5sT_47WB",
      "profile_summary": "Waanee AI is a US-based dedicated startup focused on developing an AI aggregator platform for building customer experience utilities. The patent-pending platform enables seamless transitions between various Generative AI and speech models, empowering contact centers with debt-free solutions. It offers an array of features, including an AI-powered Interactive Voice Response (IVR), CRM integration, and a comprehensive suite of Dialer software. This cutting-edge solution harnesses the power of artificial intelligence and natural language processing technologies to elevate customer service and automate call interactions. By utilizing Waanee.ai, contact centers can automate tasks such as audits, coaching, and providing assistance to agents. The remarkable virtual agents developed by Waanee.ai possess the ability to engage with customers in a manner akin to humans, effectively understanding emotions and sentiments during conversations.",
      "legally_known_as": "Waanee AI Inc",
      "industry": "Deeptech",
      "based_out_of": "United States of America, Global",
      "business_model": "B2B",
      "founded_in": "2023",
      "employees_headcount": "<10",
      "team_member_1": "Abhishek Kumar",
      "position": "CTO",
      "profile_link": "https://www.linkedin.com/in/hnakhuva/",
      "team_member_2": "Chandan Kumar",
      "team_member_3": "Saket Saurabh",
      "team_member_4": "Hiren Nakhuva",
      "revenue_stream": "Subscription",
      "customer_group": "Communication,  Insurance,  Life Sciences,  SaaS  ",
      "client_business_structure": "Small Enterprise,  Medium Enterprise,  Large Enterprise",
      "geographical_segment": "India,  Asia Pacific,  North America  "
  },
  {
      "1": "Humax Holdings",
      "2": "Series Ventures",
      "3": "Center for Creative Economy and Innovatio",
      "startup_name": "XYZ",
      "website": "https://www.xyzcorp.io/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1n1A1QwByrO5cbeCvG_zZirZ5I7ev9_-i",
      "profile_summary": "XYZ, Inc. is a service robot startup established based on the vision of “ROBOTS INTO OUR DAILYLIFE”, through artificial intelligence-based robot technology. We research and try various unmanned technologies from retail space to our daily life. We are developing various service robots for retail space automation, such as the barista robot, the ice cream robot , and the food and beverage delivery robot are reflecting. Recently, we are making achievements in collaboration with various major brands such as Porsche Korea, Hyundai Motor Company, and Grand Walkerhill Hotel.",
      "legally_known_as": "XYZ, Inc",
      "industry": "Deeptech",
      "based_out_of": "Korea South, Global",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Sungjae Hwang",
      "position": "Co-Founder & CEO",
      "profile_link": "https://www.linkedin.com/in/romanticjay",
      "total_funding_millions": "11.4"
  },
  {
      "1": "Techstars",
      "startup_name": "Designhubz",
      "website": "http://designhubz.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nPPZeHcwBS7K1z2QI4GCesgZmTgpxZ2L",
      "profile_summary": "Designhubz enables e-commerce retailers to sell their products in 3D & Augmented Reality (AR) on their websites and mobile apps. We build low-cost fully automated robotic rigs that 3D capture and digitize physical inventories with one click. Our SaaS platform automatically integrates with our rigs to enable the captured products to be visualized in 3D & Web XR (browser-based AR). We enable immersive online shopping experiences and let retailers create highly interactive online stores.",
      "legally_known_as": "Designhubz LTD",
      "industry": "Deeptech",
      "based_out_of": "United Arab Emirates, Global",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "team_member_1": "Nadim Habr",
      "position": "Chief Technology Officer",
      "profile_link": "https://www.linkedin.com/in/chady-karlitch/",
      "team_member_2": "Chady Karlitch",
      "total_funding_millions": "1.1"
  },
  {
      "1": "Carthona Capital",
      "2": "BridgeLane Capital",
      "startup_name": "Agerris",
      "website": "https://agerris.com/",
      "image_links_logo": "https://drive.google.com/uc?export=view&id=1nT0P3uideFumX0Bp3LtGl598_aZd8jOS",
      "profile_summary": "Agerris is a field robotics and AI start-up for the agriculture industry, based on technology created at the Australian Centre for Field Robotics, the University of Sydney, where they have been developing air and ground robotic solutions for the agriculture industry since 2005.",
      "legally_known_as": "Agerris Pty Ltd",
      "industry": "Deeptech",
      "based_out_of": "Australia, Global ",
      "founded_in": "2019",
      "employees_headcount": "21 to 40",
      "total_funding_millions": "6.5"
  }
];
export const industryList = [
  { name: "Fintech", logo: "https://drive.google.com/uc?export=view&id=1vVCRr3EAnTMLk0HxZr5QjKZWUzKaknwz", cagr: "14", global: "204.3 Billion", india: "1.2 Billion",key_players:`Paytm,PhonePe,Cred` },
  { name: "Edtech", logo: "https://drive.google.com/uc?export=view&id=1uoqimrL0n0syDSB_51br-ZXvK3Ryh6OB", cagr: "10", global: "252 Billion", india: "0.7 Billion",key_players:`BYJU'S,Vedantu,Unacademy` },
  { name: "Healthtech", logo: "https://drive.google.com/uc?export=view&id=1wYuJ03yUw19Zf13_wtkhz6UB5JUpmZPH", cagr: "16.30", global: "197 Billion", india: "1.94 Billion" ,key_players:`Netmeds,HealthifyMe,cult.fit`},
  { name: "Agritech", logo: "https://drive.google.com/uc?export=view&id=1k_Op__o8QK-rcnBbFMefPR4J9FqOUEix", cagr: "11", global: "50 Billion", india: "0.4 Billion",key_players:`DeHaat,BharatAgri,Ninjacart` },
  { name: "Foodtech", logo: "https://drive.google.com/uc?export=view&id=1mjHG1urdl-fze3e8GWPRzr3tiioyRxsX", cagr: "12", global: "230 Billion", india: "1.5 Billion",key_players:`Swiggy,Zomato,HungerBox` },
  { name: "Spacetech", logo: "https://drive.google.com/uc?export=view&id=1pgTVD1agboDpT3VAi0xrI2Z8UFBWbBxn", cagr: "12", global: "366 Billion", india: "0.3 Billion",key_players:`Aadyah Aerospace,Agnikul,Astrogate Labs` },
  { name: "Deeptech", logo: "https://drive.google.com/uc?export=view&id=13BwHrL1ek3sLxADHShMNCdp2TvE-Rd2_", cagr: "18", global: "13.2 Billion", india: "0.2 Billion" ,key_players:`Genrobotics,Cogknit,Jumper.ai`},
];
