import React, { useCallback, useState } from "react";
import Published from "./published";
import Draft from "./draft";
import Trash from "./trash";
import Create from "../blogs/create";

const AddIndustry = () => {
  const [activeTab, setActiveTab] = useState("Create");
  const tabHandler = useCallback(
    (tab) => {
      console.log("tab", tab);
      setActiveTab(tab);
    },
    [activeTab]
  );

  const activeTabHandler = (tab) => setActiveTab(tab);
  return <><Create/></>;
};

export default AddIndustry;
