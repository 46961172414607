import React, { useMemo, useState } from "react";
import CatagoryCards from "../common/catagory-card";
import { top4TOS } from "../../demo-data/home-data";
import useRedirect from "../../custom-hooks/common/useRedirect";
import { industryList, tosData } from "../../demo-data/tails-of-startups-data";

const colorsArray = [
  "Red",
  "Orange",
  "Amber",
  "Yellow",
  "Lime",
  "Green",
  "Emerald",
  "Teal",
  "Cyan",
  "Sky",
  "Blue",
  "Indigo",
  "Violet",
  "Purple",
  "Fuchsia",
  "Pink",
  "Rose ",
];
const TalesOfStartups = () => {
  const redirectTo = useRedirect();
  const redirectionHandler = (category = "") =>
    redirectTo(`tales-of-startups/${category}`);
  return (
    // <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200 ">
    <div className="rounded-lg m-2 md:m-8 p-4 bg-gray-100 ">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl text-gray-700 font-semibold text-xl md:text-3xl">
          Tales of Startup
        </h1>
        <p
          className="flex justify-center gap-2 content-center text-lg text-gray-700 font-semibold hover:text-green-500 fill-gray-700 hover:fill-green-500"
          onClick={() => redirectionHandler("")}
        >
          <span className="text-xs md:text-lg">View more</span>
          <svg
            className="w-3 md:w-6 h-4 md:h-6 text-xs md:text-lg"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
          </svg> */}
        </p>
      </div>
      {/* <p className="flex text-gray-500 text-lg">
        Here are the lists of most exciting startups in each of the industry
      </p> */}

      <div class="grid grid-cols-1 md:grid-cols-2 cardContainer">
        {industryList.slice(0,4).map((_) => (
          <CatagoryCards _={_} />
        ))}
      </div>
    </div>
  );
};

export default TalesOfStartups;
