import React, { useEffect } from "react";
import useRedirect from "../../../custom-hooks/common/useRedirect";
import CatagoryCards from "../../../components/common/catagory-card";
import { industryList } from "../../../demo-data/tails-of-startups-data";

const Listing = () => {
  const redirectTo = useRedirect();
  const redirectionHandler = (category = "") =>
    redirectTo(`tales-of-startups/${category}`);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        className="container mx-auto bg-gray-200 rounded-xl shadow border p-4 m-10 mb-0"
        data-aos="flip-up"
      >
        <p className="text-3xl text-gray-700 font-bold">Tales of Startups</p>
      </div>
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto p-8 m-10 mt-4">
        {industryList.map((_) => (
          <div data-aos="zoom-in">
            <CatagoryCards _={_} />
          </div>
        ))}
      </div>
    </>
  );
};

export default Listing;
