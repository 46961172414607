import React from "react";
import useRedirect from "../../../custom-hooks/common/useRedirect";
import slide1 from "../../../assets/images/contact-us/contact-us.jpeg";
const colorsArray = [
  "bg-red-200",
  "bg-orange-200",
  "bg-amber-200",
  "bg-yellow-200",
  "bg-lime-200",
  "bg-green-200",
  "bg-emerald-200",
  "bg-teal-200",
  "bg-cyan-200",
  "bg-sky-200",
  "bg-blue-200",
  "bg-indigo-200",
  "bg-violet-200",
  "bg-purple-200",
  "bg-fuchsia-200",
  "bg-pink-200",
  "bg-rose-200",
];

const Listing = () => {
  const redirectTo = useRedirect();
  const redirectionHandler = (category = "") => redirectTo(`blog/${category}`);
  return (
    <>
    <div
        className="container mx-auto bg-gray-200 rounded-xl shadow border p-4 m-10 mb-0"
        data-aos="flip-up"
      >
        <p className="text-3xl text-gray-700 font-bold">Contact us</p>
      </div>
      <div className="w-full flex justify-center ">
        <div className={`p-0 md:p-8 h-full md:h-1/2 w-full md:w-1/2`}>
          <img
            src={slide1}
            className="object-cover rounded-lg object-center mx-auto"
          />
        </div>
      </div>
    </>
  );
};

export default Listing;
