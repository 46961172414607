import React from "react";

const AddGlossary = () => {
  return (
    <>
      <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
        <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
          Add Glossary
        </h2>
        <div className="flex flex-wrap justify-start gap-4 mb-4">
          <label class="grow block">
            <span class="block text-sm font-medium text-slate-700 text-left">
              Startup name
            </span>
            <input
              type="text"
              name="startup_name"
              class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Ex: BYJUS"
            />
          </label>
          <label class="grow block">
            <span class="block text-sm font-medium text-slate-700 text-left">
              Startup Link
            </span>
            <input
              type="text"
              name="startup_link"
              class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Ex: https://xyz.com"
            />
          </label>
          <label class="grow block">
            <span class="block text-sm font-medium text-slate-700 text-left">
              Legal name
            </span>
            <input
              type="text"
              name="legal_name"
              class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Ex: BYJUS"
            />
          </label>
        </div>
        <div className="flex flex-wrap justify-start gap-4 mb-4">
          <label class="grow block">
            <span class="block text-sm font-medium text-slate-700 text-left">
              Startup infornation
            </span>
            <textarea
              rows="5"
              type="text"
              name="startup_name"
              class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 resize-y"
              placeholder="Enter startup description"
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default AddGlossary;
