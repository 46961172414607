import React, { useCallback, useState } from "react";
import Preview from "./preview";
import TextEditor from "./text-editor";
import parse from "html-react-parser";

const CreateBlog = ({ tabHandler }) => {
  const [isPreview, setIsPreview] = useState(false);
  const [blogData, setBlogData] = useState({
    title: "",
    authorName: "",
    description: "",
    featuredImg: null,
    content: "",
  });
  const previewHandler = () => setIsPreview(!isPreview);
  const getBlogData = useCallback(
    (field, value) => setBlogData({ ...blogData, [field]: value }),
    [blogData]
  );
  console.log("blogData", blogData);
  return (
    <>
      <div className="flex justify-end gap-4 align-center mt-4 me-4">
        <span
          className={`text-white bg-gradient-to-r ${
            isPreview ? "from-blue-400" : "from-green-400"
          } ${isPreview ? "via-blue-500" : "via-green-500"} ${
            isPreview ? "to-blue-600" : "to-green-600"
          } hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:${
            isPreview ? "ring-blue-300" : "ring-green-300"
          } dark:focus:${
            isPreview ? "ring-blue-800" : "ring-green-800"
          } shadow-lg ${
            isPreview ? "shadow-blue-500/50" : "shadow-green-500/50"
          } dark:shadow-lg dark:${
            isPreview ? "shadow-blue-800/80" : "shadow-green-800/80"
          } font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2`}
          onClick={previewHandler}
        >
          {isPreview ? "Back to edit" : "Preview"}
        </span>
      </div>
      {isPreview ? (
        <Preview renderData={blogData} />
      ) : (
        <div className="rounded-lg m-2 md:m-8 p-4 bg-gradient-to-r from-green-50 to-cyan-200">
          <h2 className="text-3xl text-gray-700 font-bold mb-5 text-left">
            Add Blogs
          </h2>
          <div class="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <div className="flex flex-wrap justify-start gap-4 mb-4">
                <label class="grow block">
                  <span class="block text-sm font-medium text-slate-700 text-left">
                    Title
                  </span>
                  <input
                    value={blogData?.title}
                    type="text"
                    name="startup_name"
                    class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="Ex: This is my blog"
                    onChange={(e) => getBlogData("title", e.target.value)}
                  />
                </label>

                <label class="grow block">
                  <span class="block text-sm font-medium text-slate-700 text-left">
                    Author
                  </span>
                  <input
                    value={blogData?.authorName}
                    type="text"
                    name="legal_name"
                    class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="Ex: Jhon Doe"
                    onChange={(e) => getBlogData("authorName", e.target.value)}
                  />
                </label>
              </div>
              <div className="flex flex-wrap justify-start gap-4 mb-4">
                <label class="grow block">
                  <span class="block text-sm font-medium text-slate-700 text-left">
                    Description
                  </span>
                  <textarea
                    value={blogData?.description}
                    rows="5"
                    type="text"
                    name="startup_name"
                    class="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 resize-y"
                    placeholder="Enter startup description"
                    onChange={(e) => getBlogData("description", e.target.value)}
                  />
                </label>
              </div>
            </div>
            {!!blogData?.featuredImg ? (
              <img
                src={URL.createObjectURL(blogData?.featuredImg)}
                //   src={'https://drive.google.com/uc?export=view&id=1CIQgjiF6xXdwXrUcqMW-n6o5vvVQAZI5'}
                className="object-cover h-56 w-96 rounded-lg border-2 border-slate-950"
              />
            ) : (
              <div className="">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm text-left font-medium leading-6 text-gray-900"
                >
                  Cover photo
                </label>
                <div className="mt-1 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <svg
                      class="mx-auto h-12 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="white"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span className="p-2">Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={(e) =>
                            getBlogData("featuredImg", e.target.files[0])
                          }
                        />
                      </label>
                      <p className="pl-1 ps-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-center items-center w-full mt-4">
            <TextEditor
              content={blogData?.content?.toString() ?? ""}
              getContent={(data) => getBlogData("content", data)}
            />
          </div>
        </div>
      )}
      <div className="flex justify-end gap-4 m-2 md:m-8 p-4">
        <button
          className="rounded-md bg-gray-400 p-1 px-2 text-white"
          onClick={() => tabHandler("Draft")}
        >
          Save as draft
        </button>
        <button
          className="rounded-md bg-blue-500 p-1 px-2 text-white"
          onClick={() => tabHandler("Published")}
        >
          Publish
        </button>
      </div>
    </>
  );
};

export default CreateBlog;
