import React, { useCallback, useEffect, useRef, useState } from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";
const TOOLBAR_OPTIONS = {
  container: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // [{ font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    // ["image", "blockquote", "code-block"],
    // ["clean"],
  ],
  // handlers: { undo: undoChange, redo: redoChange },
};

const TextEditor = ({ content, getContent }) => {
  const [quill, setQuill] = useState(null);
  // USEREF TO INITIALIZE QUILL IN A PROPER MANNER
  const wrapperRef = useCallback((wrapper) => {
    if (wrapper == null) return;
    wrapper.innerHTML = "";
    const editor = document.createElement("div");
    wrapper.append(editor);
    const get_Quill = new Quill(editor, {
      theme: "snow",
      modules: { toolbar: TOOLBAR_OPTIONS },
    });
    setQuill(get_Quill);
  }, []);
  useEffect(() => {
    if (quill == null) return;

    const handler = () => {
      const delta = quill.getContents();
      const text = quill.getText();
      const html = quill.root.innerHTML;
      console.log("delta", delta);
      console.log("text", text);
      console.log("html", html);
      getContent(html);
      //  setText(text);
      //  setHtml(html);
    };

    quill.on("text-change", handler);
    if (content) {
      quill.root.innerHTML = content;
    }
    return () => {
      quill.off("text-change", handler);
    };
  }, [quill]);
  return <div className="container" ref={wrapperRef}></div>;
};

export default TextEditor;
